import React from "react"

interface KycVerificationAnimationProps {
	altText?: string
}

const KycVerificationAnimation: React.FC<KycVerificationAnimationProps> = ({
	altText = "KYC Verification Animation",
}) => {
	return (
		<div className="w-full flex items-center justify-center">
			<img
				className="w-1/2 hidden dark:block"
				src={"/animations/kyc/darkmode-verification.gif"}
				alt={altText}
			/>
			<img
				className="w-1/2 block dark:hidden"
				src={"/animations/kyc/lightmode-verification.gif"}
				alt={altText}
			/>
		</div>
	)
}

export default KycVerificationAnimation
