import * as React from "react"
import { cn } from "../../lib/utils"
import CopyButton from "./buttons/CopyButton"

export interface TextareaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	copyButton?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, copyButton = false, ...props }, ref) => {
		return (
			<div className="relative">
				<textarea
					className={cn(
						"flex min-h-[100px] w-full rounded-sm border border-border bg-background p-sm text-h6 ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
						className
					)}
					ref={ref}
					{...props}
				/>
				{copyButton && (
					<CopyButton
						variant={"outline"}
						className="absolute bottom-4 right-4 bg-background [&_*]:size-icon"
						textToCopy={props.value?.toString() || ""}
					/>
				)}
			</div>
		)
	}
)
Textarea.displayName = "Textarea"

export { Textarea }