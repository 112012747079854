// useMetamapScript.ts
import { useQuery } from "@tanstack/react-query"

function loadMetamapScript(): Promise<boolean> {
	return new Promise<boolean>((resolve, reject) => {
		// Check if the script is already present in the document
		if (
			document.querySelector(
				'script[src="https://web-button.getmati.com/button.js"]'
			)
		) {
			resolve(true) // Return true if script is already loaded
			return
		}

		const script = document.createElement("script")
		script.src = "https://web-button.getmati.com/button.js"
		script.async = true
		script.onload = () => {
			resolve(true) // Return true when script is loaded
		}
		script.onerror = () => {
			reject(new Error("Failed to load Metamap script"))
		}
		document.body.appendChild(script)
	})
}

export function useMetamapScript() {
	return useQuery({
		queryKey: ["metamapScript"],
		queryFn: loadMetamapScript,
		staleTime: Infinity, // Keep the script loaded indefinitely
		refetchOnWindowFocus: false,
		retry: 2,
	})
}
