import {
	KeyRound,
	LoaderCircleIcon,
	Puzzle,
	RectangleEllipsis,
	Trash2,
} from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../../components/ui/alert"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { useAuth } from "../../../context/AuthContext"
import ArsCover from "./PaymentsCover"
import { StoreType } from "../../../services/users"

export interface Integration {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: Integration[] = [
	{
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
]

export default function Transfer() {
	const navigate = useNavigate()
	const { stores, kyc } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState<
		[boolean, any]
	>([false, undefined])

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_CVU_APP_ID === undefined) {
				return
			}

			const findTiendanubeStore = (appId: string) => {
				for (const store of stores) {
					if (store.store_type === StoreType.TIENDANUBE) {
						const app = store.store_apps.find(
							(app: any) => app.app_id === appId
						)
						if (app) {
							return { store, app }
						}
					}
				}
				return undefined
			}

			const tiendanube = findTiendanubeStore(
				process.env.REACT_APP_TIENDANUBE_CVU_APP_ID
			)

			if (tiendanube) {
				setIsTiendanubeInstalled([true, tiendanube])
			}
		}
	}, [stores])

	function handleUninstallTiendanube(store_url: string) {
		window.location.href = `https://${store_url}/admin/v2/apps`
	}

	return (
		<FeatureLayout
			coverImage={<ArsCover />}
			sectionHeaderProps={{
				title: "Transferencias automáticas",
				subtitle:
					"Aceptar transferencias nunca fue más fácil. Llená el formulario y mirá los pagos llegar.",
			}}
		>
			{(!kyc.data?.kyc_status || kyc.data.kyc_status !== 'approved') && (
				<Section footer="Para mas información podés entrar a nuestra web y conocer todas las funciones nuevas. ">
					<Alert
						variant="link"
						icon={<RectangleEllipsis />}
						title={"Formulario"}
						description={
							"Para arrancar necesitamos saber un poco mas de vos. Llená el formulario y aguardá que verifiquemos tu cuenta para continuar."
						}
						buttonText="Abrir formulario web"
						//TODO: agregar link al docs posta
						onClick={() => navigate("/onboarding")}
					></Alert>
				</Section>
			)}
			{isTiendanubeInstalled[0] ? (
				<Section
					title="Integraciones activas"
					footer="Para agregar nueva integraciones. Prueba desvinculando la activa. "
				>
					<Option
						title="Tiendanube + Talo Pay"
						description="Activa"
						icon={
							<img
								src="/dashboard/plugins/tiendanube.svg"
								className="size-full"
							/>
						}
						leading="div"
						div={
							<Trash2
								onClick={() => {
									handleUninstallTiendanube(
										isTiendanubeInstalled[1].store.store_url
									)
								}}
								className="size-icon"
							></Trash2>
						}
						iconInside={false}
						className="bg-background description:text-text-success"
					/>
					<Option
						title="Credenciales"
						icon={<KeyRound />}
						leading="arrow"
						className="description:text-text-success"
						onClick={() => navigate("/settings/plugins/credentials")}
					/>
				</Section>
			) : (
				kyc.data?.kyc_status && (
					<Section title="Conectá tu cuenta">
						<Option
							title="Integraciones"
							description="Conectá tu tienda online"
							icon={<Puzzle />}
							leading="arrow"
							onClick={() =>
								navigate("/settings/payment-methods/transfer/plugins")
							}
						/>
					</Section>
				)
			)}
		</FeatureLayout>
	)
}
