import { Card, CardContent, CardFooter, CardHeader } from "../../ui/card"
import Col from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import { Separator } from "../../ui/separator"
import { Heading4, Heading6 } from "../../ui/texts/TextStyle"

const FeatureRow = ({ feature }: { feature: string }) => {
	return (
		<Row className="items-center py-[4px] gap-[4px]">
			<svg
				className="size-[12.5px] lg:size-[20px]"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9 14.4L4.3 9.7L5.7 8.3L9 11.6L17.4 3.2C15.5 1.3 12.9 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 8.1 19.5 6.4 18.6 4.9L9 14.4Z"
					fill="#6DD593"
				/>
			</svg>
			<div className="text-h6 lg:text-h3 text-text-muted">{feature}</div>
		</Row>
	)
}

const features = [
	"Creación de cuenta",
	"Uso ilimitado del Sandbox",
	"Soporte técnico personalizado",
	"Envios de dinero ilimitados",
]

const EcommerceCard = (props: any) => {
	return (
		<Card className="w-full max-w-[608px] rounded-md border border-border-primary overflow-hidden">
			<CardHeader className="pl-[24px] bg-[#E7E7E7]">
				<Row className="justify-between items-center">
					<Heading6 className="font-bold">E-commerce</Heading6>
					<div className="bg-white rounded-xs h-[43px] w-[117px] flex flex-row items-center justify-center mx-[12px]">
						<Heading4 className="font-bold text-onPrimary">GRATIS</Heading4>
					</div>
				</Row>
			</CardHeader>
			<Separator className="w-full" />
			<CardContent className="pl-[24px] py-10">
				<div className="font-light text-h5 lg:text-h3 text-[#898989]">
					Todo lo que necesitas, gratis para siempre. QR ilimitados, envios
					ilimitados, integraciones ilimitadas.
				</div>
			</CardContent>
			<Separator className="w-full" />
			<CardFooter className="pl-[24px] pt-[24px]">
				<Col>
					{features.map((feature, i) => (
						<FeatureRow feature={feature} key={i}></FeatureRow>
					))}
				</Col>
			</CardFooter>
		</Card>
	)
}

export default EcommerceCard
