import { Link } from "react-router-dom"
import ForgotPasswordBox from "../../components/auth/ForgotPasswordBox"
import AuthLayout from "../../components/ui/layouts/landing/AuthLayout"
import { buttonVariants } from "../../components/ui/button"

const ForgotPassword = () => {
	return (
		<AuthLayout
			leftImageSrc="/home/login/placeholder.png"
			rightNavbarContent={
				<Link to="/signup" className={buttonVariants({ variant: "ghost" })}>
					Registrarse
				</Link>
			}
		>
			<ForgotPasswordBox />
		</AuthLayout>
	)
}

export default ForgotPassword
