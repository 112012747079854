import {
	Charge,
	ChargeData,
	ChargeSteps,
	ChargeType,
	ChargeTypeItem,
	PaymentOption,
	WizardChargeData,
} from "../../pages/charge/charge-wizard-types"
import { QRItem } from "../ui/inputs/QRSelector"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import ChargeTypeStep from "./ChargeTypeStep"
import EnterAmountStep from "./EnterAmountStep"
import ShareChargeBody from "./ShareChargeBody"

const ChargeWizardBody = ({
	chargeTypes,
	onDataChanged,
	wizardData,
	setSearchParams,
	qrs,
	handleCreatePayment,
	handleCancelQRPayment,
}: {
	chargeTypes: { [key in ChargeType]: ChargeTypeItem }
	onDataChanged: (wizardData: WizardChargeData) => void
	wizardData: WizardChargeData
	setSearchParams: (params: {
		type: string
		paymentOptions: PaymentOption[]
	}) => void
	qrs: QRItem[]
	handleCreatePayment: () => void
	handleCancelQRPayment: () => void
}) => {
	const stepBodys = {
		[ChargeSteps.SelectType]: (
			<ChargeTypeStep
				chargeTypes={chargeTypes}
				onSelectType={(newType, paymentType) => {
					setSearchParams({
						type: newType.toString(),
						paymentOptions: wizardData.data?.payment_options
							? [
									...wizardData.data.payment_options.filter(
										(option) => option !== null
									),
									paymentType ? paymentType : PaymentOption.CRYPTO,
							  ]
							: [paymentType],
					})
					onDataChanged({
						...wizardData,
						step: ChargeSteps.EnterAmount,
						data: {
							...wizardData.data,
							type: newType,
							payment_options: wizardData.data?.payment_options
								? [...wizardData.data.payment_options, paymentType]
								: [paymentType],
						},
					})
				}}
			/>
		),
		[ChargeSteps.EnterAmount]: (
			<EnterAmountStep
				qrId={wizardData.data.qr?.id}
				showQRSelector={wizardData.data.type === ChargeType.QR}
				onAmountChange={(newPrice) => {
					onDataChanged({
						...wizardData,
						data: {
							...wizardData.data,
							price: newPrice,
						},
					})
				}}
				onMotiveChange={(newMotive) => {
					onDataChanged({
						...wizardData,
						data: {
							...wizardData.data,
							motive: newMotive,
						},
					})
				}}
				qrs={qrs}
				onQRChange={(newQrId) => {
					onDataChanged({
						...wizardData,
						data: {
							...wizardData.data,
							qr: qrs.find((qr) => qr.id === newQrId),
						},
					})
				}}
				onContinue={
					validateData(wizardData.data) ? handleCreatePayment : undefined
				}
				loading={wizardData.loading ?? false}
				errorMessage={wizardData.errorMessage}
			/>
		),
		[ChargeSteps.ShareCharge]: (
			<ShareChargeBody
				type={wizardData.data.type as ChargeType}
				payment={wizardData.data.payment as Charge}
				onCancel={handleCancelQRPayment}
				qr={wizardData.data.qr}
				loading={wizardData.loading}
				onPaymentStatusChange={(charge) => {
					onDataChanged({
						...wizardData,
						data: {
							...wizardData.data,
							payment: charge,
						},
					})
				}}
			/>
		),
	}

	return <CenteredBody bottom>{stepBodys[wizardData.step]}</CenteredBody>
}
function validateData(data: ChargeData) {
	if (data.type === ChargeType.QR && data.qr === undefined) {
		return false
	}
	if (data.price === undefined) {
		return false
	}
	if (
		data.price.amount === 0 ||
		data.price.amount === undefined ||
		data.price.amount === "0"
	) {
		return false
	}
	if (data.price.currency === undefined) {
		return false
	}
	return true
}
export default ChargeWizardBody
