import logger from "../utils/logger"
import { apiPrivate } from "./interceptors"

export async function getAllTransactions({
	limit,
	cursor,
	date_range,
}: {
	limit?: number
	cursor?: {
		user_id: string
		transaction_id: string
	}
	date_range?: {
		start_date: string
		end_date: string
	}
}) {
	logger.info(`getTransactions ${limit} ${cursor}`)
	const res = await apiPrivate.get(
		`/transactions/${limit ? `?limit=${limit}` : ""}${
			cursor
				? limit
					? `&cursor_user_id=${cursor.user_id}&cursor_transaction_id=${cursor.transaction_id}`
					: `?cursor_transaction_id=${cursor.transaction_id}&cursor_user_id=${cursor.user_id}`
				: ""
		}${
			date_range
				? `${cursor || limit ? "&" : "?"}start_date=${
						date_range.start_date
				  }&end_date=${date_range.end_date}`
				: ""
		}`
	)
	return res.data.data
}

export async function getTransactions(user_id: string, team_id?: string) {
	const res = await apiPrivate.get(
		`/transactions/?user_id=${user_id}${team_id ? `&team_id=${team_id}` : ""}`
	)
	return res.data.data.transactions
}

export async function getUserInvoice(
	user_id: string,
	start_date: string,
	end_date: string
) {
	const res = await apiPrivate.get(
		`/transactions/invoice?user_id=${user_id}&start_date=${start_date}&end_date=${end_date}`
	)
	return res.data.data
}

export async function createOutboundTransaction(
	user_id: string,
	quote_id: string
) {
	const res = await apiPrivate.post(`/transactions/?user_id=${user_id}`, {
		user_id,
		quote_id,
		type: "OUTBOUND",
	})
	return res.data.data
}

export async function createOutboundTransactionCvu(tx_data: {
	user_id: string
	pollux_account_id: string
	amount: string
	address: string
}): Promise<{ transaction_id: string }> {
	const res = await apiPrivate.post(`/transactions/`, {
		...tx_data,
		type: "OUTBOUND",
		network: "POLLUX",
		currency: "ARS",
	})
	return res.data.data
}

export async function createOutboundTransactionPix(
	user_id: string,
	quote_id: string
): Promise<{ transaction_id: string }> {
	throw new Error("Not implemented")
}

export async function createTransactionQuote(
	user_id: string,
	amount: string,
	network: string,
	currency: string,
	to: string,
	include_gas?: boolean
) {
	const res = await apiPrivate.post(`/transactions/quote`, {
		user_id,
		network,
		currency,
		amount: amount,
		address: to,
		include_gas,
	})
	return res.data.data
}
