import { useAuth } from "../../../context/AuthContext"
import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import { DataTable } from "../../ui/data-table/data-table"
import { columns } from "./columns"
import { mapTxToTableTx } from "./data"

const AdminTransactionsTable = ({ transactions, paginationHandler }: any) => {
	const breakpoint = useBreakpoint() as ScreenBreakpoint
	const { user } = useAuth()
	console.log(transactions)
	return (
		<DataTable
			searchFilter={{
				placeholder: "User ID",
				columnName: "user_id",
			}}
			filters={[
				{
					title: "Tipo",
					column: "type",
					options: [
						{
							label: "GAS",
							value: "INTERNAL_GAS",
						},
						{
							label: "INBOUND",
							value: "INBOUND",
						},
						{
							label: "OUTBOUND",
							value: "OUTBOUND",
						},
					],
				},
			]}
			data={(transactions ?? []).map((tx: any) => mapTxToTableTx(tx, user.id))}
			loading={!transactions}
			columns={columns[breakpoint]}
			paginationHandler={paginationHandler}
			tableItem="transacciones"
		/>
	)
}

export default AdminTransactionsTable
