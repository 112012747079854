import { MenuItem } from "../../../../pages/app/items"
import Row from "../../layouts/row"
import { Separator } from "../../separator"
import BottomBarMenuItem from "./BottomBarMenuItem"

import BottomBarDropdown from "./BottomBarDropdown"

const BottomNavigationBar = ({
	items,
	activeTab,
}: {
	items: MenuItem[]
	activeTab?: string
}) => {
	return (
		<div className="fixed bottom-4 rounded-sm outline outline-8 outline-offset-0 outline-border-navbar bg-background-navbar left-1/2 transform -translate-x-1/2 inline-flex mx-auto">
			<Row className="gap-betweenComponents items-center">
				{items.map((item) => (
					<BottomBarMenuItem
						key={item.itemName}
						icon={item.icon}
						itemLink={item.itemLink}
						status={item.itemName === activeTab ? "active" : "inactive"}
					/>
				))}
				<Separator
					orientation="vertical"
					className="bg-border-navbar-item h-[24px]"
				/>
				<BottomBarDropdown />
			</Row>
		</div>
	)
}

export default BottomNavigationBar
