import { Loader2 } from "lucide-react"
import { Button } from "../ui/button"
import LoadableButton from "../ui/buttons/LoadableButton"
import { Body1 } from "../ui/texts/TextStyle"
import { SignupSteps } from "./SignupBox"
import Spacer from "../ui/spacer"

const FormFooter = ({
	step,
	handleGoBack,
	handleSubmit,
	isLoading,
}: {
	step: SignupSteps
	handleGoBack: () => void
	handleSubmit?: () => void
	isLoading: boolean
}) => {
	if (step === SignupSteps.Email) {
		return (
			<>
				<Button
					onClick={handleSubmit}
					size="full"
					type="submit"
					disabled={!handleSubmit}
				>
					{isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : ""}
					Confirmar
				</Button>
				<Spacer axis="vertical" type="betweenTexts"></Spacer>
				<div className="h-10 cursor-pointer">
					<Body1 className="text-center text-text-muted">
						Al confirmar, aceptas los{" "}
						<a
							className="font-semibold text-text-interactive"
							href="/terms-of-service"
						>
							Términos
						</a>
						y{" "}
						<a
							className="font-semibold text-text-interactive"
							href="/privacy-policy"
						>
							Política de Privacidad
						</a>
					</Body1>
				</div>
			</>
		)
	} else {
		return (
			<div className="flex flex-row gap-betweenButtons">
				<Button
					onClick={handleGoBack}
					size="full"
					variant="outline"
					type="button"
				>
					Regresar
				</Button>
				<LoadableButton
					type="submit"
					isLoading={isLoading}
					onClick={handleSubmit}
					disabled={!handleSubmit}
					size="full"
				>
					Confirmar
				</LoadableButton>
			</div>
		)
	}
}

export default FormFooter
