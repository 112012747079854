import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { BankInfoResponse, getAddressDetails } from "../services/cvu"
import { apiPrivate } from "../services/interceptors"

export default function useGetFiatAddressDetails({
	address,
}: {
	address: string | undefined
}): UseQueryResult<BankInfoResponse, Error> {
	return useQuery({
		queryKey: ["fiatAddressDetails", address],
		queryFn: async () => {
			if (!address) {
				throw new Error("CVU not provided")
			}
			const res = await apiPrivate.get(`/cvu/${address}/bank-info`)

			if (res.data.code !== 200) {
				throw new Error(res.data.message)
			}

			return res.data.data as BankInfoResponse
		},
		enabled: !!address,
		retry: false,
	})
}
