const PendingWithdrawStatus = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.2309 21.9229C7.74908 22.6027 2.75741 18.7136 2.07719 13.2247C1.39696 7.74592 5.28826 2.75699 10.7701 2.07714C16.2519 1.39729 21.2436 5.28645 21.9238 10.7753C21.9838 11.2952 22.0138 11.815 21.9938 12.3449"
				// stroke="#010101"
				stroke-width="2"
				className="stroke-icon-muted"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 7V12.6338L10 15"
				// stroke="#010101"
				stroke-width="2"
				className="stroke-icon-muted"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17 20V16H21"
				// stroke="#010101"
				stroke-width="2"
				className="stroke-icon-error group-hover:animate-diagonal-bounce"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21 20L17 16"
				// stroke="#010101"
				stroke-width="2"
				className="stroke-icon-error group-hover:animate-diagonal-bounce"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}
export default PendingWithdrawStatus
