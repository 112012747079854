import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import ToSBody from "../../../components/auth/ToS"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../../components/ui/options/option"
import { useAuth } from "../../../context/AuthContext"
import { apiPrivate } from "../../../services/interceptors"
import { useGetKyc } from "./useGetKyc"

const TermsOfServiceSignature = () => {
	const [acceptToS, setAcceptToS] = useState(false)
	const { user } = useAuth()
	const { refetch } = useGetKyc(user?.user_id)
	const tosMutation = useMutation({
		mutationFn: ({ userId }: { userId: string }) => {
			return apiPrivate.patch(`/users/${userId}/kyc`, {
				kyc_status: "signature",
			})
		},
	})
	const handleCheck = (value: boolean) => {
		if (!user || !user.user_id) {
			return
		}
		if (value) {
			tosMutation
				.mutateAsync({ userId: user.user_id })
				.then(() => {
					refetch()
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}
	return (
		<CenteredBody className="h-full justify-center items-center">
			<ToSBody />
			<div>
				<Option
					className="bg-transparent border-none pl-0"
					disabled={tosMutation.isPending}
					title={
						<p>
							Acepto los{" "}
							<a
								href="/terms-of-service"
								target="_blank"
								rel="noopener noreferrer"
								className="text-text-interactive hover:underline"
							>
								Términos y Condiciones
							</a>{" "}
							de la plataforma.
						</p>
					}
					trailing="switch"
					leading="div"
					switchChecked={acceptToS}
					onCheckedChange={handleCheck}
				/>
			</div>
		</CenteredBody>
	)
}

export default TermsOfServiceSignature
