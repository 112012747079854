import { WoocommerceCover } from "../../../../pages/settings/payment-methods/PaymentsCover"
import CenteredBody from "../../../ui/layouts/platform/body/CenteredBody"
import FeatureLayout from "../../../ui/layouts/platform/FeatureLayout"
import { Option } from "../../../ui/options/option"
import { Section } from "../../../ui/section"
import { SectionHeader } from "../../../ui/section-header"

const DownloadStep = ({
	handleDownload,
	isLoading,
}: {
	handleDownload: () => void
	isLoading?: boolean
}) => {
	return (
		<CenteredBody>
			<FeatureLayout
				coverImage={<WoocommerceCover />}
				sectionHeaderProps={{
					title: "Conectá tu Woocommerce",
					subtitle:
						"Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y conciliar los pagos automáticamente.",
				}}
			>
				<Section
					title="Pasos"
					footer="Se descargará el plugin de WooCommerce para tu tienda online. El cual deberas subir en la pestaña de plugins de Wordpress."
				>
					<Option
						title="Integración Woocommerce"
						icon={
							<img src="/dashboard/plugins/woocommerce.svg" alt="woocommerce" />
						}
						leading="button"
						buttonText="Instalar"
						onClick={handleDownload}
						buttonProps={{
							isLoading: isLoading,
						}}
						iconInside={false}
					/>
				</Section>
			</FeatureLayout>
		</CenteredBody>
	)
}

export default DownloadStep
