import { PaymentOption } from "../pages/charge/charge-wizard-types"
import { Payment } from "../pages/checkout/id"
import logger from "../utils/logger"
import { apiPrivate } from "./interceptors"

export const translatedStatus: { [key: string]: string } = {
	PENDING: "Pendiente",
	SUCCESS: "Confirmado",
	FAILURE: "Fallido",
	UNDERPAID: "Insuficiente",
	OVERPAID: "Sobrante",
	EXPIRED: "Expirado",
	REFUND: "Reembolsado",
}

export async function getAllPayments(
	params: {
		start_date?: string
		end_date?: string
	},
	cursor?: string
): Promise<{ payments: Payment[]; cursor?: string }> {
	var queryParams = ""
	if (params) {
		Object.entries(params)
			.filter(([k, v]) => v)
			.forEach(([key, value]) => {
				queryParams += `${key}=${value}&`
			})
	}
	if (cursor) {
		queryParams += `cursor=${cursor}`
	}
	const response = await apiPrivate.get(
		`/payments/${queryParams !== "" ? `?${queryParams}` : `${queryParams}`}`
	)
	if (response && response.data && response.data.data) {
		console.log(response.data.data)
		return {
			payments: response.data.data.payments as Payment[],
			cursor: response.data.data.cursor,
		}
	} else {
		return {
			payments: [],
			cursor: undefined,
		}
	}
}

export async function getPayment(
	payment_id: string
): Promise<Payment | undefined> {
	const response = await apiPrivate.get(`/payments/${payment_id}`)
	logger.info("PAYMENT RESPONSE===", response.data)

	// The API returns a single payment
	if (response && response.data.data) {
		return response.data.data as Payment
	} else {
		return undefined
	}
}

export async function getPublicPayment(
	payment_id: string
): Promise<Payment | undefined> {
	const response = await apiPrivate.get(`/payments/${payment_id}/public`)
	//logger.info("PAYMENT RESPONSE===", response.data)

	// The API returns a single payment
	if (response && response.data.data) {
		return response.data.data as Payment
	} else {
		return undefined
	}
}

export async function createPayment(
	amount: number | string,
	currency: string,
	user_id: string,
	motive: string,
	team_id?: string,
	payment_options?: PaymentOption[],
	customer_email?: string
) {
	const response = await apiPrivate.post("/payments/", {
		price: {
			currency: currency,
			amount: amount,
		},
		user_id: user_id,
		team_id: team_id,
		motive: motive,
		...(payment_options && { payment_options: payment_options }),
		customer_email: customer_email,
	})
	logger.info("PAYMENT RESPONSE===", response.data)

	// The API returns a single payment
	if (response && response.data.data) {
		return response.data.data as Payment
	} else {
		return undefined
	}
}

export async function getUserPayments(
	user_id: string,
	team_id?: string
): Promise<Payment[] | undefined> {
	const response = await apiPrivate.get(
		`/payments/?user_id=${user_id}${team_id ? `&team_id=${team_id}` : ""}`
	)
	if (response && response.data && response.data.data) {
		return response.data.data.payments as Payment[]
	} else {
		return undefined
	}
}

export async function get_refund_quote(
	payment_id: string,
	refund_quote_id: string
) {
	const res = await apiPrivate.get(
		`/payments/${payment_id}/refunds/${refund_quote_id}`
	)
	return res.data.data
}
export async function create_refund(
	paymentId: string,
	userId: string,
	amount: string,
	isFeePaidByReceiver: boolean
) {
	const response = await apiPrivate.post(`/payments/${paymentId}/refunds`, {
		payment_id: paymentId,
		user_id: userId,
		input_amount: amount,
		is_fee_paid_by_receiver: isFeePaidByReceiver,
	})
	return response.data.data.refund_quote
}

export async function confirm_refund(
	paymentId: string,
	user_id: string,
	refundQuoteId: string
) {
	const response = await apiPrivate.patch(`/payments/${paymentId}`, {
		payment_id: paymentId,
		user_id: user_id,
		payment_status: "REFUND",
		refund_quote_id: refundQuoteId,
	})
	return response.data.data
}
