import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query"
import {
	mapCombinedPaymentsToTableTransaction,
} from "../components/tables/transactions-table/data"
import {
	confirm_refund,
	createPayment,
	create_refund,
	getAllPayments,
	getPayment,
	getPublicPayment,
	getUserPayments,
	get_refund_quote,
} from "../services/payments"
import { getTransactions } from "../services/transactions"
import { Store } from "./useFetchStores"
import { Payment } from "../pages/checkout/id"

export const useAllPayments = (params: {
	start_date?: string
	end_date?: string
	cursor?: string
}) => {
	return useInfiniteQuery({
		queryKey: ["payments"],
		queryFn: async ({ pageParam }) => getAllPayments(params, pageParam),
		initialPageParam: params.cursor,
		getNextPageParam: (lastPage, pages) => lastPage.cursor,
		getPreviousPageParam: (firstPage, pages) => firstPage.cursor,
		enabled: !!params,
	})
}

export const usePayment = (payment_id: string) => {
	return useQuery({
		queryKey: ["payment", payment_id],
		queryFn: async () => getPayment(payment_id),
		enabled: !!payment_id,
	})
}

export const usePublicPayment = (payment_id: string) => {
	return useQuery({
		queryKey: ["publicPayment", payment_id],
		queryFn: async () => getPublicPayment(payment_id),
		enabled: !!payment_id,
	})
}

export const useCreatePayment = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async ({
			amount,
			currency,
			user_id,
			motive,
			team_id,
		}: {
			amount: number | string
			currency: string
			user_id: string
			motive: string
			team_id?: string
		}) => createPayment(amount, currency, user_id, motive, team_id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["payments"] })
		},
	})
}

export const useUserPayments = (user_id: string, team_id?: string) => {
	return useQuery({
		queryKey: ["userPayments", user_id, team_id],
		queryFn: async () => getUserPayments(user_id, team_id),
		enabled: !!user_id,
	})
}

export const useRefundQuote = (payment_id: string, refund_quote_id: string) => {
	return useQuery({
		queryKey: ["refundQuote", payment_id, refund_quote_id],
		queryFn: async () => get_refund_quote(payment_id, refund_quote_id),
		enabled: !!payment_id && !!refund_quote_id,
	})
}

export const useCreateRefund = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async ({
			paymentId,
			userId,
			amount,
			isFeePaidByReceiver,
		}: {
			paymentId: string
			userId: string
			amount: string
			isFeePaidByReceiver: boolean
		}) => create_refund(paymentId, userId, amount, isFeePaidByReceiver),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["payments"] })
		},
	})
}

export const useConfirmRefund = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async ({
			paymentId,
			user_id,
			refundQuoteId,
		}: {
			paymentId: string
			user_id: string
			refundQuoteId: string
		}) => confirm_refund(paymentId, user_id, refundQuoteId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["payments"] })
		},
	})
}

export const useMappedCombinedPayments = (
	user_id: string,
	stores: Store[],
	team_id?: string
) => {
	return useQuery({
		queryKey: ["combinedPayments", user_id, team_id],
		queryFn: async () => {
			const [payments, transactions] = await Promise.all([
				getUserPayments(user_id, team_id),
				getTransactions(user_id, team_id),
			])
			return mapCombinedPaymentsToTableTransaction({
				payments,
				transactions,
				stores,
			})
		},
		enabled: !!user_id , 
	})
}

export interface CombinedPayments {
	payments: Payment[] | undefined
	transactions: any
}

export const useCombinedPayments = (
	user_id: string,
	team_id?: string
) => {
	return useQuery({
		queryKey: ["combinedPayments", user_id, team_id],
		queryFn: async () => {
			const [payments, transactions] = await Promise.all([
				getUserPayments(user_id, team_id),
				getTransactions(user_id, team_id),
			])
			return { payments, transactions } as CombinedPayments
		},
		enabled: !!user_id,
	})
}
