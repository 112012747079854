import { Label } from "../label"
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./select"

export interface QRItem {
	id: string
	name: string
	code: string
}

const QRSelector = ({
	onSelect,
	qrs,
	value,
}: {
	onSelect: (val: string) => void
	qrs: QRItem[]
	value?: string
}) => {
	return (
		<Select onValueChange={onSelect} value={value}>
			<Label required>QR físico</Label>
			<SelectTrigger>
				<SelectValue placeholder="Seleccioná un QR" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					{qrs.map((qr: QRItem) => (
						<SelectItem value={qr.id} key={qr.id}>
							{qr.code}
						</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	)
}

export default QRSelector
