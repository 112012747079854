export const TimeoutPaymentAnimation = ({
	onClick,
	size,
}: {
	onClick?: () => void
	size?: string
}) => {
	return (
		<svg
			viewBox="0 0 276 276"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			className="animate-spin-slow"
		>
			<g clip-path="url(#clip0_21834_810)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M129 67.6211C94.0026 72.2348 67 101.986 67 138C67 177.212 99.0116 209 138.5 209C172.448 209 200.87 185.507 208.177 154H206.122C198.854 184.385 171.346 207 138.5 207C100.103 207 69 176.094 69 138C69 103.103 95.101 74.2385 129 69.639V67.6211Z"
					fill="#FF0000"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M44.2335 98.5493C24.9916 145.026 42.4678 199.547 86.912 225.207C135.303 253.146 197.34 236.29 225.476 187.558C249.664 145.663 240.921 93.8481 207.245 62.3816L206.217 64.1612C238.942 95.0324 247.381 145.617 223.744 186.558C196.154 234.345 135.335 250.855 87.912 223.475C44.435 198.374 27.276 145.077 45.981 99.5581L44.2335 98.5493Z"
					fill="#FF0000"
					fill-opacity="0.2"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12.8945 153.458C21.0309 215.19 73.5084 262.82 137.033 262.82C206.197 262.82 262.266 206.356 262.266 136.705C262.266 76.8257 220.827 26.6934 165.253 13.8044L165.253 15.8591C219.705 28.7086 260.266 77.9274 260.266 136.705C260.266 205.265 205.08 260.82 137.033 260.82C74.6252 260.82 23.0345 214.092 14.9122 153.458L12.8945 153.458Z"
					fill="#FF0000"
					fill-opacity="0.05"
				/>
			</g>
			<defs>
				<clipPath id="clip0_21834_810">
					<rect
						width="275.062"
						height="274.957"
						fill="white"
						transform="translate(0.0585938 0.835938)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}
