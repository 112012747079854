import { ArrowRight } from "lucide-react"
import { Button } from "../ui/button"
import Col from "../ui/layouts/column"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import Spacer from "../ui/spacer"

function NoPrivilegeErrorBody({
	handleClickGoHome,
}: {
	handleClickGoHome: () => void
}) {
	const largeTextClass = "text-center text-[4vw] font-medium"
	return (
		<CenteredBody>
			<Col>
				<div className={largeTextClass}>NO TIENES LOS</div>
				<div className={`${largeTextClass} text-[#79EEA2]`}>
					PERMISOS NECESARIOS
				</div>
				<Spacer axis="vertical" type="betweenSections" />
				<Button
					onClick={handleClickGoHome}
					size="full"
					className="gap-betweenTexts"
				>
					Ir al Tablero
					<ArrowRight className="size-[20px]" />
				</Button>
			</Col>
		</CenteredBody>
	)
}

export default NoPrivilegeErrorBody
