const TiendanubeLogo = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.2468 9.8945C14.9661 8.10917 13.4299 6.96948 11.8091 6.94073H11.8117C11.0754 6.92766 10.318 7.14462 9.65587 7.63604C9.53933 7.7223 9.5102 7.76413 9.63733 7.87914C10.3418 8.52217 10.7047 9.32466 10.7629 10.2683C10.7709 10.3859 10.7523 10.4434 10.6093 10.4356C10.3763 10.4225 10.1405 10.4251 9.90747 10.4356C9.78034 10.4408 9.74327 10.4042 9.74856 10.2814C9.75121 10.182 9.73267 10.0827 9.71413 9.98599C9.44664 8.58229 8.06149 7.67786 6.67368 7.99938C5.2647 8.32613 4.41983 9.72982 4.80651 11.1074C5.1773 12.4274 6.6419 13.2378 7.91582 12.8195C7.89463 12.7803 7.87344 12.7411 7.85226 12.7045C7.46293 12.0458 7.26165 11.3348 7.22457 10.5741C7.21927 10.4565 7.2484 10.4199 7.36758 10.4251C7.60065 10.4356 7.83636 10.4382 8.06943 10.4251C8.20715 10.4199 8.24158 10.4643 8.24688 10.5977C8.33428 12.579 10.077 14.0794 12.0792 13.9043C14.108 13.7266 15.562 11.8863 15.2468 9.8945Z"
				fill="#1186E3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.71831 7.11586C9.65057 6.31861 10.6729 5.90821 11.7693 5.92651C12.2673 5.93435 12.7811 6.03107 13.3028 6.22189C14.9714 6.82049 15.9698 8.0412 16.2426 9.77687C16.6293 12.2471 14.884 14.5343 12.3864 14.8819C12.1772 14.9107 11.968 14.9342 11.7535 14.9238C10.6014 14.9055 9.58701 14.5264 8.7289 13.7632C8.63091 13.6743 8.55675 13.6638 8.43757 13.7083C6.30555 14.482 3.99343 13.0783 3.72858 10.8512C3.50346 8.96132 4.83035 7.24395 6.7293 6.97471C7.32521 6.89106 7.90787 6.95118 8.46935 7.16553C8.57264 7.20474 8.6362 7.18644 8.71831 7.11586Z"
				fill="#1186E3"
			/>
		</svg>
	)
}

export default TiendanubeLogo
