import { Alert } from "../../../ui/alert"
import LoadableButton from "../../../ui/buttons/LoadableButton"
import { SectionHeader } from "../../../ui/section-header"

const InstallStep = ({
	handleInstall,
	isLoading,
}: {
	handleInstall: () => void
	isLoading?: boolean
}) => {
	return (
		<>
			<SectionHeader
				title="Intalá y activá el plugin en tu pagina web"
				subtitle="Podes hacerlo desde el panel de administrador de Wordpress."
			/>
			<div className="h-[300px] justify-center flex flex-row">
				<img
					className="h-[100%] rounded-sm"
					src="/animations/woocommerce/woocommerce_1.gif"
					alt="woocommerce step 1"
				/>
			</div>
			<Alert
				className="px-[50px] py-lg"
				icon={""}
				title=""
				description={
					<ul className="text-h6 flex flex-col list-outside text-text list-decimal mt-2 [&>*]:m-0">
						<li>
							<strong>Accedé al panel de administrador.</strong> Entrá en el
							panel de administrador en tu página web.
						</li>
						<li>
							<strong>Dirigite a plugins.</strong> Hacé click en la sección
							"Plugins" del menú de la izquierda.
						</li>
						<li>
							<strong>Añade nuevo plugin.</strong> Seleccioná "Agregar nuevos
							plugins".
						</li>
						<li>
							<strong>Subí el plugin.</strong> Elegí "Subir plugin" y seleccioná
							el archivo "talo_payments_wc_plugin.zip" en tus Descargas
						</li>
						<li>
							<strong>Instalá.</strong> Hacé click en "Instalar".
						</li>
						<li>
							<strong>Activá el plugin.</strong> Hacé click en "Activar" para
							poder seguir con la configuración.
						</li>
					</ul>
				}
				bulletList
			></Alert>
			<LoadableButton onClick={handleInstall} size="full" isLoading={isLoading}>
				Ya instalé el plugin
			</LoadableButton>
		</>
	)
}

export default InstallStep
