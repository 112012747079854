export function formatPriceAmount(
	amount: string | number | undefined,
	decimals = 2,
	currency?: string
): string | undefined {
	if (!amount) return undefined
	if (amount === "0,00") return "0.00"
	if (currency === "BNB") decimals = 5

	let amountNumber: number

	// Handle different input types and separators
	if (typeof amount === "string") {
		// Replace commas with dots if necessary and convert to number
		amountNumber = parseFloat(amount.replace(/,/g, ""))
	} else {
		amountNumber = amount
	}

	if (isNaN(amountNumber)) return undefined

	const options = {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	}

	// Ensure the number always uses the specified decimal places
	let formattedAmount = amountNumber.toLocaleString("en-US", options)

	// Remove unnecessary trailing zeros for BNB currency
	if (currency === "BNB" && formattedAmount.includes(".")) {
		formattedAmount = parseFloat(formattedAmount)
			.toFixed(decimals)
			.replace(/\.?0+$/, "")
	}

	return formattedAmount
}

export function trimBn(bigNr: string) {
	//bigNr is a float with many decimals and i want to trim to only 5
	const idx = bigNr.indexOf(".")
	if (idx > 0) return bigNr.slice(0, bigNr.indexOf(".") + 10)
	return bigNr
}
