import { formatDate } from "../../../utils/format"

export function mapQrToTableQR(qr: any) {
	const date = new Date(qr.creation_timestamp)
	return {
		id: qr.id,
		user_id: qr.user_id,
		code: qr.code,
		creation_date: formatDate(date),
		url: qr.qr_url,
	}
}
