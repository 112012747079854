import { Store } from "lucide-react"
import Col from "../../../ui/layouts/column"
import { Option } from "../../../ui/options/option"
import { ScrollArea } from "../../../ui/scroll-area"
import { SectionHeader } from "../../../ui/section-header"

const WoocommerceStoresCarousel = ({ stores }: { stores: any[] }) => {
	return (
		<Col className="gap-belowSectionHeader">
			<SectionHeader title="Mis tiendas" />
			<div className="relative">
				<ScrollArea>
					<div className="flex space-x-4 pb-4">
						{stores.map((store) => (
							<Option
								key={store.store_id}
								title={store.store_name}
								iconInside={store.photoURL?.length > 0}
								icon={
									store.photoURL?.length > 0 ? (
										<img src={store.photoURL} alt={store.store_name} />
									) : (
										<Store />
									)
								}
								onClick={() => {
									window.open(store.store_url, "_blank")
								}}
							/>
						))}
					</div>
				</ScrollArea>
			</div>
		</Col>
	)
}

export default WoocommerceStoresCarousel
