import { HelpCircle } from "lucide-react"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import PluginFailedModal from "../../../components/modals/plugin-success/PluginFailedModal"
import PluginSuccessModal from "../../../components/modals/plugin-success/PluginSuccessModal"
import { Alert } from "../../../components/ui/alert"
import LoadableButton from "../../../components/ui/buttons/LoadableButton"
import { Input } from "../../../components/ui/inputs/input"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import Row from "../../../components/ui/layouts/row"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import SlidingCard from "../../../components/ui/sliding-card"
import { Heading6 } from "../../../components/ui/texts/TextStyle"
import { useAuth } from "../../../context/AuthContext"
import { createShopifyStore, StoreType } from "../../../services/users"
import { ShopifyCover } from "../payment-methods/PaymentsCover"

const ShopifyBody = () => {
	const { stores, user, fetchStores } = useAuth()
	const [shopifyUrl, setShopifyUrl] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const location = useLocation()
	const [isModalOpen, setModalOpen] = useState(false)
	const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false)
	const navigate = useNavigate()
	const shopifyApp = stores?.find(
		(store: any) => store.store_type === "shopify"
	)
	const code = searchParams.get("code")
	const app_id = searchParams.get("app_id")
	useEffect(() => {
		const createStoreApp = async () => {
			setIsLoading(true)
			const newApp = stores?.find(
				(store: any) =>
					store.store_type === StoreType.SHOPIFY &&
					store.app_id &&
					store.app_id === app_id
			)
			const res = await createShopifyStore({
				user_id: user?.id,
				store_type: StoreType.SHOPIFY,
				authorization_code: code!,
				app_id: app_id!,
				myshopify_url: newApp.store_url,
			})
			if (res.status !== 200) {
				setIsLoading(false)
				setErrorModalOpen(true)
			} else {
				setModalOpen(true)
				await fetchStores()
				setIsLoading(false)
			}
		}
		if (code && app_id && shopifyApp && !isLoading) {
			createStoreApp()
		}
	}, [code, app_id, shopifyApp, isLoading])

	const handleSubmitShopifyUrl = async () => {
		//Acá vamos a maandar un mensaje a discord o por mail
		setIsLoading(true)
		const shopifyStore = await createShopifyStore({
			user_id: user?.id,
			myshopify_url: shopifyUrl,
			store_type: StoreType.SHOPIFY,
		})
		if (shopifyStore && shopifyStore.status === 200) {
			fetchStores()
		}
		setIsLoading(false)
	}

	if (shopifyApp?.installation_link) {
		return (
			<>
				<FeatureLayout
					coverImage={<ShopifyCover />}
					sectionHeaderProps={{
						title: "Conectá tu Shopify",
						subtitle:
							"Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y conciliar los pagos automáticamente.",
					}}
				>
					<Section
						title="Integración"
						footer="Se te redirigirá a tu Shopify para que instales la app de Talo Pay y aceptes los permisos necesarios."
					>
						<Option
							title="Shopify + Talo Pay"
							description="Inactiva"
							iconInside={false}
							icon={
								<img
									src="/dashboard/plugins/shopify.svg"
									className="size-full"
								/>
							}
							leading="button"
							buttonText="Instalar"
							onClick={() =>
								(window.location.href = shopifyApp.installation_link)
							}
						/>
					</Section>
				</FeatureLayout>
				<PluginFailedModal
					open={isErrorModalOpen}
					onClose={() => setErrorModalOpen(false)}
					retryFunction={() => {}}
				/>
				<PluginSuccessModal
					open={isModalOpen}
					onClose={() => {
						setModalOpen(false)
					}}
				/>

				{/* Pronto se pondrá en contacto un Talo representative para instalar la app
				en {shopifyApp.store_url}
				Link al que hay que redirigir al usuario: {shopifyApp.installation_link}
				. Este link puede ser undefined, de ser asi hay que deshabilitar el
				boton */}
			</>
		)
	}

	if (shopifyApp?.store_apps) {
		navigate(location.pathname + "/tutorial")
	}

	return (
		<FeatureLayout
			coverImage={<ShopifyCover />}
			sectionHeaderProps={{
				title: "Conectá tu Shopify",
				subtitle:
					"Deberás enviar el link de tu tienda y aguardar a ser contactado por un representante de Talo.",
			}}
		>
			<div className="flex flex-col gap-betweenTexts w-full p-md rounded-sm bg-background-subtle">
				<Heading6 className="font-normal">
					Para continuar vamos a necesitar el link de tu tienda. Asi podremos
					identificar tu numero de usuario. Se debería ver algo asi:
				</Heading6>
				<Heading6 className="text-text-info-strong font-normal">
					https://admin.shopify.com/store/talo-tazas
				</Heading6>
			</div>
			<Section footer="Este URL nos permite saber de quien es esta cuenta de Shopify.">
				<Row className="gap-betweenFields items-end">
					<Input
						label="Shopify URL"
						required
						disabled={shopifyApp ? true : false}
						placeholder="https://admin.shopify.com/store/ ..."
						onChange={(event) => setShopifyUrl(event.target.value)}
						value={shopifyApp ? shopifyApp.store_url : shopifyUrl}
					/>
					{shopifyApp ? (
						<SlidingCard
							trigger={
								<LoadableButton
									className="gap-x-1 animate-pulse"
									variant="bordered"
									size="lg"
								>
									<div className="animate-pulse ">
										Contactando representante
									</div>
									<div className="animate-pulse duration-500 ">.</div>
									<div className="animate-pulse duration-700">.</div>
									<div className="animate-pulse duration-1000">.</div>
								</LoadableButton>
							}
							side={"center"}
						>
							<CenteredBody>
								<SectionHeader
									title="Se contactara un representante por WhatsApp"
									subtitle={`El tiempo de espera puede variar dependiendo de la demanda.`}
								/>
								<Alert
									variant="link"
									icon={<HelpCircle />}
									title="¿Crees que ya te deberíamos haber contactado?"
									description={
										"Si crees que pasó demasiado tiempo desde que solicitaste la instalación, por favor contactanos."
									}
									buttonText="Contactar soporte"
									onClick={() =>
										(window.location.href =
											"https://wa.me/message/NLA2DUMYC5E3E1")
									}
								/>
							</CenteredBody>
						</SlidingCard>
					) : (
						<LoadableButton
							size="lg"
							onClick={handleSubmitShopifyUrl}
							isLoading={isLoading}
							disabled={shopifyUrl.length === 0 || isLoading}
						>
							Confirmar
						</LoadableButton>
					)}
				</Row>
			</Section>
		</FeatureLayout>
	)
}

export default ShopifyBody
