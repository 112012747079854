import Timer from "../ui/timer"

const PaymentCounter = (props: any) => {
	const { creation_timestamp, expiration_timestamp } = props
	const creationDate = new Date(creation_timestamp)
	let expirationDate = null
	if (expiration_timestamp) {
		expirationDate = new Date(expiration_timestamp)
	} else {
		//If no expiration date, set it to the creation date + 10 minutes
		expirationDate = new Date(creationDate.getTime() + 1000 * 60 * 10)
	}
	const minutesRemaining = Math.floor(
		(expirationDate.getTime() - new Date().getTime()) / (1000 * 60)
	)
	const secondsRemaining =
		Math.floor((expirationDate.getTime() - new Date().getTime()) / 1000) % 60
	return (
		<div>
			<Timer
				initialMinutes={minutesRemaining}
				initialSeconds={secondsRemaining}
			/>
		</div>
	)
}
export default PaymentCounter
