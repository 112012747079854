import { Row } from "@tanstack/react-table"
import { MailCheck, MailQuestion } from "lucide-react"
import { TableUser } from "./columns"

export function StatusCell() {
	return {
		accessorKey: "status",
		header: "Estado",
		cell: ({ row }: { row: Row<TableUser> }) => {
			const status = row.getValue("status") as string
			return status === "PENDING" ? (
				<MailQuestion className="text-icon-muted" />
			) : (
				<MailCheck className="text-icon-success" />
			)
		},
		filterFn: (row: Row<TableUser>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
	}
}
