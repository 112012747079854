import { Link } from "react-router-dom"

export interface LandingNavItem {
	label: string
	link?: string
	url?: string
}

const LandingNavBar = ({
	items,
	orientation = "horizontal",
}: {
	items: LandingNavItem[]
	orientation?: "horizontal" | "vertical"
}) => {
	const styles = {
		horizontal:
			"items-center space-x-textToComponent border-2 border-navbar px-textToComponent py-betweenComponents rounded-md",
		vertical: "flex flex-col space-y-textToComponent",
	}
	return (
		<nav className={styles[orientation]}>
			{items.map((item) =>
				item.link ? (
					<Link key={item.label} to={item.link} className="text-h5 font-normal">
						{item.label}
					</Link>
				) : (
					<a
						key={item.label}
						href={item.url}
						target="_blank"
						className="text-h5 font-normal"
						rel="noreferrer"
					>
						{item.label}
					</a>
				)
			)}
		</nav>
	)
}

export default LandingNavBar
