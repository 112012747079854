import { ReactNode } from "react"
import Col from "../../column"
import { cn } from "../../../../../lib/utils"

const CenteredBody = ({
	children,
	className,
	bottom = false,
}: {
	children: ReactNode | ReactNode[]
	className?: string
	bottom?: boolean
}) => {
	return (
		<Col
			className={cn(
				"max-w-[696px] w-full mx-auto flex flex-col gap-betweenSections",
				className,
				bottom && "pb-20"
			)}
		>
			{children}
		</Col>
	)
}

export default CenteredBody
