import SuccessQrRegisterAnimation from "./SuccessfulQrRegisterLowRes.mp4"

export const SuccessfulQrRegisterAnimation = () => {
	return (
		<video
			src={SuccessQrRegisterAnimation}
			className="rounded-md"
			width="100%"
			height="100%"
			autoPlay={true}
			muted
			loop
		/>
	)
}
