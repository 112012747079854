import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"

import { useEffect, useState } from "react"
import WelcomeTeams from "../../../components/app/teams/WelcomeTeams"
import { useAuth } from "../../../context/AuthContext"
import { getTeams } from "../../../services/users"

import MainTeamComponent from "../../../components/app/teams/MainTeamComponent"
import NewTeamModal from "../../../components/modals/new-team/NewTeamModal"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { Skeleton } from "../../../components/ui/skeleton"
import { useToast } from "../../../components/ui/use-toast"
import { useAnalytics } from "../../../context/AnalyticsContext"

interface Team {}
const TeamsBody = () => {
	const [teams, setTeams] = useState<Team[]>([])
	const [loading, setLoading] = useState(false)
	const [isModalOpen, setModalOpen] = useState<boolean>(false)
	const { user } = useAuth()
	const { logTeamCreated } = useAnalytics()
	const { toast } = useToast()
	useEffect(() => {
		loadTeams()
	}, [])
	const loadTeams = async () => {
		setLoading(true)
		try {
			const res = await getTeams(user.user_id)
			setTeams(res.data.data.teams)
		} catch (err) {
		} finally {
			setLoading(false)
		}
	}

	const handleNewTeam = (newTeam: any) => {
		toast({
			title: "Equipo creado",
			description: `Se envió un mail con la invitación a ${newTeam.email}.`,
		})
		setModalOpen(false)
		loadTeams()
		// setTeam(newTeam)
		// setRequestStatus(RequestStatus.SUCCESS)
		logTeamCreated({
			team_id: newTeam.team_id,
			user_id: newTeam.user_id,
			email: newTeam.email,
			role: newTeam.role,
			team_alias: newTeam.alias,
			team_created_at: newTeam.creation_timestamp,
		})
	}

	var body
	if (loading) {
		body = (
			<CenteredTableBody>
				<div className="flex flex-col gap-betweenTexts">
					<Skeleton className="h-6 w-[340px]" />
					<Skeleton className="h-5 w-[560px]" />
				</div>
				<div className="flex flex-row justify-end">
					<Skeleton className="h-9 w-[180px]" />
				</div>
				<Skeleton className="h-[400px] w-full" />
			</CenteredTableBody>
		)
	} else {
		if (teams.length === 0) {
			body = (
				<CenteredBody bottom>
					<NewTeamModal
						open={isModalOpen}
						onClose={() => setModalOpen(false)}
						handleSubmit={handleNewTeam}
					/>
					<WelcomeTeams openModal={() => setModalOpen(true)} />
				</CenteredBody>
			)
		} else {
			body = (
				<CenteredTableBody>
					<NewTeamModal
						open={isModalOpen}
						onClose={() => setModalOpen(false)}
						handleSubmit={handleNewTeam}
					/>
					<MainTeamComponent
						teams={teams}
						openModal={() => setModalOpen(true)}
					/>
				</CenteredTableBody>
			)
		}
	}
	return <div>{body}</div>
}

export default TeamsBody
