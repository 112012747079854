import { ArrowDownRight, ArrowUpLeft } from "lucide-react"
import { Link } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext"
import { PublicRole, getMaxRole } from "../../../lib/roles"
import { Button } from "../../ui/button"
import Col from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import Spacer from "../../ui/spacer"
import SectionTitle from "../../ui/titles/SectionTitle"
import BalanceCard from "./BalanceCard"

const BalanceSection = ({ showTitle = true, showButton = true }) => {
	const { totalBalance, user } = useAuth()
	const isOwner =
		getMaxRole(user.roles ?? user.talo_role_list) === PublicRole.OWNER

	return (
		<Col className="w-full grow">
			{showTitle && (
				<Row className="items-center pb-textToComponent justify-between">
					<SectionTitle title="Fondos" subtitle="El balance total en cuenta" />
					{showButton && (
						<Button variant="outline" size="sm" asChild>
							<Link to="/wallet">Ver más</Link>
						</Button>
					)}
				</Row>
			)}
			<div className="relative w-full pb-md">
				<BalanceCard totalBalance={totalBalance} />
				<Row className="gap-betweenChips absolute bottom-0 left-0 px-betweenSections">
					<Button
						size="lg"
						asChild
						className=" group"
						data-test="withdraw-button"
					>
						{isOwner && (
							<Link to="/withdraw">
								Retirar <Spacer axis={"horizontal"} type={"betweenTexts"} />
								<ArrowUpLeft className="group-hover:animate-diagonal-bounce " />
							</Link>
						)}
					</Button>
					<Button
						data-test="receive-button"
						size="lg"
						asChild
						className=" group"
					>
						<Link to="/charge">
							Recibir <Spacer axis={"horizontal"} type={"betweenTexts"} />
							<ArrowDownRight className="group-hover:animate-diagonal-bounce " />
						</Link>
					</Button>
				</Row>
			</div>
		</Col>
	)
}

export default BalanceSection
