import { useEffect, useLayoutEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { useAuth } from "../../context/AuthContext"
import { getUserByid, verifyUserEmail } from "../../services/users"
import logger from "../../utils/logger"
import VerificationBody from "./VerificationBody"
import { useGlobalStore } from "../../hooks/useGlobalStore"

const Verification = () => {
	let [verificationStatus, setVerificationStatus] = useState("loading")
	let [searchParams] = useSearchParams()
	const {globalStore, setGlobalStore} = useGlobalStore()
	const { updateUser } = useAuth()

	useLayoutEffect(() => {
		var deepLink = searchParams.get("deepLink") as string;
		if(deepLink && deepLink !== null &&!globalStore.deepLink ){
			setGlobalStore((prev) => ({ ...prev, deepLink: deepLink }))
		}
	}
	, [globalStore])

	useEffect(() => {
		const verifyEmail = async () => {
			const userId = searchParams.get("userId")
			const verificationCode = searchParams.get("verificationCode")

			if (userId && verificationCode) {
				const res = await verifyUserEmail(userId, verificationCode)
				logger.info("VERIFICATION RESPONSE", res)
				if (res.data.data.verify) {
					setVerificationStatus("success")
					const res = await getUserByid(userId)
					logger.info("REFRESH USER INFO===", res)
					if (res.status >= 200 && res.status < 300) {
						await updateUser(res.data.data)
					} else {
						logger.error("ERROR REFRESHING USER INFO")
					}
				} else {
					setVerificationStatus("error")
				}
			} else {
				setVerificationStatus("error")
			}
		}
		verifyEmail()
	}, [searchParams])

	return (
		<ResponsiveLayout
			item={{
				itemName: "Verificación",
				bodyComponent: (
					<VerificationBody verificationStatus={verificationStatus} />
				),
				smBodyComponent: (
					<VerificationBody verificationStatus={verificationStatus} />
				),
			}}
		></ResponsiveLayout>
	)
}

export default Verification
