import React, { ChangeEvent, FormEvent, useState } from "react"
import { Button } from "../../../components/ui/button"
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../components/ui/inputs/select"
import { Label } from "../../../components/ui/label"
import { getUserInvoice } from "../../../services/transactions"
export interface InvoiceFormData {
	invoiceNumber: string
	issueDate: Date
	dueDate: Date
	amount: string
	userId?: string
}

interface InvoiceFormProps {
	onSubmit: (data: InvoiceFormData) => void
}
const initialIssueDate = () => {
	const month = new Date().getMonth()
	const year = 2024
	const startDate = new Date()
	startDate.setFullYear(year)
	startDate.setMonth(month)
	startDate.setDate(1)
	startDate.setHours(0, 0, 0, 0)
	return startDate
}
const initialDueDate = () => {
	const month = new Date().getMonth()
	const year = 2024
	const endDate = new Date()
	endDate.setFullYear(year)
	endDate.setMonth(month + 1)
	endDate.setDate(1)
	endDate.setHours(0, 0, 0, 0)
	return endDate
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({ onSubmit }) => {
	const [formData, setFormData] = useState<InvoiceFormData>({
		invoiceNumber: "1E89B1B0-0",
		issueDate: initialIssueDate(),
		dueDate: initialDueDate(),
		amount: "",
		userId: "da9a2417-ba16-4c25-8d6b-d7e4fc663bc7",
	})

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		console.log("SUBMITTING FORM")
		e.preventDefault()
		onSubmit(formData)
	}

	async function handleGetInvoiceClick() {
		console.log("FORM DATA=", formData)
		const invoiceData = await getUserInvoice(
			formData.userId || "da9a2417-ba16-4c25-8d6b-d7e4fc663bc7",
			formData.issueDate.toISOString(),
			formData.dueDate.toISOString()
		)
		console.log("INVOICE DATA=", invoiceData)
		setFormData({ ...formData, amount: invoiceData.totalInvoiceReadable })
		return undefined
	}

	function handleDateSelect(value: string): void {
		const month = Number(value)
		const year = 2024
		console.log("CHANGING DATE VALUES", value)
		const startDate = new Date()
		startDate.setFullYear(year)
		startDate.setMonth(month)
		startDate.setDate(1)
		startDate.setHours(0, 0, 0, 0)
		const endDate = new Date()
		endDate.setFullYear(year)
		endDate.setMonth(month + 1)
		endDate.setDate(1)
		endDate.setHours(0, 0, 0, 0)
		if (value) {
			setFormData({ ...formData, issueDate: startDate, dueDate: endDate })
		}
	}

	return (
		<form onSubmit={handleSubmit} className="space-y-4">
			<div>
				<label className="block text-sm font-medium">Invoice Number</label>
				<input
					name="invoiceNumber"
					type="text"
					value={formData.invoiceNumber}
					onChange={handleChange}
					className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
				/>
			</div>
			<Select
				onValueChange={(value) => handleDateSelect(value)}
				value={formData.issueDate.getMonth().toString()}
			>
				<Label required>Permisos</Label>
				<SelectTrigger>
					<SelectValue placeholder="Seleccioná un rol" />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						{[
							"Enero",
							"Febrero",
							"Marzo",
							"Abril",
							"Mayo",
							"Junio",
							"Julio",
							"Agosto",
							"Septiembre",
							"Octubre",
							"Noviembre",
							"Diciembre",
						].map((month: string, index) => (
							<SelectItem value={index?.toString()} key={month}>
								<div>{month}</div>
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
			<div>
				<label className="block text-sm font-medium">User Id</label>
				<input
					name="userId"
					type="text"
					value={formData.userId}
					onChange={handleChange}
					className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
				/>
			</div>

			<Button onClick={() => handleGetInvoiceClick()}>
				Get Invoice Amount
			</Button>
			<div>
				<label className="block text-sm font-medium">Amount</label>
				<input
					name="amount"
					type="text"
					value={formData.amount}
					onChange={handleChange}
					className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
				/>
			</div>
			<Button type="submit">Submit data</Button>
		</form>
	)
}

export default InvoiceForm
