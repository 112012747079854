import { ColumnDef, Row } from "@tanstack/react-table"
import { ArchiveX } from "lucide-react"
import PaymentSuccessStatus from "../../../ui/status-icons/PaymentSuccessStatus"
import PendingPaymentStatus from "../../../ui/status-icons/PendingPaymentStatus"
import PendingWithdrawStatus from "../../../ui/status-icons/PendingWithdrawStatus"
import RejectedWithdrawStatus from "../../../ui/status-icons/RejectedWithdrawStatus"
import SuccessWithdrawStatus from "../../../ui/status-icons/SuccessWithdrawStatus"
import { Heading6 } from "../../../ui/texts/TextStyle"
import { TableTransaction, TableTransactionStatus } from "../columns"

export const tableTransactionStatus = [
	"SUCCESS",
	"PENDING",
	"OVERPAID",
	"UNDERPAID",
	"SENT",
]
export function StatusColumn(): ColumnDef<TableTransaction> {
	return {
		accessorKey: "status",
		header: () => <div className="text-left">Estado</div>,
		filterFn: (row: Row<TableTransaction>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			const status = row.getValue(
				"status"
			) as (typeof tableTransactionStatus)[number]

			return <StatusCell status={status} />
		},
	}
}

export const statusIcons: {
	[key: TableTransactionStatus]: JSX.Element
} = {
	SUCCESS: <PaymentSuccessStatus className="w-7 h-7 stroke-[#1FC801]" />,

	processed: <SuccessWithdrawStatus className="w-7 h-7 stroke-icon-error" />,
	SENT: <SuccessWithdrawStatus className="w-7 h-7 stroke-icon-error" />,

	processing: <PendingWithdrawStatus className="w-7 h-7 stroke-icon-muted" />,
	pending: <PendingWithdrawStatus className="w-7 h-7" />,

	PENDING: <PendingPaymentStatus className="w-7 h-7" />,

	OVERPAID: <PaymentSuccessStatus className="w-7 h-7 stroke-icon-warning" />,
	UNDERPAID: <PaymentSuccessStatus className="w-7 h-7 stroke-icon-warning" />,

	EXPIRED: <ArchiveX className="w-6 h-6  stroke-icon-muted" />,

	rejected: <RejectedWithdrawStatus />,
	reversed: <RejectedWithdrawStatus />,
}

export const statusTranslations: {
	[key: TableTransactionStatus]: string
} = {
	SUCCESS: "Completa",
	PENDING: "Pendiente",
	pending: "Pendiente",
	OVERPAID: "Sobrante",
	UNDERPAID: "Insuficiente",
	SENT: "Enviada",
	PROCESSED: "Enviada",
	processed: "Enviada",
	EXPIRED: "Expirado",
}

export const statusDescriptions: {
	[key: TableTransactionStatus]: string
} = {
	SUCCESS: "El pago fue completado en su totalidad",
	PENDING: "A la espera de confirmación del pago",
	pending: "Retiro pendiente de confirmación",
	OVERPAID: "Se recibió el pago con un monto mayor al solicitado",
	UNDERPAID: "Se recibió el pago  con un monto menor al solicitado",
	SENT: "El envío de dinero fue completado en su totalidad",
	processed: "El envío de dinero fue completado en su totalidad",
	EXPIRED: "El tiempo para completar el pago ha expirado",
}

export function StatusCell({
	status,
}: {
	status: (typeof tableTransactionStatus)[number]
}) {
	return (
		<div className="flex items-center w-fit ">
			{statusIcons[status]}
			<Heading6 className="capitalize ml-2 font-medium font-h6 group-hover:translate-x-1 transition duration-400">
				{statusTranslations[status]}
			</Heading6>
		</div>
	)
}
