import {
	BadgeHelp,
	Ghost,
	KeyRound,
	RectangleEllipsis,
	Rocket,
} from "lucide-react"
import { Alert } from "../../../components/ui/alert"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { useNavigate } from "react-router-dom"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { ReactNode } from "react"
import { Button } from "../../../components/ui/button"

const ApiBody = ({ children }: { children?: ReactNode }) => {
	const navigate = useNavigate()
	return (
		<CenteredBody>
			<SectionHeader
				title="API"
				subtitle="Podrás conectar via API tu página web. Utilizando las credenciales y nuestra documentación."
			/>
			<Alert
				icon={<Rocket />}
				title={"Con ganas de comenzar?"}
				description="Este proceso, tiene sus complicaciones. Si no estás familiarizado con desarrollo web, te recomendamos que busques alguien con conocimientos previos en el tema."
			></Alert>
			<Section title="Seguí los pasos de la documentación">
				<Alert
					variant="link"
					icon={<RectangleEllipsis />}
					title={"Leer la documentación"}
					description={
						"En nuestra documentación encontrarás un paso a paso de como seguir con la integración. No te preocupes. Te esperamos a cuando los completes."
					}
					buttonText="Abrir documentación web"
					buttonLink="https://docs.talo.com.ar/ecommerce/API/getting_started"
				/>
			</Section>
			<Section title="Deberás utilizar las credenciales">
				<Option
					title="Credenciales"
					icon={<KeyRound />}
					leading="arrow"
					className="description:text-text-success"
					onClick={() => navigate("/settings/plugins/credentials")}
				/>
			</Section>
			{children}
			<Button
				variant="ghost"
				className="text-text-interactive gap-betweenTexts"
			>
				<BadgeHelp></BadgeHelp>
				<a href="https://wa.me/541133711752/?text=Hola,%20quiero%20integrar%20por%20API.%20Necesito%20ayuda.">
					Comunicate con soporte
				</a>
			</Button>
		</CenteredBody>
	)
}
export default ApiBody
