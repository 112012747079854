import { formatPriceAmount } from "../../../utils/numbers"
import { Body1, Heading6 } from "../texts/TextStyle"
import { Option } from "./option"

const CurrencyOption = ({
	currency,
	amount,
	amountDesired,
	onClick,
	logoPath,
	name,
	pix,
	className,
}: {
	currency: string
	amount?: string
	amountDesired?: number
	logoPath: string
	name: string
	onClick?: (currency: string) => void
	pix?: boolean
	className?: string
}) => {
	return (
		<div className="relative">
			<Option
				className={className}
				title={currency}
				description={name}
				data-test={`withdraw-currency-${currency}-button`}
				icon={<img src={logoPath} width={24} alt={name} />}
				iconInside={false}
				leading="div"
				onClick={onClick && (() => onClick(currency))}
				div={
					amount && amountDesired ? (
						<div className="grid text-end gap-1">
							<Heading6 className="font-semibold">
								{formatPriceAmount(amount, undefined, currency)}
							</Heading6>
							<Body1 className="font-medium text-text-muted">
								{formatPriceAmount(amountDesired)} ARS
							</Body1>
						</div>
					) : (
						<></>
					)
				}
			/>
			{pix && (
				<div className="absolute left-10 bottom-5">
					<svg
						width="21"
						height="21"
						viewBox="0 0 21 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x="0.682255"
							y="0.682255"
							width="19.6355"
							height="19.6355"
							rx="9.81775"
							fill="#FAFAFA"
						/>
						<rect
							x="0.682255"
							y="0.682255"
							width="19.6355"
							height="19.6355"
							rx="9.81775"
							stroke="#32BCAD"
							stroke-width="1.36451"
						/>
						<path
							d="M13.8158 13.64C13.3521 13.64 12.916 13.4595 12.5882 13.1318L10.8157 11.3592C10.6912 11.2345 10.4743 11.2348 10.3499 11.3592L8.57095 13.1383C8.24308 13.466 7.807 13.6465 7.34337 13.6465H6.99406L9.239 15.8914C9.94008 16.5925 11.0768 16.5925 11.7779 15.8914L14.0293 13.64H13.8158Z"
							fill="#32BCAD"
						/>
						<path
							d="M7.34334 7.37031C7.80698 7.37031 8.24306 7.55083 8.57092 7.87854L10.3499 9.65785C10.478 9.78603 10.6872 9.7865 10.8157 9.6577L12.5882 7.88503C12.916 7.55732 13.3521 7.37679 13.8157 7.37679H14.0292L11.778 5.12546C11.0768 4.42433 9.94006 4.42433 9.23898 5.12546L6.99414 7.37032L7.34334 7.37031Z"
							fill="#32BCAD"
						/>
						<path
							d="M15.8914 9.23894L14.5309 7.87846C14.501 7.89045 14.4685 7.89794 14.4343 7.89794H13.8157C13.4959 7.89794 13.1829 8.02766 12.9569 8.25379L11.1844 10.0263C11.0186 10.1922 10.8006 10.2752 10.5829 10.2752C10.3649 10.2752 10.1471 10.1922 9.98129 10.0265L8.20214 8.24732C7.97617 8.02113 7.66312 7.89147 7.34332 7.89147H6.58272C6.55028 7.89147 6.51997 7.88382 6.49135 7.87304L5.12546 9.23894C4.42433 9.94008 4.42433 11.0768 5.12546 11.7779L6.49129 13.1437C6.51996 13.133 6.55028 13.1253 6.58272 13.1253H7.34332C7.66312 13.1253 7.97617 12.9957 8.20214 12.7695L9.98112 10.9905C10.3027 10.6692 10.8632 10.6691 11.1844 10.9907L12.9569 12.763C13.1829 12.9892 13.4959 13.1189 13.8157 13.1189H14.4343C14.4685 13.1189 14.501 13.1264 14.5309 13.1384L15.8914 11.7779C16.5925 11.0768 16.5925 9.94008 15.8914 9.23894Z"
							fill="#32BCAD"
						/>
					</svg>
				</div>
			)}
		</div>
	)
}

export default CurrencyOption
