import { Menu } from "lucide-react"
import { Button } from "../../ui/button"
import LandingNavBar, {
	LandingNavItem,
} from "../../ui/layouts/landing/LandingNavBar"
import TaloGreenLogo from "../../ui/logos/TaloGreenLogo"
import { Sheet, SheetContent, SheetTrigger } from "../../ui/sheet"

const LandingMenuSheet = ({ navItems }: { navItems: LandingNavItem[] }) => {
	return (
		<Sheet>
			<SheetTrigger asChild>
				<Button variant="outline" size="icon" className="shrink-0 md:hidden">
					<Menu className="h-5 w-5" />
				</Button>
			</SheetTrigger>
			<SheetContent side="left">
				<TaloGreenLogo />
				<LandingNavBar items={navItems} orientation="vertical" />
			</SheetContent>
		</Sheet>
	)
}

export default LandingMenuSheet
