import { formatDate } from "../../../utils/format"
import { formatPriceAmount } from "../../../utils/numbers"
import {
	TableSubTransaction,
	TiendanubePaymentInfo,
	WoocommercePaymentInfo,
} from "../transactions-table/columns"
import { mapToTableSubTransaction } from "../transactions-table/data"

export interface TablePayment {
	payment_status: "EXPIRED" | "OVERPAID" | "PENDING" | "SUCCESS" | "UNDERPAID"
	expected: string
	id: string
	creation_date: string
	user: string
	type: "tiendanube" | "woocommerce" | "qr" | "link" | "pix"
	received: string
	transactions?: TableSubTransaction[]
	woocommerce?: WoocommercePaymentInfo
	address: string
	tiendanube?: TiendanubePaymentInfo
}

export function mapPaymentToTablePayment(payment: any): TablePayment {
	const date = formatDate(new Date(payment.creation_timestamp))
	return {
		payment_status: payment.payment_status,
		expected: `${formatPriceAmount(payment.price.amount)} ${
			payment.price.currency
		}`,
		id: payment.id,

		address: payment.quotes?.[0]?.address,
		creation_date: date,
		user: payment.user_info.fantasy_name ?? payment.user_info.email,
		type: payment.tiendanube
			? "tiendanube"
			: payment.woocommerce
			? "woocommerce"
			: payment.pix
			? "pix"
			: payment.merchant_qr_id
			? "qr"
			: "link",
		received: payment.transaction_fields
			? `${formatPriceAmount(payment.transaction_fields.amountReadable)} ${
					payment.transaction_fields.currency
			  }`
			: `N/A`,
		tiendanube: payment.tiendanube,
		woocommerce: payment.woocommerce,
		transactions: (payment.transactions ?? payment.transaction_fields
			? [payment.transaction_fields]
			: undefined
		)?.map(mapToTableSubTransaction),
	}
}
