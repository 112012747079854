import { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import LoadableButton from "../../components/ui/buttons/LoadableButton"
import { Card, CardContent, CardHeader } from "../../components/ui/card"
import AmountInput from "../../components/ui/inputs/AmountInput"
import { Input } from "../../components/ui/inputs/input"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../components/ui/section-header"
import { Heading5, Heading6 } from "../../components/ui/texts/TextStyle"
import { PaymentChannel, useAnalytics } from "../../context/AnalyticsContext"
import { createPayment } from "../../services/payments"
import { ErrorPage } from "../error/ErrorPage"

const initialData = {
	amount: "",
	currency: "ARS",
}

const texts = {
	ARS: {
		header: "Para realizar tu pago...",
		title: "Ingresá el monto a pagar",
		subtitle: "Deberás ingresar el monto total de tu compra.",
		price_text: "Precio",
		continue_btn: "Continuar",
	},
	BRL: {
		header: "Para realizar o seu pagamento...",
		title: "Insira o valor a pagar",
		subtitle: "Você deve inserir o valor total da sua compra.",
		price_text: "Preço",
		continue_btn: "Continuar",
	},
}

const CryptonubePage = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<string | undefined>(undefined)
	const params = useParams()
	let [searchParams] = useSearchParams()
	const price = searchParams.get("price")
	const currency = searchParams.get("currency")
	const language = currency === "BRL" ? "BRL" : "ARS"
	const navigate = useNavigate()
	const { userId } = params
	const { logCreatePayment, logErrorCreatePayment } = useAnalytics()
	const [email, setEmail] = useState(undefined)

	const [priceData, setPriceData] = useState<{
		amount: string
		currency: string
	}>({
		amount: price || initialData.amount,
		currency: currency || initialData.currency,
	})

	const handleContinue = async () => {
		setIsLoading(true)
		try {
			const newPayment = await createPayment(
				Number(priceData.amount),
				priceData.currency,
				userId || "",
				"Tiendanube",
				undefined,
				undefined,
				email
			)
			if (newPayment) {
				//redirect to payment url
				navigate(`/payments/${newPayment.id}`)
				logCreatePayment({
					user_id: userId || "",
					price_amount: Number(priceData.amount),
					price_currency: priceData.currency,
					creation_channel: PaymentChannel.tiendanube,
					payment_created_at: new Date().toISOString(),
				})
			} else {
				setError("Error al crear el pago")
				logErrorCreatePayment({
					user_id: userId || "",
					creation_channel: PaymentChannel.tiendanube,
					error_at: new Date().toISOString(),
					error_message: error || "",
				})
			}
			setIsLoading(false)
		} catch (e) {
			setError("Error al crear el pago")
			logErrorCreatePayment({
				user_id: userId || "",
				creation_channel: PaymentChannel.tiendanube,
				error_at: new Date().toISOString(),
				error_message: error || "",
			})
			setIsLoading(false)
		}
	}

	const onPriceChange = (data: any) => {
		setPriceData({ ...priceData, amount: data.amount, currency: data.currency })
	}

	if (!userId) return <ErrorPage />
	var body
	if (price && currency) {
		body = (
			<>
				<SectionHeader
					title="Hacé tu pago"
					subtitle="Tu compra está casi completa."
				/>
				<Card>
					<CardHeader>
						<Heading5>
							Tu compra de{" "}
							<strong>
								{price} {currency}
							</strong>{" "}
							está casi completa.
						</Heading5>
					</CardHeader>
					<CardContent>
						<Heading6
							style={{
								paddingTop: "8px",
								fontWeight: "normal",
								fontSize: "15px",
								lineHeight: "23.5px",
							}}
						>
							Para proceder con el pago, ingresá tu correo para que el comercio
							pueda identificarte.
						</Heading6>
						<Input
							id="email"
							type="email"
							placeholder="m@example.com"
							required
							label="Correo"
							onChange={(e: any) => setEmail(e.target.value)}
							// initialValue={data.email}
						/>
					</CardContent>
				</Card>

				{error && <Heading6 className="text-text-error">{error}</Heading6>}
				<LoadableButton
					onClick={handleContinue}
					size="full"
					disabled={!isValidEmail(email)}
					isLoading={isLoading}
				>
					Ir a pagar
				</LoadableButton>
			</>
		)
	} else {
		body = (
			<>
				<SectionHeader
					title={texts[language].header} //"Para realizar tu pago..."
					subtitle={texts[language].subtitle} //"Deberás ingresar el monto de tu compra"
				/>
				<AmountInput
					value={priceData}
					onAmountChange={onPriceChange}
					currencies={["ARS", "BRL"]}
				/>
				{error && <Heading6 className="text-text-error">{error}</Heading6>}
				<LoadableButton
					onClick={handleContinue}
					size="full"
					isLoading={isLoading}
				>
					{texts[language].continue_btn}
				</LoadableButton>
			</>
		)
	}
	return (
		<LandingLayout rightComponent={<></>}>
			<CenteredBody className="gap-betweenSections">{body}</CenteredBody>
		</LandingLayout>
	)
}

function isValidEmail(email: string | undefined) {
	if (!email) return false
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export default CryptonubePage
