import { subDays } from "date-fns"
import { useEffect, useState } from "react"
import AdminPaymentsTable from "../../../components/tables/admin-payments-table/table"
import { DatePickerWithRange } from "../../../components/ui/inputs/DateRangePicker"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { useAllPayments } from "../../../hooks/usePayments"

const AdminPaymentsBody = () => {
	const [dateRange, setDateRange] = useState<{
		from: Date
		to: Date
	}>({
		from: subDays(new Date(), 30),
		to: new Date(),
	})

	const {
		data: paymentsData,
		refetch,
		fetchNextPage,
		hasNextPage,
		hasPreviousPage,
		fetchPreviousPage,
	} = useAllPayments({
		cursor: undefined,
		start_date: dateRange?.from.toISOString(),
		end_date: dateRange?.to.toISOString(),
	})

	const sortedPayments = paymentsData?.pages
		.map((page) => page.payments)
		.flat()
		.sort((t1, t2) => {
			return new Date(t1.creation_timestamp) > new Date(t2.creation_timestamp)
				? -1
				: 1
		})

	useEffect(() => {
		refetch()
	}, [dateRange, refetch])

	return (
		<CenteredTableBody>
			<SectionHeader title="Pagos" subtitle="Historial de pagos realizados" />
			<DatePickerWithRange
				onDateChanged={(value) => {
					if (value) {
						setDateRange({
							from: value.from ?? dateRange.from,
							to: value.to ?? dateRange.to,
						})
					}
				}}
				date={dateRange}
			/>

			<AdminPaymentsTable
				payments={sortedPayments}
				paginationHandler={{
					nextPage: hasNextPage ? () => fetchNextPage() : undefined,
					prevPage: hasPreviousPage ? () => fetchPreviousPage() : undefined,
				}}
			/>
		</CenteredTableBody>
	)
}

export default AdminPaymentsBody
