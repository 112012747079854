// const handleClickWhatsappShare = () => {
//     const url = `https://wa.me/?text=${payment && payment.payment_url}`
//     window.open(url, "_blank")
// }
function ReferralPage() {
	window.location.href =
		"https://wa.me/541133711752?text=Hola%20Talo,%20quiero%20sumarme%20al%20futuro%20de%20los%20pagos!"
	return null
}

export default ReferralPage
