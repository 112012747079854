import {
	PublicRole,
	publicRoleIcons,
	publicRoleTranslation,
} from "../../../lib/roles"
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../ui/inputs/select"
import { Label } from "../../ui/label"
import Row from "../../ui/layouts/row"

const RoleSelector = ({
	onSelect,
	value,
	roles,
}: {
	onSelect: (role: PublicRole) => void
	value: PublicRole
	roles: PublicRole[]
}) => {
	return (
		<Select onValueChange={onSelect} value={value}>
			<Label required>Permisos</Label>
			<SelectTrigger>
				<SelectValue placeholder="Seleccioná un rol" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					{roles.map((role: PublicRole) => (
						<SelectItem value={role} key={role}>
							<Row className="gap-betweenChips">
								{publicRoleIcons({
									role,
									className: "size-[20px] text-icon-muted",
								})}{" "}
								{publicRoleTranslation[role]}
							</Row>
						</SelectItem>
					))}
				</SelectGroup>
			</SelectContent>
		</Select>
	)
}

export default RoleSelector
