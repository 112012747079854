import { ChevronLeft, ChevronRight } from "lucide-react"
import { Button } from "../button"
import Row from "../layouts/row"

export interface PaginationHandler {
	nextPage?: () => void
	prevPage?: () => void
}

interface DataTablePaginationProps {
	paginationHandler: PaginationHandler
}

export function DataTablePagination({
	paginationHandler,
}: DataTablePaginationProps) {
	return (
		<Row className="gap-betweenButtons">
			{paginationHandler.prevPage && (
				<Button
					variant="outline"
					onClick={paginationHandler.prevPage}
					disabled={!paginationHandler.prevPage}
				>
					{/* <span className="sr-only">Anterior</span> */}
					<ChevronLeft />
				</Button>
			)}
			{paginationHandler.nextPage && (
				<Button
					variant="outline"
					onClick={paginationHandler.nextPage}
					disabled={!paginationHandler.nextPage}
				>
					{/* <span className="sr-only">Siguiente</span> */}
					<ChevronRight />
				</Button>
			)}
		</Row>
	)
}
