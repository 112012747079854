import ToSBody from "../../components/auth/ToS"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"

const TermsOfService: React.FC = () => {
	return (
		<LandingLayout>
			<CenteredBody className="h-full justify-center items-center">
				<ToSBody />
			</CenteredBody>
		</LandingLayout>
	)
}

export default TermsOfService
