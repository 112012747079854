import { ArrowRight, BadgeHelp, FileText, Link, QrCode } from "lucide-react"
import BalanceSection from "../../components/app/dashboard/BalanceSection"
import PaymentsSection from "../../components/app/dashboard/PaymentsSection"
import ShortcutsRow from "../../components/app/dashboard/ShortcutsRow"
import ShortcutsSection, {
	Shortcut,
} from "../../components/app/dashboard/ShortcutsSection"
import Col from "../../components/ui/layouts/column"
import Row from "../../components/ui/layouts/row"
import EcommerceLogo from "../../components/ui/logos/EcommerceLogo"
import PaymentLink from "../../components/ui/logos/PaymentLink"
import TiendanubeLogo from "../../components/ui/logos/TiendanubeLogo"
import useBreakpoint, { ScreenBreakpoint } from "../../hooks/useBreakpoint"
import { useMediaQuery } from "../../utils/useMediaQuery"

export const DashboardBody = () => {
	const breakpoint = useBreakpoint()
	const isTablet = useMediaQuery(1486)

	const shortcuts: Shortcut[] = [
		{
			label: "Links de pago",
			icon: <PaymentLink className="w-full" />,
			type: isTablet
				? "icon"
				: breakpoint === "md" || breakpoint === "xl"
				? "label"
				: "horizontal",
			className:
				breakpoint === "xl" ? "row-span-2 group " : "row-span-1 group ",
			smIcon: <Link size="20px" />,
			link: "/charge?type=link",
		},

		{
			label: "Ecommerce",
			icon: <EcommerceLogo className="w-full" />,
			type: isTablet
				? "icon"
				: breakpoint === "md" || breakpoint === "xl"
				? "label"
				: "horizontal",
			smIcon: <TiendanubeLogo />,
			className:
				breakpoint === "xl" ? "row-span-2 group " : "row-span-1 group ",
			link: "/settings/payment-methods",
		},
		{
			label: "Asistencia técnica",
			icon: (
				<div>
					<BadgeHelp
						className={`group-hover:animate-[wiggle_1s_ease-in-out_infinite] `}
					/>
				</div>
			),
			type: isTablet ? "icon" : "horizontal",
			url: "https://wa.me/541133711752/?text=Hola,%20tuve%20un%20problema%20con%20la%20plataforma.",
			className: "row-span-1 group",
			smIcon: <BadgeHelp size="20px" />,
		},
		{
			label: "Documentación",
			smIcon: <FileText size="20px" />,
			url: "https://docs.talo.com.ar",
			type: isTablet ? "icon" : "horizontal",
		},
		{
			label: "QR's",
			icon: (
				<QrCode
					className={`group-hover:animate-[wiggle_1s_ease-in-out_infinite] `}
				/>
			),
			type: isTablet ? "icon" : "horizontal",
			className: "row-span-1 group ",
			smIcon: <QrCode size="20px" />,
			link: "/qrs",
		},
	]

	const topRightSection = {
		sm: <></>,
		md: (
			<ShortcutsSection
				shortcuts={shortcuts.filter((shortcut) => shortcut.icon)}
			/>
		),
		lg: (
			<ShortcutsSection
				shortcuts={shortcuts.filter((shortcut) => shortcut.icon)}
			/>
		),
		xl: (
			<ShortcutsSection
				shortcuts={shortcuts.filter((shortcut) => shortcut.icon)}
			/>
		),
	}
	const bottomRightSection = {
		sm: <></>,
		md: <></>,
		lg: <></>,
		xl: <></>,
	}

	const middleRowSection = {
		sm: <></>,
		md: (
			<ShortcutsRow
				shortcuts={shortcuts.filter((shortcut) => shortcut.smIcon)}
			/>
		),
		lg: (
			<ShortcutsRow
				shortcuts={shortcuts.filter((shortcut) => shortcut.smIcon)}
			/>
		),
		xl: <></>,
	}
	const tableHeight = {
		sm: "h-[calc(100%-270px-48px-100px)]",
		md: "h-[calc(100%-270px-48px-88px)]",
		lg: "h-[calc(100%-270px-48px-88px)]",
		xl: "h-[calc(100%-270px-48px)]",
	}

	return (
		<div className="h-[100%] pb-10">
			<Row className="h-[270px] pb-lg mb-5 w-full justify-between gap-betweenComponents">
				<Col className="grow h-full w-full">
					<BalanceSection />
				</Col>
				{breakpoint === "lg" || breakpoint === "xl" || isTablet ? (
					<Col className="h-full w-fit justify-end">
						{topRightSection[breakpoint as ScreenBreakpoint]}
					</Col>
				) : (
					""
				)}
			</Row>
			{/* {middleRowSection[breakpoint as ScreenBreakpoint]} */}

			<div className={`${tableHeight[breakpoint as ScreenBreakpoint]}`}>
				<Col className="grow h-full">
					<PaymentsSection />
				</Col>
				<Col className="grow-0">
					{bottomRightSection[breakpoint as ScreenBreakpoint]}
				</Col>
			</div>
		</div>
	)
}

export default DashboardBody
