import { Price } from "../../components/tables/transactions-table/columns"
import { WithdrawType } from "../../utils/withdrawCurrencies"

export enum WithdrawSteps {
	SelectType,
	SelectCurrency,
	SelectNetwork,
	SelectAddress,
	EnterAmount,
	Loading,
	Success,
}

export interface FiatAddressDetails {
	name: string
	alias: string
	bank_id?: string
	cbu: string
	account_type: string
	active_account: boolean
	address: string
	creation_timestamp: string
	cuit: string
	cvu_type: string
	entity_type: string
	last_modified_timestamp: string
	last_used_timestamp: string
	psp_id?: string
	user_id: string
	icon_img?: string
	message?: string
}

export interface WithdrawData {
	withdrawType?: WithdrawType
	currency?: string
	network?: string
	address?: string
	fiatAddressDetails?: FiatAddressDetails
	amount?: Price
	quote?: any //TODO CAMBIAR EL TIPO
	transaction_id?: string
}

export interface WithdrawWizardData {
	step: WithdrawSteps
	data: WithdrawData
	loading?: boolean
	errorMessage?: string
}

export {}
