import { Clock } from "lucide-react"
import { useState } from "react"
import { Alert } from "../ui/alert"
import { Heading6 } from "../ui/texts/TextStyle"
import Timer from "../ui/timer"

const TimerCard = (props: {
	creation_timestamp: string
	expiration_timestamp: string
	handleNewRequest?: () => void
}) => {
	const { creation_timestamp, expiration_timestamp } = props
	const creationDate = new Date(creation_timestamp)
	let expirationDate = null
	if (expiration_timestamp) {
		expirationDate = new Date(expiration_timestamp)
	} else {
		//If no expiration date, set it to the creation date + 10 minutes
		expirationDate = new Date(creationDate.getTime() + 1000 * 60 * 10)
	}
	const minutesRemaining = Math.floor(
		(expirationDate.getTime() - new Date().getTime()) / (1000 * 60)
	)
	const secondsRemaining =
		Math.floor((expirationDate.getTime() - new Date().getTime()) / 1000) % 60

	const [timerExpired, setTimerExpired] = useState(
		minutesRemaining > 0 || secondsRemaining > 0 ? false : true
	)

	return (
		<Alert
			icon={<Clock />}
			title="Apurate"
			description={
				timerExpired ? (
					<Heading6>El link ha expirado</Heading6>
				) : (
					<Heading6 className="flex flex-row text-text-muted text-wrap w-full flex-wrap">
						El link y el QR se vencerán pasados los{" "}
						<Timer
							className="text-text-interactive text-h6 mx-1 text-wrap "
							initialMinutes={minutesRemaining}
							initialSeconds={secondsRemaining}
							callback={() => setTimerExpired(true)}
						/>
						asegurate de enviarlo con tiempo al comprador.
					</Heading6>
				)
			}
		/>
	)
}

export default TimerCard
