import React, { useEffect, useRef, useState } from "react"

interface GlitchEffectProps {
	textValue?: string
	children: React.ReactElement<any>
	reloadTime?: number // Optional prop for automatic glitching
}

const GlitchEffect: React.FC<GlitchEffectProps> = ({
	textValue,
	children,
	reloadTime,
}) => {
	const letters = "a1b4c6de3fghi2jk2lm4no9pq0rs8uvw3xyz"
	const [text, setText] = useState(textValue || "")
	const [isHovered, setIsHovered] = useState(false)
	const intervalRef = useRef<number | null>(null)

	const runGlitchEffect = () => {
		let iteration = 0
		clearInterval(intervalRef.current!)

		intervalRef.current = window.setInterval(() => {
			setText((prevText) =>
				prevText
					.split("")
					.map((letter, index) => {
						if (index < iteration) {
							return textValue![index]
						}
						return letters[Math.floor(Math.random() * 26)]
					})
					.join("")
			)

			if (iteration >= (textValue?.length || 0)) {
				clearInterval(intervalRef.current!)
			}

			iteration += 1 / 3 // Adjust speed of the effect
		}, 75) // Adjust glitch timing here
	}

	const handleMouseOver = () => {
		if (!reloadTime) {
			runGlitchEffect()
		}
	}

	const handleMouseOut = () => {
		if (!reloadTime) {
			setText(textValue || "")
			clearInterval(intervalRef.current!)
		}
	}

	useEffect(() => {
		if (reloadTime) {
			const glitchInterval = setInterval(() => {
				runGlitchEffect()

				// Stop the glitch effect after the duration
				const glitchDuration = (textValue?.length || 0) * 50 * (6 / 1) // Adjust based on duration logic
				setTimeout(() => {
					setText(textValue || "") // Reset to original text after the glitch
					clearInterval(intervalRef.current!) // Ensure glitch stops after the effect
				}, glitchDuration)
			}, reloadTime)

			return () => clearInterval(glitchInterval)
		}

		return () => clearInterval(intervalRef.current!)
	}, [reloadTime, textValue])

	return (
		<g onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
			{React.cloneElement(children, {
				children: text,
			})}
		</g>
	)
}

export default GlitchEffect
