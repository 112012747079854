import useBreakpoint from "../../../hooks/useBreakpoint"
import {
	PublicRole,
	publicRoleChipBackgrounds,
	publicRoleIcons,
	publicRoleShortTranslation,
	publicRoleTranslation,
} from "../../../lib/roles"
import { Badge } from "../../ui/badge"
import { Heading6 } from "../../ui/texts/TextStyle"

const RoleChip = ({ role }: { role: PublicRole }) => {
	const isMd = useBreakpoint("md")
	return (
		<Badge
			className={
				"gap-[4px] px-betweenChips py-[4px]  " + publicRoleChipBackgrounds[role]
			}
		>
			{publicRoleIcons({ role, className: "size-[14px]" })}
			<Heading6 className="whitespace-nowrap">
				{isMd ? publicRoleShortTranslation[role] : publicRoleTranslation[role]}
			</Heading6>
		</Badge>
	)
}

export default RoleChip
