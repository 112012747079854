import { Route } from "react-router-dom"
import ProtectedRoute from "../../components/routes/ProtectedRoute"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { MenuItem } from "../app/items"
import { privateMenuItems } from "./items"

export const adminRoutes: any[] = privateMenuItems.flatMap((item) => {
	const subRoutes = item.subItems?.map(mapMenuItemToRoute) ?? []

	return [mapMenuItemToRoute(item), ...subRoutes]
})

function mapMenuItemToRoute(item: MenuItem) {
	return (
		<Route
			key={item.itemLink}
			path={item.itemLink}
			element={
				<ProtectedRoute
					requiresVerification={true}
					minimumPrivateRole={item.minimumPrivateRole}
				>
					<ResponsiveLayout item={item} />
				</ProtectedRoute>
			}
		/>
	)
}
