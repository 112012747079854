import { PublicRole } from "../../../lib/roles"
import { SettingsSectionSubItem } from "../items"
import ShopifyTutorialBody from "./ShopifyTutorialBody"

export const shopifySubsections: (
	basePath: string
) => SettingsSectionSubItem[] = (basePath) => [
	{
		title: "Shopify",
		icon: <img src="/dashboard/plugins/shopify.svg" alt="shopify" />,
		link: `/${basePath}/payment-methods/transfer/plugins/shopify/tutorial`,
		bodyComponent: <ShopifyTutorialBody />,
		smBodyComponent: <ShopifyTutorialBody />,
		iconInside: false,
		minimumPublicRole: PublicRole.OWNER,
	},
]
