const MerchantInfoRow = (props: {
	property: string
	value: React.ReactNode
}) => {
	return (
		<div className="flex flex-row w-full h-16 items-center">
			<span className="w-2/5">
				<p className="font-bold text-h6 leading-5 text-[#222]">
					{props.property}
				</p>
			</span>
			<span className="justify-start">{props.value}</span>
		</div>
	)
}

export default MerchantInfoRow
