import { PrivateRole } from "../../lib/roles"
import { MenuItem } from "../app/items"
import AdminDashboardBody from "./AdminDashboardBody"
import AdminBalancesBody from "./balances/AdminBalancesBody"
import { AdminInvoicesBody } from "./invoices"
import AdminMonitoringServerBody from "./monitoring_server/AdminMonitoringServerBody"
import AdminPaymentsBody from "./payments/AdminPaymentsBody"
import AdminQRsBody from "./qrs/AdminQRsBody"
import AdminTransactionsBody from "./transactions/AdminTransactionsBody"
import AdminUsersBody from "./users/AdminUsersBody"

export const privateMenuItems: MenuItem[] = [
	{
		itemName: "Tablero",
		itemLink: "/admin",
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <AdminDashboardBody />,
		smBodyComponent: <AdminDashboardBody />,
		activeTab: "Tablero",
	},
	{
		itemName: "Users",
		itemLink: "/admin/users",
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <AdminUsersBody />,
		smBodyComponent: <AdminUsersBody />,
		activeTab: "Users",
	},
	{
		itemName: "QRs",
		itemLink: "/admin/qrs",
		minimumPrivateRole: PrivateRole.PADAWAN,
		bodyComponent: <AdminQRsBody />,
		smBodyComponent: <AdminQRsBody />,
		activeTab: "QRs",
	},
	{
		itemName: "Transactions",
		itemLink: "/admin/transactions",
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <AdminTransactionsBody />,
		smBodyComponent: <AdminTransactionsBody />,
		activeTab: "Transactions",
	},
	{
		itemName: "Payments",
		itemLink: "/admin/payments",
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <AdminPaymentsBody />,
		smBodyComponent: <AdminPaymentsBody />,
		activeTab: "Payments",
	},
	{
		itemName: "Balances",
		itemLink: "/admin/balances",
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <AdminBalancesBody />,
		smBodyComponent: <AdminMonitoringServerBody />,
		activeTab: "Balances",
	},
	{
		itemName: "Monitoring Server",
		itemLink: "/admin/monitoring-server",
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <AdminMonitoringServerBody />,
		smBodyComponent: <AdminMonitoringServerBody />,
		activeTab: "Monitoring Server",
	},
	{
		itemName: "Invoicing",
		itemLink: "/admin/invoices",
		minimumPrivateRole: PrivateRole.PADAWAN,
		bodyComponent: <AdminInvoicesBody />,
		smBodyComponent: <AdminInvoicesBody />,
		activeTab: "Invoicing",
	},
]
