import { ColumnDef, Row } from "@tanstack/react-table"
import { statusIcons } from "../transactions-table/columns/statusCell"
import { TablePayment } from "./data"

export function StatusCell(): ColumnDef<TablePayment> {
	return {
		accessorKey: "payment_status",
		header: "Status",
		cell: ({ row }: { row: Row<TablePayment> }) =>
			statusIcons[
				row.getValue("payment_status") as
					| "SUCCESS"
					| "PENDING"
					| "OVERPAID"
					| "UNDERPAID"
					| "EXPIRED"
			],
		filterFn: (row: Row<TablePayment>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
	}
}
