import { useState } from "react"
import InvoicePDFDownload from "../../../utils/invoice_pdf/generateInvoicePdf"
import InvoiceForm, { InvoiceFormData } from "./InvoiceForm"

export const AdminInvoicesBody = (props: any) => {
	const [invoiceData, setInvoiceData] = useState<InvoiceFormData>()

	const handleFormSubmit = (values: any) => {
		setInvoiceData(values)
	}

	return (
		<div className="p-8">
			<h1 className="text-2xl font-bold mb-4">Invoice Generator</h1>
			<InvoiceForm onSubmit={handleFormSubmit} />
			<div className="container" id="container" />
			{invoiceData && <InvoicePDFDownload data={invoiceData} />}
		</div>
	)
}
