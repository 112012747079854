import { Route } from "react-router-dom"
import CheckoutId from "./id"

export const checkoutItems = [
	<Route
		key="/payments/:paymentId"
		path="/payments/:paymentId"
		element={<CheckoutId />}
	/>,
	<Route
		key="/checkout/:paymentId"
		path="/checkout/:paymentId"
		element={<CheckoutId />}
	/>,
]
