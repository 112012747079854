"use client"

import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { DayPicker } from "react-day-picker"
import { buttonVariants } from "./button"
import { cn } from "../../lib/utils"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	...props
}: CalendarProps) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn("w-full  flex justify-between", className)}
			classNames={{
				months:
					"flex w-full flex-col md:flex-row  space-y-6 md:space-x-4 md:space-y-0 ",
				month: "space-y-4 w-full flex flex-col",
				caption: "flex justify-center pt-1 relative items-center",
				caption_label: "text-sm font-medium",
				nav: "space-x-1 flex items-center",
				nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
				nav_button_previous: "absolute left-6",
				nav_button_next: "absolute right-6",
				table: "w-full border-collapse  space-y-2",
				head_row: "flex",
				head_cell:
					"text-muted-foreground rounded-full w-full font-normal text-[0.8rem]",
				row: "flex w-full mt-2",
				cell: cn(
					"w-full",
					"relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-background-elevated",
					"[&:has([aria-selected].day-range-end)]:rounded-r-sm",
					"[&:has([aria-selected].day-range-start)]:rounded-l-sm",
					"[&:has([aria-selected])]:rounded-none first:[&:has([aria-selected])]:rounded-l-lg last:[&:has([aria-selected])]:rounded-r-lg",
					"[&:has([aria-selected].day-outside)]:bg-background-subtle"
				),
				day: cn(
					"h-8 w-8 p-0 font-normal aria-selected:opacity-100 rounded-full"
				),
				day_range_start: "day-range-start",
				day_range_end: "day-range-end",
				day_selected:
					"w-full bg-swatch-150 text-swatch-100 hover:bg-swatch-150 hover:text-swatch-100 focus:bg-swatch-150 focus:text-swatch-100 rounded-sm",
				day_today:
					"bg-background-subtle text-text rounded-sm outline-1 outline",
				day_outside:
					"day-outside text-text-muted opacity-50 aria-selected:bg-transparent  aria-selected:text-[#A3A3A3]",
				day_disabled: "text-text-muted opacity-50",
				day_range_middle: cn(
					"aria-selected:bg-background-elevated aria-selected:text-text rounded-none",
					"aria-selected.day-outside:bg-background-subtle aria-selected.day-outside:text-text-muted aria-selected.day-outside:opacity-100"
				),
				day_hidden: "invisible",
				...classNames,
			}}
			components={{
				IconLeft: ({ ...props }) => (
					<ChevronLeft className="size-icon text-icon" />
				), // Nav arrow color
				IconRight: ({ ...props }) => (
					<ChevronRight className="size-icon text-icon" />
				),
			}}
			{...props}
		/>
	)
}
Calendar.displayName = "Calendar"

export { Calendar }
