import NotVerifiedBody from "../../components/auth/NotVerifiedBody"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"

const NotVerified = () => {
	return (
		<ResponsiveLayout
			item={{
				itemName: "Verificación",
				bodyComponent: <NotVerifiedBody />,
				smBodyComponent: <NotVerifiedBody />,
			}}
		/>
	)
}

export default NotVerified
