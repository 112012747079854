"use client"
import React, { useState, useEffect, useRef } from "react"

import { motion } from "framer-motion"
import { cn } from "../../lib/utils"

type Direction = "TOP" | "LEFT" | "BOTTOM" | "RIGHT"

export function HoverBorderGradient({
	children,
	containerClassName,
	className,
	disableHover,
	as: Tag = "button",
	duration = 1,
	clockwise = true,
	...props
}: React.PropsWithChildren<
	{
		as?: React.ElementType
		containerClassName?: string
		className?: string
		duration?: number
		clockwise?: boolean
		disableHover?: boolean
	} & React.HTMLAttributes<HTMLElement>
>) {
	const [hovered, setHovered] = useState<boolean>(false)
	const [direction, setDirection] = useState<Direction>("TOP")

	const rotateDirection = (currentDirection: Direction): Direction => {
		const directions: Direction[] = ["TOP", "LEFT", "BOTTOM", "RIGHT"]
		const currentIndex = directions.indexOf(currentDirection)
		const nextIndex = clockwise
			? (currentIndex - 1 + directions.length) % directions.length
			: (currentIndex + 1) % directions.length
		return directions[nextIndex]
	}

	const movingMap: Record<Direction, string> = {
		TOP: "radial-gradient(20.7% 50% at 50% 0%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)",
		LEFT: "radial-gradient(16.6% 43.1% at 0% 50%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)",
		BOTTOM:
			"radial-gradient(20.7% 50% at 50% 100%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)",
		RIGHT:
			"radial-gradient(16.2% 41.199999999999996% at 100% 50%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)",
	}

	const highlight =
		"radial-gradient(75% 181.15942028985506% at 50% 50%, #8EDD65 0%, rgba(255, 255, 255, 0) 100%)"

	useEffect(() => {
		if (!hovered) {
			const interval = setInterval(() => {
				setDirection((prevState) => rotateDirection(prevState))
			}, duration * 1000)
			return () => clearInterval(interval)
		}
	}, [hovered])
	return (
		<Tag
			onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
				disableHover ? setHovered(false) : setHovered(true)
			}}
			onMouseLeave={() => setHovered(false)}
			className={cn(
				"inline-flex items-center justify-center  ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 relative content-center bg-border hover:bg-border duration-500  overflow-visible p-[1px] decoration-clone w-fit text-onPrimary  rounded-[9px]",
				containerClassName
			)}
			{...props}
		>
			<div
				className={cn(
					"w-auto bg-background text-text flex items-center space-x-2 z-10  rounded-xs",
					className
				)}
			>
				{children}
			</div>
			<motion.div
				className={cn(
					"flex-none inset-0 overflow-hidden absolute z-0 rounded-[inherit]"
				)}
				style={{
					opacity: 0.6,
					position: "absolute",
					width: "100%",
					height: "100%",
				}}
				initial={{ background: movingMap[direction] }}
				animate={{
					background: hovered ? [movingMap[direction], highlight] : "",
				}}
				transition={{ ease: "linear", duration: duration ?? 1 }}
			/>
		</Tag>
	)
}
