import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTransaction } from "../columns"

export function DateAndTimeCell(): ColumnDef<TableTransaction> {
	return {
		accessorKey: "lastModifiedDateTime",
		header: () => <div className="text-start ">Fecha</div>,
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			const dateTime = row.getValue("lastModifiedDateTime") as {
				date: string
				time: string
			}

			return (
				<div className="flex flex-row gap-betweenComponents text-h6 text-nowrap justify-center">
					{dateTime?.date === "Hoy" ? (
						<div>{dateTime?.time}</div>
					) : (
						<div>{dateTime?.date}</div>
					)}
				</div>
			)
		},
	}
}
