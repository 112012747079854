import { ArrowRight } from "lucide-react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Button } from "../../components/ui/button"
import Col from "../../components/ui/layouts/column"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import Row from "../../components/ui/layouts/row"
import { Skeleton } from "../../components/ui/skeleton"
import { Heading1 } from "../../components/ui/texts/TextStyle"
import { ErrorPage } from "../error/ErrorPage"
import { SectionHeader } from "../../components/ui/section-header"
import { use } from "chai"
import { useEffect } from "react"
import Spacer from "../../components/ui/spacer"

const VerificationBody = ({
	verificationStatus,
}: {
	verificationStatus: string
}) => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const handleGetStarted = () => {
		navigate("/onboarding")
	}

	useEffect(() => {
		const timeoutId = setTimeout(handleGetStarted, 10000)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [])

	var body
	switch (verificationStatus) {
		case "loading":
			body = <Skeleton /> //TODO TRAVE
			break
		case "success":
			body = (
				<CenteredBody className="h-full pb-32">
					<Col className="gap-betweenSections items-center">
						<SectionHeader
							title="Mail verificado"
							subtitle="Tu mail ha sido verificado con éxito"
						/>
						<div className="flex flex-col w-full  h-80 items-center">
							<img
								className="w-3/4 flex items-center justify-center"
								src="/illustrations/verified-mail.svg"
								alt=""
							/>
						</div>
					</Col>
					<Button
						variant="ghost"
						className="text-text-interactive"
						onClick={handleGetStarted}
					>
						Empezar a configurar mi cuenta
					</Button>
				</CenteredBody>
			)
	}
	if (verificationStatus === "error") {
		return <ErrorPage />
	} else {
		return <CenteredBody>{body}</CenteredBody>
	}
}

export default VerificationBody
