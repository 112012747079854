import { apiPrivate } from "./interceptors"

export async function getUserOutboundAddresses({
	user_id,
	network,
	currency,
}: {
	user_id: string
	network: string
	currency: string
}) {
	const res = await apiPrivate.get(
		`/addresses/?address_type=outbound&network=${network}&currency=${currency}&user_id=${user_id}`
	)
	return res.data.data.addresses
}

export async function createUserAddress({
	user_id,
	network,
	currency,
	address,
	alias,
}: {
	user_id: string
	network: string
	currency: string
	address: string
	alias: string
}) {
	const res = await apiPrivate.post("/addresses/", {
		user_id,
		network,
		currency,
		address,
		alias,
		address_type: "outbound",
	})
	return res.data.data
}
