export const GoogleIcon = ({
	fill = "currentColor",
	filled,
	size,
	height,
	width,
	label,
	...props
}: any) => {
	return (
		<svg
			width={size || width || 24}
			height={size || height || 24}
			viewBox="0 0 24 24"
			fill={filled ? fill : "none"}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M11.9543 3C7.02511 3 3 7.04567 3 12C3 16.9543 7.02511 21 11.9543 21C16.609 21 20.4565 17.3978 20.8655 12.8167L21 11.3077H12.643V14.0769H17.7013C16.8404 16.4784 14.6502 18.2308 11.9543 18.2308C8.51569 18.2308 5.75516 15.4561 5.75516 12C5.75516 8.54387 8.51569 5.76923 11.9543 5.76923C13.5955 5.76923 15.0753 6.40745 16.1839 7.44591L18.0673 5.42308C16.4637 3.91947 14.3112 3 11.9543 3Z"
				fill={fill}
			/>
		</svg>
	)
}
