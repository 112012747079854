import { useQuery } from "@tanstack/react-query"
import { getBalance } from "../services/balances"
import logger from "../utils/logger"

export const useFetchBalances = (
	userId: string,
	roles: string[],
	teamId?: string
) => {
	return useQuery({
		queryKey: ["balances", userId, roles, teamId],
		queryFn: async () => {
			const balanceRes = await getBalance(
				userId,
				"ARS",
				roles.includes("pos") ? teamId : undefined
			)
			logger.info("Fetched balance", balanceRes)
			return {
				balances:
					balanceRes.balances.filter(
						(bal: any) =>
							parseFloat(bal.amount) > 0 &&
							parseFloat(bal.amount_preferred_currency_readable) > 0
					) || [],
				totalBalance: balanceRes.totalBalance || {
					amountReadable: "0",
					currency: "USD",
				},
			}
		},
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
        enabled: !!userId,
	})
}
