import { Price } from "@crypto-payment-gateway/models"
import { Quote } from "../../../pages/checkout/id"
import FiatPayment from "./FiatPayment"

const PixPaymentCheckout = ({
	quotes,
	pendingHandler,
	price,
	userInfo,
	language,
}: {
	quotes: Quote[]
	pendingHandler: () => void
	price: Price
	userInfo: any
	language: "spanish" | "portuguese"
}) => {
	return (
		<FiatPayment
			language={language}
			fiatQuotes={quotes}
			pendingHandler={pendingHandler}
			price={price}
		/>
	)
}

export default PixPaymentCheckout
