import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTeam } from "../columns"
import DeleteButton from "../../../ui/buttons/DeleteButton"

export function TeamDeleteCell(
	handleDelete: (user_id: string, team_id: string) => void
): ColumnDef<TableTeam> {
	return {
		id: "delete",
		cell: ({ row }: { row: Row<TableTeam> }) => {
			return (
				<DeleteButton
					handleDelete={async () =>
						await handleDelete(row.original.user_id, row.original.id)
					}
					iconClass="size-s20"
				/>
			)
		},
	}
}
