const PaymentLink = ({ size = 107, className = "" }) => {
	return (
		<svg
			className={`default-svg-style ${className}`} // Apply default styles and custom class
			width={size}
			viewBox="0 0 107 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_23108_20432)">
				<path
					d="M101.632 53.0242H22.0597L18.3512 57.2109L14.634 53.0242H5.38364C2.66738 53.0242 0.443848 50.8016 0.443848 48.0844V5.27671C0.443848 2.55948 2.66642 0.336914 5.38364 0.336914H101.632C104.348 0.336914 106.572 2.55948 106.572 5.27671V48.0844C106.572 50.8006 104.349 53.0242 101.632 53.0242Z"
					fill="white"
					stroke="black"
					strokeWidth="0.677612"
					strokeMiterlimit="10"
				/>
				<path
					d="M33.0537 39.5752C41.3323 39.5752 48.0435 32.8641 48.0435 24.5855C48.0435 16.3068 41.3323 9.5957 33.0537 9.5957C24.7751 9.5957 18.064 16.3068 18.064 24.5855C18.064 32.8641 24.7751 39.5752 33.0537 39.5752Z"
					fill="#19CE84"
				/>
				<path
					d="M33.1167 5.80713C22.7831 5.80713 14.4068 14.1844 14.4068 24.517C14.4068 25.667 14.5114 26.7928 14.7098 27.8866C12.0371 29.6087 10.2666 32.6115 10.2666 36.0277C10.2666 41.3731 14.5994 45.7059 19.9448 45.7059C22.8934 45.7059 25.5342 44.3865 27.3095 42.3072C29.1381 42.9035 31.0887 43.2268 33.1167 43.2268C43.4502 43.2268 51.8265 34.8496 51.8265 24.517C51.8265 14.1844 43.4502 5.80713 33.1167 5.80713Z"
					stroke="black"
					strokeWidth="0.677612"
					strokeMiterlimit="10"
				/>
				<path
					d="M27.4423 17.6396C26.695 17.6396 26.089 18.2447 26.089 18.9929V19.6696L24.0601 22.3752C24.0601 23.1225 24.6651 23.7285 25.4133 23.7285C26.1616 23.7285 26.7666 23.1235 26.7666 22.3752L28.1199 19.6696H30.1489L29.4722 22.3752C29.4722 23.1225 30.0773 23.7285 30.8255 23.7285C31.5738 23.7285 32.1788 23.1235 32.1788 22.3752V19.6696H34.8844V22.3752C34.8844 23.1225 35.4894 23.7285 36.2377 23.7285C36.3316 23.7285 36.4216 23.7188 36.5097 23.7004C37.1264 23.5746 37.59 23.0296 37.59 22.3752L36.9134 19.6696H38.9424L40.2957 22.3752C40.2957 23.1225 40.9007 23.7285 41.6489 23.7285C42.3972 23.7285 43.0022 23.1235 43.0022 22.3752L40.9733 19.6696V18.9929C40.9733 18.2456 40.3683 17.6396 39.62 17.6396H27.4433H27.4423ZM32.1779 24.7042C31.7781 24.9366 31.3202 25.0808 30.8246 25.0808C30.3289 25.0808 29.8749 24.9375 29.4751 24.7052C29.0754 24.9395 28.6146 25.0808 28.118 25.0808C27.6214 25.0808 27.1703 24.9385 26.7734 24.7081C26.3727 24.9424 25.909 25.0808 25.4124 25.0808V32.522C25.4124 33.2693 26.0174 33.8752 26.7657 33.8752H34.8835V27.1107H38.9424V33.8752H40.2957C41.043 33.8752 41.6489 33.2702 41.6489 32.522V25.0808C41.1523 25.0808 40.6887 24.9433 40.2879 24.7081C39.8901 24.9385 39.4351 25.0808 38.9433 25.0808C38.4516 25.0808 37.986 24.9395 37.5862 24.7052C37.1873 24.9375 36.7314 25.0808 36.2368 25.0808C35.7421 25.0808 35.2833 24.9366 34.8835 24.7042C34.4837 24.9366 34.0258 25.0808 33.5302 25.0808C33.0346 25.0808 32.5767 24.9366 32.1769 24.7042H32.1779ZM28.119 27.1098H32.1779V31.1687H28.119V27.1098Z"
					fill="white"
				/>
				<path
					d="M19.9448 45.7061C25.29 45.7061 29.6231 41.373 29.6231 36.0279C29.6231 30.6827 25.29 26.3496 19.9448 26.3496C14.5997 26.3496 10.2666 30.6827 10.2666 36.0279C10.2666 41.373 14.5997 45.7061 19.9448 45.7061Z"
					fill="white"
					stroke="black"
					strokeWidth="0.677612"
					strokeMiterlimit="10"
				/>
				<path
					d="M22.4993 31.0905C21.873 31.1205 21.2709 31.3935 20.8043 31.8601L18.9777 33.6867C19.2642 33.3982 20.33 33.5967 20.5885 33.8551L21.6939 32.7497C21.9389 32.5048 22.2457 32.3547 22.5594 32.3412C22.7723 32.3305 23.0743 32.376 23.3405 32.6413C23.5884 32.8891 23.6406 33.1775 23.6406 33.374C23.6406 33.7032 23.4906 34.0304 23.2321 34.2879L21.3096 36.2229C20.8256 36.7069 20.0899 36.7456 19.663 36.3188C19.4201 36.0758 19.0183 36.0738 18.7734 36.3188C18.5285 36.5637 18.5285 36.9635 18.7734 37.2084C19.2119 37.6469 19.7889 37.8695 20.3842 37.8695C21.027 37.8695 21.6833 37.6062 22.1867 37.0999L24.1217 35.1775C24.6125 34.6876 24.8913 34.0323 24.8913 33.375C24.8913 32.7632 24.66 32.1824 24.2302 31.7526C23.7703 31.2928 23.1527 31.0614 22.4993 31.0915V31.0905ZM19.5188 34.1911C18.876 34.1911 18.2091 34.4553 17.7038 34.9606L15.7813 36.8831C15.2905 37.3729 15.0117 38.0283 15.0117 38.6856C15.0117 39.2973 15.2431 39.8782 15.6729 40.308C16.1327 40.7678 16.7503 40.9991 17.4037 40.9691C18.03 40.9391 18.6321 40.6661 19.0987 40.1995L20.9253 38.3729C20.6369 38.6614 19.573 38.4629 19.3146 38.2045L18.2091 39.3099C17.9642 39.5548 17.6573 39.7039 17.3437 39.7184C17.1307 39.7291 16.8287 39.6836 16.5625 39.4183C16.3147 39.1705 16.2624 38.8801 16.2624 38.6856C16.2624 38.3564 16.4124 38.0292 16.6709 37.7717L18.5934 35.8367C19.0774 35.3527 19.8131 35.3149 20.24 35.7408C20.4849 35.9858 20.8856 35.9858 21.1296 35.7408C21.3745 35.4959 21.3745 35.0961 21.1296 34.8512C20.6911 34.4127 20.1122 34.1901 19.5188 34.1901V34.1911Z"
					fill="#19CE84"
				/>
				<path
					d="M59.9468 20.6831H94.6657"
					stroke="black"
					strokeWidth="0.677612"
					strokeMiterlimit="10"
				/>
				<path
					d="M59.9468 25.7573H87.3756"
					stroke="black"
					strokeWidth="0.677612"
					strokeMiterlimit="10"
					className=" group-hover:translate-x-1.5 transition duration-300 "
				/>
				<path
					d="M59.9468 31.7144H95.4062"
					stroke="black"
					strokeWidth="0.677612"
					strokeMiterlimit="10"
				/>
			</g>
			<defs>
				<clipPath id="clip0_23108_20432">
					<rect
						width="106.804"
						height="57.7229"
						fill="white"
						transform="translate(0.0976562)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default PaymentLink
