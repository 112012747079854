import { Trash2 } from "lucide-react"
import { useState } from "react"
import LoadableButton from "./LoadableButton"

const DeleteButton = ({
	handleDelete,
	iconClass,
}: {
	handleDelete: () => void
	iconClass: string
}) => {
	const [loading, setLoading] = useState(false)
	return (
		<LoadableButton
			size="icon"
			variant="ghost"
			onClick={async () => {
				setLoading(true)
				await handleDelete()
				setLoading(false)
			}}
			isLoading={loading}
		>
			{loading ? <></> : <Trash2 className={iconClass} />}
		</LoadableButton>
	)
}

export default DeleteButton
