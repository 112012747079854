import { SectionHeader } from "../../ui/section-header"
import { SettingsItemRow, SettingsItem } from "./SettingsItem"

export type SettingsSection = {
	title: string
	description?: string
	subItems: SettingsItem[]
}

const SettingsSections = ({
	title,
	description,
	subItems,
}: SettingsSection) => {
	return (
		<div className="flex flex-col w-full justify-center items-center gap-belowSectionHeader">
			<SectionHeader title={title} subtitle={description} />
			<div className="flex flex-col w-full gap-y-betweenOptions">
				{subItems.map((item) => (
					<SettingsItemRow
						key={item.link}
						icon={item.icon}
						title={item.title}
						description={item.description}
						link={item.link}
					/>
				))}
			</div>
		</div>
	)
}

export default SettingsSections
