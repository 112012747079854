export interface ICurrency {
	[x: string]: any
	alias: string
	name: string
	logoPath: string
}

export interface ICryptoCurrency extends ICurrency {
	[x: string]: any
	alias: string
	name: string
	color: string
	disabled: boolean
	textColor: string
	logoPath: string
	gradientColor: string
	lightColor: string
}

export interface IFiatCurrency extends ICurrency {
	[x: string]: any
	logoPath: string
	name: string
	alias: string
	disabled: boolean
}

export type WithdrawType = "crypto" | "transfer" | "pix"

export const fiatCurrencies: { [key: string]: IFiatCurrency } = {
	BRL: {
		logoPath: "/currencies/brl-icon.svg",
		name: "Reales",
		alias: "BRL",
		disabled: false,
	},
	ARS: {
		logoPath: "/currencies/ars-icon.svg",
		name: "Pesos",
		alias: "ARS",
		disabled: true,
	},
	USD: {
		logoPath: "/currencies/usd-icon.svg",
		name: "Dólares",
		alias: "USD",
		disabled: true,
	},
}

export const cryptoCurrencies: { [key: string]: ICurrency } = {
	USDT: {
		alias: "USDT",
		name: "Tether",
		color: "#50AF95",
		textColor: "#19675E",
		logoPath: "/currencies/usdt-icon.svg",
		gradientColor: "#D6EDD6",
		lightColor: "#EEEFEF",
	
	},
	DAI: {
		alias: "DAI",
		name: "Dai",
		color: "#F0B459",
		textColor: "#A5813C",
		gradientColor: "#F5E0C0",
		logoPath: "/currencies/dai-icon.svg",
		lightColor: "#E9E9E9",
	
	},
	USDC: {
		alias: "USDC",
		name: "USD Coin",
		color: "#2775CA",
		textColor: "#314987",
		logoPath: "/currencies/usdc-icon.svg",
		gradientColor: "#D6E7ED",
		lightColor: "#F2F2F2",
	
	},
	BNB: {
		alias: "BNB",
		name: "Binance Coin",
		color: "#E4B559",
		textColor: "#A5813C",
		logoPath: "/currencies/bnb-icon.svg",
		gradientColor: "#EDE4D6",
		lightColor: "#FFFFFF",
	
	},
	nuARS: {
		alias: "nuARS",
		name: "Num ARS",
		color: "#99CCFF",
		textColor: "#2270BE",
		logoPath: "/currencies/legacy-icons/nuars_logo_black.svg",
		gradientColor: "#D6E2ED",
		lightColor: "#F5F5F5",
	
	},
	// UXD: {
	// 	alias: "UXD",
	// 	name: "Criptodólar",
	// 	color: "#0EFBB4",
	// 	textColor: "#042317",
	// 	logoPath: "/currencies/uxd-icon.svg",
	// 	gradientColor: "#D6E2ED",
	// 	lightColor: "#F5F5F5",
	// },
}

export const currencies: { [key: string]: ICurrency } = {
	...fiatCurrencies,
	...cryptoCurrencies,
}

export const getBlackLogoPath = (currency: string) => {
	return (
		"/currencies/legacy-icons/" + currency.toLowerCase() + "_logo_black.svg"
	)
}

export const CryptoAllLogo = () => {
	return (
		<div className="flex flex-row items-center justify-center w-[40px]">
			{Object.entries(cryptoCurrencies)
				.slice(0, 2)
				.map(([key, currency]: [string, ICurrency]) => (
					<div className="-mr-2 relative" key={currency.alias}>
						<img
							src={currency.logoPath}
							alt={currency.alias}
							className="object-cover !m-0 !p-0 object-top rounded-full h-full  border-1 border-white relative "
						/>
					</div>
				))}
		</div>
	)
}