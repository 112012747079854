import { Row } from "@tanstack/react-table"
import { Download } from "lucide-react"
import { Button } from "../../ui/button"
import { TextColumnCell } from "../TextColumnCell"

export interface TableQR {
	id: string
	user_id: string
	code: string
	creation_date: string
	url: string
}

const idColumn = TextColumnCell<TableQR>("id")
const userColumn = TextColumnCell<TableQR>("user_id")
const creationDateColumn = TextColumnCell<TableQR>("creation_date")
const codeColumn = TextColumnCell<TableQR>("code")

function ActionsCell() {
	return {
		id: "actions",
		cell: ({ row }: { row: Row<TableQR> }) => (
			<Button
				size="icon"
				variant="outline"
				onClick={() =>
					import("../../../utils/qr_crypto_download").then(module => {
						const { handleGeneratePdf } = module
						handleGeneratePdf(
							row.original.id,
							row.original.code,
							row.original.url,
							true
						)
					})
					
				}
			>
				<Download />
			</Button>
		),
	}
}

const actionsCell = ActionsCell()
export const columns = {
	xl: [idColumn, userColumn, codeColumn, creationDateColumn, actionsCell],
	lg: [idColumn, userColumn, codeColumn, creationDateColumn, actionsCell],
	md: [userColumn, codeColumn, actionsCell],
	sm: [userColumn, codeColumn, actionsCell],
}
