import { MenuItem } from "../../../../pages/app/items"
import Col from "../../layouts/column"
import SideBarMenuItem from "./SideBarMenuItem"

const SideBarMenu = ({
	items,
	activeTab,
}: {
	items: MenuItem[]
	activeTab?: string
}) => {
	return (
		<div className="p-[10px] h-fit max-h-[500px] min-w-[155px] overflow-clip overflow-y-scroll rounded-md w-full outline outline-offset-0 outline-border-navbar bg-background-navbar outline-8 hover:scale-[1.02] transform-gpu transition duration-500 hover:drop-shadow-2xl scrollbar-hide">
			<Col className="gap-[29px] items-center justify-center h-fit  py-[20%]">
				{items.map((item) => (
					<SideBarMenuItem
						{...item}
						key={item.itemName}
						status={item.itemName === activeTab ? "active" : "inactive"}
					/>
				))}
			</Col>
		</div>
	)
}

export default SideBarMenu
