import { useMediaQuery } from "../../../utils/useMediaQuery"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { Sheet, SheetContent, SheetHeader } from "../../ui/sheet"

const CryptoModalBottomSheet = (props: {
	onClose: () => void
	isModalOpen: boolean
	body: JSX.Element
}) => {
	const isSm = useMediaQuery(960)
	return (
		<Sheet open={props.isModalOpen} onOpenChange={props.onClose}>
			<SheetContent side="bottom">
				<SheetHeader />
				<CenteredBody>{props.body}</CenteredBody>
			</SheetContent>
		</Sheet>
	)
}

export default CryptoModalBottomSheet
