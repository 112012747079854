import { TextColumnCell } from "../TextColumnCell"
import { StatusCell } from "./StatusCell"
import { UserRoleCell } from "./UserRoleCell"

export interface TableUser {
	user_id: string
	team_id: string
	email: string
	role: string
	status: string
	creation_date: string
}
const emailColumnCell = TextColumnCell<TableUser>("email")
const userIdColumnCell = TextColumnCell<TableUser>("user_id")
const teamIdColumnCell = TextColumnCell<TableUser>("team_id")
const creationDateCell = TextColumnCell<TableUser>("creation_date")
const roleCell = UserRoleCell()
const statusCell = StatusCell()

export const columns = {
	xl: [
		statusCell,
		emailColumnCell,
		userIdColumnCell,
		teamIdColumnCell,
		creationDateCell,
		roleCell,
	],
	lg: [
		statusCell,
		emailColumnCell,
		userIdColumnCell,
		teamIdColumnCell,
		creationDateCell,
		roleCell,
	],
	md: [statusCell, emailColumnCell, roleCell],
	sm: [statusCell, emailColumnCell, roleCell],
}
