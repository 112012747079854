import { AvatarFallback } from "@radix-ui/react-avatar"
import { Check, Settings, User } from "lucide-react"
import { Avatar, AvatarImage } from "../../avatar"
import Row from "../../layouts/row"
import { Heading6 } from "../../texts/TextStyle"

const UserAccountRow = ({
	displayName,
	photoUrl,
	link,
	selected,
}: {
	displayName: string
	photoUrl: string
	link?: string
	selected: boolean
}) => {
	return (
		<Row className="w-full px-1 group justify-between items-center">
			<Row className="w-[200px] h-9 rounded-md justify-start items-center gap-2 inline-flex">
				<Avatar className="!size-sm">
					<AvatarImage src={photoUrl} alt={displayName} />
					<AvatarFallback>
						<div className="size-sm rounded-full [&>*]:size-[12px] [&>*]:stroke-[2.5px] group-hover:animate-[wiggle_1s_ease-in-out_infinite] bg-background-strong grid justify-center items-center">
							<User />
						</div>
					</AvatarFallback>
				</Avatar>
				<Heading6 className="justify-start items-center gap-2 text-b1 flex-1 font-normal overflow-hidden overflow-ellipsis group-hover:translate-x-1 transition duration-400">
					{displayName}
				</Heading6>
			</Row>
			{/* Esto lo dejo comentado para el proximo UPDATE
			 {link && (
				<Check
					size="16px"
					className="text-icon-muted relative w-[16px] h-[16px]"
				/>
			)}
			{selected && (
				<Settings
					size="16px"
					className="text-icon-muted relative  w-[16px] h-[16px]"
				/>
			)} */}
		</Row>
	)
}

export default UserAccountRow
