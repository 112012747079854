import { useEffect, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import PluginSuccessModal from "../../../components/modals/plugin-success/PluginSuccessModal"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { useAuth } from "../../../context/AuthContext"
import { StoreType, createUserStore } from "../../../services/users"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { TiendanubeCover } from "../payment-methods/PaymentsCover"
import { useMutation } from "@tanstack/react-query"
import { FileDigit, Loader, Search } from "lucide-react"
import PluginFailedModal from "../../../components/modals/plugin-success/PluginFailedModal"
import { Input } from "../../../components/ui/inputs/input"
import { Button } from "../../../components/ui/button"
import SlidingCard from "../../../components/ui/sliding-card"
import { FiatAddressDetails } from "../../withdraw/withdraw-wizard-types"
import useGetFiatAddressDetails from "../../../hooks/useGetFiatAddressDetails"
import { Skeleton } from "../../../components/ui/skeleton"
import CopyButton from "../../../components/ui/buttons/CopyButton"
import { mapBankInfoResponseToFiatAddressDetails } from "../../../services/cvu"
import { findImgForBankInJson, getFormattedStoreUrl } from "../../../lib/utils"
import bank_list from "../../../lib/psp_bank_id.json"
import { Textarea } from "../../../components/ui/textarea"
import BetaSnack from "../../../components/ui/beta-snack"
import InputErrorMessage from "../../../components/ui/inputs/InputErrorMessage"
import { Alert } from "../../../components/ui/alert"

export enum TiendanubeCvuSteps {
	Start = "start",
	Installed = "installed",
	Backup = "backup",
}

const AdvancedTiendanubeIntegration = ({ appId }: { appId?: string }) => {
	const navigate = useNavigate()
	const { user, stores, fetchStores } = useAuth()
	//This is the success modal
	const [isModalOpen, setModalOpen] = useState<boolean>(false)
	//This is the error modal
	const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false)
	const [tiendanubeStores, setTiendanubeStores] = useState<any[]>([])
	//This is the sliding card for backup address
	const [isSlidingCardOpen, setIsSlidingCardOpen] = useState<boolean>(false)
	const [miniWizardStatus, setMiniWizardStatus] = useState<TiendanubeCvuSteps>(
		TiendanubeCvuSteps.Start
	)
	const [fiatAddressDetails, setFiatAddressDetails] =
		useState<FiatAddressDetails>()

	//input ref for backup address
	const inputRef = useRef<HTMLInputElement>(null)

	const bankInfoResponse = useGetFiatAddressDetails({
		address: inputRef.current?.value,
	})

	const fiatAddressLoaded = useRef<boolean>(false)

	useEffect(() => {
		if (
			bankInfoResponse.isSuccess &&
			bankInfoResponse.data &&
			!fiatAddressLoaded.current
		) {
			let icon_img = findImgForBankInJson(
				bankInfoResponse.data.cuenta.nro_psp ??
					bankInfoResponse.data.cuenta.nro_bco ??
					"",
				bank_list
			)
			var fad = mapBankInfoResponseToFiatAddressDetails(
				bankInfoResponse.data,
				inputRef.current?.value ?? "",
				icon_img
			)
			setFiatAddressDetails(fad)
			setIsSlidingCardOpen(true)
			fiatAddressLoaded.current = true
		}
	}, [bankInfoResponse, inputRef.current?.value])

	const hasTriggeredMutation = useRef<boolean>(false)

	useEffect(() => {
		if (!stores) return

		const tiendanubeStores = stores.filter((store: any) => {
			const isStore =
				store.store_type === StoreType.TIENDANUBE &&
				store.store_apps.find((app: any) => app.app_id === appId)

			return isStore
		})

		if (tiendanubeStores.length > 0) {
			setMiniWizardStatus(TiendanubeCvuSteps.Installed)
		}

		setTiendanubeStores(tiendanubeStores)
	}, [stores])

	const [searchParams, setSearchParams] = useSearchParams()

	const wppLink = "https://wa.me/message/NLA2DUMYC5E3E1/"

	const code = searchParams.get("code")

	const mutation = useMutation({
		mutationFn: async () => {
			if (appId === undefined) {
				throw new Error("REACT_APP_TIENDANUBE_AUTH_URL not set")
			}

			if (!hasTriggeredMutation.current) {
				hasTriggeredMutation.current = true
				return await createUserStore({
					user_id: user?.id,
					authorization_code: code!,
					store_type: StoreType.TIENDANUBE,
					app_id: appId,
					created_by: user?.team_id,
				})
			} else {
				console.log("Mutation skipped due to previous execution.")
			}
		},
	})

	useEffect(() => {
		// Get user stores and check if it has a Tiendanube app installed

		if (!stores) {
			return
		}

		if (
			code &&
			tiendanubeStores.length === 0 &&
			!hasTriggeredMutation.current
		) {
			mutation.mutate()
			setSearchParams({})
		}
		if (mutation.isSuccess && hasTriggeredMutation.current) {
			hasTriggeredMutation.current = false
			setMiniWizardStatus(TiendanubeCvuSteps.Installed)
			fetchStores()
		}

		if (mutation.isError && hasTriggeredMutation.current) {
			hasTriggeredMutation.current = false
			setErrorModalOpen(true)
		}
	}, [code, stores, mutation, setSearchParams])

	const { kyc } = useAuth()
	const handleInstallClick = () => {
		if (process.env.REACT_APP_TIENDANUBE_CVU_AUTH_URL)
			window.location.href = process.env.REACT_APP_TIENDANUBE_CVU_AUTH_URL
		else console.error("REACT_APP_TIENDANUBE_CVU_AUTH_URL not set")
	}

	function onConfirmAddress() {
		setIsSlidingCardOpen(false)
		setMiniWizardStatus(TiendanubeCvuSteps.Backup)
	}

	function mapFiatAddressToTiendanubeText(
		bankInfoResponse: FiatAddressDetails | undefined
	) {
		if (!bankInfoResponse) return ""
		return `#id8k7p6w420e87h07 

Transferir a
Titular: ${bankInfoResponse.name}
Alias: ${bankInfoResponse.alias}
CBU: ${bankInfoResponse.cbu}
Enviar comprobante a ${user.email}`
	}

	const body = {
		[TiendanubeCvuSteps.Start]: (
			<FeatureLayout
				coverImage={<TiendanubeCover />}
				sectionHeaderProps={{
					title: "Conectá tu Tiendanube",
					subtitle:
						"Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y conciliar los pagos automáticamente.",
				}}
			>
				<Section
					title="Integración"
					footer="Se te redirigirá a tu Tiendanube para que instales la app de Talo Pay y aceptes los permisos necesarios."
				>
					{stores && (
						<Option
							title="Tiendanube + Talo Pay"
							description={tiendanubeStores.length > 0 ? "Activa" : "Inactiva"}
							iconInside={false}
							icon={
								<img
									src="/dashboard/plugins/tiendanube.svg"
									className="size-full"
								/>
							}
							leading="button"
							buttonText={
								mutation.isPending ? (
									<Loader className="animate-spin" />
								) : tiendanubeStores.length > 0 ? (
									"Instalada"
								) : (
									"Instalar"
								)
							}
							buttonProps={{ disabled: tiendanubeStores.length > 0 }}
							onClick={() => {
								handleInstallClick()
							}}
						/>
					)}
				</Section>
			</FeatureLayout>
		),
		[TiendanubeCvuSteps.Installed]: (
			<FeatureLayout
				coverImage={<TiendanubeCover />}
				sectionHeaderProps={{
					title: "Agregá un respaldo",
					subtitle:
						"Ingresá una cuenta bancaria de respaldo para recibir tus fondos directamente en el caso que nuestro servicio no este disponible. ",
				}}
			>
				<Section>
					<div className="flex flex-row gap-betweenFields">
						<Input type="text" ref={inputRef} placeholder="CVU, CBU o Alias" />

						<Button
							size="icon"
							variant={"outline"}
							className="h-full w-14 rounded-sm"
							onClick={() => {
								if (inputRef.current?.value === "") {
									return
								}
								bankInfoResponse.refetch()
								fiatAddressLoaded.current = false
							}}
						>
							{bankInfoResponse.isLoading || bankInfoResponse.isFetching ? (
								<Loader className="size-icon animate-spin" />
							) : (
								<Search className="size-icon" />
							)}
						</Button>
					</div>
					{bankInfoResponse.isError && (
						<InputErrorMessage
							errorText={"No pudimos encontrar informacion para esta cuenta"}
						/>
					)}
				</Section>
			</FeatureLayout>
		),
		[TiendanubeCvuSteps.Backup]: (
			<FeatureLayout
				coverImage={<TiendanubeCover />}
				sectionHeaderProps={{
					title: "Ultima cosa...",
					subtitle: (
						<p className="inline">
							Por ultimo, copia el siguiente texto y pegalo en tu método de pago
							personalizado dentro de{" "}
							<a
								href={
									tiendanubeStores &&
									tiendanubeStores[0] &&
									tiendanubeStores[0].store_url
										? `https://${getFormattedStoreUrl(
												tiendanubeStores[0].store_url
										  )}/admin/v2/settings/payments/custom-payments`
										: ""
								}
								target="_blank"
								rel="noreferrer"
								className="text-text-interactive"
							>
								Tiendanube.
							</a>
						</p>
					),
				}}
			>
				<Section footer="Este texto solo aparecerá en caso de que el servicio no se encuentre activo.">
					<Alert
						icon={<FileDigit />}
						title={"Atención"}
						variant={"warning"}
						description={
							"No cambies el #ID, de lo contrario no podremos identificar tu transferencia."
						}
					/>
					<Textarea
						className="min-h-[200px]"
						disabled
						copyButton
						value={mapFiatAddressToTiendanubeText(
							fiatAddressDetails ?? undefined
						)}
					/>
				</Section>
				<Button
					onClick={() => {
						setModalOpen(true)
					}}
				>
					Finalizar
				</Button>
			</FeatureLayout>
		),
	}

	return (
		<>
			{body[miniWizardStatus]}
			<PluginFailedModal
				open={isErrorModalOpen}
				onClose={() => setErrorModalOpen(false)}
				retryFunction={() => {
					handleInstallClick()
				}}
			/>
			<PluginSuccessModal
				open={isModalOpen}
				onClose={() => {
					setModalOpen(false)
					if (user.pollux_account_id) {
						navigate("/settings/payment-methods", { replace: true })
					} else {
						const url = new URL(
							window.location.pathname,
							window.location.origin
						)
						const paths = url.pathname.split("/")
						// Remove the last three paths
						paths.splice(-3)
						url.pathname = paths.join("/")
						// Redirect the user to the new URL
						navigate(url.pathname, { replace: true })
					}
				}}
			/>
			<SlidingCard
				side="center"
				open={isSlidingCardOpen}
				onOpenChange={setIsSlidingCardOpen}
			>
				<Section className="gap-betweenOptions" title="Usuario encontrado">
					{(bankInfoResponse.isLoading || bankInfoResponse.isFetching) && (
						<>
							<Skeleton className="h-[80px]" />
						</>
					)}
					{bankInfoResponse.isSuccess && bankInfoResponse.data && (
						<Option
							title={fiatAddressDetails?.name}
							description={fiatAddressDetails?.address}
							icon={
								<img
									src={
										fiatAddressDetails?.icon_img
											? fiatAddressDetails?.icon_img
											: `/currencies/ars-icon.svg`
									}
									width={24}
									alt="ARS"
								/>
							}
							iconInside={false}
							onClick={() => {
								onConfirmAddress()
							}}
						></Option>
					)}
				</Section>
			</SlidingCard>
			<BetaSnack className="md:hidden" />
		</>
	)
}

export default AdvancedTiendanubeIntegration
