import { ColumnDef } from "@tanstack/react-table"
import { TableTeam } from "../columns"
import Row from "../../../ui/layouts/row"
import { PublicRole } from "../../../../lib/roles"
import RoleChip from "../../../app/teams/RoleChip"

export function TeamRoleCell(): ColumnDef<TableTeam> {
	return {
		accessorKey: "roles",
		header: "Permisos",
		cell: ({ row }) => {
			return (
				<Row className="items-center">
					{((row.getValue("roles") as PublicRole[]) ?? []).map(
						(role: PublicRole) => (
							<RoleChip role={role} key={role} />
						)
					)}
				</Row>
			)
		},
	}
}
