import { Angry } from "lucide-react"
import { Alert } from "../ui/alert"
import { TimeoutPaymentAnimation } from "../ui/animations/timeout-payment-animation"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import Row from "../ui/layouts/row"
import { SectionHeader } from "../ui/section-header"
import Spacer from "../ui/spacer"


interface ExpiredPaymentProps {
	language: "spanish" | "portuguese"
}

const ExpiredPayment: React.FC<ExpiredPaymentProps> = ({ language }) => {
	return (
		<CenteredBody bottom>
			<Row className="w-full justify-center">
				<TimeoutPaymentAnimation size="50%" />
			</Row>
			<Spacer axis="vertical" type="belowSectionHeader" />

			<SectionHeader
				title={
					language === "spanish"
						? "Este pago se encuentra expirado"
						: "Este pagamento expirou"
				}
				subtitle={
					language === "spanish"
						? "Por motivos de seguridad, los pagos duran 10 minutos."
						: "Por motivos de segurança, os pagamentos demoram 10 minutos."
				}
			/>
			<Alert
				icon={<Angry className="h-4 w-4" />}
				title={
					language === "spanish"
						? "¿Que podes hacer?"
						: "O que você pode fazer?"
				}
				description={
					<ul className="text-h6 flex flex-col list-inside text-text-muted list-disc mt-2 [&>*]:m-0">
						{language === "spanish" ? (
							<>
								<li>Pedirle a la marca que te vuelva a enviar un link.</li>
								<li>Volver a generar la compra</li>
								<li>Realizar el pago mas rápido, la próxima vez :(</li>
							</>
						) : (
							<>
								<li>Peça à marca para lhe enviar um link novamente.</li>
								<li>Regenerar a compra</li>
								<li>Faça o pagamento mais rápido, da próxima vez :(</li>
							</>
						)}
					</ul>
				}
				bulletList
			/>
		</CenteredBody>
	)
}

export default ExpiredPayment
