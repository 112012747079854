import { SquareMenu } from "lucide-react"
import { ShopifyPaymentInfo } from "../../../../tables/transactions-table/columns"
import { Button } from "../../../../ui/button"
import Spacer from "../../../../ui/spacer"
import BodyBlock from "../BodyBlock"
import BodyItem from "../BodyItem"
import { useAuth } from "../../../../../context/AuthContext"

export function getShopifyAdminUrl(myshopify_domain: string) {
	try {
		const id = myshopify_domain.split(".")[0]
		return `https://admin.shopify.com/store/${id}`
	} catch (err) {
		return undefined
	}
}

const ShopifyPaymentDetails = ({
	info,
	store_url,
}: {
	info: ShopifyPaymentInfo
	store_url?: string
}) => {
	const { stores } = useAuth()
	const store_name = stores?.find(
		(store) => store.store_id === info.store_id
	)?.store_name
	return (
		<BodyBlock title="Shopify">
			<BodyItem name="ID de orden" value={`${info.order_id}`} />
			{info.order_name && (
				<BodyItem name="Número de orden" value={`${info.order_name}`} />
			)}
			{stores && info.store_id && store_name && (
				<BodyItem name="Tienda" value={`${store_name}`} />
			)}
			{store_url && (
				<Button asChild variant="outline" size="full">
					<a href={`${store_url}/orders/${info.order_id}`} target="_blank">
						Ir a la orden en Shopify
						<Spacer axis="horizontal" type="betweenTexts" />
						<SquareMenu className="size-icon" />
					</a>
				</Button>
			)}
		</BodyBlock>
	)
}

export default ShopifyPaymentDetails
