import { Row } from "@tanstack/react-table"
import { Link, QrCode } from "lucide-react"
import { TablePayment } from "./data"

export function PaymentTypeCell() {
	return {
		accessorKey: "type",
		header: "Type",
		cell: ({ row }: { row: Row<TablePayment> }) => {
			switch (row.getValue("type")) {
				case "tiendanube":
					return (
						<img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />
					)
				case "woocommerce":
					return (
						<img src="/dashboard/plugins/woocommerce.svg" alt="woocommerce" />
					)
				case "qr":
					return <QrCode />
				case "pix":
					return <img src="/flags/brasil.svg" alt="pix" />
				default:
					return <Link />
			}
		},
		filterFn: (row: Row<TablePayment>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
	}
}
