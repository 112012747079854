"use client"

import * as React from "react"
import * as LabelPrimitive from "@radix-ui/react-label"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../lib/utils"

const labelVariants = cva(
	"text-h6 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
)

interface LabelProps
	extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
		VariantProps<typeof labelVariants> {
	subtext?: string
	required?: boolean
}

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	LabelProps
>(({ className, subtext, required, ...props }, ref) => (
	<div className="flex flex-col gap-2">
		<div className="justify-start items-center gap-0.5 inline-flex">
			<LabelPrimitive.Root
				ref={ref}
				className={cn(labelVariants(), className)}
				{...props}
			/>
			{required && (
				<div className="text-red-600 text-sm font-medium font-['Inter'] leading-tight">
					*
				</div>
			)}
		</div>
		{subtext && (
			<p className="text-h6 font-normal leading-none text-text-muted">
				{subtext}
			</p>
		)}
	</div>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
