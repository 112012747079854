import { DateRange } from "react-day-picker"
import { TableTransaction } from "../components/tables/transactions-table/columns"

const formatDate = (dateString: string) => {
	const date = new Date(dateString)
	return date.toISOString().split("T")[0] // Returns YYYY-MM-DD
}

// Update formatNumber to handle negative values for outbound transactions
const formatNumber = (
	num: number | string | undefined,
	currency?: string
): string => {
	console.log(num)
	if (num === undefined) return '"0"'
	const numericValue =
		typeof num === "string" ? parseFloat(num.replace(",", "")) : num
	if (isNaN(numericValue)) return '"0"'

	// Set decimals based on currency
	const decimals = currency === "BNB" ? 8 : 2

	// Format number with comma decimal separator
	console.log(numericValue)
	const formattedNumber = Math.abs(numericValue).toLocaleString("es-AR", {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
		useGrouping: false,
	})

	console.log(formattedNumber)

	// Add the sign in front
	return `"${numericValue < 0 ? "-" : ""}${formattedNumber}"`
}

// Update escapeField to handle undefined values
const escapeField = (field: string | number | undefined): string => {
	if (field === undefined) return '""'
	if (typeof field === "number") return formatNumber(field)
	return `"${field.replace(/"/g, '""')}"`
}

const translateTransactionStatus = (status: string): string => {
	const statusMap: { [key: string]: string } = {
		SUCCESS: "COMPLETO",
		PENDING: "PENDIENTE",
		UNDERPAID: "PAGO PARCIAL",
		OVERPAID: "PAGO EXCEDIDO",
		SENT: "ENVIADO",
		OUTBOUND: "ENVIADO",
		processed: "PROCESADO",
		pending: "PENDIENTE",
	}
	return statusMap[status] || status
}

// Add this helper function to normalize dates for CSV export
const normalizeDate = (dateStr: string): string => {
	const today = new Date()
	const yesterday = new Date(today)
	yesterday.setDate(yesterday.getDate() - 1)

	// Convert 'Hoy' to current date
	if (dateStr.toLowerCase() === "hoy") {
		return today.toLocaleDateString("es-AR", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		})
	}

	// Convert 'Ayer' to yesterday's date
	if (dateStr.toLowerCase() === "ayer") {
		return yesterday.toLocaleDateString("es-AR", {
			day: "2-digit",
			month: "2-digit",
			year: "2-digit",
		})
	}

	// Return original date if it's already in DD/MM/YY format
	return dateStr
}

// Update the generateCSV function to include both dates
const generateCSV = (transactions: TableTransaction[]) => {
	const headers = [
		"Número de Orden",
		"Id de orden",
		"Monto",
		"Moneda",
		"CVU/CBU Comprador",
		"Estado",
		"Fecha Orden",
		"Hora Orden",
		"Fecha Pago",
		"Hora Pago",
		"Tipo",
	]

	const csvContent = [headers.map(escapeField).join(",")]

	if (transactions && transactions.length > 0) {
		const filteredTransactions = transactions.filter(
			(tx) =>
				tx.status !== "EXPIRED" &&
				tx.status !== "PENDING" &&
				tx.status !== "pending"
		)

		filteredTransactions
			.sort((a, b) => {
				const dateA = parseSpanishDate(
					a.lastModifiedDateTime.date,
					a.lastModifiedDateTime.time
				)
				const dateB = parseSpanishDate(
					b.lastModifiedDateTime.date,
					b.lastModifiedDateTime.time
				)
				return dateB.getTime() - dateA.getTime()
			})
			.forEach((tx) => {
				if (tx.currency === "USDT") {
					console.log(tx)
				}
				try {
					const isOutbound =
						tx.status.toUpperCase() === "SENT" ||
						tx.status.toUpperCase() === "OUTBOUND" ||
						tx.status.toUpperCase() === "PROCESSED" ||
						(typeof tx.price?.amount === "number" && tx.price.amount < 0)
					const amount = tx.received?.amount || tx.expected?.amount || 0

					// Normalize dates for CSV export
					const orderDate = normalizeDate(
						tx.creationDateTime?.date || tx.lastModifiedDateTime.date
					)
					const paymentDate = normalizeDate(tx.lastModifiedDateTime.date)

					const rowData = [
						escapeField(
							tx.tiendanube?.order_number ?? tx.shopify?.order_name ?? "-"
						),
						escapeField(
							tx.tiendanube?.order_id ??
								tx.shopify?.order_id ??
								tx.woocommerce?.order_id ??
								"-"
						),
						formatNumber(amount, tx.currency),
						escapeField(tx.currency),
						escapeField(tx.sender),
						escapeField(translateTransactionStatus(tx.status).toUpperCase()),
						escapeField(orderDate),
						escapeField(
							tx.creationDateTime?.time || tx.lastModifiedDateTime.time
						),
						escapeField(paymentDate),
						escapeField(tx.lastModifiedDateTime.time),
						escapeField(isOutbound ? "ENVÍO" : "COBRO"),
					].join(",")
					csvContent.push(rowData)
				} catch (error) {
					csvContent.push("-,-,-,-,-,-,-,-,-")
				}
			})
	}

	return csvContent
}

// Helper function for downloading CSV
const formatDateForFileName = (date: Date): string => {
	const day = date.getDate().toString().padStart(2, "0")
	const month = (date.getMonth() + 1).toString().padStart(2, "0")
	const year = date.getFullYear()
	return `${day}-${month}-${year}`
}

const downloadCSV = (csvContent: string[], suffix: string = "") => {
	const BOM = "\uFEFF"
	const csvString = BOM + csvContent.join("\n")
	const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" })
	const link = document.createElement("a")
	const url = URL.createObjectURL(blob)

	link.setAttribute("href", url)
	link.setAttribute("download", `Transacciones${suffix}.csv`)
	link.style.visibility = "hidden"

	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
	URL.revokeObjectURL(url)
}

// Modified export functions to use TableTransaction type
export const exportAllPaymentsToCSV = (transactions: TableTransaction[]) => {
	const csvContent = generateCSV(transactions)
	downloadCSV(csvContent)
}

// Add this helper function to parse Spanish formatted dates
const parseSpanishDate = (dateStr: string, timeStr: string): Date => {
	// Handle "Hoy" (Today) case
	if (dateStr.toLowerCase() === "hoy") {
		const today = new Date()
		const [hours, minutes] = timeStr.split(":")
		const isPM = timeStr.toLowerCase().includes("p. m.")

		today.setHours(
			isPM ? parseInt(hours) + 12 : parseInt(hours),
			parseInt(minutes),
			0,
			0
		)
		return today
	}

	// Handle "Ayer" (Yesterday) case
	if (dateStr.toLowerCase() === "ayer") {
		const yesterday = new Date()
		yesterday.setDate(yesterday.getDate() - 1)
		const [hours, minutes] = timeStr.split(":")
		const isPM = timeStr.toLowerCase().includes("p. m.")

		yesterday.setHours(
			isPM ? parseInt(hours) + 12 : parseInt(hours),
			parseInt(minutes),
			0,
			0
		)
		return yesterday
	}

	// Handle regular date format DD/MM/YY
	const [day, month, year] = dateStr.split("/")
	const [hours, minutes] = timeStr.split(":")
	const isPM = timeStr.toLowerCase().includes("p. m.")

	// Create date with full year (assuming 20xx for YY format)
	const fullYear = parseInt("20" + year)
	const date = new Date(fullYear, parseInt(month) - 1, parseInt(day))

	// Set time
	date.setHours(
		isPM ? parseInt(hours) + 12 : parseInt(hours),
		parseInt(minutes),
		0,
		0
	)

	return date
}

export const exportPaymentsInDateRange = async (
	transactions: TableTransaction[],
	dateRange: DateRange
) => {
	if (!dateRange.from) return

	const to = dateRange.to || dateRange.from
	const endDate = new Date(to.setHours(23, 59, 59, 999))

	const filteredTransactions = transactions.filter((tx) => {
		try {
			const txDate = parseSpanishDate(
				tx.creationDateTime.date,
				tx.creationDateTime.time
			)

			return (
				!isNaN(txDate.getTime()) &&
				txDate >= dateRange.from! &&
				txDate <= endDate
			)
		} catch (error) {
			return false
		}
	})

	const csvContent = generateCSV(filteredTransactions)
	const dateStr = dateRange.to
		? `${formatDateForFileName(dateRange.from)}_${formatDateForFileName(
				dateRange.to
		  )}`
		: formatDateForFileName(dateRange.from)

	downloadCSV(csvContent, `_${dateStr}`)
}
