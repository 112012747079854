import { Button } from "../../ui/button"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
import SlidingCard from "../../ui/sliding-card"
import { Heading6 } from "../../ui/texts/TextStyle"
import PluginFailedCover from "./plugin-instalation-animation"

const PluginFailedModal = ({
	open,
	onClose,
	retryFunction,
}: {
	open: boolean
	onClose: () => void
	retryFunction: () => void
}) => {
	return (
		<SlidingCard
			side="center"
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
		>
			<CenteredBody className=" items-center">
				<SectionHeader
					title="¡Lo sentimos!"
					subtitle="Hubo un problema con la integración"
				/>
				<PluginFailedCover />
				<Button
					size={"full"}
					variant={"outline"}
					onClick={() => {
						retryFunction()
					}}
				>
					Reintentar integración
				</Button>
			</CenteredBody>
		</SlidingCard>
	)
}

export default PluginFailedModal
