import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
} from "react"

export type GlobalStore = {
	deepLink?: string
	isDarkTheme: boolean
}

const defaultGlobalStore: GlobalStoreContextType = {
	globalStore: { isDarkTheme: false },
	setGlobalStore: () => {},
	setIsDarkTheme: () => {},
	isDarkTheme: false,
}

export type GlobalStoreContextType = {
	globalStore: GlobalStore
	setGlobalStore: React.Dispatch<React.SetStateAction<GlobalStore>>
	setIsDarkTheme: (value: boolean) => void
	isDarkTheme: boolean
}

const GlobalStoreContext =
	createContext<GlobalStoreContextType>(defaultGlobalStore)

export const GlobalStoreProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [globalStore, setGlobalStore] = useState<GlobalStore>({
		isDarkTheme: false,
	})

	useEffect(() => {
		const isDarkTheme = localStorage.getItem("isDarkTheme") === "true"
		if (isDarkTheme) {
			setGlobalStore((prevStore) => ({ ...prevStore, isDarkTheme: true }))
		}
	}, [])

	useEffect(() => {
		if (globalStore.isDarkTheme) {
			document.documentElement.classList.add("dark")
			localStorage.setItem("isDarkTheme", "true")
		} else {
			document.documentElement.classList.remove("dark")
			localStorage.setItem("isDarkTheme", "false")
		}
	}, [globalStore])

	const setIsDarkTheme = (value: boolean) => {
		setGlobalStore((prevStore) => ({ ...prevStore, isDarkTheme: value }))
	}

	return (
		<GlobalStoreContext.Provider
			value={{
				globalStore,
				setGlobalStore,
				setIsDarkTheme,
				isDarkTheme: globalStore.isDarkTheme,
			}}
		>
			{children}
		</GlobalStoreContext.Provider>
	)
}

export const useGlobalStore = () => useContext(GlobalStoreContext)
