import { Price } from "@crypto-payment-gateway/models"
import { QRCodeSVG } from "qrcode.react"
import { useState } from "react"
import { Quote } from "../../../pages/checkout/id"
import { fiatCurrencies } from "../../../utils/currencies"
import { useMediaQuery } from "../../../utils/useMediaQuery"
import { Button } from "../../ui/button"
import { Input } from "../../ui/inputs/input"
import Col from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import { Body1, Heading4, Heading5, Heading6 } from "../../ui/texts/TextStyle"
import FiatModalBottomSheet from "./FiatModalBottomSheet"
import QRModalMobile from "./QRModalMobile"

const FiatPayment = ({
	fiatQuotes,
	pendingHandler,
	price,
	language,
}: {
	fiatQuotes: Quote[]
	pendingHandler: () => void
	price: Price
	language: "spanish" | "portuguese"
}) => {
	const isSm = useMediaQuery(960)
	const [fiatCurrency, setFiatCurrency] = useState<string>("BRL")
	const fiatQuote = fiatQuotes.find((q) => q.currency === fiatCurrency)
	const [isModalOpen, setModalOpen] = useState<boolean>(false)
	const [qrVisible, setQrVisible] = useState<boolean>(false)
	console.log(isModalOpen)
	const handleQRModal = () => {
		setQrVisible(true)
	}

	return (
		<Col className="gap-betweenSections">
			<QRModalMobile
				isModalOpen={qrVisible}
				onClose={() => setQrVisible(false)}
				address={fiatQuote?.address || ""}
			/>

			<div className="flex flex-col gap-5 mt-4">
				<Row className="items-center justify-between">
					<Heading5 className="font-medium">
						{language === "spanish" ? "Moneda" : "Moeda"}
					</Heading5>
					<Button
						onClick={() => {
							console.log("click")
							setModalOpen(true)
						}}
						variant="ghost"
						size="sm"
					>
						<Heading6 className="font-normal text-end text-text-interactive ">
							{language === "spanish"
								? "¿No es tu moneda?"
								: "Não é a sua moeda?"}
						</Heading6>
					</Button>
					<FiatModalBottomSheet
						language={language}
						onClose={() => setModalOpen(false)}
						isModalOpen={isModalOpen}
						onCurrencySelected={(currency) => {
							setFiatCurrency(currency)
							setModalOpen(false)
						}}
					/>
				</Row>
				<Col className="gap-1	">
					<div className="flex justify-end">
						<Body1 className="text-text-muted  text-right">
							${price.amount.toLocaleString()} {price.currency}
						</Body1>
					</div>
					<div className="flex justify-between">
						<div className="flex items-center">
							<img
								className="pr-[8px] size-[28px]"
								src={fiatCurrencies[fiatCurrency].flagPath}
								alt="brasil"
							/>
							<Heading4 className="font-semibold">
								{language === "spanish"
									? fiatCurrencies[fiatCurrency].name
									: "Reais"}
							</Heading4>
						</div>
						<Heading4 className="font-bold text-right">
							= ${fiatQuote?.amount} {fiatQuote?.currency}
						</Heading4>
					</div>
				</Col>
			</div>

			<div className="rounded-sm flex justify-between items-center p-xs bg-transparent md:bg-background-subtle md:hover:scale-[1.01] transition duration-400">
				<div className="w-full md:ml-6 h-fit flex flex-col gap-textToComponent ">
					<div className="flex flex-col gap-betweenTexts">
						<div className="flex flex-row items-center">
							<Heading6 className="font-bold text-lg mr-3">
								{language === "spanish"
									? "Copie su código PIX"
									: "Copie seu código PIX"}
							</Heading6>
							<img src="/pixlogo.svg" alt="pix logo"></img>
						</div>
						<Heading6 className="font-normal text-text-muted">
							{language === "spanish"
								? "Ingrese el código en su aplicación bancaria"
								: "Digite o código abaixo em seu aplicativo bancário"}
						</Heading6>
					</div>
					<div className="flex flex-col gap-3 ">
						<Input
							readOnly
							value={fiatQuote?.address || ""}
							type="copy"
							copyButtonIcon
						/>
						<div className="text-h6 text-text-muted">
							{language === "spanish"
								? "También podés pagar apuntando tu cámara al"
								: "Também pode pagar apontando sua câmera para o"}{" "}
							{!isSm ? (
								"código QR"
							) : (
								<button
									className="font-bold underline text-text bg-transparent block justify-start border-none"
									onClick={handleQRModal} //{Request#003 Falta el modal}
								>
									código QR
								</button>
							)}
						</div>
					</div>
				</div>

				{isSm ? null : (
					<div className="rounded-sm flex justify-center items-center p-lg ml-8 bg-white">
						<QRCodeSVG
							level="M"
							value={fiatQuote?.address || ""}
							className="size-[200px]"
						/>
					</div>
				)}
			</div>
			<div className="flex justify-center">
				<Button variant="bordered" size="lg" onClick={pendingHandler}>
					{language === "spanish" ? "Ya hice el pago" : "Já fiz o pagamento"}
				</Button>
			</div>
		</Col>
	)
}

export default FiatPayment
