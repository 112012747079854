import * as ProgressPrimitive from "@radix-ui/react-progress"
import { cn } from "../../lib/utils"
import { cva } from "class-variance-authority"
import React from "react"

const progressVariants = cva(
	"h-full w-full flex-1 rounded-full transform  transition-all duration-600",
	{
		variants: {
			variant: {
				primary:
					"dark:bg-[linear-gradient(110deg,#171717,45%,#201319,55%,#171717)] bg-[linear-gradient(110deg,#FAFAFA,45%,#EDEDED,55%,#FAFAFA)] bg-[length:200%_100%]  focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 shadow-lg",
				secondary:
					"bg-[linear-gradient(110deg,#171717,45%,#201319,55%,#171717)] dark:bg-[linear-gradient(110deg,#FAFAFA,45%,#EDEDED,55%,#FAFAFA)] bg-[length:200%_100%]  focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 shadow-lg",
			},
			animated: {
				true: "animate-shimmer",
				false: "",
			},
		},
		defaultVariants: {
			variant: "primary",
			animated: true,
		},
	}
)
const boxVariants = cva("relative h-2 w-full overflow-hidden  rounded-sm ", {
	variants: {
		variant: {
			primary:
				"outline outline-6 outline-background-strong bg-background-strong",
			secondary: "bg-background-strong",
		},
	},
	defaultVariants: {
		variant: "primary",
	},
})

const Progress = React.forwardRef<
	React.ElementRef<typeof ProgressPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
		value: number
		variant?: "primary" | "secondary"
		animated?: boolean
	}
>(({ className, value, variant, animated, ...props }, ref) => (
	<ProgressPrimitive.Root
		ref={ref}
		className={cn(boxVariants({ variant, className }))}
		{...props}
	>
		<ProgressPrimitive.Indicator
			className={cn(progressVariants({ variant, animated }))}
			style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
		/>
	</ProgressPrimitive.Root>
))
Progress.displayName = ProgressPrimitive.Root.displayName

export { Progress }
