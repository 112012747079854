import { ColumnDef, Row } from "@tanstack/react-table"
import { Fuel, MoveDownLeft, MoveUpRight } from "lucide-react"
import { TableTx } from "./columns"

export function TxTypeCell(): ColumnDef<TableTx> {
	return {
		accessorKey: "type",
		header: "Tipo",
		cell: ({ row }: { row: Row<TableTx> }) => {
			switch (row.getValue("type")) {
				case "INTERNAL_GAS":
					return <Fuel className="text-icon-warning" />
				case "INBOUND":
					return <MoveDownLeft className="text-icon-success" />
				case "OUTBOUND":
					return <MoveUpRight className="text-icon-error" />
				default:
					return row.getValue("type")
			}
		},
		filterFn: (row: Row<TableTx>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
	}
}
