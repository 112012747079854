import { formatPriceAmount } from "../../utils/numbers";

interface convertedAmountProps {
    amount: string | number | undefined;
    currency: string | undefined;
    amountInPreferredCurrency: string | number;
    preferredCurrency: string;
}

export default function ConvertedAmount({amount, currency, amountInPreferredCurrency,preferredCurrency} : convertedAmountProps) {
	return (
		<>
			<span className="inline-flex gap-betweenTexts">
				<p>
					{`${formatPriceAmount(
						amount,
						undefined,
						currency
					)} ${currency}
																		`}
				</p>

				<p className="text-text-muted">
					{`(${formatPriceAmount(
						amountInPreferredCurrency,
						undefined,
						preferredCurrency
					)} ${preferredCurrency})`}
				</p>
			</span>
		</>
	)
}
