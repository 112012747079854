import { ShieldAlert } from "lucide-react"
import { Alert } from "../ui/alert"
import { Input } from "../ui/inputs/input"
import AuthTitle from "../ui/titles/AuthTitle"

const rulesClasses = {
	success: "text-text-success",
	error: "text-text-error",
	neutral: "text-text-muted",
}

const SingupPasswordStep = ({
	onPasswordChange,
	password,
	passwordRequirements,
}: {
	onPasswordChange: (password: string) => void
	password?: string
	passwordRequirements: {
		length: boolean
		number: boolean
		special: boolean
	}
}) => {
	const getRuleClassName = (rule: "length" | "number" | "special") => {
		var status: "neutral" | "success" | "error"
		if (password) {
			status = passwordRequirements[rule] ? "success" : "error"
		} else {
			status = "neutral"
		}
		return rulesClasses[status]
	}
	return (
		<div className="mx-auto grid w-full lg:min-w-[600px] gap-textToComponent">
			<AuthTitle
				title="Elige tu contraseña"
				subtitle="Asegurate de elegir una contraseña que te acuerdes."
			/>

			<Alert
				icon={<ShieldAlert className="h-4 w-4" />}
				title="Tu contraseña debe tener:"
				description={
					<ul className="text-h6 flex flex-col list-inside list-disc">
						<li className={`m-0 mt-1 ${getRuleClassName("length")}`}>
							Almenos 8 caracteres.
						</li>
						<li className={`m-0 ${getRuleClassName("special")}`}>
							Almenos 1 caracter especial.
						</li>
						<li className={`m-0 ${getRuleClassName("number")}`}>
							Almenos 1 numero.
						</li>
					</ul>
				}
				bulletList
			/>
			<div className="grid gap-2 ">
				<div className="grid gap-2">
					<Input
						id="password"
						type="password"
						onChange={(e: any) => onPasswordChange(e.target.value)}
						value={password}
						label="Contraseña"
						required
					/>
				</div>
			</div>
		</div>
	)
}
export default SingupPasswordStep
