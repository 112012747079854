import useBreakpoint from "../../../hooks/useBreakpoint"
import { DataTable } from "../../ui/data-table/data-table"
import { TableCredential, columns } from "./columns"

const CredentialsTable = ({
	credentials,
	loading,
}: {
	credentials: TableCredential[]
	loading: boolean
}) => {
	const breakpoint = useBreakpoint()
	return (
		<div className="h-full">
			<DataTable
				className="overflow-x-hidden"
				columns={columns[breakpoint as "lg" | "md" | "sm"]}
				data={credentials}
				loading={loading}
				tableItem="credenciales"
			/>
		</div>
	)
}

export default CredentialsTable
