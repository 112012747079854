import { Hand, Send } from "lucide-react"
import { Alert } from "../../ui/alert"
import { Input } from "../../ui/inputs/input"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"

import { useState } from "react"
import { useAuth } from "../../../context/AuthContext"
import {
	PublicRole,
	getRolesFromUserRole,
	publicRoleTranslation,
} from "../../../lib/roles"
import { createTeam } from "../../../services/users"
import { TeamData } from "../../app/teams/MainTeamComponent"
import RoleSelector from "../../app/teams/RoleSelector"
import LoadableButton from "../../ui/buttons/LoadableButton"
import { Sheet, SheetContent, SheetHeader } from "../../ui/sheet"
import { Body1 } from "../../ui/texts/TextStyle"

interface NewTeamModalProps {
	open: boolean
	onClose: () => void
	handleSubmit?: (res: any) => void
}

function validateData(
	data: TeamData,
	setErrorMessage: (message: string) => void
) {
	if (!data.alias || data.alias.length === 0) {
		setErrorMessage("Alias es requerido")
		return false
	}
	if (!data.email || data.email.length === 0) {
		setErrorMessage("Email es requerido")
		return false
	}
	return true
}

const privileges = {
	[PublicRole.POS]: [
		"Ver las transacciones del día",
		"Ver el balance del día",
		"Crear pagos",
		"Dar de alta otros equipos PoS",
		"Crear QRs físicos",
	],
	[PublicRole.SUPERVISOR]: [
		"Ver todas las transacciones",
		"Ver el balance total de la cuenta",
		"Crear pagos",
		"Dar de alta otros equipos PoS y supervisores",
		"Crear QRs físicos",
		"Integrar con otras plataformas como Tiendanube y Woocommerce",
	],
	[PublicRole.OWNER]: ["Todas las funciones de la plataforma"],
}

const NewTeamModal = ({ open, onClose, handleSubmit }: NewTeamModalProps) => {
	const [teamData, setTeamData] = useState<TeamData>({
		role: PublicRole.POS,
	})
	const { user } = useAuth()
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	)
	const onSubmit = async () => {
		setErrorMessage(undefined)
		// e.preventDefault()
		const userId = user.user_id
		const teamId = user.team_id
		if (validateData(teamData, setErrorMessage)) {
			setLoading(true)
			try {
				const postResponse = await createTeam(
					userId,
					{
						email: teamData.email!,
						alias: teamData.alias!,
						role: teamData.role,
					},
					teamId
				)
				if (postResponse.status === 200) {
					const newTeam = postResponse.data.data
					if (handleSubmit) handleSubmit(newTeam)
					setLoading(false)
				}
			} catch (err: any) {
				if (err?.response?.data?.message === "email.already.exists") {
					setErrorMessage("El email ya está en uso")
				}
				setErrorMessage("Hubo un error al crear el equipo")
				setLoading(false)
			}
		}
	}
	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				if (!open) {
					setTeamData({
						role: PublicRole.POS,
					})
					setErrorMessage(undefined)

					onClose()
				}
			}}
		>
			<SheetContent side="right">
				<div className="">
					<CenteredBody>
						<SheetHeader>
							<SectionHeader
								title="Nuevo integrante"
								subtitle="Podrás agregar nuevos integrantes a tu equipo con permisos personalizados. Se puede usar para empleados o sucursales"
							></SectionHeader>
						</SheetHeader>
						<div className="flex flex-col gap-betweenFields">
							<Input
								label="Alias"
								onChange={(e) =>
									setTeamData({
										...teamData,
										alias: e.target.value,
									})
								}
								required
								value={teamData.alias}
							/>
							<Input
								label="Mail del integrante"
								subtext="Se enviará una invitación al mail para formar parte del equipo."
								required
								onChange={(e) =>
									setTeamData({
										...teamData,
										email: e.target.value,
									})
								}
								value={teamData.email}
							/>
							<RoleSelector
								onSelect={(role: PublicRole) =>
									setTeamData({
										...teamData,
										role: role,
									})
								}
								value={teamData.role}
								roles={getRolesFromUserRole(user.roles ?? user.talo_role_list)}
							/>
						</div>

						<Alert
							className="border-border-onModal"
							icon={<Hand />}
							title={`Permisos ${publicRoleTranslation[teamData.role]}`}
							description={
								<ul className="text-h6 flex flex-col list-outside text-text-muted list-disc mt-2 [&>*]:m-0">
									{privileges[teamData.role].map((privilege: string) => (
										<li key={privilege}>{privilege}</li>
									))}
								</ul>

								// <Col className="gap-betweenChips">
								// 	<Heading6 className="font-normal text-text-muted">
								// 		La cuenta de equipo tendrá acceso a:
								// 	</Heading6>
								// 	{privileges[teamData.role].map((privilege: string) => (
								// 		<Row className="gap-betweenChips font-normal text-text-muted items-center">
								// 			<Asterisk className="size-[16px]" />
								// 			<Body1 key={privilege}>{privilege}</Body1>
								// 		</Row>
								// 	))}
								// </Col>
							}
							bulletList
						/>
						{errorMessage && (
							<Body1 className="text-destructive text-normal">
								{errorMessage}
							</Body1>
						)}
						<LoadableButton
							variant="secondary"
							size="full"
							isLoading={loading}
							onClick={onSubmit}
							disabled={!teamData.alias || !teamData.email}
						>
							{!loading && <Send className="m-2 size-icon" />}
							Mandar invitación
						</LoadableButton>
					</CenteredBody>
				</div>
			</SheetContent>
		</Sheet>
	)
}

export default NewTeamModal
