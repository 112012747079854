export const platformHeader = {
	height: {
		value: 130,
		class: "h-[10%]",
	},
	paddingX: {
		value: 48,
		class: "px-screenMobile md:px-[48px]",
	},
	paddingY: {
		value: 32,
		class: "py-[32px]",
	},
	titleWidth: {
		value: 350,
		class: "w-[350px]",
	},
	sidePanelWidth: {
		class: "w-[calc((100%-350px)/2)]",
	},
}

export const platformBody = {
	height: {
		class: "h-[calc(100vh-10%)]",
	},
	sidePanelWidth: {
		value: 275,
		class: "w-[20%] max-w-[280px]",
	},
	padding: {
		value: 48,
		class: "pt-[48px] px-[48px]",
	},
	centralPanelMaxWidth: {
		class: "max-w-[calc(100%-275px-275px)]",
	},
}

export const mobilePlatformHeader = {
	simpleHeight: {
		value: 88,
		class: "h-[88px]",
	},
	height: {
		value: 335,
		class: "h-[335px]",
	},
	navbarHeight: {
		value: 40,
		class: "h-[40px]",
	},
	logoWidth: {
		value: 104,
		class: "w-[124px]",
	},
}

export const mobilePlatformBody = {
	default: {
		height: "h-[calc(100%-88px)]",
	},
	bigHeader: {
		height: "h-[calc(100%-335px)]",
	},
}
