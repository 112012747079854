import { Plus } from "lucide-react"
import { PublicRole } from "../../../lib/roles"
import { mapToTableTeam } from "../../tables/teams-table/data"
import TeamTable from "../../tables/teams-table/table"
import { Button } from "../../ui/button"
import Col from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import { SectionHeader } from "../../ui/section-header"
import Spacer from "../../ui/spacer"

export interface TeamData {
	alias?: string
	role: PublicRole
	email?: string
}
const MainTeamComponent = ({
	teams,
	openModal,
}: {
	teams: any[]
	openModal: () => void
}) => {
	return (
		<Col>
			<SectionHeader
				title="Administrá los permisos de tu equipo"
				subtitle="Los permisos te permitirá decidir qué funciones estarán disponibles para cada usuario"
			/>
			<Spacer axis="vertical" type="betweenComponents" />
			<Row className="justify-end">
				<Button
					variant="outline"
					className="gap-betweenTexts"
					size="lg"
					onClick={openModal}
				>
					<Plus className="size-xs" />
					Agregar integrante
				</Button>
			</Row>
			<TeamTable teams={teams.map(mapToTableTeam)} />
		</Col>
	)
}

export default MainTeamComponent
