import { Quote } from "../pages/checkout/id"

export interface WalletProps {
	name: string
	backgroundColor: string
	textColor: string
	id: string
	onPress?: () => void
}

export type Wallet = Pick<WalletProps, "id" | "name">

export type WalletButton = Wallet & {
	link?: string
}

export type ChargeWalletProps = {
	handleWalletClick: (wallet: Wallet) => any
	handleGoBack: () => any
	style?: any
	isLoading?: boolean
}

export const paymentWallets: WalletProps[] = [
	{
		id: "lemon",
		name: "Lemon",
		backgroundColor: "#000000",
		textColor: "#FFFFFF",
	},
	{
		id: "metamask",
		name: "Metamask",
		backgroundColor: "#000000",
		textColor: "#FFFFFF",
	},
	{
		id: "other",
		name: "Otras billeteras",
		backgroundColor: "#DDE4D4",
		textColor: "#3c483c",
	},
]

const walletLinks = {
	lemon: "https://www.lemon.me/app/cash-out-crypto",
	metamask: "https://metamask.app.link/send/pay-",
}

export function getWalletLink(
	wallet: Wallet,
	quote: Quote | undefined
): string | undefined {
	switch (wallet.id) {
		case "lemon":
			// redirect to lemon url
			const lemonNetwork = quote?.network === "BSC" ? "BEP20" : quote?.network

			return `${walletLinks[wallet.id]}?currency=${
				quote?.currency
			}&network=${lemonNetwork}&amount=${quote?.amountReadable}&address=${
				quote?.address
			}`
		case "metamask":
			if (quote?.currency === "BNB") {
				return `${walletLinks[wallet.id]}${quote?.address}@${
					process.env[`REACT_APP_${quote?.network}_CHAIN_ID`]
				}?value=${quote?.amountReadable}e18&gasLimit=21000&gasPrice=10000000000`
			} else {
				return `${walletLinks[wallet.id]}${
					process.env[
						`REACT_APP_${quote?.network}_${quote?.currency}_CONTRACT_ADDRESS`
					]
				}@${
					process.env[`REACT_APP_${quote?.network}_CHAIN_ID`]
				}/transfer?address=${quote?.address}&uint256=${
					quote?.amountReadable
				}e18&gasLimit=21000&gasPrice=10000000000`
			}

		case "other":
		default:
			return undefined
	}
}
