export interface CurrencyStyle {
	[x: string]: any
	alias: string
	name: string
	color: string
	textColor: string
	logoPath: string
	gradientColor: string
	lightColor: string
}

export interface FiatCurrencyStyle {
	flagPath: string
	name: string
	alias: string
	disabled: boolean
}

export type WithdrawType = "crypto" | "transfer" | "pix"

export const fiatCurrencies: { [key: string]: FiatCurrencyStyle } = {
	BRL: {
		flagPath: "/currencies/brl-icon.svg",
		name: "Reales",
		alias: "BRL",
		disabled: false,
	},
	ARS: {
		flagPath: "/currencies/ars-icon.svg",
		name: "Pesos",
		alias: "ARS",
		disabled: true,
	},
	USD: {
		flagPath: "/currencies/usd-icon.svg",
		name: "Dólares",
		alias: "USD",
		disabled: true,
	},
}

export const currencies: { [key: string]: CurrencyStyle } = {
	USDT: {
		alias: "USDT",
		name: "Tether",
		color: "#50AF95",
		textColor: "#19675E",
		logoPath: "/currencies/usdt-icon.svg",
		gradientColor: "#D6EDD6",
		lightColor: "#EEEFEF",
	},
	DAI: {
		alias: "DAI",
		name: "Dai",
		color: "#F0B459",
		textColor: "#A5813C",
		gradientColor: "#F5E0C0",
		logoPath: "/currencies/dai-icon.svg",
		lightColor: "#E9E9E9",
	},
	USDC: {
		alias: "USDC",
		name: "USD Coin",
		color: "#2775CA",
		textColor: "#314987",
		logoPath: "/currencies/usdc-icon.svg",
		gradientColor: "#D6E7ED",
		lightColor: "#F2F2F2",
	},
	BNB: {
		alias: "BNB",
		name: "Binance Coin",
		color: "#E4B559",
		textColor: "#A5813C",
		logoPath: "/currencies/bnb-icon.svg",
		gradientColor: "#EDE4D6",
		lightColor: "#FFFFFF",
	},
	nuARS: {
		alias: "nuARS",
		name: "Num ARS",
		color: "#99CCFF",
		textColor: "#2270BE",
		logoPath: "/currencies/legacy-icons/nuars_logo_black.svg",
		gradientColor: "#D6E2ED",
		lightColor: "#F5F5F5",
	},
	// UXD: {
	// 	alias: "UXD",
	// 	name: "Criptodólar",
	// 	color: "#0EFBB4",
	// 	textColor: "#042317",
	// 	logoPath: "/currencies/uxd-icon.svg",
	// 	gradientColor: "#D6E2ED",
	// 	lightColor: "#F5F5F5",
	// },
}

export const getBlackLogoPath = (currency: string) => {
	return (
		"/currencies/legacy-icons/" + currency.toLowerCase() + "_logo_black.svg"
	)
}
