import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTransaction } from "../columns"
import { Store } from "../../../../hooks/useFetchStores"

export function StoreCell({
	accessorKey,
	header,
	stores,
}: {
	accessorKey: string
	header: string
	stores: Store[]
}): ColumnDef<TableTransaction> {
	return {
		accessorKey: accessorKey,
		size: 270,
		header: () => <div className="text-left">{header}</div>,
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			if(stores.length < 2){
				return
			}
			const store_name = row.original.store_name as string
			if (!store_name || (store_name && store_name === "")) {
				return (
					<div className="justify-end  h-4 w-full rounded-xs bg-background-elevated"></div>
				)
			} else {
				return (
					<div className="text-h6 font-medium w-fit block overflow-hidden overflow-ellipsis m-0 text-nowrap">
						{store_name}
					</div>
				)
			}
		},
	}
}
