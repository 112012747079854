import { deleteTeam, getTeams } from "../../../services/users"

export async function getUserTeams(user_id: string) {
	const res = await getTeams(user_id)
	return res.data.data.teams.map(mapToTableTeam)
}

export function mapToTableTeam(team: any) {
	return {
		status: team.team_status,
		photoUrl: team.photo_url,
		email: team.email,
		roles: team.roles,
		alias: team.alias,
		user_id: team.user_id,
		id: team.team_id,
	}
}

export async function handleDeleteTeam(user_id: string, team_id: string) {
	await deleteTeam(user_id, team_id)
	window.location.reload()
}
