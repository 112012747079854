import { ColumnDef } from "@tanstack/react-table"
import { TableCredential } from "../columns"
import CopyButton from "../../../ui/buttons/CopyButton"

export function CopyIdCell({
	sm = false,
}: {
	sm?: boolean
}): ColumnDef<TableCredential> {
	return {
		id: "copy",
		cell: ({ row }) => {
			return (
				<CopyButton
					textToCopy={row.original.client_id}
					variant="outline"
					label={!sm}
				/>
			)
		},
	}
}
