import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAnalytics } from "../../context/AnalyticsContext"
import { useAuth } from "../../context/AuthContext"
import { useGlobalStore } from "../../hooks/useGlobalStore"
import { getPathForUser } from "../../lib/roles"
import logger from "../../utils/logger"
import { Button } from "../ui/button"
import LoadableButton from "../ui/buttons/LoadableButton"
import { Input } from "../ui/inputs/input"
import { Separator } from "../ui/separator"
import { Heading6, Label1 } from "../ui/texts/TextStyle"
import AuthTitle from "../ui/titles/AuthTitle"
import GoogleSigninButton from "./GoogleSigninButton"

export const LoginBox = () => {
	const navigate = useNavigate()
	const { state }: any = useLocation()
	const [errorMessage, setErrorMessage] = useState<string>()
	const [isLoading, setIsLoading] = useState(false)
	const { logLogin } = useAnalytics()
	const { login, getErrorMessage, user } = useAuth()
	const [data, setData] = useState({
		email: (state && state.email) || "",
		password: (state && state.password) || "",
	})
	const { globalStore, setGlobalStore } = useGlobalStore()

	//write function to navigate to the correct page after login
	const handleNavigation = (user_claims: any) => {
		if (globalStore.deepLink) {
			const { deepLink, ...rest } = globalStore
			setGlobalStore(rest)
			navigate(deepLink)
		} else {
			const userPath = getPathForUser(user_claims)
			if (userPath) {
				navigate(userPath)
			} else {
				window.location.href = process.env.REACT_APP_NEXT_LANDING_URL!
			}
		}
	}

	const handleLogin = async (e: any) => {
		e.preventDefault()
		try {
			setIsLoading(true)
			const response: any = await login(data.email, data.password)

			const tokenResult = await response.user.getIdTokenResult()
			const userClaims = tokenResult.claims
			handleNavigation(tokenResult.claims)
			logLogin({
				user_id: userClaims.talo_user_id,
				team_id: userClaims.talo_team_id,
				login_method: tokenResult.signInProvider,
				login_at: new Date().toISOString(),
			})
		} catch (error: any) {
			logger.error(error)
			setErrorMessage(getErrorMessage(error.code))
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<form onSubmit={handleLogin}>
			<div className="mx-auto grid w-full lg:min-w-[600px] gap-textToComponent">
				<AuthTitle
					title="Iniciar sesión"
					subtitle="Ingrese su correo electrónico a continuación para acceder a su
						cuenta."
				/>

				<GoogleSigninButton
					loading={isLoading}
					handleSuccess={handleNavigation}
					handleError={(err: string) => setErrorMessage(err)}
				/>
				<div className="flex-row justify-center items-center inline-flex">
					<Separator className="w-full grow shrink basis-0  border-t border-border"></Separator>
					<Label1 className="w-[152.21px] text-center text-text-muted font-normal">
						O CONTINUA CON
					</Label1>
					<Separator className="w-full grow shrink basis-0  border-t border-border"></Separator>
				</div>

				<div className="grid gap-betweenFields ">
					<div className="grid gap-2">
						<Input
							id="email"
							type="email"
							placeholder="m@example.com"
							required
							label="Correo"
							onChange={(e: any) => setData({ ...data, email: e.target.value })}
							data-test='email-input'
							// initialValue={data.email}
						/>
					</div>
					<div className="grid gap-2">
						<Input
							id="password"
							type="password"
							required
							label="Contraseña"
							forgotPassword={true}
							data-test='password-input'
							onChange={(e: any) =>
								setData({ ...data, password: e.target.value })
							}
							// initialValue={data.password}
						/>
						<div className="h-sm">
							{errorMessage ? (
								<Heading6 className="text-text-error">{errorMessage}</Heading6>
							) : (
								<Heading6 className="text-text-error"></Heading6>
							)}
						</div>
					</div>
				</div>
				<LoadableButton  data-test='login-button' isLoading={isLoading} size="full" type="submit">
					Iniciar sesión
				</LoadableButton>
				<Heading6 className="text-center">
					¿No tenes una cuenta?{" "}
					<Button
						size="sm"
						variant="link"
						className="text-text-interactive"
						onClick={() =>
							navigate("/signup", {
								state: { email: data.email, password: data.password },
							})
						}
					>
						Create una
					</Button>
				</Heading6>
			</div>
		</form>
	)
}
