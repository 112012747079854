import { ColumnDef } from "@tanstack/react-table"
import { formatPriceAmount } from "../../../../utils/numbers"
import { Price, TableTransaction } from "../columns"

export function MoneyCell({
	accessorKey,
	header,
}: {
	accessorKey: string
	header: string
}): ColumnDef<TableTransaction> {
	return {
		accessorKey: accessorKey,
		header: () => <div className="text-right">{header}</div>,
		cell: ({ row }) => {
			const expected = row.getValue(accessorKey) as Price
			if (expected) {
				return (
					<div className="justify-end text-right text-h6">
						<span className="font-semibold">
							{formatPriceAmount(expected.amount, undefined, expected.currency)}
						</span>{" "}
						<span className="font-normal md:hidden ">{expected.currency}</span>
					</div>
				)
			} else {
				return (
					<div className="justify-end  h-4 w-full rounded-xs bg-background-elevated"></div>
				)
			}
		},
	}
}
