import { Loader2 } from "lucide-react"
import { Button, ButtonProps } from "../button"

const LoadableButton = ({
	isLoading = false,
	...props
}: {
	isLoading?: boolean
} & ButtonProps) => {
	return (
		<Button {...props} disabled={isLoading || props.disabled}>
			{isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : ""}
			{props.children}
		</Button>
	)
}

export default LoadableButton
