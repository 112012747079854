export type Question = {
	question: string
	answer: string | any
}
export const faq_questions: Question[] = [
	{
		question: "¿Qué es Talo?",
		answer:
			"Talo se fundó en 2022 con la misión de acelerar la transición del mundo a las criptomonedas. En Talo queremos acercar a los comercios digitales y físicos una plataforma de pagos de fácil uso e implementación para que puedan aceptar criptomonedas. De esta forma ofrecemos comisiones de hasta 1% y liquidación inmediata.",
	},
	{
		question: "¿Cómo integrar Talo a mi comercio?",
		answer: (
			<>
				Puede consultar nuestra
				<a href="https://docs.talo.com.ar"> Documentación </a>
				sobre cómo integrar Talo con su sitio web o comercio.
			</>
		),
	},
	{
		question: "¿Qué información necesito para cobrar en Talo?",
		answer:
			"Usted tiene que tener una cuenta con Talo. Cuando la tenga, puede conectarla con su integración de Talo en su sitio web con la dirección de correo y clave.",
	},
	{
		question: "¿Qué monedas digitales se pueden recibir?",
		answer:
			"Inicialmente, se pueden realizar pagos en BNB, USDC, USDT y DAI sobre la Binance Smart Chain. Pronto agregaremos más criptomonedas y redes. ¡Esto recién comienza!",
	},
	{
		question: "¿Cómo sacar mis fondos de Talo?",
		answer:
			"Los usuarios de Talo pueden sacar sus fondos de la plataforma cuando ellos deseen accediendo a su cuenta a través de la web.",
	},
	{
		question: "¿Necesito estar bancarizado para tener una cuenta en talo?",
		answer:
			"No, Talo no requiere información personal a la hora de crearse una cuenta. Queremos brindarle a los comercios una manera de cobrar, libre de regulaciones y sistemas monetarios centralizados.",
	},
]
