import { Price } from "../tables/transactions-table/columns"
import LoadableButton from "../ui/buttons/LoadableButton"
import AmountInput from "../ui/inputs/AmountInput"
import QRSelector, { QRItem } from "../ui/inputs/QRSelector"
import { Input } from "../ui/inputs/input"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../ui/section-header"

const EnterAmount = ({
	showQRSelector,
	onAmountChange,
	onQRChange,
	onMotiveChange,
	onContinue,
	qrs,
	loading,
	errorMessage,
	qrId,
}: {
	showQRSelector: boolean
	onAmountChange: (newP: Price) => void
	onQRChange?: (newQR: string) => void
	onMotiveChange?: (newMotive: string) => void
	onContinue?: () => void
	qrs: QRItem[]
	loading: boolean
	errorMessage?: string
	qrId?: string
}) => {
	return (
		<CenteredBody className="gap-betweenSections">
			<div className="flex flex-col gap-betweenFields">
				<SectionHeader
					title="Ingresá el monto"
					subtitle="Deberás ingresar el monto por el que quieres generar el cobro."
				/>
				<AmountInput onAmountChange={onAmountChange} />

				{showQRSelector ? (
					<QRSelector onSelect={onQRChange!} qrs={qrs} value={qrId} />
				) : (
					<Input
						id="motive-input"
						label="Motivo"
						placeholder="Motivo de pago"
						onChange={
							onMotiveChange ? (e) => onMotiveChange(e.target.value) : undefined
						}
					/>
				)}
			</div>
			{errorMessage && (
				<p className="text-text-error text-center text-sm">{errorMessage}</p>
			)}
			<LoadableButton
				onClick={onContinue}
				isLoading={loading}
				disabled={!onContinue}
				data-test="continue-after-enter-amount-button"
			>
				Confirmar
			</LoadableButton>
		</CenteredBody>
	)
}

export default EnterAmount
