import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTeam, TeamStatus } from "../columns"
import TRow from "../../../ui/layouts/row"
import { Avatar, AvatarFallback, AvatarImage } from "../../../ui/avatar"
import { Body1, Heading6 } from "../../../ui/texts/TextStyle"
import { Clock } from "lucide-react"
import Col from "../../../ui/layouts/column"

export function TeamAvatarCell(): ColumnDef<TableTeam> {
	return {
		accessorKey: "alias",
		header: "Alias",
		cell: ({ row }: { row: Row<TableTeam> }) => {
			let initials: string
			let isPending: boolean
			try {
				initials = (row.getValue("alias") as string)
					.split(" ")
					.map((word) => word[0])
					.join("")
				isPending = (row.original.status as TeamStatus) === TeamStatus.PENDING
			} catch (e) {
				console.log(e)
				return null
			}
			return (
				<TRow className="items-center">
					<Avatar className="size-[32px] mr-betweenChips">
						<AvatarImage src={row.original.photoUrl} />
						<AvatarFallback className="bg-background-elevated">
							{isPending ? (
								<Clock className="text-icon-primary size-s18 m-betweenChips" />
							) : (
								<Heading6 className="font-medium uppercase text-text-muted">
									{initials}
								</Heading6>
							)}
						</AvatarFallback>
					</Avatar>
					<Col
						className={`gap-[4px] max-w-[114px] lg:max-w-[140px] xl:max-w-full`}
					>
						<Heading6 className="font-normal capitalize">
							{row.getValue("alias")}
						</Heading6>
						<Body1 className="font-normal overflow-ellipsis overflow-hidden whitespace-nowrap">
							{row.original.email}
						</Body1>
					</Col>
				</TRow>
			)
		},
	}
}
