import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTeam } from "../columns"
import { Heading6 } from "../../../ui/texts/TextStyle"

export function TeamAliasCell(): ColumnDef<TableTeam> {
	return {
		accessorKey: "alias",
		header: "Alias",
		cell: ({ row }: { row: Row<TableTeam> }) => {
			return (
				<Heading6 className="font-medium capitalize">
					{row.getValue("alias")}
				</Heading6>
			)
		},
	}
}
