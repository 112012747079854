import { Loader } from "lucide-react"
import Col from "../ui/layouts/column"

const QrWithNoActivePaymentBody = () => {
	return (
		<Col className="gap-3 justify-center px-5">
			<p className="text-h1">ESTAMOS ESPERANDO</p>
			<p className="text-h2 text-text-success">
				QUE EL COMERCIO CARGUE EL PRECIO
			</p>
			<div className="py-4">
				<Loader className="animate-spin" />
			</div>
		</Col>
	)
}

export default QrWithNoActivePaymentBody
