import { Route } from "react-router-dom"
import ProtectedRoute from "../../components/routes/ProtectedRoute"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { PublicRole } from "../../lib/roles"
import WithdrawWizard from "./WithdrawWizard"

export const withdrawItems = [
	<Route
		key={"/withdraw"}
		path="/withdraw"
		element={
			<ProtectedRoute
				requiresVerification={true}
				minimumPublicRole={PublicRole.OWNER}
			>
				<ResponsiveLayout
					item={{
						itemName: "Retirar",
						bodyComponent: <WithdrawWizard />,
						smBodyComponent: <WithdrawWizard />,
					}}
				/>
			</ProtectedRoute>
		}
	/>,
]
