import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../ui/section-header"
import Spacer from "../ui/spacer"
import LoadingPayment from "../ui/animations/loading-payment-animation"


const LoadingWithdrawStep = () => {
	return (
		<CenteredBody>
			<SectionHeader
				title="Verificando tu transacción..."
				subtitle="Esto puede tardar unos segundos. Por favor aguardá, te notificaremos cuando hayamos procesado tu retiro"
			/>
			<Spacer type="imageBottom" axis="vertical" />
			<div className="flex justify-center h-[200px]">
				<LoadingPayment />
			</div>
		</CenteredBody>
	)
}

export default LoadingWithdrawStep
