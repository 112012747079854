import { PublicRole } from "../../../lib/roles"
import { TeamEmailCell } from "./columns/TeamEmailCell"
import { TeamAliasCell } from "./columns/TeamAliasCell"
import { TeamStatusCell } from "./columns/TeamStatusCell"
import { TeamDeleteCell } from "./columns/TeamDeleteCell"
import { TeamRoleCell } from "./columns/TeamRoleCell"
import { TeamAvatarCell } from "./columns/TeamAvatarCell"
import { handleDeleteTeam } from "./data"

export enum TeamStatus {
	ACTIVE = "active",
	PENDING = "pending",
	DELETED = "deleted",
}

export const translatedTeamStatus: { [key in TeamStatus]: string } = {
	[TeamStatus.ACTIVE]: "Activo",
	[TeamStatus.PENDING]: "Pendiente",
	[TeamStatus.DELETED]: "Eliminado",
}

export type TableTeam = {
	alias: string
	email: string
	photoUrl: string
	roles: PublicRole[]
	status: TeamStatus
	user_id: string
	id: string
}

export const columns = {
	xl: [
		TeamStatusCell(),
		TeamAliasCell(),
		TeamRoleCell(),
		TeamEmailCell(),
		// TeamEditCell(),
		TeamDeleteCell(handleDeleteTeam),
	],
	lg: [
		TeamStatusCell(),
		TeamAliasCell(),
		TeamRoleCell(),
		TeamEmailCell(),
		// TeamEditCell(),
		TeamDeleteCell(handleDeleteTeam),
	],
	md: [TeamAvatarCell(), TeamRoleCell(), TeamDeleteCell(handleDeleteTeam)],
	sm: [TeamAvatarCell(), TeamRoleCell(), TeamDeleteCell(handleDeleteTeam)],
}
