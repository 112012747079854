import { Heading2, Heading6 } from "../texts/TextStyle"

const AuthTitle = ({
	title,
	subtitle,
}: {
	title: string
	subtitle: string
}) => {
	return (
		<div className="grid text-start gap-betweenTexts">
			<Heading2 className="font-bold">{title}</Heading2>
			<Heading6 className="font-medium text-text-muted">{subtitle}</Heading6>
		</div>
	)
}

export default AuthTitle
