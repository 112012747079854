import GradientUnderlineButton from "../../ui/buttons/GradientUnderlineButton"

const MarketplaceTitle = () => {
	return (
		<div className="flex flex-col items-center py-4 lg:py-12 w-fit">
			<p className="text-h6 text-[#222] leading-8">Descubrí todas las</p>
			<div className="flex justify-center relative w-fit">
				<img
					className="absolute w-16 lg:w-48 left-[-20px] lg:left-[-80px] top-[-5px] lg:top-[-30px]"
					alt="cryptofriendly badge"
					src="/home/merchants/crypto_friendly_badge.svg"
				/>
				<p className="text-[60px] lg:text-[164px] leading-[80px] lg:leading-[164px] w-full">
					MARCAS
				</p>
			</div>
			<p className="text-h6 lg:text-h3 leading-6 lg:leading-8 text-center text-[#222]">
				También podés{" "}
				<strong>
					recibir de manera directa, ofertas, promociones y descuentos
				</strong>{" "}
				de nuestras marcas favoritas,<br></br> uniéndote a nuestros canales de
				anuncios
			</p>
			<div className="flex w-full justify-center py-3 lg:py-6">
				<GradientUnderlineButton href="https://tally.so/r/wzy2Xa">
					Anotame ya
				</GradientUnderlineButton>
			</div>
		</div>
	)
}

export default MarketplaceTitle
