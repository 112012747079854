import { TablePayment } from "../../tables/admin-payments-table/data"
import { SectionHeader } from "../../ui/section-header"
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
} from "../../ui/sheet"
import BodyBlock from "../payment-modal/body/BodyBlock"
import BodyItem from "../payment-modal/body/BodyItem"
import TransactionsAccordion from "../payment-modal/body/TransactionsAccordion"
import PaymentStatusRow from "../payment-modal/header/PaymentStatusRow"

const AdminPaymentsModal = ({
	open,
	onClose,
	payment,
}: {
	open: boolean
	onClose: () => void
	payment: TablePayment
}) => {
	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
		>
			<SheetContent side="right">
				<div className="grid grid-cols-1 items-center">
					<SheetHeader className="py-betweenSections">
						<SheetTitle>
							<SectionHeader title={payment.id} subtitle={payment.user} />
						</SheetTitle>

						<SheetDescription>
							<PaymentStatusRow status={payment.payment_status} />
						</SheetDescription>
					</SheetHeader>
					<BodyBlock title="Información importante:" isFirst>
						<BodyItem name="ID" value={payment.id} copy overflow />
						<BodyItem name="Tipo" value={payment.type} />
						<BodyItem name="Address" value={payment.address} copy overflow />
						<BodyItem name="Monto esperado" value={payment.expected} />
						<BodyItem name="Monto recibido" value={payment.received} />
						<BodyItem name="Fecha de pago" value={payment.creation_date} />
					</BodyBlock>
					{payment.transactions && (
						<BodyBlock title="Información de la transacción">
							<TransactionsAccordion transactions={payment.transactions} />
						</BodyBlock>
					)}
					{payment.tiendanube && (
						<BodyBlock title="Información de Tiendanube">
							{Object.entries(payment.tiendanube).map(([key, value]) => {
								return (
									<BodyItem key={key} name={key} value={value.toString()} />
								)
							})}
						</BodyBlock>
					)}
					{payment.woocommerce && (
						<BodyBlock title="Información de Woocommerce">
							{Object.entries(payment.woocommerce).map(([key, value]) => {
								return (
									<BodyItem key={key} name={key} value={value.toString()} />
								)
							})}
						</BodyBlock>
					)}

					{/* {renderModalBody(payment)} */}
				</div>
			</SheetContent>
		</Sheet>
	)
}

export default AdminPaymentsModal
