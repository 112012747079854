import { TextColumnCell } from "../TextColumnCell"
import { PaymentTypeCell } from "./PaymentTypeCell"
import { StatusCell } from "./StatusCell"
import { TablePayment } from "./data"

const paymentIdCell = TextColumnCell<TablePayment>("id")
const userCell = TextColumnCell<TablePayment>("user")
const creationDateCell = TextColumnCell<TablePayment>("creation_date")
const paymentTypeCell = PaymentTypeCell()
const expectedCell = TextColumnCell<TablePayment>("expected")
const receivedCell = TextColumnCell<TablePayment>("received")
const statusCell = StatusCell()

export const columns = {
	xl: [
		statusCell,
		paymentTypeCell,
		paymentIdCell,
		userCell,
		expectedCell,
		receivedCell,
		creationDateCell,
	],
	lg: [
		statusCell,
		paymentTypeCell,
		paymentIdCell,
		userCell,
		expectedCell,
		receivedCell,
		creationDateCell,
	],
	md: [statusCell, paymentIdCell, userCell],
	sm: [statusCell, paymentIdCell, userCell],
}
