import { Input } from "../ui/inputs/input"
import AuthTitle from "../ui/titles/AuthTitle"

const SingupConfirmPasswordStep = ({
	onPasswordChange,
	password,
}: {
	onPasswordChange: (password: string) => void
	password?: string
}) => {
	return (
		<div className="mx-auto grid w-full lg:min-w-[600px] gap-textToComponent">
			<AuthTitle
				title="Repite tu contraseña"
				subtitle="Asi nos aseguramos que te la acuerdes."
			/>

			<div className="grid gap-2 ">
				<div className="grid gap-2">
					<Input
						id="password"
						type="password"
						onChange={(e: any) => onPasswordChange(e.target.value)}
						value={password}
						label="Contraseña"
						required
					/>
				</div>
			</div>
		</div>
	)
}
export default SingupConfirmPasswordStep
