import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTransaction } from "../columns"

export function OrderCell({
	accessorKey,
	header,
}: {
	accessorKey: string
	header: string
}): ColumnDef<TableTransaction> {
	return {
		accessorKey: accessorKey,
		size: 80,
		header: () => <div className="text-left">{header}</div>,
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			const orderId = row.getValue(accessorKey) as string
			if (orderId === "-") {
				return (
					<div className="justify-end max-w-[80px] h-4 w-[100px] rounded-xs bg-background-elevated"></div>
				)
			} else {
				return (
					<div className="max-w-[100px]">
						<div className="text-h6 w-full font-medium overflow-hidden text-ellipsis whitespace-nowrap">
							#{orderId}
						</div>
					</div>
				)
			}
		},
	}
}
