const PendingPaymentStatus = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.2309 21.9229C7.74908 22.6027 2.75741 18.7136 2.07719 13.2247C1.39696 7.74592 5.28826 2.75699 10.7701 2.07714C16.2519 1.39729 21.2436 5.28645 21.9238 10.7753C21.9838 11.2952 22.0138 11.815 21.9938 12.3449"
				// stroke="#010101"
				className="stroke-icon-muted"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13 7V12.6338L10 15"
				// stroke="#010101"
				className="stroke-icon-muted"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21 16V20H17"
				stroke="#1FC801"
				stroke-width="2"
				className="group-hover:animate-diagonal-bounce"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M17 16L21 20"
				stroke="#1FC801"
				stroke-width="2"
				className="group-hover:animate-diagonal-bounce"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
		// <svg
		// 	width="28"
		// 	height="28"
		// 	viewBox="0 0 24 24"
		// 	fill="none"
		// 	xmlns="http://www.w3.org/2000/svg"
		// >
		// 	<motion.path
		// 		d="M13.2309 21.9229C7.74908 22.6027 2.75741 18.7136 2.07719 13.2247C1.39696 7.74592 5.28826 2.75699 10.7701 2.07714C16.2519 1.39729 21.2436 5.28645 21.9238 10.7753C21.9838 11.2952 22.0138 11.815 21.9938 12.3449"
		// 		stroke="#1FC801"
		// 		stroke-width="2"
		// 		stroke-linecap="round"
		// 		stroke-linejoin="round"
		// 		animate={{
		// 			rotate: [0, 360],
		// 		}}
		// 		transition={{
		// 			duration: 2,
		// 			ease: "linear",

		// 			repeat: Infinity,
		// 		}}
		// 	/>
		// 	<path
		// 		d="M9.17157 9.17146L14.8284 14.8283M14.8284 14.8283H9.17157M14.8284 14.8283V9.17146"
		// 		stroke="#1FC801"
		// 		stroke-width="2"
		// 		stroke-linecap="round"
		// 		stroke-linejoin="round"
		// 	/>
		// </svg>
	)
}

export default PendingPaymentStatus
