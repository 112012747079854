import useBreakpoint from "../../../hooks/useBreakpoint"
import { DataTable } from "../../ui/data-table/data-table"
import { TableTeam, TeamStatus, columns } from "./columns"

const TeamTable = ({ teams }: { teams: TableTeam[] }) => {
	const breakpoint = useBreakpoint()
	return (
		<div className="h-full">
			<DataTable
				rowClassName={(row) =>
					row.original.status === TeamStatus.PENDING
						? "opacity-80 text-text-muted"
						: ""
				}
				className="overflow-x-hidden"
				columns={columns[breakpoint as "lg" | "md" | "sm"]}
				data={teams}
				tableItem="equipos"
				loading={!teams}
			/>
		</div>
	)
}

export default TeamTable
