import WelcomeVideo from "./BienvenidoLowRes.mp4"
export const WelomeQrCriptoVideo = () => {
	return (
		<>
			<video
				src={WelcomeVideo}
				className="h-full object-contain min-h-0"
				autoPlay={true}
				muted
				loop
			/>
		</>
	)
}
