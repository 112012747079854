export enum OnboardingPersonalSteps {
	SpecialAgreements,
	BasicInfo,
	FacialVerification,
	Success,
	ToS,
}

export type PersonalDocumentType = "CUIT"

export type EntityType = "F"

export type SpecialAgreementsForm = {
	obligated_subject: boolean
	exposed_person: boolean
	fatca_subject: boolean
	ocde_subject: boolean
}

export type BasicInfoForm = {
	first_name: string
	last_name: string
	document_type: PersonalDocumentType
	cuit: string
} & ContactInfoForm

export type Phone = {
	number: string
	codeArea: string
	countryCode: string
	verified?: boolean
}

export type Address = {
	country: string
	street: string
	number: number
	location: string
	state: string
	postalCode: string
}

export type ContactInfoForm = {
	phone: Phone
	address: Address
}

export type DniUploadForm = {
	dni_front_file: File | null
	dni_back_file: File | null
}

export type FacialVerificationForm = {
	facial_video_file: File | null
}

export type OnboardingPersonalData = BasicInfoForm & {
	uif_conditions: SpecialAgreementsForm
} & {
	entity_type: string
}

export type KycStatus =
	| "basic_info"
	| "signature"
	| "metamap"
	| "approved"
	| "rejected"
	| "pending"

export type MetamapButtonSettings = {
	clientId: string
	flowId: string
	metadata: any
}

export type KycGetResponse = {
	basic: BasicInfoForm & { entity_type: EntityType }
	contact: ContactInfoForm
	kyc_status: KycStatus
	uif_conditions: SpecialAgreementsForm
	metamapButtonSettings: MetamapButtonSettings
}

export interface OnboardingPersonalWizardData {
	step: OnboardingPersonalSteps
	completionPercentage: number
	data: OnboardingPersonalData
	loading?: boolean
	errorMessage?: string
}
