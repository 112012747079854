import { ExternalLink, LogOut, Settings2, User } from "lucide-react"
import { useAuth } from "../../../../context/AuthContext"
import useBreakpoint from "../../../../hooks/useBreakpoint"
import { Avatar, AvatarFallback, AvatarImage } from "../../avatar"
import { Button } from "../../button"
import { Body1, Heading5, Heading6 } from "../../texts/TextStyle"

import { Link } from "react-router-dom"
import { PublicRole, getMaxRole } from "../../../../lib/roles"
import {
	Command,
	CommandGroup,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "../../command"
import Col from "../../layouts/column"
import { Popover, PopoverContent, PopoverTrigger } from "../../popover"
import { Separator } from "../../separator"
import { Switch } from "../../switch"
import UserAccountRow from "./UserAccountRow"
import { useGlobalStore } from "../../../../hooks/useGlobalStore"

const sectionLabelClassName = "text-text-placeholder font-medium"
const groupClassName = "p-1"
const itemClassName = "p-betweenChips"
const headerClassName = "px-2 py-1.5"

const UserDropdownMenu = () => {
	const { user, logout, stores } = useAuth()
	const isMobile = useBreakpoint("sm")
	const { setIsDarkTheme, isDarkTheme } = useGlobalStore()
	const isPos =
		getMaxRole(user?.roles ?? user?.talo_role_list ?? []) === PublicRole.POS
	return (
		<Popover>
			<PopoverTrigger asChild>
				{renderTrigger(
					{
						email: user.email,
						photoURL: user.photoURL,
						displayName: user.displayName,
					},
					isMobile as boolean
				)}
			</PopoverTrigger>
			<PopoverContent className="w-[260px] !px-1 py-2 rounded-md" align="end">
				<Command>
					<CommandList>
						<Col
							className={groupClassName}
							key="personal"
							// heading={}
						>
							<div className={headerClassName}>
								<UserDropdownGroupHeader title="Cuenta personal" />
							</div>
							{/* <CommandItem className="w-full p-0"> */}
							<div className="w-full">
								<UserAccountRow
									displayName={user.displayName ?? user.email}
									photoUrl={user.photoURL}
									selected
								/>
							</div>
							{/* </CommandItem> */}
						</Col>
						{stores && stores.length > 0 && !isPos && (
							<Col
								className={groupClassName}
								key="brand"
								// heading={<UserDropdownGroupHeader title="Marca" />}
							>
								{" "}
								<div className={headerClassName}>
									<UserDropdownGroupHeader title="Marca" />
								</div>
								{stores.map((store: any) => (
									// <CommandItem className="w-full p-0" key={store.store_id}>
									<div className="w-full" key={store.store_id}>
										<UserAccountRow
											displayName={store.store_name}
											selected={false}
											photoUrl={store.photoURL}
										/>
									</div>
									// </CommandItem>
								))}
								{/* Esto lo dejo comentado para cuando tengamos implementado agregar datos de marca UPDATE
								{stores.length <= 0 && (
									<CommandItem className="w-full p-0" key="new-store">
										<NewStoreRow />
									</CommandItem>
								)} */}
							</Col>
						)}
						<Separator
							orientation="horizontal"
							className="-mx-1 h-px bg-border"
						/>

						<CommandGroup>
							{!isPos && (
								<CommandItem
									className={`justify-between text-text-muted font-medium ${itemClassName}`}
									value="settings"
									asChild
								>
									<Link to="/settings">
										<Heading6>Ajustes</Heading6>
										<Settings2 size={16} />
									</Link>
								</CommandItem>
							)}
							<CommandItem
								className={`justify-between text-text-muted font-medium ${itemClassName}`}
								value="darkmode"
							>
								<Heading6>Darkmode</Heading6>
								<Switch
									onClick={() => {
										setIsDarkTheme(!isDarkTheme)

										document.documentElement.classList.toggle("dark")
									}}
								/>
							</CommandItem>
						</CommandGroup>
						<CommandSeparator />
						<CommandGroup>
							<CommandItem className="text-text-warning font-medium ">
								<Link
									to="https://sandbox.talo.com.ar/login"
									className=" flex flex-row justify-between w-full items-center"
								>
									<Heading6>Modo Sandbox</Heading6>
									<ExternalLink size={16} />
								</Link>
							</CommandItem>
						</CommandGroup>
						<CommandSeparator />
						<CommandGroup>
							<CommandItem
								className={`justify-between text-text-error font-medium ${itemClassName}`}
								value="logout"
								onSelect={(op: any) => logout()}
							>
								<Heading6>Cerrar sesión</Heading6>
								<LogOut size={16} />
							</CommandItem>
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

const UserDropdownGroupHeader = ({ title }: { title: string }) => {
	return <Body1 className={sectionLabelClassName}>{title}</Body1>
}

function renderTrigger(
	{
		email,
		photoURL,
		displayName,
	}: {
		email: string
		photoURL?: string
		displayName?: string
	},
	isMobile: boolean
) {
	if (isMobile) {
		return (
			<Avatar className="h-10 w-10  bg-background-strong">
				<AvatarImage src={photoURL} alt={displayName} />
				<AvatarFallback className="bg-strong">
					<User />
				</AvatarFallback>
			</Avatar>
		)
	} else {
		return (
			<Button
				variant="outline"
				role="combobox"
				size="xl"
				className={
					"w-[200px] !h-[64px] justify-between rounded-md border-2 group"
				}
			>
				<Heading5 className="font-semibold grow overflow-ellipsis overflow-hidden pr-betweenCards">
					{email}
				</Heading5>
				<Avatar className="h-[44px] w-[44px] grow-0 bg-background-strong">
					<AvatarImage src={photoURL} alt={displayName} />
					<AvatarFallback className="bg-strong">
						<User className="group-hover:animate-[wiggle_1s_ease-in-out_infinite]" />
					</AvatarFallback>
				</Avatar>
			</Button>
		)
	}
}

export default UserDropdownMenu
