export interface NetworkInfo {
	name: string
	alias: string
	logoPath: string
	nativeCurrency: string
	erc20: string
}
export const networks: { [key: string]: NetworkInfo } = {
	BSC: {
		name: "Binance Smart Chain",
		logoPath: "/networks/bsc_logo.svg",
		alias: "BSC",
		nativeCurrency: "BNB",
		erc20: "BEP20",
	},
	POLYGON: {
		name: "Polygon",
		logoPath: "/networks/polygon_logo.svg",
		alias: "POLYGON",
		nativeCurrency: "MATIC",
		erc20: "ERC20",
	},
	// LACHAIN: {
	// 	name: "LaChain",
	// 	logoPath: "/networks/lachain_logo.svg",
	// 	alias: "LAC",
	// 	nativeCurrency: "UXD",
	// 	erc20: "ERC20",
	// },
}

export const getNetworkString = (network: string, currency: string) => {
	const networkInfo: NetworkInfo = networks[network]
	if (networkInfo.nativeCurrency !== currency) {
		return `${networkInfo.name} (${networkInfo.erc20})`
	} else {
		return networkInfo.name
	}
}
