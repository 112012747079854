import { FlaskConical } from "lucide-react"
import React from "react"
import { cn } from "../../lib/utils"

interface BetaSnackProps {
	className?: string
}

const BetaSnack: React.FC<BetaSnackProps> = ({ className }) => {
	return (
		<div
			className={cn(
				"absolute right-screenMobile md:right-14 top-screenMobile bg-secondary w-fit rounded-md flex flex-row gap-betweenTexts text-h6 font-medium text-text px-md py-sm justify-center items-center",
				className
			)}
		>
			<FlaskConical className="size-icon" />
			Beta
		</div>
	)
}

export default BetaSnack