import { apiPrivate } from "./interceptors"

export async function getBalance(
	user_id: string,
	preferred_currency?: string,
	team_id?: string
) {
	const res = await apiPrivate.get(
		`/balances/?user_id=${user_id}&preferred_currency=${
			preferred_currency || "nuARS"
		}${team_id ? `&team_id=${team_id}` : ""}`
	)
	return res.data.data
}

export async function getBalanceSummary() {
	const res = await apiPrivate.get("/balances/")
	return res.data.data
}
