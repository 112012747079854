import { useNavigate } from "react-router-dom"
import {
	PublicRole,
	publicRoleBackgrounds,
	publicRoleColors,
	publicRoleIcons,
	publicRoleTranslation,
} from "../../../../lib/roles"
import { Button } from "../../button"
import Spacer from "../../spacer"
import { Heading6 } from "../../texts/TextStyle"

const UserRoleBadge = ({ role }: { role: PublicRole }) => {
	const navigate = useNavigate()
	return (
		<Button
			onClick={() => navigate("/teams")}
			className={`border rounded-md min-h-[65px] hover:bg-transparent group ${publicRoleColors[role]} ${publicRoleBackgrounds[role]} mr-betweenButtons`}
		>
			<Heading6>{publicRoleTranslation[role]}</Heading6>
			<Spacer axis="horizontal" type="betweenChips" />
			{publicRoleIcons({ role })}
		</Button>
	)
}

export default UserRoleBadge
