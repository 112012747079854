import { Card } from "../../ui/card"
import Col from "../../ui/layouts/column"
import { GetStartedRow, QuestionRow } from "./backgroundCard"
import EcommerceCard from "./ecommerceCard"

const PricingComponent = () => {
	const subtitleClassName = "text-xl lg:text-3xl text-[#222]"
	const titleClassName = "text-5xl lg:text-8xl text-[#222] font-bold"
	return (
		<Col className="w-full justify-start px-[5%] gap-betweenSections">
			<div className={titleClassName}>
				NUESTROS <br></br>PRECIOS
			</div>
			<div className={subtitleClassName}>
				COMISIÓN DE UN <span className="text-onPrimary">1% POR PAGO ​🎉</span>
			</div>
			<div className="grid cols-2 gap-betweenSections">
				<div className="col-span-2 lg:col-span-1">
					<EcommerceCard></EcommerceCard>
				</div>
				<div className="col-span-2 lg:col-span-1">
					<Card className="p-[24px] bg-[#79EEA2] gap-betweenSections border border-[#1B2628] w-full max-w-[608px]">
						<Col className="gap-betweenSections">
							<div className="text-h3 text-[#1B2628] font-bold">
								ES FÁCIL Y SIN COSAS RARAS
							</div>
							<div className="text-[#494949] text-h5 font-normal">
								El pago por el servicio se realiza durante el uso. Por{" "}
								<span className="font-bold">cada pago realizado</span> por la
								integración se cobrará un{" "}
								<span className="font-bold">1% de comisión</span>.
							</div>
							<QuestionRow />
						</Col>
					</Card>
				</div>
				<div className="w-full max-w-[608px] col-span-2">
					<GetStartedRow />
				</div>
			</div>
		</Col>
	)
}

export default PricingComponent
