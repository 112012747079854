import { ColumnDef, Row } from "@tanstack/react-table"
import { Heading6 } from "../../../ui/texts/TextStyle"
import { TableCredential } from "../columns"
import { cn } from "../../../../lib/utils"

export function CredentialTextCell({
	accessorKey,
	header,
	capitalize = false,
	className,
}: {
	accessorKey: string
	header: string
	capitalize?: boolean
	className?: string
}): ColumnDef<TableCredential> {
	return {
		accessorKey: accessorKey,
		header: header,
		cell: ({ row }: { row: Row<TableCredential> }) => {
			return (
				<Heading6
					className={cn(
						`font-medium ${
							capitalize ? "capitalize" : ""
						} overflow-hidden overflow-ellipsis`,
						className
					)}
				>
					{row.getValue(accessorKey)}
				</Heading6>
			)
		},
	}
}
