import { animate, motion } from "framer-motion"

export default function LoadingPaymentAnimation() {
	const pathVariants = {
		hidden: {
			opacity: 0,
			pathLength: 0,
		},
		visible: (i: number) => ({
			opacity: 1,
			pathLength: 1,
			transition: {
				duration: 2,
				ease: "easeInOut",
				delay: i * 1.1, // Delay each path by 2 seconds multiplied by its index
			},
		}),
	}
	return (
		<motion.svg
			width="230"
			height="125"
			viewBox="0 0 230 125"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<style>
				{`
					@keyframes translateLeft {
						0%, 100% { transform: translateX(0); }
						50% { transform: translateX(-6rem); } /* Adjust the value as needed */
					}
					@keyframes translateRight {
						0%, 100% { transform: translateX(0); }
						50% { transform: translateX(6rem); } /* Adjust the value as needed */
					}
					
				`}
			</style>
			<motion.path
				d="M228.821 62.5C228.821 96.5292 201.235 124.115 167.206 124.115C133.177 124.115 105.591 96.5292 105.591 62.5C105.591 28.4708 133.177 0.884615 167.206 0.884615C201.235 0.884615 228.821 28.4708 228.821 62.5Z"
				stroke="url(#paint0_linear_23141_15145)"
				stroke-width="1.76923"
				stroke-linecap="round"
				variants={pathVariants}
				initial="hidden"
				animate="visible"
				custom={2} // Custom prop to pass index
				style={{
					animation: "translateLeft 4s ease-in-out infinite",
					animationDelay: "4s",
				}}
			/>
			<motion.path
				d="M176.821 62.5C176.821 96.5292 149.235 124.115 115.206 124.115C81.1768 124.115 53.5907 96.5292 53.5907 62.5C53.5907 28.4708 81.1768 0.884615 115.206 0.884615C149.235 0.884615 176.821 28.4708 176.821 62.5Z"
				stroke="url(#paint1_linear_23141_15145)"
				stroke-width="1.76923"
				stroke-linecap="round"
				variants={pathVariants}
				initial="hidden"
				animate="visible"
				custom={1} // Custom prop to pass index
			/>
			<motion.path
				d="M124.821 62.5C124.821 96.5292 97.2353 124.115 63.2061 124.115C29.1768 124.115 1.59067 96.5292 1.59067 62.5C1.59067 28.4708 29.1768 0.884615 63.2061 0.884615C97.2353 0.884615 124.821 28.4708 124.821 62.5Z"
				stroke="url(#paint2_linear_23141_15145)"
				stroke-width="1.76923"
				stroke-linecap="round"
				variants={pathVariants}
				initial="hidden"
				animate="visible"
				custom={0} // Custom prop to pass index
				style={{
					animation: "translateRight 4s ease-in-out infinite",
					animationDelay: "4s",
				}}
			/>
			<defs>
				<linearGradient
					id="paint0_linear_23141_15145"
					x1="105.169"
					y1="1.89394"
					x2="253.018"
					y2="44.9484"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFA7A7" />
					<stop offset="0.5625" stop-color="#A30CB5" />
					<stop offset="1" stop-color="#00173A" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_23141_15145"
					x1="53.1686"
					y1="1.89394"
					x2="201.018"
					y2="44.9484"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFA7A7" />
					<stop offset="0.5625" stop-color="#A30CB5" />
					<stop offset="1" stop-color="#00173A" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_23141_15145"
					x1="1.16864"
					y1="1.89394"
					x2="149.018"
					y2="44.9484"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFA7A7" />
					<stop offset="0.5625" stop-color="#A30CB5" />
					<stop offset="1" stop-color="#00173A" />
				</linearGradient>
			</defs>
		</motion.svg>
	)
}
