import { ReactNode } from "react"
import { cn } from "../../../../lib/utils"
import TaloGreenLogo from "../../logos/TaloGreenLogo"
import { Heading3 } from "../../texts/TextStyle"
import { platformHeader } from "../layout-sizes"
import LandingNavBar, { LandingNavItem } from "./LandingNavBar"

const LandingHeader = ({
	right,
	className,
	logoColor,
	navItems,
	center,
}: {
	right?: ReactNode | ReactNode[]
	className?: string
	logoColor?: string
	navItems?: LandingNavItem[]
	center?: ReactNode
}) => {
	return (
		<div
			className={cn(
				`flex ${platformHeader.height.class} w-full ${platformHeader.paddingY.class} px-screenMobile md:${platformHeader.paddingX.class}  items-center`,
				className
			)}
		>
			<div
				className={`${platformHeader.sidePanelWidth.class} cursor-pointer`}
				onClick={() => {
					window.location.href = process.env.REACT_APP_NEXT_LANDING_URL!
				}}
			>
				<TaloGreenLogo className={`${logoColor}`} />
			</div>
			<div className={platformHeader.titleWidth.class + " flex justify-center"}>
				<Heading3>
					{center ? center : navItems && <LandingNavBar items={navItems} />}
				</Heading3>
			</div>
			<div
				className={platformHeader.sidePanelWidth.class + " flex justify-end"}
			>
				{right}
			</div>
		</div>
	)
}
export default LandingHeader
