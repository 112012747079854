const EcommerceLogo = ({ size = 107, className = "" }) => {
	return (
		<svg
			className={`default-svg-style ${className}`} // Apply default styles and custom class
			width={size}
			viewBox="0 0 107 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M46.8025 28.3842C46.1117 23.9911 42.3318 21.1867 38.3434 21.116H38.3499C36.5382 21.0838 34.6743 21.6177 33.0451 22.8269C32.7583 23.0392 32.6866 23.1421 32.9995 23.4251C34.733 25.0074 35.6258 26.982 35.7692 29.304C35.7887 29.5935 35.7431 29.735 35.3912 29.7157C34.8177 29.6835 34.2377 29.6899 33.6642 29.7157C33.3514 29.7285 33.2601 29.6385 33.2732 29.3362C33.2797 29.0918 33.2341 28.8473 33.1884 28.6094C32.5302 25.1553 29.1218 22.9298 25.7069 23.721C22.2398 24.525 20.1609 27.979 21.1124 31.3687C22.0248 34.6169 25.6287 36.6109 28.7634 35.5817C28.7113 35.4853 28.6591 35.3888 28.607 35.2987C27.649 33.6778 27.1537 31.9283 27.0624 30.0566C27.0494 29.7671 27.1211 29.6771 27.4144 29.6899C27.9879 29.7157 28.5679 29.7221 29.1414 29.6899C29.4803 29.6771 29.565 29.7864 29.578 30.1145C29.7931 34.99 34.0813 38.682 39.0081 38.2511C44.0002 37.8137 47.578 33.2855 46.8025 28.3842Z"
				fill="#1186E3"
				className="group-hover:-translate-x-0.5 transition duration-300 "
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.8919 53.2505C47.4818 53.2505 58.4986 42.2337 58.4986 28.6438C58.4986 15.0539 47.4818 4.03711 33.8919 4.03711C20.302 4.03711 9.28516 15.0539 9.28516 28.6438C9.28516 42.2337 20.302 53.2505 33.8919 53.2505ZM30.738 21.5469C33.032 19.5851 35.5476 18.5753 38.2457 18.6203C39.4709 18.6396 40.7352 18.8776 42.019 19.3471C46.1247 20.8201 48.5817 23.8239 49.2529 28.0948C50.2044 34.1731 45.9097 39.8012 39.7641 40.6567C39.2493 40.7274 38.7344 40.7853 38.2066 40.7596C35.3717 40.7146 32.8756 39.7819 30.7641 37.9037C30.523 37.685 30.3405 37.6593 30.0472 37.7687C24.801 39.6726 19.1117 36.2185 18.46 30.7384C17.906 26.088 21.1711 21.8621 25.8438 21.1996C27.3101 20.9937 28.7438 21.1417 30.1254 21.6691C30.3796 21.7656 30.536 21.7206 30.738 21.5469Z"
				fill="#1186E3"
				className="group-hover:-translate-x-0.5 transition duration-300 "
			/>
			<path
				d="M60.2101 21.4694C60.3875 21.2077 60.6535 21.07 61.0082 21.0425C61.6543 20.9874 62.0217 21.3179 62.1103 22.034C62.503 24.912 62.9338 27.3494 63.3898 29.3461L66.1641 23.6038C66.4175 23.0805 66.7342 22.8051 67.1142 22.7776C67.6716 22.7363 68.0137 23.1218 68.153 23.9343C68.4697 25.7658 68.8751 27.3219 69.3565 28.6438C69.6858 25.1461 70.2432 22.6261 71.0286 21.07C71.2186 20.6844 71.4974 20.4917 71.8647 20.4641C72.1561 20.4366 72.4222 20.533 72.6628 20.7395C72.9035 20.9461 73.0302 21.2077 73.0555 21.5245C73.0682 21.7723 73.0302 21.9789 72.9288 22.1854C72.4348 23.1769 72.0294 24.8432 71.7001 27.1566C71.3834 29.4012 71.2693 31.1501 71.3453 32.4032C71.3707 32.7474 71.32 33.0504 71.1933 33.312C71.0413 33.615 70.8133 33.7802 70.5219 33.8078C70.1926 33.8353 69.8505 33.6701 69.5212 33.2983C68.343 31.9901 67.4055 30.0346 66.7215 27.432C65.8981 29.1947 65.29 30.5166 64.8973 31.398C64.1499 32.954 63.5165 33.7527 62.9844 33.7941C62.6424 33.8216 62.351 33.5048 62.0977 32.8439C61.4516 31.0399 60.7548 27.556 60.0074 22.392C59.9694 22.034 60.0328 21.7172 60.2101 21.4694Z"
				fill="#7F54B3"
				className="group-hover:translate-x-0.5 transition duration-300 "
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M87.4464 23.6314C86.9904 22.7638 86.319 22.2406 85.4196 22.034C85.1789 21.9789 84.9508 21.9514 84.7355 21.9514C83.5194 21.9514 82.5313 22.6399 81.7585 24.017C81.0998 25.1875 80.7704 26.4819 80.7704 27.9002C80.7704 28.9606 80.9731 29.8695 81.3784 30.6269C81.8345 31.4944 82.5059 32.0177 83.4053 32.2242C83.646 32.2793 83.8741 32.3068 84.0894 32.3068C85.3182 32.3068 86.3063 31.6183 87.0664 30.2412C87.7252 29.057 88.0545 27.7625 88.0545 26.3442C88.0545 25.2701 87.8518 24.375 87.4464 23.6314ZM85.8503 27.4458C85.6729 28.3547 85.3562 29.0294 84.8875 29.4839C84.5201 29.8419 84.1781 29.9934 83.8614 29.9245C83.5573 29.8556 83.304 29.5665 83.1139 29.0294C82.9619 28.6026 82.8859 28.1757 82.8859 27.7763C82.8859 27.4321 82.9112 27.0878 82.9746 26.7711C83.0886 26.2065 83.304 25.6557 83.646 25.1324C84.0641 24.4576 84.5075 24.1822 84.9635 24.2786C85.2675 24.3475 85.5209 24.6366 85.7109 25.1737C85.8629 25.6006 85.9389 26.0275 85.9389 26.4268C85.9389 26.7848 85.901 27.1291 85.8503 27.4458Z"
				fill="#7F54B3"
				className="group-hover:translate-x-0.5 transition duration-300 "
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M77.4767 22.034C78.3634 22.2406 79.0475 22.7638 79.5036 23.6314C79.9089 24.375 80.1116 25.2701 80.1116 26.3442C80.1116 27.7625 79.7823 29.057 79.1235 30.2412C78.3634 31.6183 77.3753 32.3068 76.1465 32.3068C75.9311 32.3068 75.7031 32.2793 75.4624 32.2242C74.563 32.0177 73.8916 31.4944 73.4355 30.6269C73.0302 29.8695 72.8275 28.9606 72.8275 27.9002C72.8275 26.4819 73.1568 25.1875 73.8156 24.017C74.5884 22.6399 75.5765 21.9514 76.7925 21.9514C77.008 21.9514 77.2359 21.9789 77.4767 22.034ZM76.9446 29.4839C77.4133 29.0294 77.7301 28.3547 77.9073 27.4458C77.9707 27.1291 77.9961 26.7848 77.9961 26.4268C77.9961 26.0275 77.92 25.6006 77.768 25.1737C77.578 24.6366 77.3246 24.3475 77.0206 24.2786C76.5646 24.1822 76.1212 24.4576 75.7031 25.1324C75.3611 25.6557 75.1457 26.2065 75.0317 26.7711C74.9684 27.0878 74.943 27.4321 74.943 27.7763C74.943 28.1757 75.019 28.6026 75.1711 29.0294C75.3611 29.5665 75.6144 29.8556 75.9185 29.9245C76.2352 29.9934 76.5772 29.8419 76.9446 29.4839Z"
				fill="#7F54B3"
				className="group-hover:translate-x-0.5 transition duration-300 "
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M74.1575 53.2505C87.7474 53.2505 98.7642 42.2337 98.7642 28.6438C98.7642 15.0539 87.7474 4.03711 74.1575 4.03711C60.5676 4.03711 49.5508 15.0539 49.5508 28.6438C49.5508 42.2337 60.5676 53.2505 74.1575 53.2505ZM61.5402 18.6189H86.7623C88.3585 18.6189 89.6506 20.0235 89.6506 21.7586V32.2242C89.6506 33.9592 88.3585 35.3639 86.7623 35.3639H77.7173L78.9588 38.6688L73.4988 35.3639H61.5529C59.9567 35.3639 58.6645 33.9592 58.6645 32.2242V21.7586C58.6519 20.0372 59.944 18.6189 61.5402 18.6189Z"
				fill="#7F54B3"
				className="group-hover:translate-x-0.5 transition duration-300 "
			/>
		</svg>
	)
}

export default EcommerceLogo
