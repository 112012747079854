import { Navigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { getPathForUser } from "../../lib/roles"

const PublicRoute = ({
	children,
	onlyPublic,
}: {
	children: React.ReactNode
	onlyPublic?: boolean
}) => {
	const { user } = useAuth()
	if (onlyPublic && user) {
		const userPath = getPathForUser(user)
		if (userPath) {
			return <Navigate to={userPath} replace={true} />
		} else {
			window.location.href = process.env.REACT_APP_NEXT_LANDING_URL!
		}
	}
	return <>{children}</>
}

export default PublicRoute
