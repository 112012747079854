import { Route } from "react-router-dom"
import ProtectedRoute from "../../components/routes/ProtectedRoute"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { PublicRole } from "../../lib/roles"
import CreateChargeBody from "./CreateChargeWizard"

export const chargeItems = [
	<Route
		key={"/charge"}
		path="/charge"
		element={
			<ProtectedRoute
				requiresVerification={true}
				minimumPublicRole={PublicRole.POS}
			>
				<ResponsiveLayout
					item={{
						itemName: "Recibir",
						bodyComponent: <CreateChargeBody />,
						smBodyComponent: <CreateChargeBody />,
					}}
				/>
			</ProtectedRoute>
		}
	/>,
]
