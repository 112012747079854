import { Store } from "lucide-react"
import { Sheet, SheetContent, SheetHeader } from "../ui/sheet"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"

const PaymentDescriptionModal = (props: {
	onClose: () => void
	isModalOpen: boolean
	time: Date
	email: string
	avatarUrl?: string
	motive?: string
}) => {
	return (
		<Sheet open={props.isModalOpen} onOpenChange={props.onClose}>
			<SheetContent side="bottom">
				<SheetHeader />
				<CenteredBody className="justify-center items-center flex">
					{/* Icono y email */}
					<div className="rounded-full bg-primarytalo-200  p-5 size-fit inline-flex items-center justify-center">
						<Store size="60px" className="text-primarytalo-800" />
					</div>
					<div className="my-4 text-center font-bold text-lg">
						{props.email}
					</div>

					{/* Hora de la compra */}
					<div>
						<div className="flex justify-between items-center mb-10">
							<p className="text-right text-h6 font-bold">
								{`Hoy, ${props.time.toLocaleTimeString([], {
									hour: "2-digit",
									minute: "2-digit",
								})}`}
							</p>
						</div>
					</div>
				</CenteredBody>
			</SheetContent>
		</Sheet>
	)
}
export default PaymentDescriptionModal
