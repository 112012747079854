import { CredentialStatus } from "./columns"

export function mapToTableCredential(credential: any) {
	const date = new Date(credential.creation_timestamp)
	return {
		client_id: credential.client_id,
		alias: credential.alias,
		creation_date: date.toLocaleDateString("es-AR"),
		status: credential.is_deleted
			? CredentialStatus.DELETED
			: CredentialStatus.ACTIVE,
	}
}
