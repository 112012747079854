import { useMutation } from "@tanstack/react-query"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import PluginSuccessModal from "../../../components/modals/plugin-success/PluginSuccessModal"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { useAuth } from "../../../context/AuthContext"
import { StoreType, createUserStore } from "../../../services/users"
import { TiendanubeCover } from "../payment-methods/PaymentsCover"
import { Loader } from "lucide-react"
import PluginFailedModal from "../../../components/modals/plugin-success/PluginFailedModal"

const TiendanubeBody = ({ appId }: { appId: string | undefined }) => {
	const navigate = useNavigate()
	const { user, stores, fetchStores } = useAuth()
	const [isModalOpen, setModalOpen] = useState<boolean>(false)
	const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false)
	const [tiendanubeStores, setTiendanubeStores] = useState<any[]>([])

	// Ref to persist mutation trigger status across renders
	const hasTriggeredMutation = useRef<boolean>(false)

	// Filtramos los stores con la app en cuestión instalada

	useEffect(() => {
		if (!stores) return

		const tiendanubeStores = stores.filter((store: any) => {
			const isStore =
				store.store_type === StoreType.TIENDANUBE &&
				store.store_apps.find((app: any) => app.app_id === appId)

			return isStore
		})

		setTiendanubeStores(tiendanubeStores)
	}, [stores])

	const [searchParams, setSearchParams] = useSearchParams()

	const wppLink = "https://wa.me/message/NLA2DUMYC5E3E1"

	const code = searchParams.get("code")

	const mutation = useMutation({
		mutationFn: async () => {
			if (appId === undefined) {
				throw new Error("REACT_APP_TIENDANUBE_AUTH_URL not set")
			}

			if (!hasTriggeredMutation.current) {
				hasTriggeredMutation.current = true
				return await createUserStore({
					user_id: user?.id,
					authorization_code: code!,
					store_type: StoreType.TIENDANUBE,
					app_id: appId,
					created_by: user?.team_id,
				})
			} else {
				console.log("Mutation skipped due to previous execution.")
			}
		},
	})

	useEffect(() => {
		// Get user stores and check if it has a Tiendanube app installed

		if (!stores) {
			return
		}

		if (
			code &&
			tiendanubeStores.length === 0 &&
			!hasTriggeredMutation.current
		) {
			mutation.mutate()
			setSearchParams({})
		}
		if (mutation.isSuccess && hasTriggeredMutation.current) {
			hasTriggeredMutation.current = false
			fetchStores()
			setModalOpen(true)
		}

		if (mutation.isError && hasTriggeredMutation.current) {
			hasTriggeredMutation.current = false
			setErrorModalOpen(true)
		}
	}, [code, stores, mutation, setSearchParams])

	const handleInstallClick = () => {
		if (appId && appId !== "")
			window.location.href = `https://www.tiendanube.com/apps/${appId}/authorize`
		else console.error("REACT_APP_TIENDANUBE_AUTH_URL not set")
	}

	return (
		<FeatureLayout
			coverImage={<TiendanubeCover />}
			sectionHeaderProps={{
				title: "Conectá tu Tiendanube",
				subtitle:
					"Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y conciliar los pagos automáticamente.",
			}}
		>
			<Section
				title="Integraciones"
				footer="Se te redirigirá a tu Tiendanube para que instales la app de Talo Pay y aceptes los permisos necesarios."
			>
				{tiendanubeStores && (
					<Option
						title="Tiendanube + Talo Pay"
						description={tiendanubeStores.length > 0 ? "Activa" : "Inactiva"}
						iconInside={false}
						icon={
							<img
								src="/dashboard/plugins/tiendanube.svg"
								className="size-full"
							/>
						}
						leading="button"
						buttonText={
							mutation.isPending ? (
								<Loader className="animate-spin" />
							) : tiendanubeStores.length > 0 ? (
								"Instalada"
							) : (
								"Instalar"
							)
						}
						buttonProps={{ disabled: tiendanubeStores.length > 0 }}
						onClick={() => {
							handleInstallClick()
						}}
					/>
				)}
			</Section>
			<PluginFailedModal
				open={isErrorModalOpen}
				onClose={() => setErrorModalOpen(false)}
				retryFunction={() => {
					handleInstallClick()
				}}
			/>
			<PluginSuccessModal
				open={isModalOpen}
				onClose={() => {
					setModalOpen(false)
					if (user.pollux_account_id) {
						navigate("/settings/payment-methods", { replace: true })
					} else {
						const url = new URL(
							window.location.pathname,
							window.location.origin
						)
						const paths = url.pathname.split("/")
						// Remove the last three paths
						paths.splice(-3)
						url.pathname = paths.join("/")
						// Redirect the user to the new URL
						navigate(url.pathname, { replace: true })
					}
				}}
			/>
		</FeatureLayout>
	)
}

export default TiendanubeBody
