import { Info } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { Button } from "../ui/button"
import LabelButton from "../ui/buttons/LabelButton"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import Row from "../ui/layouts/row"
import { SectionHeader } from "../ui/section-header"

const ConfirmWithdrawStep = ({
	transaction_id,
}: {
	transaction_id?: string
}) => {
	const { fetchBalance } = useAuth()
	const navigate = useNavigate()
	const handleGoBack = () => {
		fetchBalance()
		navigate("/")
	}
	return (
		<CenteredBody>
			<SectionHeader
				title="Envío realizado"
				subtitle="Tu retiro ha sido procesado con éxito"
			/>

			<Row className="gap-betweenComponents">
				{/* <LabelButton label="Comprobante" icon={<Share />} variant="outline"  size="xl"/> */}
				{transaction_id && (
					<LabelButton
						label="Ver detalles"
						icon={<Info />}
						variant="outline"
						size="xl"
						onClick={() => {
							navigate(
								`/app/payments?transaction_id=${transaction_id.split("#")[1]}`
							)
						}}
					/>
				)}
			</Row>
			<Button onClick={() => handleGoBack()} size="full">
				Regresar
			</Button>
		</CenteredBody>
	)
}

export default ConfirmWithdrawStep
