import { Input } from "../ui/inputs/input"
import { Separator } from "../ui/separator"
import { Label1 } from "../ui/texts/TextStyle"
import AuthTitle from "../ui/titles/AuthTitle"
import GoogleSigninButton from "./GoogleSigninButton"

const SignupEmailStep = ({
	onEmailChange,
	handleError,
	allowGoogleSignin = false,
	email,
	loading,
	disabled = false,
}: {
	onEmailChange: (email: string) => void
	handleError?: (error: string) => void
	allowGoogleSignin?: boolean
	email?: string
	loading: boolean
	disabled?: boolean
}) => {
	return (
		<div className="mx-auto grid w-full lg:min-w-[600px] gap-textToComponent">
			<AuthTitle
				title="Create una cuenta"
				subtitle="Ingrese su correo electrónico a continuación para crear su cuenta."
			/>

			{allowGoogleSignin && (
				<GoogleSigninButton handleError={handleError} loading={loading} />
			)}
			<div className="flex-row justify-center items-center inline-flex">
				<Separator className="w-full grow shrink basis-0  border-t border-border"></Separator>
				<Label1 className="w-[152.21px] text-center text-text-muted font-normal">
					O CONTINUA CON
				</Label1>
				<Separator className="w-full grow shrink basis-0  border-t border-border"></Separator>
			</div>
			<div className="grid gap-2 ">
				<div className="grid gap-2">
					<Input
						id="email"
						type="email"
						placeholder="m@example.com"
						required
						readOnly={disabled}
						label="Correo"
						onChange={(e: any) => onEmailChange(e.target.value)}
						value={email}
					/>
				</div>
			</div>
		</div>
	)
}

export default SignupEmailStep
