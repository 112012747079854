import {
	ColumnDef,
	ColumnFiltersState,
	Row,
	RowSelectionState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { Lightbulb } from "lucide-react"
import { useState } from "react"
import { Alert } from "../alert"
import { Skeleton } from "../skeleton"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../table/table"
import {
	DataTablePagination,
	PaginationHandler,
} from "./data-table-pagination-footer"
import DataTableToolbar from "./data-table-toolbar"

export interface FilterProps {
	column: string
	title: string
	options: { label: string; value: string }[]
}
interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[]
	data: TData[]
	searchFilter?: {
		placeholder: string
		columnName: string
	}
	filters?: FilterProps[]
	onRowSelected?: (row: TData) => void
	className?: string
	rowHeightClass?: string
	viewOptions?: boolean
	variant?: "default" | "bordered"
	rowClassName?: (row: Row<TData>) => string
	loading?: boolean
	paginationHandler?: PaginationHandler
	tableItem?: string
}

export function DataTable<TData, TValue>({
	columns,
	data,
	searchFilter,
	filters = [],
	onRowSelected,
	className,
	rowHeightClass = "h",
	viewOptions,
	variant = "default",
	rowClassName = () => "",
	loading = false,
	paginationHandler,
	tableItem,
}: DataTableProps<TData, TValue>) {
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const [storeFilter, setStoreFilter] = useState<string[]>([])

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			columnFilters,
			columnVisibility,
			rowSelection,
			globalFilter: storeFilter,
		},
		globalFilterFn: globalStoreFilterFn,
		enableRowSelection: onRowSelected ? true : false,
		enableMultiRowSelection: false,
		enableGlobalFilter: true,
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	})

	return (
		<div>
			{(searchFilter || viewOptions || filters?.length > 0) && (
				<DataTableToolbar
					table={table}
					searchInput={searchFilter}
					viewOptions={viewOptions}
					badgeFilters={filters
						.filter((filter) => {
							table.getAllLeafColumns().find((col) => filter.column === col.id)
						})
						.map((filter) => {
							const column = table.getColumn(filter.column)
							return {
								column: column,
								title: filter.title,
								options: filter.options,
							}
						})}
					storeFilter={storeFilter}
					setStoreFilter={setStoreFilter}
				/>
			)}

			<div
				className={
					variant === "bordered"
						? "h-full rounded-md border border-border bg-background-subtle"
						: ""
				}
			>
				<Table className={className}>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext()
												  )}
										</TableHead>
									)
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{loading ? (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24">
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
								</TableCell>
							</TableRow>
						) : table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									className={`hover:bg-background p-xs group ${rowHeightClass} ${rowClassName(
										row
									)}`}
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
									onClick={() => {
										if (onRowSelected) {
											onRowSelected(row.original)
										}
										row.toggleSelected(!row.getIsSelected())
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 bg-background rounded-b-md  py-6 "
								>
									<Alert
										title={`Parece que todavía no hay ${
											tableItem ?? "transacciones"
										}`}
										description={`Cuando haya ${
											tableItem ?? "transacciones"
										}, las veras reflejadas aca.`}
										icon={<Lightbulb />}
									></Alert>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			{paginationHandler && (
				<DataTablePagination paginationHandler={paginationHandler} />
			)}
		</div>
	)
}

// Adjust the globalStoreFilterFn to work with strings
function globalStoreFilterFn<TData>(
	row: Row<TData>,
	columnId: string,
	filterValue: string[]
) {
	if (!filterValue || filterValue.length === 0) {
		return true
	}
	const storeId = String(row.getValue("store_name"))
	return !filterValue.includes(storeId)
}
