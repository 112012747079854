import { Check, CircleHelp, Layout, Radar, Settings } from "lucide-react"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { Section } from "../../../components/ui/section"
import { Steps } from "../../../components/ui/steps"
import { ShopifyCover } from "../payment-methods/PaymentsCover"
import SlidingCard from "../../../components/ui/sliding-card"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Heading6 } from "../../../components/ui/texts/TextStyle"

const ShopifyTutorialBody = () => {
	return (
		<FeatureLayout
			coverImage={<ShopifyCover />}
			sectionHeaderProps={{
				title: "Configura tu checkout",
				subtitle:
					"Deberas agregar el plugin instalado a tu checkout entrando a tu panel de administrador de Shopify.",
			}}
		>
			<div className="flex flex-col gap-0">
				<Steps
					title="Configurar el checkout"
					description="Configuración > Pantalla de Pago > Personalizar"
					icon={<Settings />}
					leading="div"
					div={
						<SlidingCard
							side={"center"}
							trigger={
								<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
							}
						>
							<CenteredBody>
								<Section title="Configuración > Pantalla de Pago > Personalizar">
									<img
										src="/home/shopify/Step1_installation.gif"
										alt="Step1_installation"
									/>
									<Heading6 className="font-medium text-text-muted text-justify">
										Deberás acceder a tu panel de administrador de Shopify y
										acceder a la sección de ajustes, luego a la sección de
										Pantalla de pago donde sera necesario personalizarla.
									</Heading6>
								</Section>
							</CenteredBody>
						</SlidingCard>
					}
					position="top"
				/>

				<Steps
					title="Sección de Gracias"
					description="Agregar integración en la sección `Gracias`."
					icon={<Layout />}
					leading="div"
					div={
						<SlidingCard
							side={"center"}
							trigger={
								<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
							}
						>
							<CenteredBody>
								<Section title="Gracias > Secciones > Agregar bloque de aplicaciones">
									<img
										src="/home/shopify/Step2_installation.gif"
										alt="Step2_installation"
									/>
									<Heading6 className="font-medium text-text-muted text-justify">
										Aca deberás agregar la integración en la sección de Gracias,
										para que el cliente pueda ver los datos de la transferencia.
									</Heading6>
								</Section>
							</CenteredBody>
						</SlidingCard>
					}
				/>
				<Steps
					title="Sección de Estado de pedido"
					description="Agregar integración en la sección `Estado de pedido`."
					icon={<Radar />}
					leading="div"
					div={
						<SlidingCard
							side={"center"}
							trigger={
								<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
							}
						>
							<CenteredBody>
								<Section title="Estado de pedido > Secciones > Agregar bloque de aplicaciones">
									<img
										src="/home/shopify/Step3_installation.gif"
										alt="Step3_installation"
									/>
									<Heading6 className="font-medium text-text-muted text-justify">
										Aca deberás agregar la integración en la sección de Estado
										de pedido, para que el cliente pueda ver el estado de su
										orden con respecto al envió del dinero.
									</Heading6>
								</Section>
							</CenteredBody>
						</SlidingCard>
					}
				/>
				<Steps
					title="Guardar cambios"
					description="Asegurate de guardar los cambios realizados en Shopify"
					icon={<Check />}
					leading="none"
					position="bottom"
				/>
			</div>
		</FeatureLayout>
	)
}

export default ShopifyTutorialBody
