import { Key } from "lucide-react"
import { useState } from "react"
import { useAuth } from "../../../context/AuthContext"
import { createUserCredentials } from "../../../services/users"
import LoadableButton from "../../ui/buttons/LoadableButton"
import { Input } from "../../ui/inputs/input"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
import { Sheet, SheetContent, SheetHeader } from "../../ui/sheet"
import { Skeleton } from "../../ui/skeleton"
import { Body1 } from "../../ui/texts/TextStyle"
import SuccessfulCredentialCreation from "./SuccessfulCredentialCreation"

interface NewCredentialModalProps {
	open: boolean
	onClose: () => void
	handleSubmit?: (res: any) => void
}
export interface Credential {
	user_id: string
	creation_timestamp: string
	client_id: string
	alias: string
	client_secret: string
}
type CredentialData = {
	alias?: string
}

function validateData(
	data: CredentialData,
	setErrorMessage: (message: string) => void
) {
	if (!data.alias || data.alias.length === 0) {
		setErrorMessage("Alias es requerido")
		return
	}
}

const NewCredentialModal = ({
	open,
	onClose,
	handleSubmit,
}: NewCredentialModalProps) => {
	const [alias, setAlias] = useState<string | undefined>()
	const [credential, setCredential] = useState<Credential | undefined>()
	const { user } = useAuth()
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	)
	const onSubmit = async () => {
		setErrorMessage(undefined)
		// e.preventDefault()
		const userId = user.user_id
		validateData({ alias: alias }, setErrorMessage)
		setLoading(true)
		try {
			const postResponse = await createUserCredentials(userId, alias!)
			if (postResponse.status === 200) {
				const credential = postResponse.data.data
				setCredential(credential)
				setLoading(false)
			}
		} catch (err) {
			setErrorMessage("Hubo un error al crear la credencial")
			setLoading(false)
		}
	}
	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				if (!open) {
					setAlias(undefined)
					setCredential(undefined)
					setErrorMessage(undefined)
					onClose()
				}
			}}
		>
			<SheetContent side="right">
				<div className="grid grid-cols-1 h-full items-center content-center">
					<CenteredBody>
						{loading ? (
							<Skeleton className="h-[125px] w-full rounded-xl bg-background-strong" />
						) : credential ? (
							<SuccessfulCredentialCreation credential={credential} />
						) : (
							<>
								<SheetHeader>
									<SectionHeader
										title="Nueva credencial"
										subtitle="Podrás generar credenciales para realizar integración por API o Woocommerce"
									></SectionHeader>
								</SheetHeader>
								<div className="flex flex-col gap-betweenFields">
									<Input
										label="Alias"
										onChange={(e) => setAlias(e.target.value)}
										required
										value={alias}
									/>
								</div>

								{errorMessage && (
									<Body1 className="text-destructive text-normal">
										{errorMessage}
									</Body1>
								)}
								<LoadableButton
									variant="secondary"
									size="full"
									isLoading={loading}
									onClick={onSubmit}
								>
									{!loading && <Key className="m-2 size-icon" />}
									Generar credencial
								</LoadableButton>
							</>
						)}
					</CenteredBody>
				</div>
			</SheetContent>
		</Sheet>
	)
}

export default NewCredentialModal
