import FAQComponent from "../../components/home/faq/faq_component"
import PricingComponent from "../../components/home/pricing/PricingComponent"
import MarketplaceBody from "./MarketplaceBody"

export const landingItems = [
	{
		label: "Documentación",
		url: "https://docs.talo.com.ar",
		showNavbar: true,
	},
	{
		label: "Precios",
		link: "/pricing",
		bodyComponent: <PricingComponent />,
		showNavbar: true,
	},
	{
		label: "Merch",
		url: "https://talostickers.mitiendanube.com/",
		showNavbar: true,
	},
	{
		label: "Marketplace",
		link: "/marketplace",
		bodyComponent: <MarketplaceBody />,
		showNavbar: true,
	},
	{
		label: "FAQ",
		link: "/faq",
		bodyComponent: <FAQComponent />,
		showNavbar: false,
	},
]
