import { NavigateFunction, useNavigate } from "react-router-dom"
import { useAuth } from "../../../../context/AuthContext"
import useBreakpoint from "../../../../hooks/useBreakpoint"
import {
	PublicRole,
	publicRoleColors,
	publicRoleIcons,
} from "../../../../lib/roles"
import Row from "../../layouts/row"
import UserDropdownMenu from "./UserDropdownMenu"
import UserRoleBadge from "./UserRoleBadge"

const UserHeader = ({ className = "" }: { className?: string }) => {
	const isMobile = useBreakpoint("sm")
	const { user } = useAuth()
	const navigate = useNavigate()
	const role_list = user?.roles ?? user?.talo_role_list ?? []
	return (
		<Row className={`items-center  ${className}`}>
			{renderUserBadge(role_list, isMobile as boolean, navigate)}
		</Row>
	)
}

function renderUserBadge(
	role_list: PublicRole[],
	isMobile: boolean,
	navigate: NavigateFunction
) {
	if (isMobile) {
		return (
			<Row className="gap-betweenComponents items-center">
				{role_list.map((role) => {
					return (
						<div
							key={role}
							className={`${publicRoleColors[role]} size-[40px] flex rounded-full items-center justify-center content-center bg-background-warningMuted border border-border-warning text-text-warning [&_*]:size-6`}
							onClick={() => navigate("/teams")}
						>
							{publicRoleIcons({ role })}
						</div>
					)
				})}
				<UserDropdownMenu />
			</Row>
		)
	} else {
		return (
			<>
				{role_list.map((role, index) => (
					<UserRoleBadge key={index} role={role} />
				))}
				<UserDropdownMenu />
			</>
		)
	}
}

export default UserHeader
