import { useEffect, useState } from "react"

const screenBreakpoint = ["sm", "md", "lg", "xl"] as const
export type ScreenBreakpoint = (typeof screenBreakpoint)[number]

const breakpoints = {
	sm: 917,
	md: 1338,
	lg: 1600,
	xl: 1925,
}
const useBreakpoint = (size?: ScreenBreakpoint) => {
	const [breakpoint, setBreakpoint] = useState(getBreakpoint())

	useEffect(() => {
		const handleResize = () => {
			setBreakpoint(getBreakpoint())
		}
		window.addEventListener("resize", handleResize)
		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, []) // Empty dependency array means this effect runs once on mount

	function getBreakpoint() {
		const screenWidth = window.innerWidth
		if (!size) {
			const bk = Object.entries(breakpoints).find(
				([key, value]) => screenWidth < value
			)
			return bk ? (bk[0] as ScreenBreakpoint) : "xl"
		}

		const targetBreakpoint = breakpoints[size]
		return screenWidth < targetBreakpoint
	}

	return breakpoint
}

export default useBreakpoint
