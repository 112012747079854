import React from "react"

import { TriangleAlert } from "lucide-react"
import { Heading6 } from "../texts/TextStyle"
import Row from "../layouts/row"

interface InputErrorMessageProps {
	errorText: React.ReactNode
}

const InputErrorMessage: React.FC<InputErrorMessageProps> = ({ errorText }) => {
	return (
		<Row className="gap-betweenTexts p-2">
			<TriangleAlert className="text-text-error size-icon mt-1" />
			<Heading6 className="text-text-error font-medium">{errorText}</Heading6>
		</Row>
	)
}

export default InputErrorMessage
