import { Route } from "react-router-dom"
import MerchantQr from "."

export const merchantItems = [
	<Route
		key="/merchants/:qrId"
		path="/merchants/:qrId"
		element={<MerchantQr />}
	/>,
]
