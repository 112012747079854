import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTeam, TeamStatus, translatedTeamStatus } from "../columns"
import { Heading6 } from "../../../ui/texts/TextStyle"
import TRow from "../../../ui/layouts/row"
import { Avatar, AvatarFallback } from "../../../ui/avatar"
import { AvatarImage } from "@radix-ui/react-avatar"
import { Clock } from "lucide-react"

export function TeamStatusCell(): ColumnDef<TableTeam> {
	return {
		accessorKey: "status",
		header: "Estado",
		cell: ({ row }: { row: Row<TableTeam> }) => {
			let initials: string
			let status: TeamStatus
			try {
				initials = (row.getValue("alias") as string)
					.split(" ")
					.map((word) => word[0])
					.join("")
				status = row.getValue("status") as TeamStatus
			} catch (e) {
				console.log(e)
				return null
			}
			return (
				<TRow className="items-center gap-betweenTexts">
					{status === TeamStatus.PENDING ? (
						<Clock className="text-icon-disabled size-sm" />
					) : (
						<Avatar className="!size-sm">
							<AvatarImage src={row.original.photoUrl} />
							<AvatarFallback className="bg-background-elevated">
								<Heading6 className="font-medium uppercase text-text-muted">
									{initials}
								</Heading6>
							</AvatarFallback>
						</Avatar>
					)}
					<Heading6 className="font-normal">
						{translatedTeamStatus[status]}
					</Heading6>
				</TRow>
			)
		},
	}
}
