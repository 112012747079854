import { Link } from "react-router-dom"
import { Button } from "../ui/button"
import AuthTitle from "../ui/titles/AuthTitle"

const ForgotPasswordConfirmationStep = () => {
	return (
		<div className="mx-auto grid w-full lg:min-w-[600px] gap-textToComponent">
			<AuthTitle
				title="Email enviado"
				subtitle="Hemos enviado un correo a tu dirección de email con las instrucciones para restablecer tu contraseña."
			/>
			<div className="flex items-center justify-center">
				<img
					className="w-1/3 flex items-center justify-center"
					src="/illustrations/verified-mail.svg"
					alt=""
				/>
			</div>
			<Button asChild size="full">
				<Link to="/login">Iniciar sesión</Link>
			</Button>
		</div>
	)
}

export default ForgotPasswordConfirmationStep
