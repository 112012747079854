import { ArrowLeft } from "lucide-react"
import {
	ChargeWalletProps,
	WalletProps,
	paymentWallets,
} from "../../../utils/wallets"
import { Button } from "../../ui/button"
import { Heading3, Heading4, Heading6 } from "../../ui/texts/TextStyle"

const ChargeWallet = (props: ChargeWalletProps) => {
	// const { style, chargeCurrencies, handleSelectCurrency } = props
	const { handleWalletClick, handleGoBack } = props

	return (
		<div className="w-full block">
			<div className="w-full items-center mt-5">
				<div>
					<Heading3 className="font-medium">Elegí tu billetera</Heading3>
					<Heading6 className="text-md font-medium text-text-muted">
						Elegí con qué billetera pagar
					</Heading6>
				</div>
			</div>
			<div className="w-full mt-6">
				<div className="grid grid-flow-row gap-3">
					{paymentWallets.map((wallet) => {
						return (
							<WalletCard
								key={wallet.id}
								{...wallet}
								onPress={() =>
									handleWalletClick({
										id: wallet.id,
										name: wallet.name,
									})
								}
							/>
						)
					})}
				</div>
			</div>{" "}
			<div className="w-full mt-12 mb-7">
				<Button variant="outline" onClick={handleGoBack}>
					<ArrowLeft size={25} color="black" />
					<Heading4 className="font-md ml-1">Atrás</Heading4>
				</Button>
			</div>
		</div>
	)
}

const WalletCard = (props: WalletProps) => {
	return (
		<Button
			className="bg-card min-h-5 w-full max-w-3xl border-r-4"
			onClick={props.onPress}
		>
			<div className="w-full text-black flex items-center flex-row h-full px-4 py-2">
				<p className="text-black px-4 py-1">{props.name}</p>
			</div>
		</Button>
	)
}
export default ChargeWallet
