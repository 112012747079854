import { useAuth } from "../../../../context/AuthContext"
import useBreakpoint from "../../../../hooks/useBreakpoint"
import { isInternal } from "../../../../lib/roles"
import { privateMenuItems } from "../../../../pages/admin/items"
import { publicMenuItems } from "../../../../pages/app/items"
import MobilePlatformLayout from "./MobilePlatformLayout"
import PlatformLayout from "./PlatformLayout"
import { LayoutItem } from "./platform-types"

const ResponsiveLayout = ({ item }: { item: LayoutItem }) => {
	var body
	const { user, hasPrivilege } = useAuth()

	if (useBreakpoint("sm")) {
		body = (
			<MobilePlatformLayout
				headerContent={item.smHeaderContent}
				activeTab={item.activeTab}
				variant={item.smHeaderContent ? "bigHeader" : "default"}
				headerTitle={item.itemName}
			>
				{item.smBodyComponent}
			</MobilePlatformLayout>
		)
	} else {
		body = (
			<PlatformLayout
				title={item.itemName}
				activeTab={item.activeTab}
				navbarItems={
					isInternal(user?.roles ?? user?.talo_role_list ?? [])
						? privateMenuItems
						: publicMenuItems.filter(
								(item) =>
									hasPrivilege(
										item.minimumPrivateRole,
										item.minimumPublicRole
									) && item.showInNavbar !== false
						  )
				}
			>
				{item.bodyComponent}
			</PlatformLayout>
		)
	}
	return body
}

export default ResponsiveLayout
