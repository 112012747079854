import { MouseEventHandler, ReactNode } from "react"
import { cn } from "../../../lib/utils"

interface OptionCardProps {
	header: ReactNode
	footer: ReactNode
	className?: string
	onClick?: MouseEventHandler<HTMLDivElement>
}

const OptionCard: React.FC<OptionCardProps> = ({
	header,
	footer,
	className,
	onClick,
}) => {
	const classes = className
		?.split(" ")
		.reduce<{ header: string[]; footer: string[]; main: string[] }>(
			(acc, curr) => {
				if (curr.startsWith("header:")) {
					acc.header.push(curr.replace("header:", ""))
				} else if (curr.startsWith("footer:")) {
					acc.footer.push(curr.replace("footer:", ""))
				} else {
					acc.main.push(curr)
				}
				return acc
			},
			{ main: [], header: [], footer: [] }
		) || { main: [], header: [], footer: [] }

	return (
		<div
			className={cn(
				"h-[128px] w-full rounded-sm bg-background-subtle p-sm transition duration-200 ",
				...classes.main
			)}
			onClick={onClick}
		>
			<div className="flex flex-col h-full justify-between ">
				<div
					className={cn(
						"text-h6 group-hover/slide:translate-x-2 transition duration-200",
						...classes.header
					)}
				>
					{header}
				</div>
				<div
					className={cn(
						"text-h5 font-semibold group-hover/slide:translate-x-2 transition duration-500",
						...classes.footer
					)}
				>
					{footer}
				</div>
			</div>
		</div>
	)
}

export default OptionCard
