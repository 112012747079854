import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { apiPrivate } from "../../../services/interceptors"
import { KycGetResponse } from "./onboard-personal-wizard-types.ts"
import { AxiosError } from "axios"

export const useGetKyc = (
	user_id: string | undefined
): UseQueryResult<KycGetResponse, Error> => {
	return useQuery<KycGetResponse, Error>({
		queryKey: ["onboarding-personal-wizard", user_id],
		queryFn: async () => {
			if (!user_id) {
				throw new Error("User ID not provided")
			}
			const { data } = await apiPrivate.get(`/users/${user_id}/kyc`)
			return data.data as KycGetResponse
		},
		retry: (failureCount, error) => {
			const parsedErr = error as AxiosError
			if (parsedErr.response?.status == 404) return false
			return failureCount < 2
		},
		refetchOnWindowFocus: false,
	})
}
