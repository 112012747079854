import { CircleDollarSign, Loader2 } from "lucide-react"
import { Charge } from "../../../pages/charge/charge-wizard-types"

import { useState } from "react"
import LoadableButton from "../../ui/buttons/LoadableButton"
import { Card, CardContent, CardHeader } from "../../ui/card"
import { Body1, Heading6 } from "../../ui/texts/TextStyle"

export interface QRCryptoItem {
	id: string
	active_payment: Charge
	code: string
	qr_url: string
}

export const QRCryptoCard = ({
	qr,
	handleDeletePayment,
	handleNewPayment,
}: {
	qr: QRCryptoItem
	handleDeletePayment: () => void
	handleNewPayment: () => void
}) => {
	const [loading, setLoading] = useState(false)
	function getStatusIcon(status: "busy" | "available") {
		switch (status) {
			case "busy":
				return <Loader2 className="size-[26px] text-border-warning-muted" />
			case "available":
				return <CircleDollarSign className="size-[26px] text-icon-success" />
		}
	}

	function getActionButton(
		status: "busy" | "available",
		handleDeletePayment: () => void,
		handleNewPayment: () => void
	) {
		return (
			<LoadableButton
				isLoading={loading}
				onClick={
					status === "busy"
						? () => {
								setLoading(true)
								handleDeletePayment()
								setLoading(false)
						  }
						: handleNewPayment
				}
				size="md"
				variant="outline"
			>
				{status === "busy" ? "Cancelar" : "Cargar"}
			</LoadableButton>
		)
	}
	return (
		<Card>
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				{getStatusIcon(qr.active_payment ? "busy" : "available")}
				{getActionButton(
					qr.active_payment ? "busy" : "available",
					handleDeletePayment,
					handleNewPayment
				)}
			</CardHeader>
			<CardContent>
				<Heading6 className="font-semibold">{qr.code}</Heading6>
				<Body1 className="text-text-muted">
					{qr.active_payment ? "Esperando pago" : "Disponible para cargar"}
				</Body1>
			</CardContent>
		</Card>
	)
}
