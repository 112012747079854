import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import { publicRoleShortTranslation } from "../../../lib/roles"
import { DataTable } from "../../ui/data-table/data-table"
import { PaginationHandler } from "../../ui/data-table/data-table-pagination-footer"
import { TableUser, columns } from "./columns"

const AdminUsersTable = ({
	users,
	paginationHandler,
}: {
	users: TableUser[]
	paginationHandler: PaginationHandler
}) => {
	const breakpoint = useBreakpoint() as ScreenBreakpoint
	return (
		<DataTable
			data={users}
			columns={columns[breakpoint]}
			paginationHandler={paginationHandler}
			searchFilter={{
				placeholder: "Email",
				columnName: "email",
			}}
			loading={!users}
			tableItem="usuarios"
			filters={[
				{
					column: "role",
					title: "Rol",
					options: Object.entries(publicRoleShortTranslation).map(
						([key, value]) => ({
							value: key,
							label: value,
						})
					),
				},
			]}
		/>
	)
}

export default AdminUsersTable
