import useBreakpoint from "../../../hooks/useBreakpoint"
import Row from "../layouts/row"
import { Separator } from "../separator"
import { Heading4, Heading5 } from "../texts/TextStyle"

const SectionTitle = ({
	title,
	subtitle,
}: {
	title: string
	subtitle?: string
}) => {
	const isMd = useBreakpoint("md")
	return (
		<div className="flex flex-row w-full items-center">
			<Heading4 className="font-semibold flex-initial">{title}</Heading4>
			{!isMd && subtitle && (
				<Row className="items-center">
					<Separator
						orientation="vertical"
						className="mx-betweenTexts h-4"
						decorative
					/>
					<Heading5 className="font-normal text-text-muted text-nowrap overflow-clip overflow-ellipsis">
						{subtitle}
					</Heading5>
				</Row>
			)}
		</div>
	)
}

export default SectionTitle
