import { CSVLink } from "react-csv"
import { Input } from "../../ui/inputs/input"
import Col from "../../ui/layouts/column"
import { SectionHeader } from "../../ui/section-header"
import { SheetHeader } from "../../ui/sheet"
import { Credential } from "./NewCredentialModal"
import { Button } from "../../ui/button"
import { Download } from "lucide-react"

const SuccessfulCredentialCreation = ({
	credential,
}: {
	credential: Credential
}) => {
	return (
		<Col className="gap-betweenSections">
			<SheetHeader>
				<SectionHeader
					title={`Credencial generada: ${credential.alias}`}
					subtitle="Guardá esta información en un lugar seguro"
				/>
			</SheetHeader>
			<Col className="gap-betweenFields">
				<Input
					readOnly
					label="Client ID"
					value={credential.client_id}
					type="copy"
				/>
				<Input
					readOnly
					label="Client Secret"
					value={credential.client_secret}
					type="copy"
				/>
			</Col>
			<CSVLink
				filename={`talo_credentials_${credential.alias}.csv`}
				data={[
					{
						client_id: credential.client_id,
						client_secret: credential.client_secret,
					},
				]}
			>
				<Button size="full">
					Descargar credenciales (.csv) <Download className="pl-betweenChips" />
				</Button>
			</CSVLink>
		</Col>
	)
}

export default SuccessfulCredentialCreation
