import { SectionHeader } from "../../ui/section-header"

const EmptyWallet = () => {
	return (
		<div className="flex flex-col items-center w-full gap-betweenSections bg-background-subtle rounded-sm p-lg border border-border">
			<img
				className="w-1/2 flex items-center justify-center"
				src="/illustrations/no-coins.svg"
				alt=""
			/>

			<SectionHeader
				title="No hay mucho..."
				subtitle="Le pasa a los mejores, no te preocupes. Cuando recibas dinero lo verás reflejado aca."
			/>
		</div>
	)
}

export default EmptyWallet
