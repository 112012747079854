import { useNavigate } from "react-router-dom"
import TaloSmallGreenLogo from "../../logos/TaloSmallGreenLogo"
import UserHeader from "../../menus/user-nav/UserHeader"
import { Heading3 } from "../../texts/TextStyle"
import { mobilePlatformHeader } from "../layout-sizes"
import Row from "../row"
import { ArrowLeft } from "lucide-react"

interface SimpleMobileHeaderProps {
	title: string
	backLink?: string
}

const SimpleMobileHeader: React.FC<SimpleMobileHeaderProps> = ({
	title,
	backLink,
}) => {
	const navigate = useNavigate()

	return (
		<div className={`px-screenMobile pt-3 mb-belowSimpleHeader`}>
			<Row
				className={`${mobilePlatformHeader.simpleHeight.class} items-center `}
			>
				<span className="basis-1/4 justify-start">
					{backLink ? (
						<div
							className="flex p-1 items-center"
							onClick={() => {
								navigate(-1)
							}}
						>
							<ArrowLeft />
						</div>
					) : (
						<TaloSmallGreenLogo />
					)}
				</span>
				<Heading3 className="font-semibold basis-1/2 text-center">
					{title}
				</Heading3>
				{/* <UserHeader className="basis-1/4 justify-end" /> */}
			</Row>
		</div>
	)
}

export default SimpleMobileHeader
