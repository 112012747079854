import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../lib/utils"
import { HoverBorderGradient } from "./hover-border-gradient"

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium font-sans ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "bg-primary text-onPrimary hover:bg-background-primaryHover",
				destructive:
					"bg-background-errorStrong text-background hover:bg-background-errorStrongHover",
				outline:
					"border border-border bg-background-subtle hover:bg-background-elevated",
				secondary:
					"bg-secondary text-onSecondary hover:bg-background-secondaryHover",
				ghost: "hover:bg-background-elevated",
				link: "relative after:absolute after:bg-text-interactive after:-bottom-1 after:h-[1px] after:w-[80%] after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300",
				bordered: "",
			},
			size: {
				sm: "h-sm rounded-xs px-sm py-[15px]",
				md: "h-md rounded-xs px-sm py-xs",
				lg: "h-lg rounded-xs px-sm py-xs",
				xl: "h-xl rounded-xs px-sm py-xs",
				flex: "rounded-xs px-sm",
				full: "h-lg rounded-xs w-full px-sm py-xs",
				icon: "h-10 w-10 ",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "lg",
		},
	}
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button"
		return variant === "bordered" ? (
			<HoverBorderGradient>
				<Comp
					className={cn(buttonVariants({ variant, size, className }))}
					ref={ref}
					{...props}
				/>
			</HoverBorderGradient>
		) : (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		)
	}
)
Button.displayName = "Button"

export { Button, buttonVariants }
