import { SuccessfulQrRegisterAnimation } from "../../app/qrs/animations/SuccessfulQrRegisterAnimation"
import { Button } from "../../ui/button"
import { SectionHeader } from "../../ui/section-header"
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "../../ui/sheet"

const SuccessfulQRRegistrationModal = ({
	open,
	onClose,
	handleConfirm,
}: {
	open: boolean
	onClose: () => void
	handleConfirm: () => void
}) => {
	return (
		<Sheet
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
		>
			<SheetContent side="right">
				<div className="grid grid-cols-1 items-center gap-imageBottom">
					<SheetHeader className="py-betweenComponents">
						<SheetTitle className="pb-betweenSections">
							<SectionHeader
								title="¡Asociaste tu QR!"
								subtitle="Ya podés empezar a cobrar en Crypto"
							/>
						</SheetTitle>

						<SheetDescription></SheetDescription>
					</SheetHeader>
					<SuccessfulQrRegisterAnimation />
					{/*<div style={{ backgroundColor: "#E2F7E9", borderRadius: 7, padding: 20 }}>
				<Text style={{ fontWeight: "bold", fontSize: 14 }}>
					Consejos antes de comenzar
				</Text>
				 <div style={{ display: "flex" }}>
					<Text
						style={{
							fontWeight: 500,
							fontSize: 28,
							color: "black",
							marginTop: -8,
						}}
					>
						*
					</Text>
					<Text
						style={{
							fontWeight: 500,
							fontSize: 13,
							color: "#5B7162",
							marginLeft: 11,
						}}
					>
						Asegurese de ubicar en algún lugar visible del local el QR
						registrado.
					</Text>
				</div>

				<div style={{ display: "flex" }}>
					<Text
						style={{
							fontWeight: 500,
							fontSize: 28,
							color: "black",
							marginTop: -8,
						}}
					>
						*
					</Text>
					<Text
						style={{
							fontWeight: 500,
							fontSize: 13,
							color: "#5B7162",
							marginLeft: 11,
						}}
					>
						Coloque el sticker de “Aceptamos cripto” afuera del comercio.
					</Text>
				</div>
			</div> */}
					<SheetFooter>
						<Button size="full" onClick={handleConfirm}>
							Comenzar a cobrar
						</Button>
					</SheetFooter>
				</div>
			</SheetContent>
		</Sheet>
	)
}

export default SuccessfulQRRegistrationModal
