import { QRCodeSVG } from "qrcode.react"
import { useMediaQuery } from "../../../utils/useMediaQuery"
import { Heading3 } from "../../ui/texts/TextStyle"
import { Sheet, SheetContent, SheetHeader } from "../../ui/sheet"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
interface QRModalProps {
	isModalOpen: boolean
	onClose: () => void
	address: string
}

const QRModalMobile = (props: QRModalProps) => {
	const isSm = useMediaQuery(960)
	return (
		<Sheet open={props.isModalOpen} onOpenChange={props.onClose}>
			<SheetHeader />
			<SheetContent side="bottom">
				<CenteredBody className="my-10">
					<SheetHeader>
						<SectionHeader
							title="Escaneá el código"
							subtitle="Escaneá el código QR desde tu móvil para acceder a los datos de
								la transacción."
						/>
					</SheetHeader>
					<div className="w-full flex justify-center items-center">
						<div className="w-fit bg-white p-sm rounded-sm flex justify-center">
							<QRCodeSVG
								level="M"
								value={props.address}
								className="size-[250px]"
							/>
						</div>
					</div>
				</CenteredBody>
			</SheetContent>
		</Sheet>
	)
}

export default QRModalMobile
