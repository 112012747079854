// Import the functions you need from the SDKs you need
import { getAnalytics } from "@firebase/analytics"
import { getAuth } from "@firebase/auth"
import { getRemoteConfig } from "@firebase/remote-config"
import { initializeApp } from "firebase/app"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const aux = process.env.REACT_APP_FIREBASE_CONFIG_FILE
const firebaseConfig = require(`./${aux}`)

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)
export const auth = getAuth()
export const remoteConfig = getRemoteConfig(app)
// const analytics = getAnalytics(app);
