import { formatEther } from "ethers"
import { useEffect, useState } from "react"
import { BalanceItem } from "../../utils/balances"
import { formatPriceAmount, trimBn } from "../../utils/numbers"
import { Price } from "../tables/transactions-table/columns"
import { Button } from "../ui/button"
import AmountInput from "../ui/inputs/AmountInput"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../ui/section-header"
import { Body1, Heading6 } from "../ui/texts/TextStyle"
import WithdrawSummaryCryptoCard, {
	WithdrawQuote,
} from "./WithdrawSummaryCryptoCard"
import InputErrorMessage from "../ui/inputs/InputErrorMessage"


const EnterAmountStepCrypto = ({
	onAmountChange,
	balanceAvailable,
	onConfirmAmount,
	quote,
	prices,
	loading,
}: {
	onAmountChange: (newPrice: Price) => void
	balanceAvailable?: BalanceItem
	onConfirmAmount: () => void
	quote?: WithdrawQuote
	prices?: Price[]
	loading?: boolean
}) => {
	const [amount, setAmount] = useState<Price | undefined>()
	const [validData, setValidData] = useState<[boolean, string]>([true, ""])
	const handleWithdrawAll = () => {
		if (!balanceAvailable) return
		onAmountChange({
			amount: Number(trimBn(formatEther(balanceAvailable?.amount))) ?? 0,
			currency: balanceAvailable?.currency ?? "ARS",
		})
		setAmount({
			amount: Number(trimBn(formatEther(balanceAvailable?.amount))) ?? 0,
			currency: balanceAvailable?.currency ?? "ARS",
		})
	}
	var inputCurrencies = ["ARS"]
	if (balanceAvailable && balanceAvailable.currency !== "ARS") {
		inputCurrencies.push(balanceAvailable.currency)
	}

	useEffect(() => {
		let validData = validateData({ quote, amount, balanceAvailable })
		setValidData(validData)
	}, [quote, amount, balanceAvailable])

	return (
		<CenteredBody>
			<SectionHeader
				title="Retirar en criptomonedas"
				subtitle="Ingresar el monto que deseas retirar."
			/>
			<div>
				<AmountInput
					onAmountChange={(price: Price) => {
						onAmountChange(price)
						setAmount(price)
					}}
					value={amount}
					currencies={inputCurrencies}
				/>
				<div className="justify-between flex flex-row items-center">
					{balanceAvailable && (
						<Body1 className="text-text-muted font-semibold">
							{getBalanceInCurrency(
								balanceAvailable,
								amount?.currency ?? "ARS"
							)}
						</Body1>
					)}
					<Button variant="ghost" onClick={handleWithdrawAll}>
						<Heading6 className="font-semibold text-text-warning">
							Retirar todo
						</Heading6>
					</Button>
				</div>
				{validData[1] && validData[1] != "" ? (
					<InputErrorMessage errorText={validData[1]} />
				) : (
					<WithdrawSummaryCryptoCard
						loading={loading}
						quote={quote}
						priceData={{
							amount: amount?.amount as string,
							currency: amount?.currency ?? "ARS",
							availableBalanceCurrency: balanceAvailable?.currency ?? "ARS",
						}}
						prices={prices}
					/>
				)}
			</div>

			<Button
				size="full"
				onClick={onConfirmAmount}
				disabled={validData[0]}
				data-test="withdraw-crypto-send-button"
			>
				Retirar
			</Button>
		</CenteredBody>
	)
}
function validateData({
	quote,
	amount,
	balanceAvailable,
}: {
	quote?: WithdrawQuote
	amount?: Price
	balanceAvailable?: BalanceItem
}): [boolean, string] {
	if (!quote) {
		return [true, ""]
	}
	if (
		!amount ||
		!amount.amount ||
		(amount.amount as number) <= 0 ||
		!amount.currency
	) {
		return [true, ""]
	}

	if (!balanceAvailable || !balanceAvailable.amount) {
		return [true, ""]
	}

	let numberToCompare

	if (amount.currency === "ARS") {
		numberToCompare = Number(
			balanceAvailable?.amount_preferred_currency_readable
		)
	} else {
		numberToCompare = Number(trimBn(formatEther(balanceAvailable?.amount)))
	}

	if (
		Number(amount.amount) >
		numberToCompare
	) {
		return [true, "El monto ingresado supera el saldo disponible."]
	}
	return [false, ""]
}

function getBalanceInCurrency(balanceAvailable: BalanceItem, currency: string) {
	if (currency === "ARS") {
		return `${formatPriceAmount(
			balanceAvailable.amount_preferred_currency_readable
		)} ${currency}`
	} else {
		return `${formatPriceAmount(
			formatEther(balanceAvailable.amount)
		)} ${currency}`
	}
}

export default EnterAmountStepCrypto
