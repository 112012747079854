import { useLayoutEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext.tsx"
import {
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
} from "./onboard-personal-wizard-types.ts"
import PersonalBody from "./PersonalBody"

export default function PersonalWizard() {
	const navigate = useNavigate()
	const { kyc } = useAuth()
	let initialStep: OnboardingPersonalSteps =
		OnboardingPersonalSteps.SpecialAgreements

	useLayoutEffect(() => {
		if (!kyc.data) return
		switch (kyc.data.kyc_status) {
			case "basic_info":
				if (!kyc.data.contact.phone.verified) {
					initialStep = OnboardingPersonalSteps.BasicInfo
				} else {
					initialStep = OnboardingPersonalSteps.ToS
				}
				setWizardData((prev) => ({
					...prev,
					step: initialStep,
				}))
				break
			case "signature":
				initialStep = OnboardingPersonalSteps.FacialVerification
				setWizardData((prev) => ({
					...prev,
					step: initialStep,
				}))
				break
			case "metamap":
				navigate("/onboarding/payment-methods")
				break
			case "approved":
			case "rejected":
			case "pending":
				navigate("/onboarding/success")
				break
			default:
				break
		}
	}, [kyc.data])

	const [wizardData, setWizardData] = useState<OnboardingPersonalWizardData>({
		step: OnboardingPersonalSteps.SpecialAgreements,
		loading: false,
		data: {
			entity_type: "F",
			first_name: "",
			last_name: "",
			document_type: "CUIT",
			cuit: "",
			phone: {
				number: "",
				codeArea: "",
				countryCode: "",
			},
			address: {
				country: "",
				street: "",
				number: 0,
				location: "",
				state: "",
				postalCode: "",
			},
			uif_conditions: {
				obligated_subject: false,
				exposed_person: false,
				fatca_subject: false,
				ocde_subject: false,
			},
		},
		completionPercentage: 10,
	})

	return <PersonalBody setWizardData={setWizardData} wizardData={wizardData} />
}
