import { zodResolver } from "@hookform/resolvers/zod"
import { CircleHelp } from "lucide-react"
import { FormEvent, useEffect } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Alert } from "../../../components/ui/alert.tsx"
import { Button } from "../../../components/ui/button.tsx"
import {
	Form,
	FormControl,
	FormField,
	FormItem,
} from "../../../components/ui/form"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../../components/ui/options/option.tsx"
import { SectionHeader } from "../../../components/ui/section-header"
import { Section } from "../../../components/ui/section.tsx"
import SlidingCard from "../../../components/ui/sliding-card.tsx"
import Spacer from "../../../components/ui/spacer.tsx"
import { Heading6 } from "../../../components/ui/texts/TextStyle.tsx"
import { fiatCurrencies } from "../../../utils/currencies.ts"
import {
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
	SpecialAgreementsForm,
} from "./onboard-personal-wizard-types.ts"

const schema = z.object({
	obligated_subject: z.boolean(),
	exposed_person: z.boolean(),
	fatca_subject: z.boolean(),
	ocde_subject: z.boolean(),
})

export default function SpecialAgreementsBody({
	setWizardData,
	wizardData,
}: {
	setWizardData: (data: OnboardingPersonalWizardData) => void
	wizardData: OnboardingPersonalWizardData
}) {
	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
		defaultValues: {
			obligated_subject:
				wizardData.data.uif_conditions.obligated_subject || false,
			exposed_person: wizardData.data.uif_conditions.exposed_person || false,
			fatca_subject: wizardData.data.uif_conditions.fatca_subject || false,
			ocde_subject: wizardData.data.uif_conditions.ocde_subject || false,
		},
	})

	useEffect(() => {
		const { obligated_subject, exposed_person, fatca_subject, ocde_subject } =
			wizardData.data.uif_conditions
		const filteredData = {
			obligated_subject,
			exposed_person,
			fatca_subject,
			ocde_subject,
		}

		console.log("Filtered wizard data:", filteredData)
	}, [])

	function onSubmit(data: SpecialAgreementsForm) {
		console.log("DATA===", data)
		const parsedData = schema.parse(data)

		console.log("PARSED DATA===", parsedData)

		setWizardData({
			...wizardData,
			data: {
				...wizardData.data,
				...parsedData,
			},
			step: OnboardingPersonalSteps.BasicInfo,
			completionPercentage: 40,
		})
	}

	return (
		<CenteredBody>
			<SectionHeader
				title="Antes que nada"
				subtitle="Seleccioná cuál o cuáles de las siguientes aplican"
			/>
			<Form {...form}>
				<form
					onSubmit={(e: FormEvent<HTMLFormElement>) => {
						e.preventDefault()
						form.handleSubmit(onSubmit)(e)
					}}
					className="grid grid-cols-1 gap-betweenFields"
				>
					<Section
						title="Condiciones especiales"
						footer={
							<div className="inline-flex items-center">
								Tocá en{" "}
								<CircleHelp className="size-xs mx-betweenTexts text-text-muted" />
								de cada uno, para leer su explicación.
							</div>
						}
					>
						{/* Obligated Subject */}
						<FormField
							control={form.control}
							name="obligated_subject"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Option
											title={"Soy un sujeto obligado."}
											trailing="switch"
											leading="div"
											switchChecked={field.value}
											onCheckedChange={field.onChange}
											div={
												<SlidingCard
													side={"center"}
													trigger={
														<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
													}
												>
													<CenteredBody>
														<Section title="Sujeto Obligado">
															<Heading6 className="font-medium text-text-muted text-justify">
																Son las Personas Físicas y Jurídicas que, debido
																a su actividad, la legislación considera
																vulnerables al Lavado de Dinero y Financiamiento
																del Terrorismo. Por lo tanto, están obligadas a
																presentar declaraciones ante la UIF (Unidad de
																Información Financiera), son responsables de
																llevar a cabo la debida diligencia al
																interactuar con clientes y, cuando corresponda,
																informar actividades sospechosas a la UIF.
															</Heading6>
														</Section>
													</CenteredBody>
												</SlidingCard>
											}
										/>
									</FormControl>
								</FormItem>
							)}
						/>

						{/* Exposed Person */}

						<FormField
							control={form.control}
							name="exposed_person"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Option
											title={"Soy una persona políticamente expuesta."}
											trailing="switch"
											leading="div"
											switchChecked={field.value}
											onCheckedChange={field.onChange}
											div={
												<SlidingCard
													side={"center"}
													trigger={
														<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
													}
												>
													<CenteredBody>
														<Section title="PEP (Persona Políticamente Expuesta):">
															<Heading6 className="font-medium text-text-muted text-justify">
																Las Personas Políticamente Expuestas (PEP) son
																consideradas como Funcionarios Públicos
																Nacionales, Provinciales y Municipales (tanto
																nacionales como extranjeros) del Poder
																Ejecutivo, Legislativo y Judicial, que ocupan o
																han ocupado cargos altos en posiciones
																jerárquicas, así como sus familiares hasta el
																segundo grado de consanguinidad. Los
																funcionarios clasificados como PEP continúan
																siendo considerados como tales hasta dos años
																después de haber cesado sus funciones.
															</Heading6>
														</Section>
													</CenteredBody>
												</SlidingCard>
											}
										/>
									</FormControl>
								</FormItem>
							)}
						/>

						{/* FATCA Subject */}

						<FormField
							control={form.control}
							name="fatca_subject"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Option
											title={"Sujeto a (FATCA)"}
											description="Foreign Account Tax Compliance Act "
											trailing="switch"
											leading="div"
											switchChecked={field.value}
											onCheckedChange={field.onChange}
											div={
												<SlidingCard
													side={"center"}
													trigger={
														<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
													}
												>
													<CenteredBody>
														<Section title="FATCA (Ley de Cumplimiento Fiscal de Cuentas Extranjeras)">
															<Heading6 className="font-medium text-text-muted text-justify">
																El Servicio de Impuestos Internos (IRS) invita a
																las Instituciones Financieras Extranjeras (FFI)
																a firmar un acuerdo con el Gobierno de EE.UU.
																con el objetivo de prevenir la evasión fiscal y
																promover la transparencia financiera. El
																objetivo es detectar y prevenir la evasión
																fiscal en inversiones en el extranjero por parte
																de residentes y ciudadanos estadounidenses. Es
																un régimen amplio que puede aplicarse a
																cualquier persona física o jurídica,
																estadounidense o extranjera, en la medida en que
																esa persona esté involucrada en realizar o
																recibir pagos que caigan dentro del alcance de
																FATCA, siempre que cumpla con las condiciones de
																“Persona de EE.UU.”
															</Heading6>
														</Section>
														<Alert
															icon={
																<img
																	src={fiatCurrencies.USD.flagPath}
																	alt="USD"
																/>
															}
															title={"Persona de EE.UU"}
															bulletList
															description={
																<ul className="text-h6 flex flex-col list-inside text-text-muted list-disc mt-2 [&>*]:m-0">
																	<li>
																		Ciudadano estadounidense con pasaporte
																	</li>
																	<li>Residente de EE.UU. con Green Card</li>
																	<li>
																		Residente parcial de EE.UU. (183 días en 1
																		año calendario, o 122 días en promedio en
																		los últimos 3 años calendario)
																	</li>
																</ul>
															}
														></Alert>
													</CenteredBody>
												</SlidingCard>
											}
										/>
									</FormControl>
								</FormItem>
							)}
						/>

						{/* OCDE Subject */}

						<FormField
							control={form.control}
							name="ocde_subject"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Option
											title={"Sujeto a las regulaciones de la OCDE"}
											trailing="switch"
											leading="div"
											switchChecked={field.value}
											onCheckedChange={field.onChange}
											div={
												<SlidingCard
													side={"center"}
													trigger={
														<CircleHelp className="hover:-translate-y-1 transition-all text-icon" />
													}
												>
													<CenteredBody>
														<Section title="OCDE (Organización para la Cooperación y el Desarrollo Económicos)">
															<Heading6 className="font-medium text-text-muted text-justify">
																Es un acuerdo para el intercambio automático de
																información fiscal firmado por 113 países
																(incluida la República Argentina). En Argentina,
																está regulado por la Resolución General AFIP N.º
																4056/2017 y sus modificaciones. El objetivo del
																Acuerdo de la OCDE es intensificar la lucha
																contra la evasión fiscal a nivel global, ya que
																aplica a personas físicas y jurídicas sujetas al
																pago del impuesto a las ganancias en los 113
																países que han firmado el acuerdo. Tu Número de
																Identificación Fiscal, si tienes residencia
																fiscal en Argentina, es tu CUIT o CUIL; si
																tienes residencia fiscal en Estados Unidos, es
																tu Número de Seguro Social (SSN); y si tienes
																otra residencia fiscal, es el número de
																identificación fiscal que aplique a esa
																jurisdicción.
															</Heading6>
														</Section>
													</CenteredBody>
												</SlidingCard>
											}
										/>
									</FormControl>
								</FormItem>
							)}
						/>
					</Section>

					<Spacer value="300px" axis={"vertical"} />
					<Button type="submit">
						{Object.values(form.getValues()).find(
							(val) => val.valueOf() == true
						)
							? "Siguiente"
							: "No aplica"}
					</Button>
				</form>
			</Form>
		</CenteredBody>
	)
}
