import { useState } from "react"
import { useAuth } from "../../../../context/AuthContext"
import { createUserCredentials } from "../../../../services/users"
import { Credential } from "../../../modals/new-credential/NewCredentialModal"
import PluginSuccessModal from "../../../modals/plugin-success/PluginSuccessModal"
import CredentialsStep from "./CredentialsStep"
import DownloadStep from "./DownloadStep"
import InstallStep from "./InstallStep"
import { useNavigate } from "react-router-dom"

enum WoocommerceWizardSteps {
	DOWNLOAD,
	INSTALL,
	CREDENTIALS,
}

const WoocommerceWizard = () => {
	const [step, setStep] = useState(WoocommerceWizardSteps.DOWNLOAD)
	const [loading, setLoading] = useState(false)
	const [credential, setCredential] = useState<Credential | undefined>(
		undefined
	)
	const [modalVisible, setModalVisible] = useState(false)
	const navigate  = useNavigate()
	const { user } = useAuth()

	const handleDownloadZip = async () => {
		try {
			setLoading(true)
			const s3Url = process.env.REACT_APP_WOOCOMMERCE_S3_URL
			console.log(s3Url)
			if (!s3Url) {
				console.log("REACT_APP_WOOCOMMERCE_S3_URL not found")
				setLoading(false)
				return
			}

			const a = document.createElement("a")
			a.href = s3Url
			a.download = `talo-payments-wc-plugin.zip`
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
			window.URL.revokeObjectURL(a.href)
			setStep(WoocommerceWizardSteps.INSTALL)
		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}
	const handleCreateWcCredential = async () => {
		setLoading(true)
		const cred = await createUserCredentials(
			user.id,
			"woocommerce",
			user.team_id
		)
		setCredential({ ...cred.data.data, user_id: user.user_id })

		setStep(WoocommerceWizardSteps.CREDENTIALS)

		setLoading(false)
	}

	const handleDownloadCredential = async () => {
		try {
			setLoading(true)
			if (credential) {
				const csvContent = `user_id,client_id,client_secret\n${user.id},${credential.client_id},${credential.client_secret}\n`
				const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
				const a = document.createElement("a")
				a.href = URL.createObjectURL(blob)
				a.download = `talo_credentials_woocommerce.csv`
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
				window.URL.revokeObjectURL(a.href)
			}
		} catch (e) {
			console.error(e)
		} finally {
			setLoading(false)
		}
	}
	const handleFinish = () => {
		setModalVisible(true)
	}

	const bodys = {
		[WoocommerceWizardSteps.DOWNLOAD]: (
			<DownloadStep handleDownload={handleDownloadZip} isLoading={loading} />
		),
		[WoocommerceWizardSteps.INSTALL]: (
			<InstallStep
				handleInstall={handleCreateWcCredential}
				isLoading={loading}
			/>
		),
		[WoocommerceWizardSteps.CREDENTIALS]: (
			<CredentialsStep
				isLoading={loading}
				handleDownloadCredentials={handleDownloadCredential}
				handleFinish={handleFinish}
				credential={credential}
			/>
		),
	}

	return (
		<>
			<PluginSuccessModal
				open={modalVisible}
				onClose={() => {
					setModalVisible(false)
					const url = new URL(window.location.pathname, window.location.origin)
					const paths = url.pathname.split("/")
					// Remove the last two paths
					paths.splice(-2)
					url.pathname = paths.join("/")
					// Redirect the user to the new URL
					navigate(url.pathname, { replace: true })
				}}
			/>
			{bodys[step]}
		</>
	)
}

export default WoocommerceWizard
