import { useNavigate } from "react-router-dom"
import { platformHeader } from "../../../components/ui/layouts/layout-sizes.ts"
import PlatformHeader from "../../../components/ui/layouts/platform/PlatformHeader.tsx"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody.tsx"
import Row from "../../../components/ui/layouts/row.tsx"
import { Progress } from "../../../components/ui/progress"
import { useAuth } from "../../../context/AuthContext.tsx"
import Success from "../Success"
import FirstForm from "./BasicInfoForm"
import FacialVerification from "./FacialVerification.tsx"
import SpecialAgreementsBody from "./SpecialAgreementsForm.tsx"
import TermsOfServiceSignature from "./TermsOfServiceSignature.tsx"
import {
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
} from "./onboard-personal-wizard-types.ts"

export default function PersonalBody({
	setWizardData,
	wizardData,
}: {
	setWizardData: (data: OnboardingPersonalWizardData) => void
	wizardData: OnboardingPersonalWizardData
}) {
	const navigate = useNavigate()
	const { kyc } = useAuth()

	const body = {
		[OnboardingPersonalSteps.SpecialAgreements]: (
			<SpecialAgreementsBody
				setWizardData={setWizardData}
				wizardData={wizardData}
			/>
		),
		[OnboardingPersonalSteps.BasicInfo]: (
			<FirstForm setWizardData={setWizardData} wizardData={wizardData} />
		),
		[OnboardingPersonalSteps.FacialVerification]: (
			<FacialVerification
				setWizardData={setWizardData}
				wizardData={wizardData}
			/>
		),
		[OnboardingPersonalSteps.Success]: <Success />,
		[OnboardingPersonalSteps.ToS]: <TermsOfServiceSignature />,
	}

	return (
		<>
			<PlatformHeader
				title={"Personal"}
				variant="secondary"
				backButton={!kyc.data?.kyc_status}
				onBackClick={() => {
					if (
						kyc.data?.kyc_status === "signature" &&
						wizardData.step === OnboardingPersonalSteps.FacialVerification
					) {
						return
					}
					if (wizardData.step === OnboardingPersonalSteps.SpecialAgreements) {
						navigate("/onboarding")
					}
					setWizardData({
						...wizardData,
						step: wizardData.step - 1,
					})
				}}
			/>
			<CenteredBody bottom className={`${platformHeader.paddingX.class} pt-4`}>
				<Row className="gap-betweenTexts">
					{/* <div className="h-2 w-24 bg-black rounded-full" /> */}
					<Progress
						variant="secondary"
						value={wizardData.completionPercentage}
					/>
				</Row>
				{body[wizardData.step]}
			</CenteredBody>
		</>
	)
}
