import { Link } from "react-router-dom"
import { LoginBox } from "../../components/auth/LoginBox"
import AuthLayout from "../../components/ui/layouts/landing/AuthLayout"
import { buttonVariants } from "../../components/ui/button"

const Login = () => {
	return (
		<AuthLayout
			leftImageSrc="/home/login/placeholder.png"
			rightNavbarContent={
				<Link to="/signup" className={buttonVariants({ variant: "ghost" })}>
					Registrarse
				</Link>
			}
		>
			<LoginBox />
		</AuthLayout>
	)
}

export default Login
