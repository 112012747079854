import { PaymentStatus } from "@crypto-payment-gateway/models"
import { TriangleAlert } from "lucide-react"
import { Alert } from "../../ui/alert"

export type PaymentErrorCardProps = {
	payment_status?: PaymentStatus
	quote?: { amount: string; currency: string; amountReadable?: string }
	payment_transaction?: {
		amount: string
		currency: string
		amountReadable?: string
	}
}

const PaymentErrorCard = (props: PaymentErrorCardProps) => {
	const { payment_status, quote, payment_transaction } = props
	return (
		<Alert
			variant={payment_status === "SUCCESS" ? "default" : "destructive"}
			className="alert alert-"
			icon={
				payment_status !== "SUCCESS" && (
					<TriangleAlert className="text-icon-error size-icon" />
				)
			}
			title={"Cuidado"}
			description={
				payment_status === "SUCCESS"
					? "El monto recibido aparecerá en tu activad reciente del tablero principal, podrás verificar toda la información ahi."
					: `El monto recibido parece ser ${
							payment_status && payment_status === "UNDERPAID"
								? "inferior"
								: "superior"
					  }
	a lo solicitado, puede contactarse con el vendedor`
			}
		/>
	)
}

export default PaymentErrorCard
