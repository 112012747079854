import { Route } from "react-router-dom"
import { ErrorPage } from "./ErrorPage"

export const errorRoutes = [
	<Route
		key="/no-privilege"
		path="/no-privilege"
		element={<ErrorPage error="no-privilege" />}
	/>,
	<Route key="*" path="*" element={<ErrorPage error="not-found" />} />,
]
