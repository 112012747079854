import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import { DataTable } from "../../ui/data-table/data-table"
import { PaginationHandler } from "../../ui/data-table/data-table-pagination-footer"
import { columns, TableQR } from "./columns"

const QRAdminTable = ({
	qrs,
	paginationHandler,
}: {
	qrs: TableQR[]
	paginationHandler: PaginationHandler
}) => {
	const breakpoint = useBreakpoint() as ScreenBreakpoint
	return (
		<>
			<DataTable
				searchFilter={{
					placeholder: "code",
					columnName: "code",
				}}
				data={qrs}
				columns={columns[breakpoint]}
				paginationHandler={paginationHandler}
				tableItem="qrs"
			/>
		</>
	)
}

export default QRAdminTable
