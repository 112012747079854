import { subDays } from "date-fns"
import { useEffect, useState } from "react"
import AdminTransactionsTable from "../../../components/tables/admin-transactions/table"
import { DatePickerWithRange } from "../../../components/ui/inputs/DateRangePicker"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { getAllTransactions } from "../../../services/transactions"

interface AdminTransactionsData {
	items: any[]
	prevCursor?: {
		user_id: string
		transaction_id: string
	}
	nextCursor?: {
		user_id: string
		transaction_id: string
	}
}

const AdminTransactionsBody = () => {
	const [transactionsData, setTransactionsData] =
		useState<AdminTransactionsData>()
	const [dateRange, setDateRange] = useState<{
		from: Date
		to: Date
	}>({
		from: subDays(new Date(), 30),
		to: new Date(),
	})
	const fetchData = async (prev?: boolean) => {
		const transactionsRes = await getAllTransactions({
			cursor: prev
				? transactionsData?.prevCursor
				: transactionsData?.nextCursor,
			date_range: {
				start_date: dateRange?.from.toISOString(),
				end_date: dateRange?.to.toISOString(),
			},
		})
		transactionsRes.transactions.sort((t1: any, t2: any) => {
			const res =
				new Date(t1.creation_timestamp) > new Date(t2.creation_timestamp)
			return res ? -1 : 1
		})

		setTransactionsData({
			items: transactionsRes.transactions,
			prevCursor: transactionsData?.nextCursor,
			nextCursor: transactionsRes.lastEvaluatedKey,
		})
	}

	useEffect(() => {
		fetchData()
	}, [dateRange])
	return (
		<CenteredTableBody>
			<SectionHeader
				title="Transacciones"
				subtitle="Historial de transacciones realizadas"
			/>
			<DatePickerWithRange
				onDateChanged={(value) => {
					if (value) {
						setDateRange({
							from: value.from ?? dateRange.from,
							to: value.to ?? dateRange.to,
						})
					}
				}}
				date={dateRange}
			/>
			<AdminTransactionsTable
				transactions={transactionsData?.items}
				paginationHandler={{
					nextPage: transactionsData?.nextCursor
						? () => fetchData()
						: undefined,
					prevPage: transactionsData?.prevCursor
						? () => fetchData(true)
						: undefined,
				}}
			/>
		</CenteredTableBody>
	)
}

export default AdminTransactionsBody
