import { Lightbulb } from "lucide-react"
import { BalanceItem } from "../../utils/balances"
import { networks } from "../../utils/networks"
import { Alert } from "../ui/alert"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { Option } from "../ui/options/option"
import { Section } from "../ui/section"
import { SectionHeader } from "../ui/section-header"

const SelectNetworkStep = ({
	networkBalances,
	onNetworkSelected,
}: {
	networkBalances: BalanceItem[]
	onNetworkSelected: (network: string) => void
}) => {
	return (
		<CenteredBody>
			<SectionHeader
				title="Retirar en criptomonedas"
				subtitle="Seleccioná la red en la que deseas retirar tus fondos."
			/>

			<Section title="Redes disponibles">
				{networkBalances.map((balance) => (
					<Option
						key={balance.network}
						iconInside={false}
						leading="arrow"
						title={balance.network}
						data-test={`withdraw-network-${balance.network}-button`}
						description={networks[balance.network].name}
						icon={
							<img
								src={`/networks/${balance.network.toLowerCase()}_logo.svg`}
								alt={balance.network}
							/>
						}
						onClick={() => onNetworkSelected(balance.network)} // Fix: Pass balance.network as an argument
					></Option>
				))}
			</Section>

			<Alert
				icon={<Lightbulb />}
				title="Tips"
				description="Cada red tiene una comisión distinta y se aplica al monto que vas a
				enviar. Esta comisión no es cobrada por Talo sino por la red para ser confirmada."
			></Alert>
		</CenteredBody>
	)
}

export default SelectNetworkStep
