import { WithdrawSteps } from "../../pages/withdraw/withdraw-wizard-types"
import { BalanceItem } from "../../utils/balances"
import { currencies, WithdrawType } from "../../utils/withdrawCurrencies"

import Col from "../ui/layouts/column"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import IntegrationOption from "../ui/options/IntegrationOption"
import { Section } from "../ui/section"
import { SectionHeader } from "../ui/section-header"

export type SelectWithdrawTypeProps = {
	balances: BalanceItem[]
	onWithdrawTypeSelected: (type: WithdrawType, step: WithdrawSteps) => void
}

export default function SelectWithdrawTypeStep({
	balances,
	onWithdrawTypeSelected,
}: SelectWithdrawTypeProps) {
	const cryptoBalance = balances.find(
		(balance) => balance.network === "BSC" || balance.network === "POLYGON"
	)
	const pesosBalance = balances.find((balance) => balance.network === "POLLUX")

	return (
		<CenteredBody>
			<SectionHeader
				title="Retirar dinero"
				subtitle="Seleccioná la moneda que deseas retirar."
			/>
			<Section footer="Solo se mostrará disponible el dinero en cuenta.">
				{cryptoBalance && (
					<IntegrationOption
						step={WithdrawSteps.SelectCurrency}
						type="crypto"
						key="crypto"
						title={"Criptomonedas"}
						description={"Billeteras digitales"}
						logoPath={`/currencies/${cryptoBalance.currency.toLowerCase()}-icon.svg`}
						onClick={() =>
							onWithdrawTypeSelected("crypto", WithdrawSteps.SelectCurrency)
						}
					/>
				)}
				{pesosBalance && (
					<IntegrationOption
						step={WithdrawSteps.SelectAddress}
						type="transfer"
						key="transfer"
						title={currencies[pesosBalance.currency].name}
						description={"Transferencia bancaria"}
						logoPath={`/currencies/${pesosBalance.currency.toLowerCase()}-icon.svg`}
						onClick={() =>
							onWithdrawTypeSelected("transfer", WithdrawSteps.SelectAddress)
						}
					/>
				)}
			</Section>
		</CenteredBody>
	)
}
