const TaloGreenLogo = ({
	className,
	onClick,
}: {
	className?: string
	onClick?: () => void
}) => {
	return (
		<svg
			className={`${className} bg-transparent fill-current cursor-pointer`}
			onClick={onClick}
			width="159"
			height="32"
			viewBox="0 0 159 32"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.5859 12.9827L27.6028 6.00302L24.4734 2.89021L17.4565 9.86986V0H13.0312V11.114C13.0312 11.8956 13.1804 12.6704 13.4666 13.3951C12.738 13.1104 11.9591 12.962 11.1733 12.962H0V17.3637H9.92258L2.90565 24.3434L6.03508 27.4562L13.052 20.4765V30.3464H17.4773V19.2324C17.4773 18.4508 17.3281 17.676 17.0419 16.9513C17.7704 17.236 18.5493 17.3844 19.3352 17.3844H30.5085V12.9827H20.5859Z"
				fill="#71E150"
			/>
			<path d="M41.8129 9.68397V4.36426H45.121V9.68397H48.077V12.9745H45.121V26.0383H41.8129V12.9745H38.8569V9.68397H41.8129Z" />
			<path d="M66.1805 9.6857H69.5042V26.0555H66.1805V24.0056C64.5828 25.6483 62.7284 26.4696 60.6207 26.4696C58.1071 26.4696 55.963 25.5879 54.1901 23.8227C52.4172 22.0593 51.529 19.9265 51.529 17.4263C51.529 14.926 52.4155 12.7933 54.1901 11.0299C55.963 9.2664 58.1071 8.38295 60.6207 8.38295C62.7301 8.38295 64.5828 9.19911 66.1805 10.8314V9.6857ZM66.1805 16.2184C65.9879 15.19 65.4779 14.2583 64.647 13.4214C63.5281 12.3188 62.1854 11.7684 60.6207 11.7684C59.056 11.7684 57.6994 12.3188 56.5857 13.4214C55.4721 14.524 54.9152 15.8578 54.9152 17.4263C54.9152 18.9948 55.4721 20.3165 56.5857 21.4243C57.6994 22.5321 59.0438 23.0859 60.6207 23.0859C62.1976 23.0859 63.5281 22.5355 64.647 21.4329C65.4779 20.596 65.9896 19.6643 66.1805 18.6359V16.2184Z" />
			<path d="M77.6365 5.27877V26.0383H74.3128V5.27877H77.6365Z" />
			<path d="M90.5636 8.35016C93.0772 8.35016 95.2214 9.23535 96.9942 11.004C98.7671 12.7726 99.6553 14.9088 99.6553 17.409C99.6553 19.9093 98.7688 22.0455 96.9942 23.8141C95.2196 25.5827 93.0772 26.4679 90.5636 26.4679C88.05 26.4679 85.9198 25.5827 84.14 23.8141C82.3602 22.0455 81.472 19.9093 81.472 17.409C81.472 14.9088 82.3619 12.7726 84.14 11.004C85.9181 9.23535 88.0604 8.35016 90.5636 8.35016ZM86.5287 13.4042C85.415 14.5171 84.8582 15.8526 84.8582 17.409C84.8582 18.9654 85.415 20.3131 86.5287 21.4139C87.6424 22.5165 88.9868 23.067 90.5636 23.067C92.1405 23.067 93.4849 22.5165 94.5986 21.4139C95.7123 20.3113 96.2691 18.9775 96.2691 17.409C96.2691 15.8406 95.7123 14.5171 94.5986 13.4042C93.4849 12.2912 92.1405 11.7356 90.5636 11.7356C88.9868 11.7356 87.6424 12.2912 86.5287 13.4042Z" />
			<path d="M103.491 10.4311H104.929V12.6242C105.194 12.2636 105.498 11.915 105.84 11.5751C107.555 9.87896 109.621 9.03174 112.039 9.03174C114.458 9.03174 116.538 9.88241 118.246 11.582C119.957 13.2834 120.81 15.3402 120.81 17.7559C120.81 20.1716 119.955 22.2163 118.246 23.9228C116.536 25.6293 114.468 26.4817 112.039 26.4817C109.611 26.4817 107.555 25.6293 105.84 23.9228C105.498 23.5829 105.194 23.2343 104.929 22.8737V31.9964H103.491V10.4294V10.4311ZM104.929 16.2633V19.2519C105.206 20.6185 105.866 21.8211 106.91 22.8599C108.327 24.2696 110.036 24.9736 112.039 24.9736C114.043 24.9736 115.752 24.2696 117.169 22.8599C118.586 21.4502 119.294 19.7505 119.294 17.7576C119.294 15.7646 118.586 14.065 117.169 12.6553C115.752 11.2456 114.043 10.5415 112.039 10.5415C110.036 10.5415 108.327 11.2456 106.91 12.6553C105.866 13.694 105.205 14.8967 104.929 16.2633Z" />
			<path d="M141.743 26.04H140.305V22.8616C140.027 23.2326 139.724 23.5881 139.394 23.9263C137.679 25.6328 135.607 26.4852 133.179 26.4852C130.75 26.4852 128.694 25.6328 126.979 23.9263C125.263 22.2197 124.406 20.1647 124.406 17.7593C124.406 15.354 125.263 13.2834 126.979 11.5768C128.694 9.87033 130.761 9.01793 133.179 9.01793C135.597 9.01793 137.679 9.87033 139.394 11.5768C139.724 11.9168 140.027 12.2705 140.305 12.6415V10.4328H141.743V26.04ZM140.305 19.1742V16.3444C140.038 14.945 139.373 13.7165 138.308 12.657C136.891 11.2473 135.182 10.5433 133.179 10.5433C131.175 10.5433 129.466 11.2473 128.049 12.657C126.632 14.0667 125.924 15.7664 125.924 17.7593C125.924 19.7523 126.632 21.4484 128.049 22.853C129.466 24.2576 131.175 24.9581 133.179 24.9581C135.182 24.9581 136.891 24.2541 138.308 22.8444C139.373 21.7849 140.04 20.5615 140.305 19.1725V19.1742Z" />
			<path d="M152.177 24.6406L148.63 31.9999H147.223L151.457 23.1636L145.337 10.4328H146.968L152.177 21.6227L157.387 10.4328H159L152.177 24.6579V24.6406Z" />
		</svg>
	)
}

export default TaloGreenLogo
