import { useNavigate } from "react-router-dom"

import NoPrivilegeErrorBody from "../../components/error/NoPrivilegeErrorBody"
import NotFoundErrorBody from "../../components/error/NotFoundErrorBody"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { useAuth } from "../../context/AuthContext"
import { getPathForUser } from "../../lib/roles"

export const ErrorPage = ({
	error = "default",
}: {
	error?: "not-found" | "no-privilege" | "default"
}) => {
	const navigate = useNavigate()
	const { user } = useAuth()
	const handleClickGoHome = () => {
		const userPath = getPathForUser(user)
		if(userPath){
			navigate(userPath)
		} else {
			window.location.href = process.env.REACT_APP_NEXT_LANDING_URL!
		}
	}
	var body
	switch (error) {
		case "no-privilege":
			body = <NoPrivilegeErrorBody handleClickGoHome={handleClickGoHome} />
			break
		case "not-found":
		default:
			body = <NotFoundErrorBody handleClickGoHome={handleClickGoHome} />
			break
	}
	if (user) {
		return (
			<ResponsiveLayout
				item={{
					itemName: "",
					bodyComponent: body,
					smBodyComponent: body,
					smHeaderContent: <></>,
				}}
			/>
		)
	} else {
		return <LandingLayout>{body}</LandingLayout>
	}
}
