import useBreakpoint from "../../../hooks/useBreakpoint"
import { formatPriceAmount } from "../../../utils/numbers"
import { BackgroundGradientAnimation } from "../../ui/background-gradient-animation"
import { Card, CardContent } from "../../ui/card"
import Row from "../../ui/layouts/row"
import Spacer from "../../ui/spacer"
import { Heading1, Heading5 } from "../../ui/texts/TextStyle"

const BalanceCard = ({
	totalBalance,
}: {
	totalBalance: {
		amountReadable: string
		currency: string
	}
}) => {
	const isSm = useBreakpoint("sm")
	return isSm ? (
		<BackgroundGradientAnimation containerClassName="rounded-md h-[140px] border-border border group">
			<div className="p-betweenSections  ">
				<CardContent className="p-0">
					<Row className="gap-betweenTexts">
						<Heading1 className="font-semibold z-50">
							{formatPriceAmount(totalBalance?.amountReadable)}{" "}
						</Heading1>
						<Heading1 className="font-normal z-50">
							{totalBalance?.currency ?? "ARS"}
						</Heading1>
					</Row>
					<Spacer axis="vertical" type="betweenTexts" />
					<Heading5 className="font-medium z-50">Balance total</Heading5>
				</CardContent>
			</div>
		</BackgroundGradientAnimation>
	) : (
		<BackgroundGradientAnimation containerClassName="rounded-md border border-border group">
			<Card className="p-betweenSections h-[175px] border-none">
				<CardContent className="p-0">
					<Row className="gap-betweenTexts">
						<Heading1 className="font-semibold z-50">
							{formatPriceAmount(totalBalance?.amountReadable)}{" "}
						</Heading1>
						<Heading1 className="font-normal z-50">
							{totalBalance?.currency ?? "ARS"}
						</Heading1>
					</Row>
					<Spacer axis="vertical" type="betweenTexts" />
					<Heading5 className="font-semibold z-50">Balance total</Heading5>
				</CardContent>
			</Card>
		</BackgroundGradientAnimation>
	)
}

export default BalanceCard
