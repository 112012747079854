import { formatEther, parseEther } from "ethers"

export interface BalanceItem {
	amount: string
	amount_preferred_currency_readable: string
	currency: string
	network: string
}

export interface GroupedBalanceItem {
	currency: string
	amount: string
	amountDesired: number
}

export function groupBalancesByCurrency(
	balances: BalanceItem[]
): GroupedBalanceItem[] {
	//group balances by currency, ignoring network and add up amounts
	const balance = balances.reduce((acc: any, elem: any) => {
		const key = elem.currency
		if (acc[key]) {
			acc[key].push(elem)
		} else {
			acc[key] = [elem]
		}
		return acc
	}, {})
	//map to array and add up amounts
	return Object.entries(balance).map(([k, v]: [k: string, v: any]) => {
		const balanceItems = v
		const balanceItem = balanceItems.reduce(
			(acc: any, elem: any) => {
				if (elem.currency === "ARS") {
					return {
						amount: parseEther(elem.amount) + acc.amount,
						amount_preferred_currency_readable:
							parseFloat(elem.amount_preferred_currency_readable) +
							acc.amount_preferred_currency_readable,
					}
				}
				return {
					amount: BigInt(elem.amount) + acc.amount,
					amount_preferred_currency_readable:
						parseFloat(elem.amount_preferred_currency_readable) +
						acc.amount_preferred_currency_readable,
				}
			},
			{
				amount: BigInt(0),
				amount_preferred_currency_readable: 0,
			}
		)
		return {
			currency: k,
			amount: formatEther(balanceItem.amount),
			amountDesired: balanceItem.amount_preferred_currency_readable,
		}
	})
}
