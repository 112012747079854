import { Calendar, RectangleEllipsis } from "lucide-react"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/inputs/input"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../../components/ui/section-header"
import Spacer from "../../../components/ui/spacer"

import PlatformHeader from "../../../components/ui/layouts/platform/PlatformHeader.tsx"
import { Alert } from "../../../components/ui/alert.tsx"
import { Section } from "../../../components/ui/section.tsx"
import { platformHeader } from "../../../components/ui/layouts/layout-sizes.ts"

export default function BusinessBody() {
	return (
		<>
			<PlatformHeader title={"Empresa"} variant="secondary" />
			<CenteredBody className={`${platformHeader.paddingX.class} pt-4`}>
				<SectionHeader
					title="Contanos de vos"
					subtitle="Contactate con nosotros y te pediremos documentación necesaria para que arranques a cobrar con talo"
				/>

				<Section
					footer={
						<p>
							Para mas información podes entrar a{" "}
							<a
								className="text-text-interactive"
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.talo.com.ar"
							>
								nuestra web
							</a>{" "}
							y conocer todas las funciones nuevas.{" "}
						</p>
					}
				>
					<Alert
						variant="link"
						icon={<RectangleEllipsis />}
						title={"Whatsapp"}
						description={
							"Para arrancar necesitamos saber un poco mas de vos. Contactanos y aguarda que te pidamos la documentación necesaria para arrancar."
						}
						buttonText="Redirigirme"
						buttonLink="https://wa.me/541133711752?text=Hola,%20quiero%20abrir%20una%20cuenta%20como%20persona%20juridica"
					/>
				</Section>
			</CenteredBody>
		</>
	)
}
