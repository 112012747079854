import { useEffect, useState } from "react"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { getMonitoringServerStatus } from "../../../services/monitoring-server"
import { Option } from "../../../components/ui/options/option"
import { Badge } from "../../../components/ui/badge"

const AdminMonitoringServerBody = () => {
	const [items, setItems] = useState([])
	useEffect(() => {
		const load = async () => {
			const statuses = await getMonitoringServerStatus()

			setItems(statuses.results)
		}
		load()
	}, [])

	return (
		<CenteredBody>
			<SectionHeader
				title="Monitoring Server"
				subtitle="Estado de las conexiones del monitoring server"
			/>

			{items.map((item) => {
				const key = Object.keys(item)[0]
				return (
					<Option
						key={key}
						title={key}
						leading="div"
						div={<Badge variant={_getBadgeColor(item[key])}>{item[key]}</Badge>}
					/>

					// <Card
					// 	variant="flat"
					// 	style={{
					// 		margin: "20px 0px",
					// 	}}
					// >
					// 	<Card.Body
					// 		style={{
					// 			display: "flex",
					// 			flexDirection: "row",
					// 			justifyContent: "space-between",
					// 		}}
					// 	>
					// 		<Text>{key}</Text>{" "}
					// 		<Badge disableOutline color={_getBadgeColor(item[key])}>
					// 			{item[key]}
					// 		</Badge>
					// 	</Card.Body>
					// </Card>
				)
			})}
		</CenteredBody>
	)
}

const _getBadgeColor = (status: string) => {
	switch (status) {
		case "connected":
			return "default"
		case "no aplica":
			return "secondary"
		default:
			return "destructive"
	}
}

export default AdminMonitoringServerBody
