import { ArrowDownRight, ArrowUpLeft } from "lucide-react"
import { Link } from "react-router-dom"

import { Button } from "../../components/ui/button"
import Col from "../../components/ui/layouts/column"
import Row from "../../components/ui/layouts/row"
import { PublicRole, getMaxRole } from "../../lib/roles"
import { useAuth } from "../../context/AuthContext"
import SectionTitle from "../../components/ui/titles/SectionTitle"
import PaymentsSection from "../../components/app/dashboard/PaymentsSection"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"

const MobileDashboardBody = () => {
	const { user } = useAuth()
	const isOwner =
		getMaxRole(user.roles ?? user.talo_role_list) === PublicRole.OWNER
	return (
		<CenteredBody className="h-full">
			<Row className="gap-betweenCards pb-betweenChips">
				<Button
					size="flex"
					variant="secondary"
					asChild
					className="group gap-betweenTexts bg-background-elevated dark:bg-background-subtle w-full py-5 rounded-md"
				>
					{isOwner && (
						<Link to="/withdraw">
							Retirar
							<ArrowUpLeft className="group-hover:animate-diagonal-bounce " />
						</Link>
					)}
				</Button>
				<Button
					size="flex"
					variant="secondary"
					asChild
					className="group gap-betweenTexts bg-background-elevated dark:bg-background-subtle w-full py-5 rounded-md"
				>
					<Link to="/charge">
						Recibir
						<ArrowDownRight className="group-hover:animate-diagonal-bounce " />
					</Link>
				</Button>
			</Row>
			<Col className="grow h-full">
				<PaymentsSection />
			</Col>
		</CenteredBody>
	)
}

export default MobileDashboardBody
