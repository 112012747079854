import { formatEther } from "ethers"
import { formatDate } from "../../../utils/format"
import { formatPriceAmount } from "../../../utils/numbers"

export function mapTxToTableTx(tx: any, talo_user_id: string) {
	const formattedAmount =
		tx.currency === "ARS" ? tx.amount : formatEther(tx.amount)
	const date = new Date(tx.creation_timestamp)
	return {
		id: tx.id,
		user_id: tx.user_id === talo_user_id ? "talo" : tx.user_id,
		amount: `${formatPriceAmount(
			formattedAmount,
			tx.currency === "BNB" ? 5 : 2
		)} ${tx.currency}`,
		creation_date: formatDate(date),
		type: tx.transactionType,
		currency: tx.currency,
	}
}
