import { Star, Store, Tag } from "lucide-react"

export const enum PrivateRole {
	YODA = "yoda",
	PADAWAN = "padawan",
	JEDI = "jedi",
	CLONE = "clone",
	R2D2 = "r2d2",
}
export const enum PublicRole {
	OWNER = "owner",
	SUPERVISOR = "supervisor",
	POS = "pos",
}

export const publicRoleTranslation = {
	owner: "Administrador",
	supervisor: "Supervisor",
	pos: "Punto de venta",
}

export const publicRoleShortTranslation = {
	owner: "Admin",
	supervisor: "Sup",
	pos: "PdV",
}

export function getMaxRole(role_list: PublicRole[]): PublicRole {
	if (role_list.includes(PublicRole.OWNER)) {
		return PublicRole.OWNER
	}
	if (role_list.includes(PublicRole.SUPERVISOR)) {
		return PublicRole.SUPERVISOR
	}
	if (role_list.includes(PublicRole.POS)) {
		return PublicRole.POS
	}
	return PublicRole.POS
}

export function isOwner(user: any) {
	return (
		getMaxRole(user.roles ?? user.talo_role_list ?? []) === PublicRole.OWNER
	)
}
export function getRolesFromUserRole(roles: PublicRole[]) {
	const role = getMaxRole(roles)
	if (role === PublicRole.OWNER || role === PublicRole.SUPERVISOR) {
		return [PublicRole.SUPERVISOR, PublicRole.POS]
	}
	return [PublicRole.POS]
}

export function isInternal(roles: any[]) {
	return roles.includes(PrivateRole.YODA)
}

export function getPathForUser(user: any) {
	if (!user) {
		return undefined
	}
	if (isInternal(user.roles ?? user.talo_role_list ?? [])) {
		return "/admin"
	} else {
		return "/"
	}
}

export const publicRoleIcons = ({
	className,
	role,
}: {
	className?: string
	role: PublicRole
}) => {
	switch (role) {
		case PublicRole.OWNER:
			return (
				<Star
					className={`size-icon group-hover:animate-[wiggle_1s_ease-in-out_infinite] ${className}`}
				/>
			)
		case PublicRole.SUPERVISOR:
			return <Store className={className} />
		case PublicRole.POS:
			return <Tag className={className} />
	}
}

export const publicRoleColors = {
	owner: "text-[#E2CD0A] ", //TRAVE TODO COLOR
	supervisor: "text-[#669E3B] ",
	pos: "text-[#3B809E] ",
}

export const publicRoleBackgrounds = {
	owner: "border-[#E2CD0A] bg-[#FEFDF2] dark:bg-[#171600]", //TRAVE TODO COLOR
	supervisor: "border-[#669E3B] bg-[#D2FAE0] dark:bg-[#000C04]",
	pos: "border-[#3B809E] bg-[#D2FAF8] dark:bg-[#001C1A]",
}

export const publicRoleChipBackgrounds = {
	owner: "bg-[#E2CD0A] text-text",
	supervisor: "bg-[#669E3B] text-text-inverse",
	pos: "bg-[#3B809E] text-text-inverse ",
}
