import { Store } from "../../../hooks/useFetchStores"
import {
	getPaymentAddress,
	getPaymentAddressArs,
	getPaymentAlias,
	getPaymentComission,
	getPaymentCommissionArs,
	getPaymentCreditedAmount,
	getPaymentCreditedAmountArs,
	getPaymentCurrency,
	getPaymentExpectedAmount,
	getPaymentNetwork,
	getPaymentReceivedAmount,
	getPaymentReceivedAmountArs,
	getPaymentReceivedAmountInRequestedCurrency,
	getPaymentSenderAddress,
	getPaymentSenderCuit,
	getPspOrBankId,
	getWithdrawnAmount,
	getWithdrawnAmountArs,
} from "../../../lib/utils"
import { Payment } from "../../../pages/checkout/id"
import { formatDate } from "../../../utils/format"
import { TableTransaction } from "./columns"

export function mapCombinedPaymentsToTableTransaction({
	payments,
	transactions,
	stores,
}: {
	payments: Payment[] | undefined
	transactions: any
	stores: Store[]
}): TableTransaction[] {
	if (transactions && payments) {
		// Remove the separation of pending and other payments
		const validPayments = payments.filter(
			(payment) => payment.payment_status !== "EXPIRED"
		)

		const txList = transactions || []
		const outboundTransactions = txList.filter(
			(transaction: any) =>
				transaction.transactionType === "OUTBOUND" ||
				transaction.type === "OUTBOUND"
		)

		// Merge all payments and sort by date
		const allTransactions = [...validPayments, ...outboundTransactions].sort(
			(a, b) => {
				return (
					new Date(b.creation_timestamp).getTime() -
					new Date(a.creation_timestamp).getTime()
				)
			}
		)

		var tableTransactions = allTransactions.map((payment) =>
			mapToTableTransaction(payment, stores)
		)
		return tableTransactions
	}
	return []
}

function isTransferPayment(payment: any): boolean {
	return (
		(payment.payment_options && payment.payment_options.includes("transfer")) ||
		(payment.currency &&
			payment.network &&
			payment.currency === "ARS" &&
			payment.network === "POLLUX")
	)
}

export function mapToTableSubTransaction(transaction: any): any {
	return {
		network: transaction.network,
		currency: transaction.currency,
		amount: transaction.amount,
		amountReadable: transaction.amountReadable,
		transactionHash: transaction.transaction_hash,
		transactionId: transaction.transaction_id,
		senderAddress: transaction.sender_address,
		date: formatDate(new Date(transaction.creation_timestamp)),
		time: new Date(transaction.creation_timestamp).toLocaleTimeString("es-AR", {
			hour: "2-digit",
			minute: "2-digit",
		}),
	}
}

export function mapToTableSubTransactionArs(transaction: any): any {
	let pspOrBank = getPspOrBankId(transaction.sender_address)
	return {
		network: transaction.network,
		currency: transaction.currency,
		amount: transaction.amount,
		amountReadable: transaction.amountReadable,
		transactionId: transaction.transaction_id,
		senderAddress: transaction.sender_address,
		senderCuit: transaction.transaction_data?.PROCESSED?.senderCuit ?? "-",
		senderIsPsp: pspOrBank[1] === "psp_id" ? true : false,
		psp_id: pspOrBank[1] === "psp_id" ? pspOrBank[0] : undefined,
		bank_id: pspOrBank[1] === "bank_id" ? pspOrBank[0] : undefined,
		date: formatDate(new Date(transaction.creation_timestamp)),
		time: new Date(transaction.creation_timestamp).toLocaleTimeString("es-AR", {
			hour: "2-digit",
			minute: "2-digit",
		}),
	}
}

function getStoreDetails(
	payment: any,
	stores: Store[]
): { store_id: string | number | undefined; store_name: string | undefined } {
	const store_id = getStoreId(payment)
	const store = stores.find(
		(store) => String(store.store_id) === String(store_id)
	)
	return {
		store_id,
		store_name: store ? store.store_name : undefined,
	}
}

export function mapToTableTransactionArs(
	payment: any,
	stores: Store[]
): TableTransaction {
	try {
		const lastModifiedDateTime = getFormattedDateTime(
			payment.last_modified_timestamp
		)
		const creationDateTime = getFormattedDateTime(payment.creation_timestamp)

		if (payment.payment_status) {
			const received = getPaymentReceivedAmountArs(payment)
			const receivedInRequestedCurrency = getPaymentReceivedAmountArs(payment)
			const comission = getPaymentCommissionArs(payment)
			const credited = getPaymentCreditedAmountArs(payment)
			const creditedInRequestedCurrency = getPaymentCreditedAmountArs(payment)
			return {
				id: payment.id,
				lastModifiedDateTime: lastModifiedDateTime,
				creationDateTime: creationDateTime,
				orderId:
					(
						payment.tiendanube?.order_number ??
						payment.tiendanube?.order_id ??
						payment.woocommerce?.order_id ??
						payment?.shopify?.order_name ??
						payment?.shopify?.order_id
					)?.toString() ?? "-",
				currency: getPaymentCurrency(payment) ?? "-",
				expected: payment.price,
				received: received,
				receivedInRequestedCurrency: receivedInRequestedCurrency,
				credited: credited,
				creditedInRequestedCurrency: creditedInRequestedCurrency,
				status: payment.payment_status ?? "-",
				price: payment.price,
				comission: comission,
				network: getPaymentNetwork(payment),
				address: getPaymentAddressArs(payment),
				sender: getPaymentSenderAddress(payment),
				sender_cuit: getPaymentSenderCuit(payment),
				alias: getPaymentAlias(payment),
				transactions: payment.transactions?.map(mapToTableSubTransactionArs),
				payment_options: payment.payment_options ?? undefined,
				tiendanube: payment.tiendanube,
				woocommerce: payment.woocommerce,
				shopify: payment.shopify,
				paymentUrl: payment.payment_url,
				psp_id: payment.psp_id ?? undefined,
				bank_id: payment.bank_id ?? undefined,
				expiration_timestamp: {
					date: formatDate(new Date(payment.expiration_timestamp)),
					time: new Date(payment.expiration_timestamp).toLocaleTimeString(
						"es-AR",
						{
							hour: "2-digit",
							minute: "2-digit",
						}
					),
				},
				...getStoreDetails(payment, stores),
			}
		} else {
			const withdrawnAmount = getWithdrawnAmountArs(payment)
			if (payment.amount) {
				return {
					id: payment.transaction_id ?? "-",
					lastModifiedDateTime: lastModifiedDateTime,
					creationDateTime: creationDateTime,
					orderId: "-",
					currency: payment.currency ?? "-",
					network: payment.network,
					address: payment.address,
					credited: withdrawnAmount,
					received: withdrawnAmount,
					expected: withdrawnAmount,
					status: payment?.transaction_status?.toLowerCase() ?? "SENT",
					transactionHash: payment.transaction_hash,
					psp_id: payment?.recipient_account?.psp_id ?? undefined,
					bank_id: payment?.recipient_account?.bank_id ?? undefined,
					recipient_account: payment?.recipient_account ?? undefined,
					...getStoreDetails(payment, stores),
				}
			} else {
				console.error(payment)
				throw new Error("Invalid transaction")
			}
		}
	} catch (err) {
		console.error("Error in mapToTableTransactionArs", err)
		return payment
	}
}
export function mapToTableTransaction(
	payment: any,
	stores: Store[]
): TableTransaction {
	if (isTransferPayment(payment)) {
		return mapToTableTransactionArs(payment, stores)
	} else {
		return mapToTableTransactionCrypto(payment, stores)
	}
}

export function mapToTableTransactionCrypto(
	payment: any,
	stores: Store[]
): TableTransaction {
	const lastModifiedDateTime = getFormattedDateTime(
		payment.last_modified_timestamp
	)
	const creationDateTime = getFormattedDateTime(payment.creation_timestamp)

	if (payment.payment_status) {
		const quote = payment.quotes!.find(
			(q: any) =>
				q.currency === payment.transaction_fields?.currency &&
				q.network === payment.transaction_fields?.network
		)
		const received = getPaymentReceivedAmount(payment)
		const receivedInRequestedCurrency = quote
			? getPaymentReceivedAmountInRequestedCurrency(payment, quote)
			: undefined
		const comission = quote ? getPaymentComission(payment, quote) : undefined
		const credited = getPaymentCreditedAmount(received, comission?.comission)
		const creditedInRequestedCurrency = getPaymentCreditedAmount(
			receivedInRequestedCurrency,
			comission?.comissionInRequestedCurrency
		)
		return {
			id: payment.id,
			lastModifiedDateTime: lastModifiedDateTime,
			creationDateTime: creationDateTime,
			orderId:
				(
					payment.tiendanube?.order_id ??
					payment.woocommerce?.order_id ??
					payment.customer_email
				)?.toString() ?? "-",
			currency: getPaymentCurrency(payment) ?? "-",
			expected: getPaymentExpectedAmount(payment, quote),
			received: received,
			receivedInRequestedCurrency: receivedInRequestedCurrency,
			credited: credited,
			creditedInRequestedCurrency: creditedInRequestedCurrency,
			status: payment.payment_status ?? "-",
			price: payment.price,
			comission: comission,
			network: getPaymentNetwork(payment),
			address: getPaymentAddress(payment),
			transactions: payment.transactions?.map(mapToTableSubTransaction),
			transactionHash: payment.transaction_fields?.transaction_hash,
			tiendanube: payment.tiendanube,
			woocommerce: payment.woocommerce,
			paymentUrl: payment.payment_url,
			payment_options: payment.payment_options ?? undefined,
			...getStoreDetails(payment, stores),
		}
	} else {
		const withdrawnAmount = getWithdrawnAmount(payment)
		if (payment.amount) {
			return {
				id: payment.transaction_id ?? "-",
				lastModifiedDateTime: lastModifiedDateTime,
				creationDateTime: creationDateTime,
				orderId: "-",
				currency: payment.currency ?? "-",
				network: payment.network,
				address: payment.address,
				credited: withdrawnAmount,
				received: withdrawnAmount,
				expected: withdrawnAmount,
				status: "SENT",
				transactionHash: payment.transaction_hash,
				...getStoreDetails(payment, stores),
			}
		} else {
			console.error(payment)
			throw new Error("Invalid transaction")
		}
	}
}

// Helper function to format datetime
function getFormattedDateTime(timestamp: string) {
	try {
		const dateTime = new Date(timestamp)
		return {
			date: formatDate(dateTime),
			time: dateTime
				.toLocaleTimeString("es-AR", {
					hour: "2-digit",
					minute: "2-digit",
					hour12: true, // Ensure 12-hour format is used
				})
				.replace("a. m.", "AM") // Replace lowercase 'a. m.' with uppercase 'AM'
				.replace("p. m.", "PM"), // Replace lowercase 'p. m.' with uppercase 'PM'
		}
	} catch (error) {
		console.error("Error formatting datetime:", error)
		return {
			date: formatDate(new Date()),
			time: new Date()
				.toLocaleTimeString("es-AR", {
					hour: "2-digit",
					minute: "2-digit",
					hour12: true,
				})
				.replace("a. m.", "AM")
				.replace("p. m.", "PM"),
		}
	}
}

function getStoreId(payment: any): string | number | undefined {
	switch (true) {
		case !!payment.tiendanube:
			return payment.tiendanube.store_id
		case !!payment.woocommerce:
			return payment.woocommerce.store_id
		case !!payment.shopify:
			return payment.shopify.store_id
		default:
			// Search all nested objects for store_id
			for (const key in payment) {
				if (
					payment[key] &&
					typeof payment[key] === "object" &&
					payment[key].store_id
				) {
					return payment[key].store_id
				}
			}
			return undefined
	}
}
