import { MailQuestion } from "lucide-react"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { getPathForUser } from "../../lib/roles"
import { createVerificationCode } from "../../services/users"
import { Alert } from "../ui/alert"
import { Button } from "../ui/button"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../ui/section-header"
import Spacer from "../ui/spacer"
import { Heading5 } from "../ui/texts/TextStyle"
import Timer from "../ui/timer"

export interface TimeRemaining {
	minutesRemaining: number
	secondsRemaining: number
}

const NotVerifiedBody = () => {
	const [timeRemaining, setTimeRemaining] = useState<TimeRemaining | undefined>(
		undefined
	)

	const { user } = useAuth()
	//const [lastSentVerificationTimestamp, setLastSentVerificationTimestamp] =
	//useState<string>()
	const startTimer = (lastSentVerificationTimestamp: string) => {
		// Set the timer to count until 3 minutes after the last_verification_sent_timestamp
		const now = Date.now()
		const lastVerificationDate =
			(lastSentVerificationTimestamp &&
				new Date(lastSentVerificationTimestamp).getTime()) ||
			now
		const newExpTime = lastVerificationDate + 3 * 60 * 1000
		const mins = Math.floor((newExpTime - now) / (1000 * 60))
		const secs = Math.floor((newExpTime - now) / 1000) % 60
		if (mins < 0 || secs < 0) {
			setTimeRemaining({ minutesRemaining: 0, secondsRemaining: 0 })
		} else {
			setTimeRemaining({ minutesRemaining: mins, secondsRemaining: secs })
		}
	}

	useEffect(() => {
		startTimer(user && user.last_verification_sent_timestamp)
	}, [user])

	const handleOnSendEmail = async () => {
		//setLastSentVerificationTimestamp()
		startTimer(new Date().toISOString())
		await createVerificationCode(user!.user_id)
	}
	if (!user) {
		return <Navigate to="/" replace={true} />
	} else if (user.email_verified) {
		const userPath = getPathForUser(user)
		if (userPath) {
			return <Navigate to={userPath} replace={true} />
		} else {
			window.location.href = process.env.REACT_APP_NEXT_LANDING_URL!
		}
	}

	return (
		<CenteredBody bottom>
			<div className="mx-auto flex flex-col w-full  max-w-[696px] justify-center items-center gap-textToComponent">
				<img
					className="w-1/2 flex items-center justify-center"
					src="/illustrations/verified-mail.svg"
					alt=""
				/>
				<Spacer axis="vertical" type="belowSectionHeader"></Spacer>

				<SectionHeader
					title="Revisá tu casilla de mail"
					subtitle="Te enviamos un correo a tu casilla de mail para verificar la cuenta."
				/>
				<Alert
					icon={<MailQuestion className="h-4 w-4" />}
					title="¿No te llegó?"
					description={
						<ul className="text-h6 flex flex-col list-inside text-text-muted list-disc mt-2 [&>*]:m-0">
							<li>Verificá tu casilla de SPAM.</li>
							<li>Compobá que el mail sea el correcto.</li>
							<li>Esperá unos minutos, puede demorar.</li>
						</ul>
					}
					bulletList
				/>
				<div className="flex w-full flex-row justify-center">
					<Button
						variant="ghost"
						disabled={timeRemaining ? true : false}
						onClick={handleOnSendEmail}
					>
						{timeRemaining ? (
							<Timer
								initialMinutes={timeRemaining.minutesRemaining}
								initialSeconds={timeRemaining.secondsRemaining}
								callback={() => {
									setTimeRemaining(undefined)
								}}
							/>
						) : (
							<Heading5 className="text-text-interactive">
								Reenviar mail
							</Heading5>
						)}
					</Button>
				</div>
			</div>
		</CenteredBody>
	)
}

export default NotVerifiedBody
