import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { Button } from "../button"
import Spacer from "../spacer"
import LabelButton from "./LabelButton"

const ShortcutButton = ({
	type,
	label,
	icon,
	link,
	url,
	size,
}: {
	type: "label" | "horizontal" | "icon"
	size: "sm" | "lg"
	label: string
	icon?: React.ReactNode
	link?: string
	url?: string
}) => {
	if (type === "label") {
		return (
			<LabelButton
				label={label}
				icon={icon}
				link={link}
				url={url}
				size="full"
				variant="outline"
				className="rounded-md px-7"
			/>
		)
	} else if (type === "horizontal") {
		var body: ReactNode = (
			<>
				{label} <Spacer axis="horizontal" type="textToComponent" />
				{icon}
			</>
		)
		if (link) {
			body = <Link to={link}>{body}</Link>
		} else if (url) {
			body = (
				<a href={url} target="_blank" rel="noreferrer">
					{body}
				</a>
			)
		}
		const buttonProps: {
			[key: string]: {
				size: "md" | "xl"
				variant: "outline" | "link" | "secondary"
				className: string
			}
		} = {
			sm: {
				size: "md",
				variant: "outline",
				className: "rounded-md px-sm py-xs",
			},
			lg: {
				variant: "secondary",
				size: "xl",
				className: "rounded-md w-[221px] !h-full justify-between",
			},
		}
		return (
			<Button {...buttonProps[size]} asChild={link || url ? true : false}>
				{body}
			</Button>
		)
	} else {
		return (
			<Button
				onClick={() => {
					if (link) {
						window.location.href = link
					}
				}}
				size="icon"
				variant="secondary"
				className="size-[80px] rounded-md p-3 border border-border bg-background-subtle"
			>
				{icon}
			</Button>
		)
	}
}

export default ShortcutButton
