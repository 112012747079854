import { useEffect, useState } from "react"
import BodyItem from "../../../components/modals/payment-modal/body/BodyItem"
import { Card, CardContent, CardHeader } from "../../../components/ui/card"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { getBalanceSummary } from "../../../services/balances"
import { formatPriceAmount } from "../../../utils/numbers"

interface AdminBalanceItem {
	admin?: string
	users?: string
	onChain?: string
	difference?: string
	total?: string
}

const AdminBalancesBody = () => {
	const [balances, setBalances] = useState<
		| {
				[key: string]: {
					[key: string]: AdminBalanceItem
				}
		  }
		| undefined
	>()

	useEffect(() => {
		load()
	}, [])
	const load = async () => {
		const balancesRes = await getBalanceSummary()
		var balancesByCurrNet: {
			[key: string]: {
				[key: string]: AdminBalanceItem
			}
		} = {}
		Object.entries(balancesRes).forEach(([k, v]: [k: string, v: any]) => {
			v.forEach(
				(item: { network: string; currency: string; amount: string }) => {
					if (!balancesByCurrNet[item.network]) {
						balancesByCurrNet[item.network] = {}
					}
					if (!balancesByCurrNet[item.network][item.currency]) {
						balancesByCurrNet[item.network][item.currency] = {}
					}
					balancesByCurrNet[item.network][item.currency][
						k as "users" | "admin" | "onChain" | "difference" | "total"
					] = item.amount
				}
			)
		})

		setBalances(balancesByCurrNet)
	}
	const currNetList = balances
		? Object.entries(balances).flatMap(([k, v]: [k: string, v: any]) => {
				return Object.entries(v).map(([k2, v2]: [k2: string, v2: any]) => {
					return {
						currency: k2,
						network: k,
					}
				})
		  })
		: []
	return (
		<CenteredTableBody>
			<SectionHeader
				title="Balances"
				subtitle="Detalle de los balances al momento"
			/>
			{balances && (
				<div className="grid gap-4 grid-cols-2">
					{currNetList.map((currNet) => {
						return (
							<Card key={`${currNet.network}-${currNet.currency}`}>
								<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
									<img
										src={`/networks/${currNet.network.toLowerCase()}_logo.svg`}
										alt={currNet.network}
									/>
									<img
										src={`/currencies/${currNet.currency.toLowerCase()}-icon.svg`}
										alt={currNet.currency}
									/>
								</CardHeader>
								<CardContent>
									{Object.entries(
										balances[currNet.network][currNet.currency]
									).map(([k, v]: [k: string, v: string]) => {
										const formattedPrice = formatPriceAmount(v)
										if (formattedPrice)
											return (
												<BodyItem
													key={k}
													name={k}
													value={`${formattedPrice} ${currNet.currency}`}
												/>
											)
										else return <BodyItem key={k} name={k} value="-" />
									})}
								</CardContent>
							</Card>
						)
					})}
				</div>
			)}
		</CenteredTableBody>
	)
}

export default AdminBalancesBody
