import { useNavigate } from "react-router-dom"
import { Button } from "../../../components/ui/button"
import ApiBody from "../../settings/plugins/ApiBody"
import { redirectToEndOfOnboarding } from "../../../lib/utils"
import { useAuth } from "../../../context/AuthContext"

export default function ApiBodyWrapper() {
	const user = useAuth().user
	const navigate = useNavigate()

	return (
		<ApiBody>
			<Button
				onClick={() => {
					redirectToEndOfOnboarding(navigate,user)
				}}
			>
				Finalizar
			</Button>
		</ApiBody>
	)
}
