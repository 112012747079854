import ShortcutButton from "../../ui/buttons/ShortcutButton"
import { ScrollArea, ScrollBar } from "../../ui/scroll-area"
import { Shortcut } from "./ShortcutsSection"

const ShortcutsRow = ({ shortcuts }: { shortcuts: Shortcut[] }) => {
	return (
		//fade out to show it can be scrolled
		<div className="relative py-contentToButton h-[98px] fade-out-40 ">
			<ScrollArea>
				<div className="flex space-x-4 pb-4 ">
					{shortcuts.map((shortcut) => (
						<ShortcutButton
							key={shortcut.label}
							label={shortcut.label}
							type="horizontal"
							icon={shortcut.smIcon}
							size="sm"
							link={shortcut.link}
							url={shortcut.url}
						/>
					))}
				</div>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	)
}

export default ShortcutsRow
