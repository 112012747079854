import { Route } from "react-router-dom"
import CryptonubePage from "./id"

export const cryptonubeItems = [
	<Route
		key="/cryptonube/:userId"
		path="/cryptonube/:userId"
		element={<CryptonubePage />}
	/>,
	<Route
		key="/cloud-payments/:userId"
		path="/cloud-payments/:userId"
		element={<CryptonubePage />}
	/>,
]
