import { PrivateRole, PublicRole } from "../../../lib/roles"
import { currencies, fiatCurrencies } from "../../../utils/currencies"
import { CryptoAllLogo } from "../../../utils/withdrawCurrencies"
import Crypto from "./CryptoBody"
import Reales from "./PixBody"
import Pesos from "./TransferBody"

export const paymentMethodsSubSections = [
	{
		title: "Monedas tradicionales",
		description: "Los costos por transacción son diferentes para cada moneda",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Pesos",
				description: "Transferencias automáticas",
				subleading: "Ver Integraciones", //TODO: cambiar por el estado real
				icon: <img src={fiatCurrencies.ARS.flagPath} alt="ARS" />,
				link: "/settings/payment-methods/transfer",
				bodyComponent: <Pesos />,
				smBodyComponent: <Pesos />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
				featureFlag: "show_transfer_ars",
				flaggedTitle: "ARS (Proximamente)",
				flaggedMethodState: "Proximamente!!",
			},
			{
				title: "BRL",
				description: "Transferencias por pix",
				subleading: "Ver Integraciones", //TODO: cambiar por el estado real
				icon: <img src={fiatCurrencies.BRL.flagPath} alt="BRL" />,
				link: "/settings/payment-methods/pix",
				bodyComponent: <Reales />,
				smBodyComponent: <Reales />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
		],
	},
	{
		title: "Monedas digitales (criptomonedas)",
		minimumPublicRole: PublicRole.SUPERVISOR,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Criptomonedas",
				description: "Aceptá monedas estables",
				subleading: "Ver Integraciones", //TODO: cambiar por el estado real
				icon: <CryptoAllLogo />,
				link: "/settings/payment-methods/crypto",
				bodyComponent: <Crypto />,
				smBodyComponent: <Crypto />,
				iconInside: false,
				minimumPublicRole: PublicRole.SUPERVISOR,
			},
		],
	},
]
