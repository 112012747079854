import { Route, Routes } from "react-router-dom"
import ProtectedRoute from "./components/routes/ProtectedRoute"
import PublicRoute from "./components/routes/PublicRoute"
import { PrivateRole, PublicRole } from "./lib/roles"
import { adminRoutes } from "./pages/admin/routes"
import { appRoutes } from "./pages/app/routes"
import { chargeItems } from "./pages/charge/items"
import { checkoutItems } from "./pages/checkout/items"
import { cryptonubeItems } from "./pages/cryptonube/items"
import { errorRoutes } from "./pages/error/items"
import ForgotPassword from "./pages/forgot-password/ForgotPassword"
import Login from "./pages/login/login"
import { merchantItems } from "./pages/merchants/items"
import { allOnboardingRoutes } from "./pages/onboarding/items"
import { referralItems } from "./pages/referral/items"
import { settingsRoutes } from "./pages/settings/items"
import Signup from "./pages/signup/signup"
import Verification from "./pages/verification/Verification"
import NotVerified from "./pages/verify-email/NotVerified"
import { withdrawItems } from "./pages/withdraw/items"
import Terms from "./pages/signup/PrivacyPolicy"
import PrivacyPolicy from "./pages/signup/PrivacyPolicy"
import TermsOfService from "./pages/signup/TermsOfService"
import TermsOfServicePollux from "./pages/signup/TermsOfServicePollux"

const App = () => (
	<Routes>
		<Route
			key={"/login"}
			path="/login"
			element={
				<PublicRoute onlyPublic>
					<Login />
				</PublicRoute>
			}
		/>
		<Route
			key={"/signup"}
			path="/signup"
			element={
				<PublicRoute onlyPublic>
					<Signup />
				</PublicRoute>
			}
		/>
		<Route
			key={"/forgot-password"}
			path="/forgot-password"
			element={
				<PublicRoute onlyPublic>
					<ForgotPassword />
				</PublicRoute>
			}
		/>
		<Route
			key={"/verify-email"}
			path="/verify-email"
			element={
				<ProtectedRoute
					minimumPublicRole={PublicRole.POS}
					minimumPrivateRole={PrivateRole.YODA}
					requiresVerification={false}
					requiresKyc={false}
				>
					<NotVerified />
				</ProtectedRoute>
			}
		/>
		<Route
			key={"/verification"}
			path="/verification"
			element={
				<ProtectedRoute
					minimumPublicRole={PublicRole.POS}
					minimumPrivateRole={PrivateRole.YODA}
					requiresVerification={false}
					requiresKyc={false}
				>
					<Verification />
				</ProtectedRoute>
			}
		/>
		<Route
			key={"/privacy-policy"}
			path="/privacy-policy"
			element={
				<PublicRoute>
					<PrivacyPolicy />
				</PublicRoute>
			}
		/>
		<Route
			key={"/terms-of-service"}
			path="/terms-of-service"
			element={
				<PublicRoute>
					<TermsOfService />
				</PublicRoute>
			}
		/>
		<Route
			key={"/terms-of-service/polux"}
			path="/terms-of-service/polux"
			element={
				<PublicRoute>
					<TermsOfServicePollux />
				</PublicRoute>
			}
		/>
		{/* admin routes */}
		{adminRoutes}
		{/* private routes */}
		{appRoutes}
		{settingsRoutes}
		{allOnboardingRoutes}
		{chargeItems}
		{withdrawItems}
		{/* public routes */}
		{/* {homeRoutes} */}
		{cryptonubeItems} {/* /cryptonube/:user_id */}
		{merchantItems} {/* /merchants/:qrId */}
		{checkoutItems} {/* /checkout/:id */}
		{/* referral routes */}
		{referralItems}
		{/* error routes */}
		{errorRoutes}
	</Routes>
)

export default App
