import { fetchAndActivate, getAll } from "@firebase/remote-config"
import { createContext, useContext, useEffect, useState } from "react"
import { remoteConfig } from "../config/firebase"
import logger from "../utils/logger"

const FlagsContext = createContext<any>({})
export const useFlags = () => useContext(FlagsContext)
const _remoteConfig = remoteConfig

const _defaults = {}

_remoteConfig.settings = {
	minimumFetchIntervalMillis: 600000,
	fetchTimeoutMillis: 60000,
}

const FlagsProvider = ({ children }: any) => {
	const [flags, setFlags] = useState<Record<string, any>>(_defaults)
	const getFlag = (name: string) => flags[name]
	useEffect(() => {
		_remoteConfig.defaultConfig = _defaults
		fetchAndActivate(_remoteConfig).then((activated) => {
			if (!activated) logger.info("No flags were activated")
			const config = getAll(_remoteConfig)
			setFlags(config)
		})
	}, [])
	return (
		<FlagsContext.Provider value={{ flags, getFlag }}>
			{children}
		</FlagsContext.Provider>
	)
}

export default FlagsProvider
