import { TableTransaction } from "../../../../tables/transactions-table/columns"
import Spacer from "../../../../ui/spacer"
import { Heading6 } from "../../../../ui/texts/TextStyle"
import BodyBlock from "../BodyBlock"
import BodyItem from "../BodyItem"
import DateInfo from "../DateInfo"
import TransactionsAccordion from "../TransactionsAccordion"
import bank_list from "../../../../../lib/psp_bank_id.json"
import { findDisplayNameForBankInJson } from "../../../../../lib/utils"
import { Section } from "../../../../ui/section"
import { Separator } from "../../../../ui/separator"
const SuccessPaymentTransactionDetails = ({
	payment,
	variant,
}: {
	payment: TableTransaction
	variant?: "transfer" | "crypto"
}) => {
	switch (variant) {
		case "transfer":
			return (
				<BodyBlock title="Origen">
					{payment.transactions && payment.transactions.length > 1 ? (
						<div className="py-betweenTexts">
							<Heading6 className="font-medium text-text-muted pb-between">
								Transacciones
							</Heading6>
							<Spacer axis="vertical" type="betweenTexts"></Spacer>
							<TransactionsAccordion
								transactions={payment.transactions}
								variant={variant}
							/>
						</div>
					) : (
						<Section>
							{payment.address && (
								<BodyItem
									name={payment.bank_id ? "CBU" : "CVU"}
									value={payment.sender}
									copy={true}
									overflow={true}
								/>
							)}
							{payment.sender_cuit && (
								<BodyItem name="CUIT" value={`${payment.sender_cuit}`} />
							)}
							{(payment.bank_id || payment.psp_id) && (
								<BodyItem
									name="Banco"
									value={
										payment.bank_id
											? findDisplayNameForBankInJson(payment.bank_id, bank_list)
											: payment.psp_id
											? findDisplayNameForBankInJson(payment.psp_id, bank_list)
											: ""
									}
									copy={true}
									overflow={true}
								/>
							)}
							<Separator orientation="horizontal" className="bg-border" />
							<DateInfo {...payment.lastModifiedDateTime} />
						</Section>
					)}
				</BodyBlock>
			)
		default:
		case "crypto":
			return (
				<BodyBlock title="Otros detalles">
					<DateInfo {...payment.lastModifiedDateTime} />
					<BodyItem
						name="Cant. de transacciones"
						value={`${payment.transactions?.length ?? 1}`}
					/>
					{payment.network && <BodyItem name="Red" value={payment.network} />}

					{payment.address && (
						<BodyItem
							name="Dirección de destino"
							value={payment.address}
							copy={true}
							overflow={true}
						/>
					)}
					{payment.transactionHash && (
						<BodyItem
							name="Hash de transacción"
							value={payment.transactionHash}
							copy={true}
							overflow={true}
						/>
					)}
					{payment.transactions && payment.transactions.length > 1 && (
						<div className="py-betweenTexts">
							<Heading6 className="font-medium text-text-muted pb-betweenTexts">
								Transacciones
							</Heading6>
							<Spacer axis="vertical" type="betweenTexts"></Spacer>
							<TransactionsAccordion
								transactions={payment.transactions}
								variant={variant}
							/>
						</div>
					)}
					<Spacer axis="vertical" type="betweenTexts"></Spacer>
				</BodyBlock>
			)
	}
}

export default SuccessPaymentTransactionDetails
