import React, { useState, useEffect } from "react"
import { Input } from "./input"
import { MapPin, MapPinCheck, PencilIcon } from "lucide-react"
import { Address } from "../../../pages/onboarding/personal/onboard-personal-wizard-types.ts"
import Row from "../layouts/row.tsx"
import Spacer from "../spacer.tsx"
import { Button } from "../button.tsx"

interface AddressAutocompleteProps {
	onSelectAddress: (addressComponents: Address) => void
	setShowAddressConfirmation: (value: boolean) => void
	setIsConfirmed: (value: boolean) => void
	value: Address | string // Add the value prop to keep the input in sync with the parent component
	isConfirmed?: boolean
}

declare global {
	interface Window {
		initGoogleMaps: () => void
	}
}
export type AddressComponents = {
	street_number?: string
	route?: string
	locality?: string
	administrative_area_level_2?: string
	administrative_area_level_1?: string
	country?: string
	postal_code?: string
}

export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
	onSelectAddress,
	setShowAddressConfirmation,
	setIsConfirmed,
	value,
	isConfirmed = false,
}) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [inputValue, setInputValue] = useState(value) // Use value from props as the initial state
	const [suggestions, setSuggestions] = useState<any[]>([])
	const [sessionToken, setSessionToken] = useState<any>(null)

	const loadGoogleMapsScript = (callback: string) => {
		if (!process.env.REACT_APP_GOOGLE_API_KEY) {
			console.error("Google API key is not defined.")
			return
		}

		const existingScript = document.getElementById("google-maps-script")
		if (!existingScript) {
			const script = document.createElement("script")
			script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=${callback}`
			script.id = "google-maps-script"
			script.async = true
			script.defer = true
			document.body.appendChild(script)

			script.onload = () => {
				const cb = window[callback as keyof Window] as () => void
				if (cb && typeof cb === "function") {
					cb()
				}
			}
		} else {
			setIsLoaded(true)
		}
	}

	useEffect(() => {
		window.initGoogleMaps = function () {
			setIsLoaded(true)
			setSessionToken(new google.maps.places.AutocompleteSessionToken())
		}

		loadGoogleMapsScript("initGoogleMaps")
	}, [])

	useEffect(() => {
		setInputValue(value) // Keep input in sync with the value prop
	}, [value])

	const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value
		setInputValue(newValue)

		if (!isLoaded || newValue.trim() === "") return

		const request = {
			input: newValue,
			locationRestriction: {
				west: -73.56036,
				north: -21.781168,
				east: -53.637451,
				south: -55.052312,
			},
			origin: { lat: -34.603722, lng: -58.381592 },
			includedPrimaryTypes: [],
			language: "es",
			region: "ar",
			sessionToken: sessionToken,
		} as google.maps.places.AutocompleteRequest

		try {
			const { AutocompleteSuggestion } = (await google.maps.importLibrary(
				"places"
			)) as google.maps.PlacesLibrary
			const { suggestions } =
				await AutocompleteSuggestion.fetchAutocompleteSuggestions(request)
			if (suggestions.length === 0) {
				setSuggestions([
					{
						placePrediction: {
							text: "No hay resultados, haz click aquí para ingresar manualmente",
						},
						place_id: "no-results",
					},
				])
			} else {
				setSuggestions(suggestions)
			}
		} catch (error) {
			console.error("Error fetching autocomplete suggestions:", error)
		}
	}

	const handleSelect = async (suggestion: any) => {
		const placePrediction =
			suggestion.placePrediction as google.maps.places.PlacePrediction

		try {
			const place = placePrediction.toPlace() as google.maps.places.Place
			await place.fetchFields({
				fields: [
					"displayName",
					"formattedAddress",
					"types",
					"addressComponents",
				],
			})

			const formattedAddress = place.formattedAddress
			const displayName = place.displayName
			const types = place.types
			const addressComponents = place.addressComponents

			let mappedAddress: Address = {
				country: "",
				street: "",
				number: 1,
				location: "",
				state: "",
				postalCode: "",
			}
			if (addressComponents && addressComponents.length > 0) {
				mappedAddress = mapAddressComponents(addressComponents)
			}

			onSelectAddress(mappedAddress)
		} catch (error) {
			console.error("Error fetching place details:", error)
		}

		setInputValue("")
		setSuggestions([])
		setSessionToken(new google.maps.places.AutocompleteSessionToken())
	}

	const mapAddressComponents = (addressComponents: any[]): Address => {
		const mappedComponents: Address = {
			country: "",
			street: "",
			number: 0,
			location: "",
			state: "",
			postalCode: "",
		}
		addressComponents.forEach((component) => {
			const type = component.Eg[0]
			const val = component.Fg
			switch (type) {
				case "street_number":
					mappedComponents.number = Number(val)
					break
				case "route":
					mappedComponents.street = val
					break
				case "administrative_area_level_2":
					mappedComponents.location = val
					break
				case "administrative_area_level_1":
					mappedComponents.state = val
					break
				case "country":
					if (val) {
						mappedComponents.country = val.substring(0, 3).toUpperCase()
					}
					break
				case "postal_code":
					mappedComponents.postalCode = val
					break
			}
		})

		return mappedComponents
	}

	const renderSuggestions = () =>
		suggestions.map((suggestion) => {
			const {
				placePrediction: { text },
				place_id,
			} = suggestion

			if (suggestion.place_id === "no-results") {
				return (
					<div className="flex flex-row gap-4 group p-3 py-2 rounded-sm hover:bg-background-strong transition-all duration-150">
						<div>
							<MapPinCheck className="size-icon text-text-muted  group-hover:text-text transition-all duration-150" />
						</div>
						<div
							className="text-h5 font-normal group-hover:font-semibold text-text-muted cursor-pointer group-hover:text-text  hover:translate-x-1 transition-all duration-150"
							onClick={() => {
								let mappedAddress: Address = {
									country: "",
									street: "",
									number: 0,
									location: "",
									state: "",
									postalCode: "",
								}
								onSelectAddress(mappedAddress)

								setInputValue("")
								setSuggestions([])
								setSessionToken(
									new google.maps.places.AutocompleteSessionToken()
								)
							}}
						>
							{text.toString()}
						</div>
					</div>
				)
			}

			return (
				<div className="flex flex-row gap-4 group p-3 py-2 rounded-sm hover:bg-background-strong transition-all duration-150">
					<div>
						<MapPinCheck className="size-icon text-text-muted  group-hover:text-text transition-all duration-150" />
					</div>
					<div
						className="text-h5 font-normal group-hover:font-semibold text-text-muted cursor-pointer group-hover:text-text  hover:translate-x-1 transition-all duration-150"
						key={place_id}
						onClick={() => handleSelect(suggestion)}
					>
						{text.toString()}
					</div>
				</div>
			)
		})

	return (
		<div className="relative">
			<Row className="gap-betweenFields">
				<Input
					label="Dirección de facturación"
					required
					placeholder="Av. Corrientes 1234"
					type="text"
					value={
						typeof inputValue === "string"
							? inputValue
							: Object.values(inputValue)
									.filter((str) => str !== "" && str !== 0 && str !== "0")
									.join(", ")
					}
					onChange={handleInputChange}
					disabled={!isLoaded}
					icon={<MapPin className="size-icon text-text-muted" />}
				/>
				{isConfirmed && (
					<>
						<div className="h-full flex flex-col">
							<Spacer className="h-[25px]" axis="vertical" />
							<Button
								variant={"outline"}
								size={"lg"}
								onClick={() => {
									setIsConfirmed(false)
									setShowAddressConfirmation(true)
								}}
							>
								<PencilIcon className="size-icon" />
							</Button>
						</div>
					</>
				)}
			</Row>
			{suggestions.length > 0 && (
				<div className="bg-background-subtle py-sm px-1 rounded-sm border border-border mt-2 ">
					{renderSuggestions()}
				</div>
			)}
		</div>
	)
}

export default AddressAutocomplete
