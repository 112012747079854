import { useQuery } from "@tanstack/react-query";
import logger from "../utils/logger";
import { updateToken } from "../services/interceptors";
import { getPublicUser, getTeam, getUserByid } from "../services/users";

export const useRefreshAuth = (
	firebaseUser: any
) => {
	return useQuery<any, Error>({
		queryKey: ["refreshAuth", firebaseUser],
		queryFn: async () => {
			if (firebaseUser) {
				const tokenResult = await firebaseUser.getIdTokenResult();
				logger.info("TOKEN RESULT ", tokenResult);
				const user_custom_attributes =
					firebaseUser?.reloadUserInfo?.customAttributes &&
					JSON.parse(firebaseUser?.reloadUserInfo?.customAttributes);
				logger.info("user_custom_attributes===", user_custom_attributes);
				const idToken = await firebaseUser.getIdToken();
				logger.info(idToken);
				updateToken("FB-" + idToken);
				
				let taloUser;
				if (tokenResult.claims.talo_team_id) {
					const [taloTeam, taloOwner] = await Promise.all([
						getTeam(tokenResult.claims.talo_user_id, tokenResult.claims.talo_team_id),
						getPublicUser(tokenResult.claims.talo_user_id),
					]);
					taloUser = {
						...taloTeam.data.data,
						fiat_payments_enabled: taloOwner.data.data.fiat_payments_enabled,
						pricing_profile: taloOwner.data.data.pricing_profile,
						payment_notifications: taloOwner.data.data.payment_notifications,
						owner: taloOwner.data.data.email,
					};
					console.log("taloOwner", taloOwner);
				} else {
					const res = await getUserByid(tokenResult.claims.talo_user_id);
					taloUser = res.data.data;
				}
				logger.info("Auth context ", taloUser);
				return {
					...taloUser,
					firebase_uid: tokenResult?.claims?.user_id,
					displayName: firebaseUser.displayName,
					role_list: tokenResult.claims.role_list,
				};
			}
			return null;
		},
		enabled: !!firebaseUser,
        staleTime: 1000 * 60 * 5,
	});
};
