import { Table } from "@tanstack/react-table"
import { EyeOff, FileDown, TableProperties, X } from "lucide-react"
import { useEffect, useState } from "react"
import { DateRange } from "react-day-picker"
import { useAuth } from "../../../context/AuthContext"
import { useCombinedPayments } from "../../../hooks/usePayments"
import { exportPaymentsInDateRange } from "../../../utils/csv-utils"
import { TableTransaction } from "../../tables/transactions-table/columns"
import { mapCombinedPaymentsToTableTransaction } from "../../tables/transactions-table/data"
import { Alert } from "../alert"
import { Avatar, AvatarFallback, AvatarImage, AvatarOverlay } from "../avatar"
import { Button } from "../button"
import { DateRangePicker } from "../date-range-picker"
import { Input } from "../inputs/input"
import { Label } from "../label"
import Col from "../layouts/column"
import CenteredBody from "../layouts/platform/body/CenteredBody"
import { Section } from "../section"
import { SectionHeader } from "../section-header"
import { Skeleton } from "../skeleton"
import SlidingCard from "../sliding-card"
import {
	DataTableFacetedFilter,
	DataTableFacetedFilterProps,
} from "./data-table-faceted-filter"
import { DataTableViewOptions } from "./data-table-view-options"

interface DataTableToolbarProps<TData, TValue> {
	table: Table<TData>
	searchInput?: {
		placeholder: string
		columnName: string
	}
	badgeFilters?: DataTableFacetedFilterProps<TData, TValue>[]
	viewOptions?: boolean
	storeFilter: string[]
	setStoreFilter: (filter: string[]) => void
}

export function DataTableToolbar<TData, TValue>({
	table,
	searchInput,
	badgeFilters,
	viewOptions = false,
	storeFilter,
	setStoreFilter,
}: DataTableToolbarProps<TData, TValue>) {
	const isFiltered = table.getState().columnFilters.length > 0
	const { user, stores } = useAuth()
	const { data: combinedPayments, isLoading } = useCombinedPayments(
		user.id,
		user.roles.includes("pos") ? user.team_id : undefined
	)

	const [transactions, setTransactions] = useState<TableTransaction[]>([])

	useEffect(() => {
		const mappedTransactions = mapCombinedPaymentsToTableTransaction({
			payments: combinedPayments?.payments,
			transactions: combinedPayments?.transactions,
			stores,
		})
		setTransactions(mappedTransactions)
	}, [combinedPayments, stores])

	const [dateRange, setDateRange] = useState<DateRange | undefined>()
	const [isExporting, setIsExporting] = useState(false)

	const handleExport = async () => {
		if (!transactions || !dateRange?.from) return
		setIsExporting(true)
		try {
			await exportPaymentsInDateRange(transactions, dateRange)
		} catch (error) {
			console.error("Error exporting transactions:", error)
		} finally {
			setIsExporting(false)
		}
	}

	const handleClick = (storeId: string) => {
		if (storeFilter.includes(storeId)) {
			setStoreFilter(storeFilter.filter((id) => id !== storeId))
		} else {
			setStoreFilter([...storeFilter, storeId])
		}
	}

	return (
		<div className="flex  items-center lg:justify-between justify-end ">
			<div className="lg:flex flex-row hidden items-center space-x-2 py-betweenFields">
				{searchInput &&
					table
						.getAllLeafColumns()
						.find((col) => col.id === searchInput.columnName)
						?.getIsVisible() && (
						<div className="w-[150px] lg:w-[250px]">
							<Input
								placeholder={searchInput.placeholder}
								value={
									(table
										.getColumn(searchInput.columnName)
										?.getFilterValue() as string) ?? ""
								}
								onChange={(event) =>
									table
										.getColumn(searchInput.columnName)
										?.setFilterValue(event.target.value)
								}
							/>
						</div>
					)}
				{viewOptions && <DataTableViewOptions table={table} />}
				{badgeFilters &&
					badgeFilters
						.filter(
							(filter) =>
								filter.column &&
								table
									.getAllLeafColumns()
									.find((col) => col.id === filter.column?.id)
									?.getIsVisible()
						)
						.map((filter) => (
							<DataTableFacetedFilter key={filter.title} {...filter} />
						))}
				{isFiltered && (
					<Button
						variant="ghost"
						size="md"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<X className="ml-2 h-4 w-4" />
					</Button>
				)}
			</div>
			<div className="flex flex-row gap-betweenComponents items-center">
				{stores &&
					stores.length >= 2 &&
					stores.map((store) => (
						<Avatar
							key={store.store_id}
							onClick={() => handleClick(String(store.store_name))}
							className={`size-10 border-2 -ml-6 dark:border-background-invers border-[#d0d0d0] hover:z-40 hover:scale-110 transition-all duration-500 ${
								storeFilter.includes(String(store.store_name)) &&
								"dark:border-white/60"
							}`}
						>
							{storeFilter.includes(String(store.store_name)) && (
								<AvatarOverlay>
									<EyeOff className="size-icon" />
								</AvatarOverlay>
							)}
							{store.logo_url ? (
								<AvatarImage src={store.logo_url} />
							) : (
								<AvatarFallback>
									{store.store_name
										? store.store_name.slice(0, 2).toUpperCase()
										: "??"}
								</AvatarFallback>
							)}
						</Avatar>
					))}
				<SlidingCard
					side={"center"}
					trigger={
						<Button
							aria-label="Toggle columns"
							variant="outline"
							size="flex"
							className="ml-auto py-2 lg:py-3"
						>
							<FileDown className="mr-2 size-icon" />
							Extracto
						</Button>
					}
				>
					<CenteredBody>
						<SectionHeader
							title={"Movimientos"}
							subtitle={
								"Descarga los movimientos de tu cuenta en el periodo solicitado."
							}
						/>
						<Col className="gap-2 w-fit">
							<Label>Rango de tiempo</Label>
							<DateRangePicker
								onUpdate={(values) => setDateRange(values.range)}
								align="start"
								locale="es-ar"
								showCompare={false}
							/>
						</Col>
						<Section>
							{isLoading ? (
								<Skeleton className="h-32 w-full" />
							) : (
								<Alert
									className={`${
										!dateRange?.from || isExporting ? "opacity-20" : ""
									}`}
									variant="link"
									icon={<TableProperties />}
									title={"Resumen de cuenta"}
									description={
										"Descargate el resumen de cuenta en formato tabla, esto incluye: solicitado, recibido, comisión, moneda, fecha y numero de orden"
									}
									buttonText={isExporting ? "Exportando..." : "Descargar .CSV"}
									buttonDisabled={!dateRange?.from || isExporting}
									onClickHandler={() => {
										handleExport()
									}}
								/>
							)}
						</Section>
					</CenteredBody>
				</SlidingCard>
			</div>
		</div>
	)
}

export default DataTableToolbar
