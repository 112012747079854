export default function LoadingProcessCover() {
	return (
		<svg
			width="382"
			height="242"
			viewBox="0 0 382 242"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M161.667 63.8333H147.25C143.427 63.8333 139.76 65.3522 137.056 68.0559C134.352 70.7595 132.833 74.4265 132.833 78.25V179.167C132.833 182.99 134.352 186.657 137.056 189.361C139.76 192.064 143.427 193.583 147.25 193.583H186.896M248.167 132.312V78.25C248.167 74.4265 246.648 70.7595 243.944 68.0559C241.24 65.3522 237.574 63.8333 233.75 63.8333H219.333M212.125 49.4167H168.875C164.894 49.4167 161.667 52.644 161.667 56.625V71.0417C161.667 75.0227 164.894 78.25 168.875 78.25H212.125C216.106 78.25 219.333 75.0227 219.333 71.0417V56.625C219.333 52.644 216.106 49.4167 212.125 49.4167Z"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M228.833 154.333V172.833L241.167 179M259.667 172.833C259.667 189.862 245.862 203.667 228.833 203.667C211.805 203.667 198 189.862 198 172.833C198 155.805 211.805 142 228.833 142C245.862 142 259.667 155.805 259.667 172.833Z"
				stroke="url(#paint0_linear_25695_10035)"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<rect
				x="155"
				y="102"
				width="71"
				height="6"
				rx="3"
				className="fill-icon-disabled animate-pulse"
			/>
			<rect
				x="155"
				y="139"
				width="34"
				height="6"
				rx="3"
				className="fill-icon-disabled animate-pulse"
			/>
			<path
				d="M14 141C39.7489 141 28.6502 104 48.1839 104C67.7175 104 56.1749 141 77.4843 141C98.7937 141 85.9193 104 113 104"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<circle cx="351" cy="122" r="8" className="fill-icon" />
			<path
				d="M351 122C330.932 122 279.927 122 277 122"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_25695_10035"
					x1="198"
					y1="172.833"
					x2="259.664"
					y2="172.833"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FFD02B" />
					<stop offset="1" stop-color="#FD8A3F" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export function RejectedProcessCover() {
	return (
		<svg
			width="382"
			height="242"
			viewBox="0 0 382 242"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M161.667 63.8333H147.25C143.427 63.8333 139.76 65.3522 137.056 68.0559C134.352 70.7595 132.833 74.4265 132.833 78.25V179.167C132.833 182.99 134.352 186.657 137.056 189.361C139.76 192.064 143.427 193.583 147.25 193.583H186.896M248.167 132.312V78.25C248.167 74.4265 246.648 70.7595 243.944 68.0559C241.24 65.3522 237.574 63.8333 233.75 63.8333H219.333M212.125 49.4167H168.875C164.894 49.4167 161.667 52.644 161.667 56.625V71.0417C161.667 75.0227 164.894 78.25 168.875 78.25H212.125C216.106 78.25 219.333 75.0227 219.333 71.0417V56.625C219.333 52.644 216.106 49.4167 212.125 49.4167Z"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M220 164L238 182M238 164L220 182M259.667 172.833C259.667 189.862 245.862 203.667 228.833 203.667C211.805 203.667 198 189.862 198 172.833C198 155.805 211.805 142 228.833 142C245.862 142 259.667 155.805 259.667 172.833Z"
				stroke="url(#paint0_linear_25685_9821)"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<rect
				x="155"
				y="102"
				width="71"
				height="6"
				rx="3"
				className="fill-icon-disabled"
			/>
			<rect
				x="155"
				y="139"
				width="34"
				height="6"
				rx="3"
				className="fill-icon-disabled"
			/>
			<path
				d="M14 141C39.7489 141 28.6502 104 48.1839 104C67.7175 104 56.1749 141 77.4843 141C98.7937 141 85.9193 104 113 104"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<circle cx="351" cy="122" r="8" className="fill-icon" />
			<path
				d="M351 122C330.932 122 279.927 122 277 122"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_25685_9821"
					x1="222.419"
					y1="145"
					x2="161.217"
					y2="171.482"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#FF512F" />
					<stop offset="0.213542" stop-color="#E9B4A2" />
					<stop offset="0.572917" stop-color="#F67A22" />
					<stop offset="1" stop-color="#F09819" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export function ApprovedProcessCover() {
	return (
		<svg
			width="382"
			height="242"
			viewBox="0 0 382 242"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M161.667 63.8333H147.25C143.427 63.8333 139.76 65.3522 137.056 68.0558C134.352 70.7595 132.833 74.4264 132.833 78.2499V179.167C132.833 182.99 134.352 186.657 137.056 189.361C139.76 192.064 143.427 193.583 147.25 193.583H186.896M248.167 132.312V78.2499C248.167 74.4264 246.648 70.7595 243.944 68.0558C241.24 65.3522 237.574 63.8333 233.75 63.8333H219.333M212.125 49.4166H168.875C164.894 49.4166 161.667 52.6439 161.667 56.625V71.0416C161.667 75.0227 164.894 78.25 168.875 78.25H212.125C216.106 78.25 219.333 75.0227 219.333 71.0416V56.625C219.333 52.6439 216.106 49.4166 212.125 49.4166Z"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M217.5 172.833L225.5 180.833L241.5 164.833M259.667 172.833C259.667 189.862 245.862 203.667 228.833 203.667C211.805 203.667 198 189.862 198 172.833C198 155.805 211.805 142 228.833 142C245.862 142 259.667 155.805 259.667 172.833Z"
				stroke="url(#paint0_linear_25695_9965)"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<rect
				x="155"
				y="102"
				width="71"
				height="6"
				rx="3"
				className="fill-icon-disabled"
			/>
			<rect
				x="155"
				y="139"
				width="34"
				height="6"
				rx="3"
				className="fill-icon-disabled"
			/>
			<path
				d="M14 141C39.7489 141 28.6502 104 48.1839 104C67.7175 104 56.1749 141 77.4843 141C98.7937 141 85.9193 104 113 104"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<circle cx="351" cy="122" r="8" className="fill-icon" />
			<path
				d="M351 122C330.932 122 279.927 122 277 122"
				className="stroke-icon"
				stroke-width="5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_25695_9965"
					x1="208.261"
					y1="166.037"
					x2="260.14"
					y2="182.848"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#61FD17" />
					<stop offset="0.395833" stop-color="#008B2F" />
					<stop offset="0.645833" stop-color="#929C9D" />
					<stop offset="1" stop-color="#1FDDFF" />
				</linearGradient>
			</defs>
		</svg>
	)
}
