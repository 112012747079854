import { CircleHelp } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { CallToAction, useAnalytics } from "../../../context/AnalyticsContext"
import { Button } from "../../ui/button"
import Row from "../../ui/layouts/row"

export const GetStartedRow = () => {
	const navigate = useNavigate()
	return (
		<Button
			onClick={() => navigate("/signup")}
			size="full"
			className="justify-between"
		>
			<div className="text-h2 text-white">ARRANCÁ YA</div>
			<svg
				width="54"
				height="51"
				viewBox="0 0 54 51"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M39.538 13.2403C38.6711 13.2084 37.8667 13.6825 37.5125 14.4396C37.1541 15.1966 37.3083 16.0811 37.9084 16.6868L44.4641 23.4602H3.20025C2.42923 23.4483 1.71657 23.8347 1.32898 24.4722C0.937216 25.1058 0.937216 25.8947 1.32898 26.5282C1.71657 27.1657 2.42923 27.5522 3.20025 27.5402H44.4641L37.9084 34.3136C37.3749 34.8475 37.1874 35.6125 37.4125 36.3178C37.6375 37.023 38.2418 37.5569 38.992 37.7163C39.7463 37.8757 40.5299 37.6326 41.0425 37.0868L52.2493 25.5002L41.0425 13.9136C40.6591 13.5072 40.1131 13.2602 39.538 13.2403Z"
					fill="#79EEA2"
				/>
			</svg>
		</Button>
	)
}

export const QuestionRow = (props: any) => {
	const { logButtonClicked } = useAnalytics()
	const navigate = useNavigate()

	const handleFaqClick = () => {
		//mandar evento a analytics
		logButtonClicked({
			action: CallToAction.faq,
			button_clicked_at: new Date().toISOString(),
		})
		navigate("/faq")
	}

	return (
		<Row className="items-center gap-betweenTexts">
			<div className="text-h4 lg:text-h1 text-[#1B2628] font-bold underline lg:no-underline">
				¿TENÉS PREGUNTAS?
			</div>
			<div onClick={handleFaqClick} className="cursor-pointer">
				<CircleHelp className="size-[24px] lg:size-[32px]" />
			</div>
		</Row>
	)
}

// const BackgroundCard = (props: any) => {
// 	const navigate = useNavigate()
// 	const { logButtonClicked } = useAnalytics()

// 	const handleStartClick = () => {
// 		navigate("/signup")
// 		logButtonClicked({
// 			action: CallToAction.start_now,
// 			button_clicked_at: new Date().toISOString(),
// 		})
// 	}
// 	return (
// 		<Card
// 			variant="bordered"
// 			css={{
// 				mw: "1047px",
// 				borderRadius: "12px",
// 				borderColor: "$black",
// 				my: "24px",
// 				backgroundColor: "#F6F6F6",
// 			}}
// 			{...props}
// 		>
// 			<Card.Header>
// 				<Col>
// 					<svg
// 						width="96"
// 						height="22"
// 						viewBox="0 0 96 22"
// 						fill="none"
// 						xmlns="http://www.w3.org/2000/svg"
// 					>
// 						<g clipPath="url(#clip0_18004_2795)">
// 							<path
// 								d="M2.88144 2.94845L9.18032 7.83089C8.82663 5.01428 8.518 2.55906 8.19708 0H13.7961C13.4704 2.59595 13.1631 5.04434 12.8128 7.83704L19.1192 2.93752L21.9727 7.88349L14.5342 10.9945L22 14.0612L19.0974 18.9744L12.8156 14.1472C13.1822 17.0971 13.4759 19.458 13.792 22.0034H8.18138C8.51117 19.3166 8.8041 16.9277 9.15165 14.0899L2.86574 19.0201L0.0204849 14.0612L7.4221 10.974L0 7.87256L2.88144 2.94845Z"
// 								fill="#B5B5B5"
// 							/>
// 						</g>
// 						<g clipPath="url(#clip1_18004_2795)">
// 							<path
// 								d="M39.8814 2.94845L46.1803 7.83089C45.8266 5.01428 45.518 2.55906 45.1971 0H50.7961C50.4704 2.59595 50.1631 5.04434 49.8128 7.83704L56.1192 2.93752L58.9727 7.88349L51.5342 10.9945L59 14.0612L56.0974 18.9744L49.8156 14.1472C50.1822 17.0971 50.4759 19.458 50.792 22.0034H45.1814C45.5112 19.3166 45.8041 16.9277 46.1516 14.0899L39.8657 19.0201L37.0205 14.0612L44.4221 10.974L37 7.87256L39.8814 2.94845Z"
// 								fill="#B5B5B5"
// 							/>
// 						</g>
// 						<g clipPath="url(#clip2_18004_2795)">
// 							<path
// 								d="M76.8814 2.94845L83.1803 7.83089C82.8266 5.01428 82.518 2.55906 82.1971 0H87.7961C87.4704 2.59595 87.1631 5.04434 86.8128 7.83704L93.1192 2.93752L95.9727 7.88349L88.5342 10.9945L96 14.0612L93.0974 18.9744L86.8156 14.1472C87.1822 17.0971 87.4759 19.458 87.792 22.0034H82.1814C82.5112 19.3166 82.8041 16.9277 83.1516 14.0899L76.8657 19.0201L74.0205 14.0612L81.4221 10.974L74 7.87256L76.8814 2.94845Z"
// 								fill="#B5B5B5"
// 							/>
// 						</g>
// 						<defs>
// 							<clipPath id="clip0_18004_2795">
// 								<rect
// 									width="22"
// 									height="22"
// 									fill="white"
// 									transform="matrix(-1 0 0 1 22 0)"
// 								/>
// 							</clipPath>
// 							<clipPath id="clip1_18004_2795">
// 								<rect
// 									width="22"
// 									height="22"
// 									fill="white"
// 									transform="matrix(-1 0 0 1 59 0)"
// 								/>
// 							</clipPath>
// 							<clipPath id="clip2_18004_2795">
// 								<rect
// 									width="22"
// 									height="22"
// 									fill="white"
// 									transform="matrix(-1 0 0 1 96 0)"
// 								/>
// 							</clipPath>
// 						</defs>
// 					</svg>
// 					<Spacer y={1}></Spacer>
// 					<Row css={{ ml: "10%", width: "100%" }}>
// 						<Text b color="#1B2628" size="36px">
// 							ES FACIL Y SIN COSAS RARAS
// 						</Text>
// 					</Row>
// 				</Col>
// 			</Card.Header>
// 			<Card.Divider
// 				align="center"
// 				style={{
// 					borderBottom: "1px solid #CECECE",
// 					width: "80%",
// 					marginLeft: "10%",
// 				}}
// 			/>
// 			<Card.Body css={{ py: "$10", pl: "10%" }}>
// 				<Text
// 					weight="medium"
// 					size="25px"
// 					style={{ lineHeight: "112%" }}
// 					color="#494949"
// 				>
// 					El pago por el servicio se realiza durante el uso. Por{" "}
// 					<strong>cada pago realizado</strong> por la integración se cobrará un{" "}
// 					<strong>1% de comisión</strong>.
// 				</Text>
// 				<Spacer y={1.5}></Spacer>
// 				<Row align="center">
// 					<QuestionRow></QuestionRow>
// 				</Row>
// 			</Card.Body>
// 			<Card.Footer
// 				onClick={handleStartClick}
// 				style={{
// 					cursor: "pointer",
// 				}}
// 				css={{
// 					backgroundColor: "$black",
// 					borderRadius: "0px 12px 12px 0px",
// 					pl: "8%",
// 				}}
// 			>
// 				<Row css={{ pl: "24px" }}>
// 					<GetStartedRow></GetStartedRow>
// 				</Row>
// 			</Card.Footer>
// 		</Card>
// 	)
// }

// export default BackgroundCard
