import { ReactNode } from "react"
import { cn } from "../../../../../lib/utils"
import Col from "../../column"

const CenteredTableBody = ({
	children,
	className,
}: {
	children: ReactNode | ReactNode[]
	className?: string
}) => {
	return (
		<Col
			className={cn(
				"w-full md:max-w-[90%] mx-auto flex flex-col gap-betweenSections",
				className
			)}
		>
			{children}
		</Col>
	)
}

export default CenteredTableBody
