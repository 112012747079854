import BodyItem from "./BodyItem"

const DateInfo = ({ date, time, dateAlternativeTitle, timeAlternativeTitle }: { date: string; time: string, dateAlternativeTitle? : string, timeAlternativeTitle? : string }) => {
	return (
		<>
			{date && <BodyItem name={dateAlternativeTitle ? dateAlternativeTitle : "Fecha"} value={date} />}
			{time && <BodyItem name={timeAlternativeTitle ? timeAlternativeTitle : "Hora"} value={time} />}
		</>
	)
}

export default DateInfo
