import { Clock, Globe, Instagram } from "lucide-react"
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar"
import { Card, CardContent, CardHeader } from "../../ui/card"
import { Separator } from "../../ui/separator"
import MerchantInfoRow from "./MerchantInfoRow"
import { MerchantProps } from "./MerchantRow"
import MerchantTypeIndicator from "./MerchantTypeIndicator"

const MerchantCard = (props: MerchantProps) => {
	return (
		<Card className="rounded-sm w-full max-w-[407px] ">
			<CardHeader className="p-0 rounded-t-md">
				<img
					src={props.cover_url}
					alt={props.name}
					className="w-full object-cover aspect-[407/305.25] rounded-t-sm"
				/>
			</CardHeader>
			<CardContent className="h-80">
				<div className="flex flex-col w-full">
					<div className="flex flex-row w-full items-center py-4">
						<Avatar className="size-[55px] mr-betweenChips">
							<AvatarImage src={props.logo_url} />
							<AvatarFallback className="bg-background-elevated">
								<Clock className="text-icon-primary size-s18 m-betweenChips" />
							</AvatarFallback>
						</Avatar>
						<div className="flex flex-col gap-1 w-full pl-2 overflow-hidden">
							<p className="text-h6 font-normal text-text-muted truncate">
								{props.description}
							</p>
							<p className="text-h4  font-bold text-text">{props.name}</p>
						</div>
					</div>
					<Separator className=" bg-border" />
					<MerchantInfoRow
						property="Tipo"
						value={<MerchantTypeIndicator type={props.type} />}
					/>
					<Separator className=" bg-border" />
					<MerchantInfoRow
						property="Redes"
						value={
							<div className="flex flex-row w-full">
								{props.web_url && (
									<a
										href={props.web_url}
										target="_blank"
										rel="noreferrer"
										className="flex justify-center"
									>
										<Globe className="text-[#213C33] size-[30px]" />
									</a>
								)}
								{props.web_url && props.ig_url && (
									<div style={{ width: "16px" }}></div>
								)}
								{props.ig_url && (
									<a
										href={props.ig_url}
										target="_blank"
										rel="noreferrer"
										className="flex justify-center"
									>
										<Instagram className="text-[#213C33] size-[30px]" />
									</a>
								)}
							</div>
						}
					/>
					<Separator className=" bg-border" />
					<MerchantInfoRow
						property="Ubicación"
						value={<p className="text-h6 leading-5">{props.address}</p>}
					/>
				</div>
			</CardContent>
		</Card>
	)
}

export default MerchantCard
