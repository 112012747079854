import { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import ForgotPasswordEmailStep from "./ForgotPasswordEmailStep"
import ForgotPasswordConfirmationStep from "./ForgotPasswordConfirmationStep"

enum ForgotPasswordSteps {
	EMAIL = "EMAIL",
	CONFIRMATION = "CONFIRMATION",
}

const ForgotPasswordBox = () => {
	const { resetPassword, getErrorMessage } = useAuth()
	const [email, setEmail] = useState<string>("")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>("")
	const [step, setStep] = useState<ForgotPasswordSteps>(
		ForgotPasswordSteps.EMAIL
	)
	const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setErrorMessage("")
		setIsLoading(true)
		try {
			const res = await resetPassword(email)
			console.log(res)
			setStep(ForgotPasswordSteps.CONFIRMATION)
		} catch (err: any) {
			setErrorMessage(getErrorMessage(err.code))
		}
		setIsLoading(false)
	}
	const stepComponents = {
		[ForgotPasswordSteps.EMAIL]: (
			<ForgotPasswordEmailStep
				handleSubmit={handleForgotPassword}
				isLoading={isLoading}
				onEmailChange={setEmail}
				errorMessage={errorMessage}
			/>
		),
		[ForgotPasswordSteps.CONFIRMATION]: <ForgotPasswordConfirmationStep />,
	}
	return stepComponents[step]
}

export default ForgotPasswordBox
