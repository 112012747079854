import { TextColumnCell } from "../TextColumnCell"
import { TxTypeCell } from "./TxTypeCell"

export interface TableTx {
	id: string
	amount: string
	creation_date: string
	type: "INTERNAL_GAS" | "INBOUND" | "OUTBOUND"
	user_id: string
	currency: string
}

const idCell = TextColumnCell<TableTx>("id")
const userIdCell = TextColumnCell<TableTx>("user_id")
const amountCell = TextColumnCell<TableTx>("amount")
const creationDateCell = TextColumnCell<TableTx>("creation_date")
const txTypeCell = TxTypeCell()

export const columns = {
	xl: [txTypeCell, idCell, userIdCell, amountCell, creationDateCell],
	lg: [txTypeCell, idCell, userIdCell, amountCell, creationDateCell],
	md: [txTypeCell, idCell, amountCell],
	sm: [txTypeCell, idCell, amountCell],
}
