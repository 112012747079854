import { ReactNode } from "react"
import { useAuth } from "../../../../context/AuthContext"
import { landingItems } from "../../../../pages/home/items"
import LandingMenuSheet from "../../../modals/landing-menu/LandingMenuSheet"
import TaloSmallGreenLogo from "../../logos/TaloSmallGreenLogo"
import { Heading3 } from "../../texts/TextStyle"
import { mobilePlatformHeader } from "../layout-sizes"
import LandingHeader from "./LandingHeader"
import NavbarButton from "./NavbarButton"

const LandingLayout = ({
	children,
	withNav,
	centerComponent,
	rightComponent,
	logoColor,
}: {
	children: ReactNode | ReactNode[]
	withNav?: boolean
	centerComponent?: ReactNode
	rightComponent?: ReactNode
	logoColor?: string
}) => {
	const { user } = useAuth()
	return (
		<div className="w-full h-screen">
			<LandingHeader
				logoColor={logoColor}
				className="hidden md:flex md:flex-row z-40"
				navItems={
					withNav ? landingItems.filter((item) => item.showNavbar) : undefined
				}
				center={centerComponent}
				right={
					rightComponent ? (
						rightComponent
					) : user ? (
						<NavbarButton label="Ir al tablero" link="/" />
					) : (
						<NavbarButton label="Iniciar sesión" link="/login" />
					)
				}
			/>
			<header
				className={`flex ${mobilePlatformHeader.simpleHeight.class} items-center justify-between px-screenMobile py-betweenComponents md:hidden`}
			>
				{withNav ? (
					<LandingMenuSheet
						navItems={landingItems.filter((item) => item.showNavbar)}
					/>
				) : (
					<span className="basis-1/4 justify-start">
						<TaloSmallGreenLogo />
					</span>
				)}
				<Heading3 className="font-semibold basis-1/2 text-center">
					{centerComponent}
				</Heading3>
				<div className="flex basis-1/4 items-center justify-end">
					{rightComponent ? (
						rightComponent
					) : user ? (
						<NavbarButton label="Ir al tablero" link="/" variant="default" />
					) : (
						<NavbarButton
							label="Iniciar sesión"
							link="/login"
							variant="default"
						/>
					)}
				</div>
			</header>
			<div className="flex items-center justify-center py-12  w-full">
				{children}
			</div>
		</div>
	)
}

export default LandingLayout
