import { Heading6 } from "lucide-react"
import { Link } from "react-router-dom"
import { Button } from "../ui/button"
import LoadableButton from "../ui/buttons/LoadableButton"
import { Input } from "../ui/inputs/input"
import { Body1 } from "../ui/texts/TextStyle"
import AuthTitle from "../ui/titles/AuthTitle"

const ForgotPasswordEmailStep = ({
	handleSubmit,
	isLoading,
	onEmailChange,
	errorMessage,
}: {
	handleSubmit: (e: any) => void
	isLoading: boolean
	onEmailChange: (email: string) => void
	errorMessage?: string
}) => {
	return (
		<form onSubmit={handleSubmit} className="grow">
			<div className="mx-auto grid w-full lg:min-w-[600px] gap-textToComponent">
				<AuthTitle
					title="Recuperar contraseña"
					subtitle="Ingresa tu correo para recuperar tu contraseña."
				/>

				<div className="grid gap-2 ">
					<div className="flex items-center">
						<Body1
							href="/forgot-password"
							className="ml-auto inline-block text-sm underline"
						>
							Te enviaremos un mail para que puedas recuperar tu contraseña.
						</Body1>
					</div>
					<div className="grid gap-2">
						<Input
							id="email"
							type="email"
							placeholder="m@example.com"
							required
							label="Correo"
							onChange={(e: any) => onEmailChange(e.target.value)}
							// initialValue={data.email}
						/>
						<div className="h-sm">
							{errorMessage && (
								<Heading6 className="text-text-error">{errorMessage}</Heading6>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-row gap-betweenButtons">
					<Button type="button" size="full" variant="outline" asChild>
						<Link to="/login">Regresar</Link>
					</Button>
					<LoadableButton isLoading={isLoading} size="full">
						Enviar{" "}
					</LoadableButton>
				</div>
			</div>
		</form>
	)
}

export default ForgotPasswordEmailStep
