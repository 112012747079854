import { useEffect, useState } from "react"
import { TableQR } from "../../../components/tables/admin-qrs/columns"
import { mapQrToTableQR } from "../../../components/tables/admin-qrs/data"
import QRAdminTable from "../../../components/tables/admin-qrs/table"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { getQrs } from "../../../services/qrs"

interface QRsData {
	items: TableQR[]
	prevCursor?: string
	nextCursor?: string
}
const AdminQRsBody = () => {
	const [qrsData, setQRsData] = useState<QRsData | undefined>()
	// const [modalVisible, setModalVisible] = useState(false)
	// const [newQr, setNewQr] = useState<any>()

	useEffect(() => {
		load()
	}, [])

	// const closeHandler = () => {
	// 	setModalVisible(false)
	// }

	const load = async (prev?: boolean) => {
		const qrsRes = await getQrs({
			limit: 25,
			cursor: prev ? qrsData?.prevCursor : qrsData?.nextCursor,
		})
		console.log(qrsRes)
		setQRsData({
			items: qrsRes.qrs?.map(mapQrToTableQR) || [],
			prevCursor: qrsData?.items ? qrsData?.items[-1]?.id : undefined,
			nextCursor: qrsRes.cursor.id,
		})
	}
	return (
		<CenteredTableBody>
			<SectionHeader title="QRs" subtitle="Historial de QRs generados" />
			<QRAdminTable
				qrs={qrsData?.items || []}
				paginationHandler={{
					nextPage: () => load(),
					prevPage: () => load(true),
				}}
			/>
		</CenteredTableBody>
	)
}

export default AdminQRsBody
