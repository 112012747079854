import { Plus } from "lucide-react"
import Col from "../../ui/layouts/column"
import { Option } from "../../ui/options/option"
import { SectionHeader } from "../../ui/section-header"

const WelcomeTeams = ({ openModal }: { openModal: () => void }) => {
	return (
		<Col className="gap-belowSectionHeader">
			<div className="mx-auto flex flex-col w-full  max-w-[696px] justify-center items-center gap-belowSectionHeader">
				<img
					className="w-5/6 flex items-center justify-center"
					src="/illustrations/teams.svg"
					alt=""
				/>

				<SectionHeader
					title="Bienvenido a equipos"
					subtitle="Desde acá podés agregar nueva gente a tu equipo, compartí el acceso de funciones, a empleados y a sucursales."
				></SectionHeader>
			</div>
			<div className="grid grid-flow-col">
				<Option
					title="Agregar un nuevo integrante"
					icon={<Plus />}
					leading="arrow"
					onClick={openModal}
				/>
			</div>
		</Col>
	)
}

export default WelcomeTeams
