export const formatCurrency = (amount: number, currency: string) => {
	switch (currency) {
		case "nuARS":
		case "ARS":
			return amount.toFixed(2)
		default:
			return amount
	}
}

export const formatDate = (date: Date) => {
	//if date is today
	if (date.toDateString() === new Date().toDateString()) {
		return "Hoy"
	}
	//if date is yesterday
	// if (
	// 	date.toDateString() ===
	// 	new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
	// ) {
	// 	return "Ayer"
	// }
	return date.toLocaleDateString("es-AR", {
		day: "2-digit",
		month: "2-digit",
		year: "2-digit",
	})
}
