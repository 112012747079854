import PlatformHeader from "./PlatformHeader"
import PlatformBody from "./PlatformBody"
import { PlatformProps } from "./platform-types"

const PlatformLayout = ({
	children,
	title,
	activeTab,
	navbarItems,
}: PlatformProps) => {
	return (
		<div className="h-[100vh] overscroll-none overflow-hidden">
			<PlatformHeader title={title} />
			<PlatformBody activeTab={activeTab} navbarItems={navbarItems}>
				{children}
			</PlatformBody>
		</div>
	)
}

export default PlatformLayout
