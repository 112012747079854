import { SignupBox } from "../../components/auth/SignupBox"
import AuthLayout from "../../components/ui/layouts/landing/AuthLayout"
import { buttonVariants } from "../../components/ui/button"
import { Link } from "react-router-dom"

const Signup = () => {
	return (
		<AuthLayout
			leftImageSrc="/home/login/placeholder.png"
			rightNavbarContent={
				<Link to="/login" className={buttonVariants({ variant: "ghost" })}>
					Iniciar sesión
				</Link>
			}
		>
			<SignupBox />
		</AuthLayout>
	)
}

export default Signup
