import { TriangleAlert } from "lucide-react"
import { useState } from "react"
import { Price } from "../../../components/tables/transactions-table/columns"
import { Alert } from "../../../components/ui/alert"
import LoadableButton from "../../../components/ui/buttons/LoadableButton"
import AmountInput from "../../../components/ui/inputs/AmountInput"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import OptionCard from "../../../components/ui/options/option-card"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import Spacer from "../../../components/ui/spacer"
import { useAuth } from "../../../context/AuthContext"
import { updateUser } from "../../../services/users"

const selectedProfileClassName = "outline outline-2 outline-onSecondary"
const notSelectedProfileClassName =
	"group/slide  hover:bg-background-elevated hover:outline hover:outline-1 hover:outline-border"

const brl_rates = [
	{ title: "Cotización en tiempo real (recomendado)", id: "automatic" },
	{ title: "Cotización fija. 1 BRL = ", id: "custom" },
]

const BrlRateBody = () => {
	const { user, updateUser: updateUserStore } = useAuth()
	const [selectedRate, setSelectedRate] = useState(
		user?.brl_rate && user?.brl_rate !== "automatic" ? "custom" : "automatic"
	)
	const [brlRate, setBrlRate] = useState(user?.brl_rate)
	const [loading, setLoading] = useState(false)

	const onSaveRate = async () => {
		setLoading(true)
		await updateUser(user?.id, {
			brl_rate: selectedRate === "automatic" ? "automatic" : brlRate,
		})
		updateUserStore({
			...user,
			brl_rate: selectedRate === "automatic" ? "automatic" : brlRate,
		})
		setLoading(false)
	}

	return (
		<CenteredBody>
			<SectionHeader
				title="Elegí tu cotización"
				subtitle="Estás por cambiar la taza de cambio que se genera a la hora de vender un producto con PIX."
			></SectionHeader>
			<Section
				title="Real Brasileño"
				footer="Los cambios se verán reflejados en tus próximas transacciones. "
			>
				{selectedRate === "custom" && (
					<Alert
						icon={<TriangleAlert className="text-icon-error size-icon" />}
						title="Cuidado"
						description="Al configurar una cotización fija para BRL se verán modificadas las cotizaciones de criptodolares en base a la nueva cotización. Además esta taza se aplicará para pagos cuyo precio esté indicado en ARS."
					/>
				)}
				<div className="flex flex-row justify-between gap-betweenOptions border border-border rounded-md p-xs">
					{brl_rates.map((elem: { title: string; id: string }, index) => {
						const isSelected = selectedRate === elem.id
						return (
							<OptionCard
								key={"pricing_profile_" + elem.id}
								header={elem.title}
								footer={
									elem.id === "custom" ? (
										<AmountInput
											onAmountChange={(newPrice: Price) =>
												setBrlRate(newPrice.amount)
											}
											currencies={["ARS"]}
											value={
												brlRate && brlRate !== "automatic"
													? {
															amount: brlRate,
															currency: "ARS",
													  }
													: undefined
											}
										/>
									) : (
										<></>
									)
								}
								className={
									isSelected
										? selectedProfileClassName
										: notSelectedProfileClassName
								}
								onClick={() => setSelectedRate(elem.id)}
							/>
						)
					})}
				</div>
				<Spacer value="200px" axis="vertical" />
				<LoadableButton
					onClick={onSaveRate}
					isLoading={loading}
					disabled={selectedRate === "custom" && !validRate(brlRate)}
				>
					Guardar Cambios
				</LoadableButton>
			</Section>
		</CenteredBody>
	)
}

function validRate(rate: string | undefined) {
	if (!rate) return false
	const rateNumber = parseFloat(rate)
	return rateNumber > 0
}

export default BrlRateBody
