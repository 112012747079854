import { isTransferOrCrypto } from "../../../lib/utils"
import {
	Price,
	TableTransaction,
} from "../../tables/transactions-table/columns"
import SlidingCard from "../../ui/sliding-card"
import OutboundTransactionModalBody from "./body/OutboundTransactionModalBody"
import PendingPaymentModalBody from "./body/PendingPaymentModalBody"
import SuccessPaymentModalBody from "./body/SuccessPaymentModalBody"
import PaymentStatusRow from "./header/PaymentStatusRow"

export interface PaymentComission {
	comission?: Price
	comissionInRequestedCurrency?: Price
	percent?: number
}

interface PaymentModalProps {
	open: boolean
	onClose: () => void
	payment: TableTransaction
}
const PaymentModal = ({ open, onClose, payment }: PaymentModalProps) => {
	return (
		<SlidingCard
			// header={
			// 	<SectionTitle
			// 		title="Transacción"
			// 		subtitle="Accedé a la información más detallada"
			// 	/>
			// }
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
			side={"right"}
		>
			<div>
				<PaymentStatusRow status={payment.status} />
				{renderModalBody(payment)}
			</div>
		</SlidingCard>
	)
}

function renderModalBody(payment: TableTransaction) {
	switch (payment.status) {
		case "SUCCESS":
		case "OVERPAID":
		case "UNDERPAID":
			return <SuccessPaymentModalBody payment={payment} />
		case "pending":
		case "processed":
		case "SENT":
		case "PROCESSED":
			return (
				<OutboundTransactionModalBody
					payment={payment}
					//esta no tiene payment_options porque es outbound
					variant={payment.currency === "ARS" ? "transfer" : "crypto"}
				/>
			)
		case "PENDING":
			return (
				<PendingPaymentModalBody
					payment={payment}
					variant={isTransferOrCrypto(payment.payment_options)}
				/>
			)
	}
}

export default PaymentModal
