import { ReactNode } from "react"
import { cn } from "../../../lib/utils"

const Col = ({
	className,
	children,
	style,
}: {
	className?: string
	style?: React.CSSProperties
	children: ReactNode | ReactNode[]
}) => {
	return (
		<div className={cn("flex flex-col", className)} style={style}>
			{children}
		</div>
	)
}

export default Col
