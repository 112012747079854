import { Info } from "lucide-react"
import { WithdrawData } from "../../../pages/withdraw/withdraw-wizard-types"
import { Alert } from "../../ui/alert"

import Row from "../../ui/layouts/row"
import { SectionHeader } from "../../ui/section-header"
import { Separator } from "../../ui/separator"
import {
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "../../ui/sheet"
import { Heading1, Heading2, Heading6 } from "../../ui/texts/TextStyle"
import BodyItem from "../payment-modal/body/BodyItem"
import { formatAmountAndCurrency } from "./WithdrawConfirmationModal"
import { Button } from "../../ui/button"
import { findDisplayNameForBankInJson } from "../../../lib/utils"
import bank_list from "../../../lib/psp_bank_id.json"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import Col from "../../ui/layouts/column"
import { Section } from "../../ui/section"

interface CvuSheetContentProps {
	data: WithdrawData
	handleConfirm: () => void
}

export default function CvuSheetContent({
	data,
	handleConfirm,
}: CvuSheetContentProps) {
	return (
		<SheetContent side="right">
			<CenteredBody>
				<SectionHeader
					title="Confirmá tu retiro"
					subtitle="Estás por retirar dinero de tu cuenta."
				/>
				<Col className="gap-betweenTexts">
					<Heading6 className="text-text-muted font-medium">Recibirás</Heading6>
					<Row className="gap-betweenTexts">
						<img
							src={
								data.fiatAddressDetails?.icon_img
									? data.fiatAddressDetails?.icon_img
									: `/currencies/${data?.currency?.toLowerCase()}-icon.svg`
							}
							alt=""
						/>
						<Heading1 className="font-normal text-text">
							{formatAmountAndCurrency(
								data.amount?.amount as string,
								data.amount?.currency!,
								false
							)}
						</Heading1>
					</Row>
				</Col>

				<Separator orientation="horizontal" className="bg-border" />
				<Section>
					<BodyItem name="CVU" value={data.address ?? ""} overflow />
					<BodyItem
						name="Banco"
						value={
							data.fiatAddressDetails?.bank_id
								? findDisplayNameForBankInJson(
										data.fiatAddressDetails?.bank_id,
										bank_list
								  )
								: data.fiatAddressDetails?.psp_id
								? findDisplayNameForBankInJson(
										data.fiatAddressDetails?.psp_id,
										bank_list
								  )
								: ""
						}
					/>
					<BodyItem
						name="Monto total"
						value={formatAmountAndCurrency(
							data.amount?.amount as string,
							data?.currency as string,
							false
						)}
					/>
				</Section>
				<Alert
					icon={<Info />}
					title="Cuidado"
					variant="warning"
					description="Asegurate de que el monto y el destino sean correctos."
				/>
				<Button size="full" onClick={handleConfirm}>
					Confirmar
				</Button>
			</CenteredBody>
		</SheetContent>
	)
}
