import { getMaxRole } from "../../../lib/roles"
import { formatDate } from "../../../utils/format"

export function mapUserToTableUser(user: any) {
	const date = new Date(user.creation_timestamp)
	return {
		email: user.email,
		role: getMaxRole(user.roles ?? user.talo_role_list ?? []),
		user_id: user.user_id,
		team_id: user.team_id,
		status: user.email_verified ? "VERIFIED" : "PENDING",
		creation_date: formatDate(date),
	}
}
