import { useNavigate } from "react-router-dom"
import TaloGreenLogo from "../../logos/TaloGreenLogo"
import UserHeader from "../../menus/user-nav/UserHeader"
import { Heading3 } from "../../texts/TextStyle"
import { platformHeader } from "../layout-sizes"
import { PlatformHeaderProps } from "./platform-types"
import { ArrowLeft } from "lucide-react"

const PlatformHeader = ({
	title,
	variant = "primary",
	onBackClick,
	backButton = true,
}: PlatformHeaderProps) => {
	const navigate = useNavigate()

	if (variant === "secondary") {
		return (
			<div
				className={`flex ${platformHeader.height.class} w-full ${platformHeader.paddingY.class} ${platformHeader.paddingX.class} items-center`}
			>
				<div className="basis-1/4 h-8 justify-start">
					{backButton === true && (
						<div
							className="flex p-1 items-center"
							onClick={() => {
								if (onBackClick) {
									onBackClick()
								} else {
									navigate(-1)
								}
							}}
						>
							<ArrowLeft />
						</div>
					)}
				</div>
				<Heading3 className="font-semibold basis-1/2 text-center">
					{title}
				</Heading3>
			</div>
		)
	}

	return (
		<div
			className={`flex ${platformHeader.height.class} w-full ${platformHeader.paddingY.class} ${platformHeader.paddingX.class} items-center`}
		>
			<div
				className={`${platformHeader.sidePanelWidth.class} cursor-pointer`}
				onClick={() => navigate("/")}
			>
				<TaloGreenLogo />
			</div>
			<div className={platformHeader.titleWidth.class + " flex justify-center"}>
				<Heading3>{title}</Heading3>
			</div>
			<div
				className={platformHeader.sidePanelWidth.class + " flex justify-end"}
			>
				<UserHeader />
			</div>
		</div>
	)
}

export default PlatformHeader
