import { Link } from "react-router-dom"
import { Heading6 } from "../../texts/TextStyle"
import { HoverBorderGradient } from "../../hover-border-gradient"

export interface SideBarMenuItemProps {
	itemName: string
	itemLink: string
	status?: "active" | "inactive"
}

const statusClasses = {
	active: "text-text-navbar-active",
	inactive: "text-text-navbar",
}

const SideBarMenuItem = (item: SideBarMenuItemProps) => {
	return (
		<HoverBorderGradient
			disableHover={item.status === "active"}
			className="bg-background-navbar rounded-md"
			containerClassName={`rounded-[18px] p-[2px] ${
				item.status === "active"
					? "bg-background-navbar hover:bg-background-navbar"
					: "bg-border-navbar-item hover:bg-border-navbar-item"
			}`}
		>
			<Link to={item.itemLink}>
				<div
					className={
						"w-[115px] h-[58px] rounded-md p-sm flex items-center justify-center " +
						statusClasses[item.status || "inactive"]
					}
				>
					<Heading6 className="font-medium hover:scale-[1.05] transition-colors duration-600">
						{item.itemName}
					</Heading6>
				</div>
			</Link>
		</HoverBorderGradient>
	)
}

export default SideBarMenuItem
