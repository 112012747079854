import { Link } from "react-router-dom"
import { buttonVariants } from "../../button"

const NavbarButton = ({
	label,
	link,
	variant = "ghost",
}: {
	label: string
	link: string
	variant?: "ghost" | "default" | "secondary" | "outline"
}) => {
	return (
		<Link to={link} className={buttonVariants({ variant: variant })}>
			{label}
		</Link>
	)
}

export default NavbarButton
