import axios from "axios"
import logger from "../utils/logger"

export const apiUrl = process.env.REACT_APP_API_URL
logger.info("APIURL ", apiUrl)

let apiPublic = axios.create({ baseURL: apiUrl })

let apiPrivate = axios.create({
	baseURL: apiUrl,
	headers: { "Content-Type": "application/json" },
})

const updateToken = (token: string) => {
	window.localStorage.setItem("token", token)
	const bearerToken = token && token.length > 0 ? `Bearer ${token}` : ""
	apiPrivate.defaults.headers.common["authorization"] = bearerToken
}

const customAuthHeader = () => {
	return { authorization: apiPrivate.defaults.headers.common["authorization"] } // eslint-disable-line
}

function removeInterceptors(interceptorsArray: any[]) {
	interceptorsArray.forEach((i) => {
		axios.interceptors.request.eject(i)
	})
}
let _expirationTime: any
const setInterceptor = (
	firebaseUser: any,
	expirationTime: any,
	getNewTokenResult: any
) => {
	_expirationTime = expirationTime
	return apiPrivate.interceptors.request.use(
		async (config) => {
			if (isTokenExpired(_expirationTime)) {
				const tokenResult = await getNewTokenResult()
				logger.info(`GETTING NEW TOKEN=${tokenResult}`)
				logger.info(`NEW EXPIRATION TIME IS ${tokenResult.expirationTime}`)
				_expirationTime = tokenResult.expirationTime
				updateToken("FB-" + tokenResult.token)
				return config
			} else {
				logger.info("token is still valid")
			}
			return config
		},
		() => {
			logger.info("INTERCEPTOR FAILURE")
		}
	)
}

// axios.interceptors.response.use(
// 	(response: any) => {
// 		return response
// 	},
// 	async (error: any) => {
// 		if (error.response) {
// 			const { status, data } = error.response
// 			switch (status) {
// 				case 401:
// 					try {
// 						logger.info("Attempting token refresh")
// 						const { firebaseUser } = useAuth()
// 						const tokenResult = await firebaseUser.getIdTokenResult()

// 						if (isTokenExpired(tokenResult.expirationTime)) {
// 							// attempting to refresh token;
// 							const idToken = await firebaseUser.getIdToken()
// 							logger.info(idToken)
// 							updateToken("FB-" + idToken)
// 							// token refreshed, reattempting request;
// 							const config = error.config
// 							// configure new request in a new instance;
// 							return await axios({
// 								method: config.method,
// 								url: config.url,
// 								data: config.data,
// 							})
// 						} else {
// 							const navigate = useNavigate()
// 							navigate("/login")
// 						}
// 					} catch (e) {
// 						logger.error("Auth interceptor: Error refreshing token", e)
// 						const navigate = useNavigate()
// 						navigate("/login")
// 					}
// 			}
// 		}
// 	}
// )

const isTokenExpired = (expTime: string) => {
	const now = new Date()
	const exp = new Date(expTime)
	return now.getTime() > exp.getTime()
}

export {
	apiPrivate,
	apiPublic,
	customAuthHeader,
	removeInterceptors,
	setInterceptor,
	updateToken,
}
