import { ColumnDef, Row } from "@tanstack/react-table"
import { PublicRole, publicRoleIcons } from "../../../lib/roles"
import { TableUser } from "./columns"

export function UserRoleCell(): ColumnDef<TableUser> {
	return {
		accessorKey: "role",
		header: "Rol",
		cell: ({ row }) => {
			const rol = row.getValue("role") as PublicRole
			return publicRoleIcons({ role: rol })
		},
		filterFn: (row: Row<TableUser>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
	}
}
