import { Route } from "react-router-dom"
import ReferralPage from "./Referral"

export const referralItems = [
	<Route key="/agustormakh" path="/agustormakh" element={<ReferralPage />} />,
	<Route
		key="/camilaborinsky"
		path="/camilaborinsky"
		element={<ReferralPage />}
	/>,
	<Route
		key="/bautitravella"
		path="/bautitravella"
		element={<ReferralPage />}
	/>,
]
