import { ColumnDef, Row } from "@tanstack/react-table"
import { Body1 } from "../ui/texts/TextStyle"

export function TextColumnCell<T>(accessorKey: string): ColumnDef<T> {
	return {
		accessorKey: accessorKey,
		header: accessorKey,
		cell: ({ row }: { row: Row<T> }) => {
			return <Body1>{row.getValue(accessorKey)}</Body1>
		},
	}
}
