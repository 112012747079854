import { ColumnDef } from "@tanstack/react-table"
import {
	CredentialStatus,
	TableCredential,
	translatedCredentialsStatus,
} from "../columns"
import { Circle, CircleX } from "lucide-react"
import Row from "../../../ui/layouts/row"
import { Heading6 } from "../../../ui/texts/TextStyle"

export function CredentialStatusCell(): ColumnDef<TableCredential> {
	return {
		accessorKey: "status",
		header: "Estado",
		cell: ({ row }) => {
			const status = row.getValue("status") as CredentialStatus
			var icon
			switch (status) {
				case CredentialStatus.ACTIVE: {
					icon = <Circle className="text-icon-success size-sm" />
					break
				}
				case CredentialStatus.DELETED: {
					icon = <CircleX className="text-icon-danger size-sm" />
					break
				}
			}
			return (
				<Row className="gap-betweenChips items-center">
					{icon}
					<Heading6 className="font-normal">
						{translatedCredentialsStatus[status]}
					</Heading6>
				</Row>
			)
		},
	}
}
