import { Home, WalletMinimal } from "lucide-react"
import MobileDashboardTabs from "../../components/app/dashboard/MobileDashboardTabs"
import WelcomeQRCrypto from "../../components/app/qrs/WelcomeQRCrypto"
import { PrivateRole, PublicRole } from "../../lib/roles"
import DashboardBody from "./DashboardBody"
import MobileDashboardBody from "./MobileDashboardBody"
import TransactionsBody from "./payments/TransactionsBody"
import QRCryptoBody from "./qrs/QRCryptoBody"
import TeamsBody from "./teams/TeamsBody"
import WalletBody from "./wallet/WalletBody"

export type MenuItem = {
	itemName: string
	itemLink: string
	minimumPublicRole?: PublicRole
	minimumPrivateRole?: PrivateRole
	bodyComponent: React.ReactNode | React.ReactNode[]
	smBodyComponent: React.ReactNode | React.ReactNode[]
	smHeaderContent?: React.ReactNode
	icon?: React.ReactNode
	activeTab: string
	subItems?: MenuItem[]
	showInNavbar?: boolean
}

export const publicMenuItems: MenuItem[] = [
	{
		itemName: "Tablero",
		itemLink: "/",
		minimumPublicRole: PublicRole.POS,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <DashboardBody />,
		smBodyComponent: <MobileDashboardBody />,
		smHeaderContent: <MobileDashboardTabs />,
		icon: <Home />,
		activeTab: "Tablero",
	},
	{
		itemName: "Pagos",
		itemLink: "/payments",
		minimumPublicRole: PublicRole.POS,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <TransactionsBody />,
		smBodyComponent: <TransactionsBody />,
		activeTab: "Pagos",
	},

	{
		itemName: "Billetera",
		itemLink: "/wallet",
		minimumPublicRole: PublicRole.POS,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <WalletBody />,
		smBodyComponent: <WalletBody />,
		smHeaderContent: <MobileDashboardTabs />,
		icon: <WalletMinimal />,
		activeTab: "Billetera",
	},
	{
		itemName: "QR Cripto",
		itemLink: "/qrs",
		minimumPublicRole: PublicRole.POS,
		bodyComponent: <QRCryptoBody />,
		smBodyComponent: <QRCryptoBody />,
		showInNavbar: false,
		activeTab: "QR Cripto",
		subItems: [
			{
				itemName: "QR",
				itemLink: "/qrs/new",
				minimumPublicRole: PublicRole.POS,
				bodyComponent: <WelcomeQRCrypto />,
				smBodyComponent: <WelcomeQRCrypto />,
				activeTab: "QR Cripto",
			},
		],
	},

	{
		itemName: "Equipos",
		itemLink: "/teams",
		minimumPublicRole: PublicRole.OWNER,
		bodyComponent: <TeamsBody />,
		smBodyComponent: <TeamsBody />,
		activeTab: "Equipos",
	},
]
