const TaloSmallGreenLogo = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			width="31"
			height="31"
			viewBox="0 0 31 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.5859 12.9827L27.6028 6.00302L24.4734 2.89021L17.4565 9.86986V0H13.0312V11.114C13.0312 11.8956 13.1804 12.6704 13.4666 13.3951C12.738 13.1104 11.9591 12.962 11.1733 12.962H0V17.3637H9.92258L2.90565 24.3434L6.03508 27.4562L13.052 20.4765V30.3464H17.4773V19.2324C17.4773 18.4508 17.3281 17.676 17.0419 16.9513C17.7704 17.236 18.5493 17.3844 19.3352 17.3844H30.5085V12.9827H20.5859Z"
				fill="#71E150"
			/>
		</svg>
	)
}

export default TaloSmallGreenLogo
