import { CurrencyStyle, currencies } from "../../../utils/currencies"
import CurrencyOption from "../../ui/options/CurrencyOption"
import { SectionHeader } from "../../ui/section-header"
import { Section } from "../../ui/section"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"

export type ChargeCurrencyProps = {
	handleSelectCurrency?: (currency: string) => any
	chargeCurrencies: string[]
	style?: any
	isLoading?: boolean
	step?: number
}

const ChargeCurrency = (props: ChargeCurrencyProps) => {
	const { chargeCurrencies, handleSelectCurrency } = props

	const selectedCurrencies = Object.entries(currencies).filter(
		([k, v]: [k: string, v: CurrencyStyle]) =>
			chargeCurrencies.find((elem) => elem === k)
	)
	console.log("CURRENCIES===", selectedCurrencies)

	const handleOnCurrencyClick = (currency: string) => {
		if (handleSelectCurrency) {
			handleSelectCurrency(currency)
		}
	}

	return (
		// openChosseCoin.Modal Request#004
		<CenteredBody>
			<SectionHeader
				title="Moneda"
				subtitle="Seleccioná la moneda con la que quieras realizar el pago."
			/>
			{/* Todo lo siguiente deberia ir dentro del ChosseCoin.Modal Request#004 */}

			<Section>
				{selectedCurrencies.map(
					([k, v]: [k: string, v: CurrencyStyle], index) => (
						<div key={`payment-currency-${index}-${k}`} className="col-span-1">
							<CurrencyOption
								currency={v.alias}
								logoPath={v.logoPath}
								name={v.name}
								onClick={() => handleOnCurrencyClick(k)}
							/>
						</div>
					)
				)}
			</Section>
		</CenteredBody>
	)
}

export default ChargeCurrency
