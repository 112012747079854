import { Loader2 } from "lucide-react"
import { useState } from "react"
import { useAnalytics } from "../../context/AnalyticsContext"
import { useAuth } from "../../context/AuthContext"
import { useGlobalStore } from "../../hooks/useGlobalStore"
import { updateToken } from "../../services/interceptors"
import {
	AuthProvider,
	createUser,
	deleteFirebaseUser,
} from "../../services/users"
import logger from "../../utils/logger"
import { Button } from "../ui/button"
import { GoogleIcon } from "../ui/logos/GoogleIcon"

const GoogleSigninButton = ({
	handleSuccess,
	handleError,
	loading = false,
}: {
	handleSuccess?: (user_claims: any) => void
	handleError?: (error: string) => void
	loading?: boolean
}) => {
	const [isLoading, setIsLoading] = useState(loading)
	const { signInWithGoogle, getErrorMessage } = useAuth()
	const { globalStore } = useGlobalStore()
	const { logSignUp, logLogin } = useAnalytics()

	const handleLoginWithGoogle = async (e: any) => {
		try {
			setIsLoading(true)
			const response = await signInWithGoogle()
			logger.info("Firebase response: ", response)
			const idToken = await response.user.getIdToken()
			updateToken(`FB-${idToken}`)
			// Check user claims
			const tokenResult = await response.user.getIdTokenResult()
			logger.info("tokenResult", tokenResult)
			if (!tokenResult?.claims?.talo_user_id) {
				logger.info("No Firebase claims")
				const taloRes = await createUser({
					firebase_uid: response.user.uid,
					provider: AuthProvider.Google,
					avatar_url: response.user.photoUrl ?? undefined,
					email: response.user.email,
					display_name: response.user.displayName ?? undefined,
					email_verified: response.user.emailVerified ?? false,
					deep_link: globalStore.deepLink,
				})

				logger.info("Talo response: ", taloRes)
				if (taloRes.status < 300 && taloRes.status >= 200) {
					const newIdToken = await response.user.getIdToken(true)
					updateToken(`FB-${newIdToken}`)
					if (handleSuccess) handleSuccess(taloRes.data.data)
					logSignUp({
						sign_up_method: taloRes.data.data.provider,
						user_id: taloRes.data.data.user_id,
						team_id: taloRes.data.data.team_id,
						user_role: taloRes.data.data.roles[0],
						signup_at: taloRes.data.data.creation_timestamp,
					})
				} else {
					if (handleError) handleError(getErrorMessage(taloRes.status))
					await deleteFirebaseUser() //TODO: chequear esto
				}
			} else {
				if (handleSuccess) handleSuccess(tokenResult.claims)
				logLogin({
					user_id: tokenResult?.claims?.talo_user_id,
					team_id: tokenResult?.claims?.talo_team_id,
					login_method: tokenResult.signInProvider,
					login_at: new Date().toISOString(),
				})
			}
		} catch (err: any) {
			logger.error(err)
			if (handleError) handleError(getErrorMessage(err.code))
		} finally {
			setIsLoading(false)
		}
	}
	return (
		<Button
			size="full"
			variant="outline"
			onClick={handleLoginWithGoogle}
			type="button"
		>
			<div className="mr-2">
				{isLoading ? (
					<Loader2 className="mr-2 h-4 w-4 animate-spin" />
				) : (
					<GoogleIcon
						color="background-invers"
						size="24px"
						src="/google.svg"
					></GoogleIcon>
				)}
			</div>
			Continuar con Google
		</Button>
	)
}

export default GoogleSigninButton
