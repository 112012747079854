import { Key } from "lucide-react"
import { Credential } from "../../../modals/new-credential/NewCredentialModal"
import { Button } from "../../../ui/button"
import { Input } from "../../../ui/inputs/input"
import { Option } from "../../../ui/options/option"
import { Section } from "../../../ui/section"
import { SectionHeader } from "../../../ui/section-header"

const CredentialsStep = ({
	handleDownloadCredentials,
	handleFinish,
	credential,
	isLoading,
}: {
	handleDownloadCredentials: () => void
	handleFinish: () => void
	credential?: Credential
	isLoading?: boolean
}) => {
	console.log(credential)
	return (
		<>
			<SectionHeader
				title="Configurá el plugin"
				subtitle="Ahora solo queda configurar el plugin. Talo te generara credenciales que vas a necesitar para la configuración del plugin."
			/>
			<Section
				title="Pasos"
				footer="Asegura te de no compartir las credenciales. Estas son privadas y compartirlas podría resultar en la perdida de tu dinero. Talo no guarda tu credenciales, por lo que recomendamos descargarlas."
			>
				<Option
					title="Credenciales"
					description="Puedes descargar tus credenciales para mayor seguridad."
					icon={<Key />}
					leading="button"
					buttonText="Descargar"
					onClick={handleDownloadCredentials}
					buttonProps={{
						isLoading: isLoading,
					}}
				/>
			</Section>
			<Section title="Copiá y pegá las credenciales en la sección de Medios de Pago ubicada en el panel de Woocommerce > Ajustes > Pagos.">
				<div className="h-[300px] justify-center flex flex-row">
					<img
						className="h-[100%] rounded-sm"
						src="/animations/woocommerce/woocommerce_2.gif"
						alt="woocommerce step 2"
					/>
				</div>
			</Section>
			{credential && (
				<div className="flex flex-col gap-betweenFields">
					<Input label="User ID" type="copy" value={credential.user_id} />
					<Input label="Client ID" type="copy" value={credential.client_id} />
					<Input
						label="Client secret"
						type="copy"
						value={credential.client_secret}
					/>
				</div>
			)}
			<Button onClick={handleFinish} size="full">
				Finalizar
			</Button>
		</>
	)
}

export default CredentialsStep
