import { Angry, MessageCircleWarning, Undo2 } from "lucide-react"
import { Alert } from "../ui/alert"
import { Button } from "../ui/button"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import Row from "../ui/layouts/row"
import { SectionHeader } from "../ui/section-header"
import Spacer from "../ui/spacer"

const NotFoundErrorBody = ({
	handleClickGoHome,
}: {
	handleClickGoHome: () => void
}) => {
	return (
		<CenteredBody bottom>
			<Row className="w-full justify-center">
				<img className="w-1/2" src="/illustrations/error.svg" alt="not found" />
			</Row>
			<Spacer axis="vertical" type="belowSectionHeader"></Spacer>

			<SectionHeader
				title="Oops, parece que hubo un error"
				subtitle="Le pasa a los mejores, no te preocupes. Podés volver al menú
						inicial."
			/>
			<Alert
				icon={<Angry className="h-4 w-4" />}
				title="¿Creés que es culpa nuestra?"
				description={
					<ul className="text-h6 flex flex-col list-inside text-text-muted list-disc mt-2 [&>*]:m-0">
						<li>Estás acá, porque tocaste un botón.</li>
						<li>Estás acá, porque algo no cargaba.</li>
						<li>Estás acá, porque estabas aburrido.</li>
					</ul>
				}
				bulletList
			></Alert>
			<div className="flex w-full flex-row justify-between">
				<Button
					onClick={() =>
						window.open(
							"https://wa.me/541133711752/?text=Hola,%20tuve%20un%20problema%20con%20la%20plataforma.",
							"_blank"
						)
					}
					variant="ghost"
					className="text-text-interactive"
				>
					<MessageCircleWarning className="m-2" />
					Comunicaté con soporte
				</Button>
				<Button onClick={handleClickGoHome}>
					<Undo2 className="m-2" />
					Volver al menú principal
				</Button>
			</div>
		</CenteredBody>
	)
}

export default NotFoundErrorBody
