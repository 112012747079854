import { findDisplayNameForBankInJson } from "../../../../lib/utils"
import { TableTransaction } from "../../../tables/transactions-table/columns"
import { CurrencyImage } from "../../../tables/transactions-table/columns/currencyImageCell"
import { Heading2, Heading6 } from "../../../ui/texts/TextStyle"
import BodyBlock from "./BodyBlock"
import BodyItem from "./BodyItem"
import DateInfo from "./DateInfo"
import bank_list from "../../../../lib/psp_bank_id.json"

const OutboundTransactionModalBody = ({
	payment,
	variant,
}: {
	payment: TableTransaction
	variant?: "transfer" | "crypto"
}) => {
	let bankName = findDisplayNameForBankInJson(
		payment.psp_id ? payment.psp_id : payment.bank_id ? payment.bank_id : "",
		bank_list
	)

	switch (variant) {
		case "transfer":
			return (
				<>
					<BodyBlock title="Resumen">
						<DateInfo {...payment.lastModifiedDateTime} />
						<div className="flex justify-between items-center py-betweenComponents">
							<Heading6 className="font-medium text-text-muted">
								Enviado
							</Heading6>
							{payment.credited && (
								<div className="flex-col mb-2 whitespace-nowrap">
									<div className="flex justify-end">
										<CurrencyImage currency={payment.credited.currency} />

										<Heading2 className="ml-betweenTexts font-medium whitespace-nowrap">
											{payment.credited.amount}
										</Heading2>

										<Heading2 className="ml-betweenTexts font-light">
											{payment.credited.currency}
										</Heading2>
									</div>
								</div>
							)}
						</div>
					</BodyBlock>
					<BodyBlock title="Destino">
						{payment.address && (
							<BodyItem
								name={payment.psp_id ? "CVU" : "CBU"}
								value={payment.address}
								copy={true}
							/>
						)}
						{payment.recipient_account && payment.recipient_account.name && (
							<BodyItem
								name={"Nombre del destinatario"}
								value={payment.recipient_account.name}
								copy={true}
							/>
						)}
						{bankName && <BodyItem name="Banco" value={bankName} />}
					</BodyBlock>
				</>
			)
		case "crypto":
		default:
			return (
				<BodyBlock
					title="Detalles del Retiro"
					subtitle="Los siguientes son los datos bancarios específicos de este retiro"
				>
					<DateInfo {...payment.lastModifiedDateTime} />
					{payment.network && <BodyItem name="Red" value={payment.network} />}
					{payment.address && (
						<BodyItem
							name="Dirección de envío"
							value={payment.address}
							overflow={true}
							copy={true}
						/>
					)}
					{payment.transactionHash && (
						<BodyItem
							name="Hash de transacción"
							value={payment.transactionHash}
							copy={true}
							overflow={true}
						/>
					)}
					<div className="flex justify-between items-center py-betweenComponents">
						<Heading6 className="font-medium text-text-muted">Enviado</Heading6>
						{payment.credited && (
							<div className="flex-col mb-2 whitespace-nowrap">
								<div className="flex justify-end">
									<CurrencyImage currency={payment.credited.currency} />

									<Heading2 className="ml-betweenTexts font-medium whitespace-nowrap">
										{payment.credited.amount}
									</Heading2>

									<Heading2 className="ml-betweenTexts font-light">
										{payment.credited.currency}
									</Heading2>
								</div>
							</div>
						)}
					</div>
				</BodyBlock>
			)
	}
}

export default OutboundTransactionModalBody
