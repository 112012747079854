import { CredentialTextCell } from "./columns/CredentialTextCell"
import { CredentialStatusCell } from "./columns/CredentialStatusCell"
import { CopyIdCell } from "./columns/CopyIdCell"
import { StatusAliasIdCompactCell } from "./columns/StatusAliasIdCompactCell"

export enum CredentialStatus {
	ACTIVE,
	DELETED,
}

export const translatedCredentialsStatus = {
	[CredentialStatus.ACTIVE]: "Activo",
	[CredentialStatus.DELETED]: "Eliminado",
}

export interface TableCredential {
	client_id: string
	alias: string
	creation_date: string
	status: CredentialStatus
}
const credentialAliasCell = CredentialTextCell({
	accessorKey: "alias",
	header: "Alias",
	capitalize: true,
})
const credentialClientIdCell = CredentialTextCell({
	accessorKey: "client_id",
	header: "ID de cliente",
})

const credentialCreationDateCell = CredentialTextCell({
	accessorKey: "creation_date",
	header: "Creación",
	className: "font-normal",
})

export const columns = {
	xl: [
		CredentialStatusCell(),
		credentialAliasCell,
		credentialCreationDateCell,
		credentialClientIdCell,
		CopyIdCell({}),
	],
	lg: [
		CredentialStatusCell(),
		credentialAliasCell,
		credentialCreationDateCell,
		credentialClientIdCell,
		CopyIdCell({}),
	],
	md: [
		StatusAliasIdCompactCell(),
		credentialCreationDateCell,
		CopyIdCell({ sm: true }),
	],
	sm: [
		StatusAliasIdCompactCell(),
		credentialCreationDateCell,
		CopyIdCell({ sm: true }),
	],
}
