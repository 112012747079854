import ShortcutButton from "../../ui/buttons/ShortcutButton"

export type Shortcut = {
	label: string
	icon?: React.ReactNode
	smIcon?: React.ReactNode
	type: "label" | "horizontal" | "icon"
	className?: string
	link?: string
	url?: string
}

const ShortcutsSection = ({ shortcuts }: { shortcuts: Shortcut[] }) => {
	return (
		<div className="grid grid-rows-2 w-full h-full justify-items-end justify-end grid-flow-col gap-betweenButtons pb-[5px] pt-[58px]">
			{shortcuts.map((shortcut) => (
				<div
					className={`${shortcut.className} w-full overflow-visible`}
					key={`shortcut-button-${shortcut.label}`}
				>
					<ShortcutButton
						type={shortcut.type}
						size="lg"
						icon={shortcut.icon}
						label={shortcut.label}
						link={shortcut.link}
						url={shortcut.url}
					/>
				</div>
			))}
		</div>
	)
}

export default ShortcutsSection
