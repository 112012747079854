import { Price } from "../../components/tables/transactions-table/columns"
import { QRItem } from "../../components/ui/inputs/QRSelector"

export enum ChargeType {
	QR = "qr",
	LINK = "link",
}

export enum PaymentOption {
	TRANSFER = "transfer",
	CRYPTO = "crypto",
	PIX = "pix",
}

export const PaymentOptionItem = {
	[PaymentOption.TRANSFER]: {
		label: "Pesos",
		description: "Transferencia bancaria en ARS",
		icon: "ARS",
	},
	[PaymentOption.CRYPTO]: {
		label: "Crypto",
		description: "Transferencias entre billeteras digitales",
		//TODO: Le puse el logo de BNB porque todavía no tenemos el componente que es un array de icons
		icon: "USDT",
	},
	[PaymentOption.PIX]: {
		label: "Pix",
		description: "Transferencias instantáneas en BRL",
		icon: "BRL",
	},
}

export enum ChargeSteps {
	SelectType,
	EnterAmount,
	ShareCharge,
}

export type ChargeTypeItem = {
	label: string
	icon: React.ReactNode
	disabled?: boolean
}

export interface Charge {
	id: string
	price: Price
	motive?: string
	merchant_qr_id?: string
	payment_url: string
	creation_timestamp: string
	expiration_timestamp: string
	payment_status: "PENDING" | "SUCCESS" | "EXPIRED" | "OVERPAID" | "UNDERPAID"
}

export interface ChargeData {
	type?: ChargeType
	qr?: QRItem
	price?: Price
	motive?: string
	payment?: Charge
	payment_options?: PaymentOption[]
}

export interface WizardChargeData {
	step: ChargeSteps
	data: ChargeData
	loading?: boolean
	errorMessage?: string
}
