import { ReactNode } from "react"
import LandingHeader from "./LandingHeader"

const AuthLayout = ({
	children,
	leftImageSrc,
	rightNavbarContent,
}: {
	children: ReactNode | ReactNode[]
	leftImageSrc: string
	rightNavbarContent?: ReactNode | ReactNode[]
}) => {
	return (
		<div className="w-full h-screen  grid grid-flow-col justify-stretch">
			<LandingHeader
				className="absolute top-0 z-40"
				right={rightNavbarContent}
				logoColor="lg:text-white"
			/>
			<div className="hidden bg-muted lg:block  dark:opacity-75">
				<img
					src={leftImageSrc}
					alt=""
					width="1920"
					height="1080"
					className="h-full w-full object-cover"
				/>
			</div>

			<div className="flex items-center justify-center py-12 px-screenMobile md:px-[148px] w-full">
				{children}
			</div>
		</div>
	)
}

export default AuthLayout
