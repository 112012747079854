import { PaymentOption } from "../pages/charge/charge-wizard-types"
import { apiPrivate, apiPublic } from "./interceptors"
export async function createQr(user_id?: string, creation_team_id?: string) {
	const res = await apiPrivate.post(`/qrs/`, {
		user_id,
	})

	return res.data.data
}
export async function getUserQr(user_id: string) {
	const res = await apiPrivate.get(`/qrs/?user_id=${user_id}`)
	return res.data.data.qrs
}
export async function getQrs({
	limit,
	cursor,
}: {
	limit?: number
	cursor?: string
}) {
	const res = await apiPrivate.get(
		`/qrs/${limit ? `?limit=${limit}` : ""}${
			cursor ? (limit ? `&cursor=${cursor}` : `?cursor=${cursor}`) : ""
		}`
	)
	return res.data.data
}
export async function getQr(qr_id: string) {
	const res = await apiPublic.get(`/qrs/${qr_id}`)
	return res.data.data
}

export async function createQrPayment(
	qr_id: string,
	amount: number | string,
	currency: string,
	user_id: string,
	motive: string,
	team_id?: string,
	payment_options?: PaymentOption[]
) {
	const res = await apiPrivate.post(`/qrs/${qr_id}/payments`, {
		user_id,
		price: {
			amount: amount,
			currency: currency,
		},
		motive: motive,
		team_id: team_id,
		...(payment_options && { payment_option: payment_options }),
	})
	return res.data.data
}

export async function deleteQrActivePayment(qr_id: string, payment_id: string) {
	const res = await apiPrivate.delete(`/qrs/${qr_id}/payments/${payment_id}`)
	return res.data.data
}

export async function registerQrToUser(
	qr_id: string,
	user_id: string,
	code: string
) {
	const res = await apiPrivate.put(`/qrs/${qr_id}`, {
		code,
		user_id,
	})
	return res
}
