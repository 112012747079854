import ScanQrCriptoVideo from "./ScanReadyLowDef.mp4"
export const ScanQrCripto = () => {
	return (
		<>
			<video
				src={ScanQrCriptoVideo}
				width="100%"
				height="100%"
				style={{
					maxHeight: 400,
				}}
				autoPlay={true}
				muted
				loop
			/>
		</>
	)
}
