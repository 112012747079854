import { Badge } from "../../ui/badge"

const MerchantTypeIndicator = ({ type }: { type: string }) => {
	return (
		<Badge size="sm" variant="secondary">
			{type}
		</Badge>
	)
}

export default MerchantTypeIndicator
