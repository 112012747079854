import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../../ui/accordion"
import Col from "../../ui/layouts/column"
import { faq_questions } from "./faq_questions"
const FAQComponent = () => {
	const bigTextClassName = "font-normal text-h1 md:text-d2 lg:text-d1"
	const subtitleClassName = "text-base lg:text-xl text-[#222]"

	return (
		<Col className="gap-betweenSections w-full max-w-[608px]">
			<span className={bigTextClassName}>FAQ</span>
			<h1 className={subtitleClassName}>Preguntas frecuentes</h1>
			<Accordion className="w-full" type="multiple">
				{faq_questions.map((item, index) => {
					return (
						<AccordionItem value={item.question} key={item.question}>
							<AccordionTrigger>{item.question}</AccordionTrigger>
							<AccordionContent>{item.answer}</AccordionContent>
						</AccordionItem>
					)
				})}
			</Accordion>
		</Col>
	)
}

export default FAQComponent
