import { ReactNode } from "react"
import { Link } from "react-router-dom"

export interface BottomBarMenuItemProps {
	icon: ReactNode
	itemLink: string
	status?: "active" | "inactive"
}

//TODO TRAVE COLOR
export const statusClasses = {
	active: "text-[#8EDD65]",
	inactive: "text-[#B9C8B9]",
}

const BottomBarMenuItem = (item: BottomBarMenuItemProps) => {
	return (
		<Link to={item.itemLink}>
			<div
				className={
					"w-[56px] h-[56px] p-xs flex items-center justify-center " +
					statusClasses[item.status || "inactive"]
				}
			>
				{item.icon}
			</div>
		</Link>
	)
}

export default BottomBarMenuItem
