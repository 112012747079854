import { ReactNode } from "react"
import { cn } from "../../lib/utils"
import { Heading6 } from "./texts/TextStyle"

export interface SectionProps {
	children: ReactNode | ReactNode[]
	className?: string
	title?: string | ReactNode
	footer?: string | ReactNode
}

const Section = ({ children, className, title, footer }: SectionProps) => {
	return (
		<div
			className={cn("grid w-full text-start gap-belowSectionHeader", className)}
		>
			{title ? <Heading6 className="font-semibold">{title}</Heading6> : null}

			<div className="flex flex-col gap-betweenOptions">
				{children}
				{footer ? (
					<Heading6 className="font-normal text-text-muted">{footer}</Heading6>
				) : null}
			</div>
		</div>
	)
}

export { Section }
