import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTeam } from "../columns"
import { Heading6 } from "../../../ui/texts/TextStyle"

export function TeamEmailCell(): ColumnDef<TableTeam> {
	return {
		accessorKey: "email",
		header: "Mail",
		cell: ({ row }: { row: Row<TableTeam> }) => {
			return (
				<div className="max-w-[90%]">
					<Heading6 className="font-medium overflow-hidden overflow-ellipsis">
						{row.getValue("email")}
					</Heading6>
				</div>
			)
		},
		enableResizing: true,
	}
}
