import { Template } from "@pdfme/common"
import React from "react"
import { Button } from "../../components/ui/button"
import { InvoiceFormData } from "../../pages/admin/invoices/InvoiceForm"
const invoiceTemplatePdf = import(
	/* webpackChunkName: "invoiceTemplate" */ "./invoice-inpro-blank-template"
)

interface InvoicePDFDownloadProps {
	data: InvoiceFormData
}

const generateInvoicePdf = async (
	data: InvoiceFormData,
	isDownload: boolean
) => {
	const template: Template = {
		basePdf: (await invoiceTemplatePdf).invoiceTemplate,
		schemas: [
			{
				invoiceNumber: {
					type: "text",
					position: { x: 38, y: 22 },
					width: 40,
					height: 4,
					size: 11,
					fontSize: 10,
				},
				issueDate: {
					type: "text",
					position: { x: 38, y: 27 },
					width: 40,
					height: 4,
					fontSize: 10,
				},
				dueDate: {
					type: "text",
					position: { x: 38, y: 31 },
					width: 40,
					height: 4,
					fontSize: 10,
				},
				descriptionAmount: {
					type: "text",
					position: { x: 186, y: 115 },
					width: 22,
					height: 4,
					fontSize: 10,
				},
				subtotalAmount: {
					type: "text",
					position: { x: 186, y: 126 },
					width: 22,
					height: 4,
					fontSize: 10,
				},
				totalAmount: {
					type: "text",
					position: { x: 186, y: 134 },
					width: 22,
					height: 4,
					fontSize: 10,
				},
				dueAmount: {
					type: "text",
					position: { x: 186, y: 141 },
					width: 22,
					height: 4,
					fontSize: 10,
				},
				footerSummary: {
					type: "text",
					position: { x: 11, y: 276 },
					width: 100,
					height: 4,
					fontSize: 10,
				},
			},
		],
	}

	const inputs = [
		{
			invoiceNumber: data.invoiceNumber,
			issueDate: data.issueDate.toLocaleDateString(),
			dueDate: data.issueDate.toLocaleDateString(),
			descriptionAmount: `$${data.amount}`,
			subtotalAmount: `$${data.amount}`,
			totalAmount: `$${data.amount}`,
			dueAmount: `$${data.amount}`,
			footerSummary: `${data.invoiceNumber} - $${
				data.amount
			} due ${data.issueDate.toLocaleDateString()}`,
		},
	]
	// if (!isDownload) {
	// 	const domContainer = document.getElementById("container")
	// 	if (domContainer !== null) {
	// 		new Designer({ domContainer, template })
	// 	} else {
	// 		console.log("NO SE CREO EL DESIGNER")
	// 	}
	const pdfme = await import(/* webpackChunkName: "pdfme" */ "@pdfme/generator")

	const pdf = await pdfme.generate({ template, inputs })
	const blob = new Blob([pdf.buffer], { type: "application/pdf" })
	const url = URL.createObjectURL(blob)

	const a = document.createElement("a")
	a.href = url
	a.download = "Invoice-Talo-Inpro.pdf"
	a.click()
	URL.revokeObjectURL(url)
}

const InvoicePDFDownload: React.FC<InvoicePDFDownloadProps> = ({ data }) => {
	return (
		<>
			<Button
				onClick={() => generateInvoicePdf(data, true)}
				className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md shadow-sm"
			>
				Download PDF
			</Button>
		</>
	)
}

export default InvoicePDFDownload
