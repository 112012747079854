import { ScanQrCripto } from "../app/qrs/animations/ScanQrCripto"
import { Input } from "../ui/inputs/input"
import { SectionHeader } from "../ui/section-header"
import { QRItem } from "../ui/inputs/QRSelector"
import LoadableButton from "../ui/buttons/LoadableButton"
import Col from "../ui/layouts/column"
import { Charge } from "../../pages/charge/charge-wizard-types"
const QRChargeBody = ({
	payment,
	onCancel,
	qr,
	loading = false,
}: {
	payment: Charge
	onCancel: () => void
	qr?: QRItem
	loading?: boolean
}) => {
	return (
		<Col className="gap-betweenSections">
			<SectionHeader
				title="Compartí el QR físico"
				subtitle="Pedile a tu cliente que escanee el código QR en caja, con la cámara del celular."
			/>
			<div className="bg-[#1e2c2d] rounded-md">
				<ScanQrCripto />
			</div>
			<div className="flex flex-row gap-betweenFields">
				<Input
					id="monto"
					placeholder={payment.price.amount.toString()}
					disabled
					label="Monto"
				/>
				<Input id="qr" placeholder={qr!.code} disabled label="QR físico" />
			</div>
			<Col className="gap-betweenButtons">
				<LoadableButton isLoading={true}>Esperando el pago</LoadableButton>
				<LoadableButton
					onClick={onCancel}
					isLoading={loading}
					variant="outline"
				>
					Cancelar cobro
				</LoadableButton>
			</Col>
		</Col>
	)
}

export default QRChargeBody
