import { Sheet, SheetContent, SheetHeader } from "../../ui/sheet"
import { fiatCurrencies } from "../../../utils/currencies"
import { useMediaQuery } from "../../../utils/useMediaQuery"
import { Option } from "../../ui/options/option"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
import { Section } from "../../ui/section"

const FiatModalBottomSheet = (props: {
	onCurrencySelected: (currency: string) => void
	onClose: () => void
	isModalOpen: boolean
	language: "spanish" | "portuguese"
}) => {
	const { language } = props
	const isSm = useMediaQuery(960)
	// const [selectedCurrency, setSelectedCurrency] = useState<string>(
	// 	fiatCurrencies["BRL"].alias
	// )
	console.log("fiatCurrencies", props)

	return (
		<Sheet open={props.isModalOpen} onOpenChange={props.onClose}>
			<SheetContent side="bottom">
				<CenteredBody className="my-8">
					<SheetHeader>
						<SectionHeader
							title={language === "spanish" ? "Otras monedas" : "Outras moedas"}
							subtitle={
								language === "spanish"
									? "Seleccioná con qué moneda deseas pagar"
									: "Selecione com qual moeda você deseja pagar"
							}
						/>
					</SheetHeader>

					<Section title={language === "spanish" ? "Monedas" : "Moedas"}>
						{Object.entries(fiatCurrencies).map(([k, v]) => (
							<Option
								key={`currency-${v.name}`}
								title={v.name}
								icon={<img src={v.flagPath} alt={v.name} />}
								onClick={() => props.onCurrencySelected(v.alias)}
								disabled={v.disabled}
								iconInside={false}
								// className="my-1"
							/>
						))}
					</Section>
				</CenteredBody>
			</SheetContent>

			{/* Request#007 */}
		</Sheet>
	)
}
export default FiatModalBottomSheet
