import React from "react"
import { WithdrawSteps } from "../../../pages/withdraw/withdraw-wizard-types"
import {
	currencies,
	CurrencyStyle,
} from "../../../utils/currencies"
import { Option } from "./option"
import { WithdrawType } from "../../../utils/withdrawCurrencies"

export type IntegrationOptionProps = {
	type: WithdrawType
	step: WithdrawSteps
	title: string
	description: string
	logoPath: string
	onClick: (type: WithdrawType, step: WithdrawSteps) => void
}

const IntegrationOption = ({
	type,
	step,
	title,
	description,
	logoPath,
	onClick,
}: IntegrationOptionProps) => {
	return (
		<Option
			data-test={`withdraw-type-${type}-button`}
			title={title}
			description={description}
			icon={
				type === "crypto" ? (
					<div className="flex flex-row items-center justify-center w-[40px]">
						{Object.entries(currencies)
							.slice(0, 2)
							.map(([key, currency]: [string, CurrencyStyle]) => (
								<div className="-mr-2 relative" key={currency.alias}>
									<img
										src={currency.logoPath}
										alt={currency.alias}
										className="object-cover !m-0 !p-0 object-top rounded-full h-full  border-1 border-white relative "
									/>
								</div>
							))}
					</div>
				) : (
					<img src={logoPath} width={24} alt={title} />
				)
			}
			iconInside={false}
			leading="arrow"
			onClick={() => onClick && onClick(type, step)}
		/>
	)
}

export default IntegrationOption
