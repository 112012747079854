import { ReactNode } from "react"
import { cn } from "../../../lib/utils"

const Row = ({
	className,
	children,
}: {
	className?: string
	children: ReactNode | ReactNode[]
}) => {
	return <div className={cn("flex flex-row", className)}>{children}</div>
}

export default Row
