import { useEffect, useState } from "react"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { getUserCredentials } from "../../../services/users"
import { useAuth } from "../../../context/AuthContext"
import { TableCredential } from "../../../components/tables/credentials-table/columns"
import { mapToTableCredential } from "../../../components/tables/credentials-table/data"
import CredentialsTable from "../../../components/tables/credentials-table/table"
import { Input } from "../../../components/ui/inputs/input"
import Row from "../../../components/ui/layouts/row"
import { Button } from "../../../components/ui/button"
import { PlusIcon } from "lucide-react"
import NewCredentialModal from "../../../components/modals/new-credential/NewCredentialModal"

const CredentialsBody = () => {
	const [loading, setLoading] = useState(false)
	const [credentials, setCredentials] = useState<TableCredential[]>([])
	const { user } = useAuth()
	const [isModalOpen, setIsModalOpen] = useState(false)

	useEffect(() => {
		fetchCredentials()
	}, [])
	const fetchCredentials = async () => {
		setLoading(true)

		const res = await getUserCredentials(user.id)
		setCredentials(res.data.data.credentials.map(mapToTableCredential))
		setLoading(false)
	}

	return (
		<CenteredTableBody>
			<NewCredentialModal
				open={isModalOpen}
				onClose={() => {
					fetchCredentials()
					setIsModalOpen(false)
				}}
			/>
			<SectionHeader
				title="Administrá las credenciales generadas"
				subtitle="Estas credenciales te permiten autenticar las solicitudes a la API de Talo."
			/>
			<Input readOnly label="User ID" value={user.id} type="copy" />
			<div>
				<Row className="justify-end">
					<Button
						variant="outline"
						size="md"
						className="gap-betweenChips"
						onClick={() => setIsModalOpen(true)}
					>
						<PlusIcon />
						Nueva credencial
					</Button>
				</Row>
				<CredentialsTable credentials={credentials} loading={loading} />
			</div>
		</CenteredTableBody>
	)
}

export default CredentialsBody
