import { useState } from "react"
import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import AdminPaymentsModal from "../../modals/admin-payment/AdminPaymentModal"
import { DataTable } from "../../ui/data-table/data-table"
import { PaginationHandler } from "../../ui/data-table/data-table-pagination-footer"
import { columns } from "./columns"
import { TablePayment, mapPaymentToTablePayment } from "./data"

const AdminPaymentsTable = ({
	payments,
	paginationHandler,
}: {
	payments?: any[]
	paginationHandler: PaginationHandler
}) => {
	const breakpoint = useBreakpoint() as ScreenBreakpoint
	const [selectedPayment, setSelectedPayment] = useState<TablePayment | null>(
		null
	)
	const [modalOpen, setModalOpen] = useState(false)
	return (
		<>
			{selectedPayment && (
				<AdminPaymentsModal
					open={modalOpen}
					onClose={() => {
						setModalOpen(false)
						setSelectedPayment(null)
					}}
					payment={selectedPayment}
				/>
			)}
			<DataTable
				onRowSelected={(row: TablePayment) => {
					setSelectedPayment(row)
					setModalOpen(true)
				}}
				tableItem="pagos"
				searchFilter={{
					placeholder: "Mail de cliente",
					columnName: "user",
				}}
				filters={[
					{
						column: "payment_status",
						title: "Estado",
						options: [
							"OVERPAID",
							"UNDERPAID",
							"SUCCESS",
							"EXPIRED",
							"PENDING",
						].map((status) => ({
							value: status,
							label: status,
						})),
					},
					{
						column: "type",
						title: "Tipo",
						options: ["tiendanube", "woocommerce", "qr", "pix", "other"].map(
							(curr) => ({
								value: curr,
								label: curr,
							})
						),
					},
				]}
				loading={!payments}
				data={payments?.map(mapPaymentToTablePayment) ?? []}
				paginationHandler={paginationHandler}
				columns={columns[breakpoint]}
			/>
		</>
	)
}

export default AdminPaymentsTable
