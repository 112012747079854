import "./GradientUnderlineButton.css"

const GradientUnderlineButton = (props: {
	href?: string
	dark?: boolean
	children: React.ReactNode
}) => {
	return (
		<a
			className="font-bold text-[#222] text-b1 md:text-h3"
			href={props.href}
			target="_blank"
			rel="noreferrer"
		>
			<span
				className={
					props.dark ? "gradient-underline-dark" : "gradient-underline"
				}
			>
				{props.children}
			</span>
		</a>
	)
}

export default GradientUnderlineButton
