import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import WoocommerceStoresCarousel from "../../../components/app/settings/woocommerce/WoocommerceStoresCarousel"
import { useAuth } from "../../../context/AuthContext"
import WoocommerceWizard from "../../../components/app/settings/woocommerce/WoocommerceWizard"
import { useEffect, useState } from "react"

const WoocommerceBody = () => {
	const { stores } = useAuth()
	const [woocommerceStores, setWoocommerceStores] = useState<any[]>([])
	useEffect(() => {
		if(!stores) return
		
		const woocommerceStores = stores.filter(
			(store: any) => store.store_type === "woocommerce"
		)

		setWoocommerceStores(woocommerceStores)
	}, [stores])

	return (
		<CenteredBody>
			{woocommerceStores?.length > 0 && (
				<WoocommerceStoresCarousel stores={woocommerceStores} />
			)}
			<WoocommerceWizard />
		</CenteredBody>
	)
}
export default WoocommerceBody
