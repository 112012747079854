import { CircleHelp, Eye, EyeOff } from "lucide-react"
import React, { useCallback, useState } from "react"
import { cn } from "../../../lib/utils"
import { Button } from "../button"
import CopyButton from "../buttons/CopyButton"
import { Label } from "../label"
import Row from "../layouts/row"
import { Controller } from "react-hook-form/dist/controller"
import { Select, SelectContent, SelectTrigger, SelectValue } from "./select"
import SlidingCard from "../sliding-card"
import CenteredBody from "../layouts/platform/body/CenteredBody"
import { Section } from "../section"
import { Heading6 } from "../texts/TextStyle"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: React.ReactNode
	required?: boolean
	forgotPassword?: boolean
	subtext?: string
	copyButtonIcon?: boolean
	leading?: React.ReactNode
	icon?: React.ReactNode
	helpText?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			type,
			label,
			required,
			forgotPassword = false,
			subtext,
			copyButtonIcon = false,
			leading,
			icon,
			helpText,
			...props
		},
		ref
	) => {
		const [showPassword, setShowPassword] = useState(false)

		const handleTogglePassword = useCallback(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				event.preventDefault()
				setShowPassword((prev) => !prev)
			},
			[]
		)

		const passwordButton = (
			<Button
				variant="link"
				size="sm"
				type="button"
				onClick={handleTogglePassword}
				className="absolute right-2 top-1/2 transform"
			>
				{showPassword ? <Eye /> : <EyeOff />}
			</Button>
		)
		const forgotPasswordButton = type === "password" && forgotPassword && (
			<Button
				variant="link"
				size="sm"
				type="button"
				className="text-text-muted"
				onClick={() => {
					window.location.href = "/forgot-password"
				}}
			>
				¿Olvidaste tu contraseña?
			</Button>
		)

		let inputElement

		if (type === "copy") {
			inputElement = (
				<div className="flex flex-row gap-1 items-end">
					<input
						className={cn(
							"flex h-lg rounded-xs px-sm py-xs w-full border border-dashed border-border-input bg-background text-h5 font-medium ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-text-disabled focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
							className
						)}
						ref={ref}
						{...props}
					/>
					<CopyButton
						variant="secondary"
						label={!copyButtonIcon}
						textToCopy={props.value?.toString() ?? ""}
					/>
				</div>
			)
		} else if (type === "leading") {
			inputElement = (
				<Row>
					{leading}
					<input
						type={type === "password" && showPassword ? "text" : type}
						className={cn(
							"rounded-l-none border-l-0 flex h-lg rounded-r-xs px-sm py-xs w-full border border-border-input bg-background-subtle  text-h5 font-medium ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
							className
						)}
						ref={ref}
						{...props}
					/>
				</Row>
			)
		} else if (icon) {
			inputElement = (
				<Row>
					<div className="flex h-lg rounded-xs pl-sm py-xs border-r-0 border border-border-input bg-background-subtle text-text-muted ring-offset-background placeholder:text-muted-foreground rounded-e-none text-center items-center justify-center">
						{icon}
					</div>
					<input
						type={type === "password" && showPassword ? "text" : type}
						className={cn(
							"rounded-l-none  flex h-lg rounded-r-xs px-sm py-xs w-full border border-l-0 border-border-input bg-background-subtle  text-h5 font-medium ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
							className
						)}
						ref={ref}
						{...props}
					/>
				</Row>
			)
		} else {
			inputElement = (
				<input
					type={type === "password" && showPassword ? "text" : type}
					className={cn(
						"flex h-lg rounded-xs px-sm py-xs w-full border border-border-input bg-background-subtle text-h5 font-medium ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
						className
					)}
					ref={ref}
					{...props}
				/>
			)
		}

		return (
			<div className="relative grid gap-2 w-full">
				{label && (
					<div className="flex items-center justify-between">
						{label && (
							<Row className="gap-betweenTexts">
								<Label subtext={subtext} required={required}>
									{label}
								</Label>

								{helpText && (
									<SlidingCard
										side={"center"}
										trigger={<CircleHelp className="size-icon" />}
									>
										<CenteredBody>
											<Section title={label}>
												<Heading6 className="font-medium text-text-muted text-justify">
													{helpText}
												</Heading6>
											</Section>
										</CenteredBody>
									</SlidingCard>
								)}
							</Row>
						)}
						{forgotPasswordButton}
						{type === "password" && passwordButton}
					</div>
				)}
				{inputElement}
			</div>
		)
	}
)

Input.displayName = "Input"

export { Input }
