import { useEffect, useState } from "react"
import { TableUser } from "../../../components/tables/admin-users/columns"
import { mapUserToTableUser } from "../../../components/tables/admin-users/data"
import AdminUsersTable from "../../../components/tables/admin-users/table"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { getUsers } from "../../../services/users"

interface AdminUsersData {
	items: TableUser[]
	prevCursor?: {
		pk: string
		sk: string
	}
	nextCursor?: {
		pk: string
		sk: string
	}
}

const AdminUsersBody = () => {
	const [usersData, setUsersData] = useState<AdminUsersData | undefined>()
	useEffect(() => {
		load()
	}, [])
	const load = async (prev?: boolean) => {
		const usersRes = await getUsers({
			limit: 25,
			cursor: prev ? usersData?.prevCursor : usersData?.nextCursor,
		})
		console.log(usersRes)
		const prevUser = usersRes?.users ? usersRes?.users[-1] : undefined

		setUsersData({
			items: usersRes.users?.map(mapUserToTableUser),
			nextCursor: usersRes.cursor,
			prevCursor: prevUser
				? {
						pk: prevUser?.user_id,
						sk: prevUser?.team_id,
				  }
				: undefined,
		})
	}
	return (
		<CenteredTableBody>
			<SectionHeader
				title="Usuarios"
				subtitle="Listado de usuarios registrados"
			/>
			<AdminUsersTable
				users={usersData?.items ?? []}
				paginationHandler={{
					nextPage: () => load(),
					prevPage: () => load(true),
				}}
			/>
		</CenteredTableBody>
	)
}

export default AdminUsersBody
