import { merchants } from "../../components/home/community/MarketplaceBox"
import MarketplaceTitle from "../../components/home/community/MarketplaceTitle"
import MerchantCard from "../../components/home/community/MerchantCard"
import GradientUnderlineButton from "../../components/ui/buttons/GradientUnderlineButton"

const MarketplaceBody = () => {
	return (
		<div className="grid grid-cols-12 mx-[5%] max-w-[90%] mb-20 gap-3">
			<div className="flex col-span-12 justify-center">
				<MarketplaceTitle />
			</div>
			{merchants.map((merchant, index) => {
				return (
					<div key={merchant.name} className="col-span-12 lg:col-span-6  my-4">
						<MerchantCard {...merchant} />
					</div>
				)
			})}
			<div className="col-span-12 justify-end py-4 lg:py-6">
				<GradientUnderlineButton>
					Más por venir, próximamente
				</GradientUnderlineButton>
			</div>
		</div>
	)
}

export default MarketplaceBody
