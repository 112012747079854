import { Plus } from "lucide-react"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { Option } from "../ui/options/option"
import { Section } from "../ui/section"
import { SectionHeader } from "../ui/section-header"
import { SavedAddress } from "./SelectAddressStep"

const FrequentAddressesStep = ({
	addresses,
	onConfirmAddress,
	handleNewAddress,
}: {
	addresses: SavedAddress[]
	onConfirmAddress: (address: string) => void
	handleNewAddress: () => void
}) => {
	return (
		<>
			<Option
				title="Nueva dirección"
				description="Agendar una nueva dirección"
				icon={<Plus />}
				leading="arrow"
				onClick={handleNewAddress}
			/>
			{addresses.length > 0 && (
				<Section title="Direcciones guardadas">
					{addresses.map((ad) => (
						<Option
							key={ad.address}
							icon={
								<img
									src={ad.icon_img ?? `/currencies/${ad.currency.toLowerCase()}-icon.svg`}
									width={24}
									alt={ad.currency}
								/>
							}
							iconInside={false}
							leading="arrow"
							title={ad.name ?? ad.alias}
							description={ad.address}
							onClick={() => {
								onConfirmAddress(ad.address)
							}}
						/>
					))}
				</Section>
			)}
		</>
	)
}

export default FrequentAddressesStep
