import axios from "axios"

export async function getMonitoringServerStatus() {
	const url = getMsUrl()
	const response = await axios.get(url + "/health-check")
	return response.data.data
}

const getMsUrl = () => {
	if (process.env.REACT_APP_ENV_NAME !== "prod") {
		return `https://${process.env.REACT_APP_ENV_NAME}-monitor.talo.com.ar`
	}
	return "https://monitor.talo.com.ar"
}
