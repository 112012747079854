"use client"

import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { cn } from "../../lib/utils"
import { cva, type VariantProps } from "class-variance-authority"

const switchVariants = cva(
	"peer inline-flex  shrink-0 cursor-pointer items-center border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 ",
	{
		variants: {
			variant: {
				default:
					"h-6 w-11 rounded-full data-[state=checked]:bg-primary data-[state=unchecked]:bg-background-strong",
				expanded:
					"h-7 w-14 rounded-sm data-[state=checked]:bg-primary data-[state=unchecked]:bg-background-strong",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

const thumbVariants = cva(
	"pointer-events-none block  bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
	{
		variants: {
			variant: {
				default: "h-5 w-5 rounded-full",
				expanded:
					"h-6 w-8 rounded-xs text-center justify-center items-center group-hover:border-background-invers",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

export interface SwitchProps
	extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
		VariantProps<typeof switchVariants> {}

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	SwitchProps
>(({ className, variant, ...props }, ref) => {
	const [checked, setChecked] = React.useState(props.checked || false)

	const handleCheckedChange = (newChecked: boolean) => {
		setChecked(newChecked)
		if (props.onCheckedChange) {
			props.onCheckedChange(newChecked)
		}
	}

	return (
		<SwitchPrimitives.Root
			className={cn(switchVariants({ variant }))}
			{...props}
			ref={ref}
			checked={checked}
			onCheckedChange={handleCheckedChange}
		>
			<SwitchPrimitives.Thumb
				className={cn(thumbVariants({ variant, className }))}
			>
				{variant === "expanded" && (
					<div
						className={`flex h-full ${
							checked ? "text-text" : "text-background-strong"
						} text-[10px] font-semibold text-center justify-center group-hover:text-text items-center transition-all duration-200`}
					>
						{checked ? "Si" : "No"}
					</div>
				)}
			</SwitchPrimitives.Thumb>
		</SwitchPrimitives.Root>
	)
})

Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }