import { getAddress } from "ethers"
import { useEffect, useState } from "react"
import { FiatAddressDetails } from "../../pages/withdraw/withdraw-wizard-types"
import { getAddressDetails } from "../../services/cvu"
import { WithdrawType } from "../../utils/withdrawCurrencies"
import Col from "../ui/layouts/column"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { Option } from "../ui/options/option"
import { SectionHeader } from "../ui/section-header"
import { Sheet, SheetContent } from "../ui/sheet"
import { Skeleton } from "../ui/skeleton"
import FrequentAddressesStep from "./FrequentAddressesStep"
import NewAddressStep from "./NewAddressStep"
import { Section } from "../ui/section"
import SlidingCard from "../ui/sliding-card"
import { useAuth } from "../../context/AuthContext"
import { Alert } from "../ui/alert"
import { Lock } from "lucide-react"

export interface SavedAddress {
	name: string
	network: string
	currency: string
	alias: string
	address: string
	creation_timestamp: string
	bank_id?: string
	psp_id?: string
	icon_img?: string
}

export interface SelectAddressStepProps {
	network?: string
	addresses?: SavedAddress[]
	onNewAddress: ({ address, alias }: { address: string; alias: string }) => void
	onConfirmAddress: (
		address?: string,
		fiatAddressDetails?: FiatAddressDetails
	) => void
	onAddressChange: (address: string) => void
	address?: string
	withdrawType: WithdrawType
}

export const validateAddressCrypto = (address?: string) => {
	if (!address) return false
	try {
		getAddress(address)
		return true
	} catch (e) {
		return false
	}
}

//TODO: la validacion de cvu deberia ser mas robusta y incluir alias tambien
export const validateAddressCvu = (address?: string) => {
	if (!address) return false
	return true
}

export const validateAddressPix = (address?: string) => {
	if (!address) return false
	if (address.length !== 20) return false
	return true
}

const NewAddressStepVariants: {
	[key in WithdrawType]: {
		title: string
		subtitle: string
		placeholder: string
		validateAddress: (address: string) => boolean
	}
} = {
	crypto: {
		title: "Retirar en criptomonedas",
		subtitle:
			"Ingresá la dirección de la billetera donde querés recibir el dinero",
		placeholder: "Dirección de destino",
		validateAddress: validateAddressCrypto,
	},
	transfer: {
		title: "Retirar en pesos",
		subtitle: "Ingresá la cuenta bancaria donde querés recibir el dinero",
		placeholder: "CVU, CBU o Alias",
		validateAddress: validateAddressCvu,
	},
	pix: {
		title: "Retirar en USDT",
		subtitle:
			"Ingresá la dirección de la billetera donde querés recibir el dinero cobrado por PIX en USDT",
		placeholder: "Dirección de destino",
		validateAddress: validateAddressCrypto,
	},
}

const SelectAddressStep = ({
	network,
	addresses,
	onConfirmAddress,
	onAddressChange,
	onNewAddress,
	address,
	withdrawType,
}: SelectAddressStepProps) => {
	const [newAddress, setNewAddress] = useState(false)
	const [isSheetOpen, setIsSheetOpen] = useState(false)
	const { kyc } = useAuth()
	const [fiatAddressDetails, setFiatAddressDetails] =
		useState<FiatAddressDetails>({
			name: "",
			cbu: "",
			account_type: "",
			active_account: false,
			address: "",
			alias: "",
			creation_timestamp: "",
			cuit: "",
			cvu_type: "",
			entity_type: "",
			last_modified_timestamp: "",
			last_used_timestamp: "",
			user_id: "",
		})
	const [fiatAddressError, setFiatAddressError] = useState<string | undefined>(
		undefined
	)

	function isSameNameAddress(addressCuit: string) {
		console.log(
			"kyc cuit=" + kyc?.data?.basic?.cuit + " addressCuit=" + addressCuit
		)
		return kyc?.data?.basic?.cuit === addressCuit
		// return kyc?.data?.basic?.cuit === "43081035"
	}
	useEffect(() => {
		if (addresses && addresses.length === 0) {
			setNewAddress(true)
		} else {
			setNewAddress(false)
		}

		if (withdrawType === "transfer") {
			network = "POLLUX"
		}
		if (withdrawType === "pix") {
			network = "POLYGON"
		}
	}, [addresses])

	async function fetchAddressDetails(
		address: string
	): Promise<FiatAddressDetails> {
		const res = await getAddressDetails(address)
		return res
	}

	async function handleOnConfirmAddressModalProxy() {
		setFiatAddressError(undefined)
		if (withdrawType !== "crypto" && address) {
			if (!isSheetOpen) {
				const response = await fetchAddressDetails(address)
				if (response.message) {
					setFiatAddressError(
						"No encontramos información de esta cuenta, por favor verificá los datos ingresados"
					)
				} else {
					setFiatAddressDetails(response)
					setIsSheetOpen(true)
				}
			}
		} else {
			onConfirmAddress(address)
		}
	}

	return (
		<CenteredBody>
			{addresses ? (
				<>
					<SectionHeader
						title={NewAddressStepVariants[withdrawType as WithdrawType].title}
						subtitle={
							NewAddressStepVariants[withdrawType as WithdrawType].subtitle
						}
					/>
					{newAddress ? (
						<NewAddressStep
							onNewAddress={onNewAddress}
							onConfirmAddress={() => handleOnConfirmAddressModalProxy()}
							address={address}
							withdrawType={withdrawType}
							fiatAddressError={fiatAddressError}
							{...NewAddressStepVariants[withdrawType as WithdrawType]}
							network={network}
							onAddressChange={onAddressChange}
						/>
					) : (
						<FrequentAddressesStep
							addresses={addresses || []}
							onConfirmAddress={onConfirmAddress}
							handleNewAddress={() => setNewAddress(true)}
						/>
					)}
				</>
			) : (
				<Col className="gap-betweenSections">
					<div className="grid w-full text-start gap-betweenTexts">
						<Skeleton className="h-[20px] w-1/3" />

						<Skeleton className="h-[20px] w-full" />
					</div>
					<Section>
						<Skeleton className="h-[80px] w-full" />
					</Section>
					<Section>
						<Skeleton className="h-[80px] w-full" />
					</Section>
				</Col>
			)}
			<SlidingCard
				open={isSheetOpen}
				onOpenChange={setIsSheetOpen}
				side={"center"}
			>
				<Col className="gap-belowSectionHeader">
					<Section className="gap-betweenOptions" title="Usuario encontrado">
						<Option
							title={fiatAddressDetails.name}
							description={fiatAddressDetails.address}
							disabled={!isSameNameAddress(fiatAddressDetails?.cuit)}
							icon={
								<img
									src={
										fiatAddressDetails.icon_img
											? fiatAddressDetails.icon_img
											: `/currencies/${withdrawType.toLowerCase()}-icon.svg`
									}
									width={24}
									alt="ARS"
								/>
							}
							iconInside={false}
							onClick={() => onConfirmAddress(undefined, fiatAddressDetails)}
						/>
						{!isSameNameAddress(fiatAddressDetails?.cuit) && (
							<Alert
								variant="destructive"
								title="Como medida de seguridad"
								description={`Solo podrás retirar fondos a cuentas bajo tu mismo nombre (${
									kyc?.data?.basic?.first_name
								} ${" "} ${kyc?.data?.basic?.last_name})`}
								icon={<Lock />}
							/>
						)}
					</Section>
				</Col>
			</SlidingCard>
		</CenteredBody>
	)
}

export default SelectAddressStep
