import { ColumnDef, Row } from "@tanstack/react-table"
import { Circle, CircleX } from "lucide-react"
import Col from "../../../ui/layouts/column"
import TRow from "../../../ui/layouts/row"
import { Body1, Heading6 } from "../../../ui/texts/TextStyle"
import { CredentialStatus, TableCredential } from "../columns"

export function StatusAliasIdCompactCell(): ColumnDef<TableCredential> {
	return {
		accessorKey: "client_id",
		header: "Credencial",
		cell: ({ row }: { row: Row<TableCredential> }) => {
			const status = row.original.status
			var icon
			switch (status) {
				case CredentialStatus.ACTIVE: {
					icon = <Circle className="text-icon-success size-sm" />
					break
				}
				case CredentialStatus.DELETED: {
					icon = <CircleX className="text-icon-danger size-sm" />
					break
				}
			}

			return (
				<TRow className="items-center gap-betweenChips">
					{icon}
					<Col
						className={`gap-[4px] max-w-[114px] lg:max-w-[140px] xl:max-w-full`}
					>
						<Heading6 className="font-normal capitalize">
							{row.original.alias}
						</Heading6>
						<Body1 className="font-normal overflow-ellipsis overflow-hidden whitespace-nowrap">
							{row.getValue("client_id")}
						</Body1>
					</Col>
				</TRow>
			)
		},
	}
}
