import { Route } from "react-router-dom"
import ResponsiveLayout from "../../components/ui/layouts/platform/ResponsiveLayout"
import { MenuItem, publicMenuItems } from "./items"
import ProtectedRoute from "../../components/routes/ProtectedRoute"

export const appRoutes = publicMenuItems.flatMap((item) => {
	const subRoutes = item.subItems?.map(mapMenuItemToRoute) ?? []
	
	return [mapMenuItemToRoute(item), ...subRoutes]
})


function mapMenuItemToRoute(item:MenuItem){
	return (
		<Route
			key={item.itemLink}
			path={item.itemLink}
			element={
				<ProtectedRoute
					requiresVerification={true}
					minimumPrivateRole={item.minimumPrivateRole}
					minimumPublicRole={item.minimumPublicRole}
				>
					<ResponsiveLayout item={item} />
				</ProtectedRoute>
			}
		/>
	)
}