import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import QrWithNoActivePaymentBody from "../../components/merchants/QrWithNoActivePaymentBody"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { Skeleton } from "../../components/ui/skeleton"
import { getQr } from "../../services/qrs"

function MerchantQr() {
	const params = useParams()
	const { qrId } = params
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const fetchQr = async () => {
			setIsLoading(true)
			try {
				if (qrId) {
					const qr_data = await getQr(qrId)
					//CHECK IF QR HAS BEEN REGISTERED
					if (qr_data.user_id && qr_data.user_id !== "") {
						if (qr_data.active_payment && qr_data.active_payment !== "") {
							window.location.href = qr_data.active_payment.payment_url
						}
					} else {
						navigate("/qrs/new")
					}
				}
			} catch (err) {
			} finally {
				setIsLoading(false)
			}
		}
		fetchQr()
	}, [qrId])
	return (
		<LandingLayout rightComponent={<></>}>
			{isLoading ? (
				<Skeleton /> //TODO Trave: Add skeleton loader
			) : (
				<CenteredBody>
					<QrWithNoActivePaymentBody />
				</CenteredBody>
			)}
		</LandingLayout>
	)
}

export default MerchantQr
