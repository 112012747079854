import { Mail } from "lucide-react"
import { useState } from "react"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import { useAnalytics } from "../../../context/AnalyticsContext"
import { useAuth } from "../../../context/AuthContext"
import { isOwner } from "../../../lib/roles"
import { updateUser } from "../../../services/users"

const NotificationsBody = () => {
	const { user } = useAuth()
	const [isEmailCheckboxSelected, setIsEmailCheckboxSelected] = useState(
		user?.payment_notifications || false
	)
	const { logTogglePaymentNotification } = useAnalytics()

	const onCheckboxChange = async (checked: any) => {
		setIsEmailCheckboxSelected(checked)
		const res = await updateUser(user?.id, { payment_notifications: checked })
		if (res && res.status >= 200 && res.status < 300) {
			logTogglePaymentNotification({
				user_id: user?.user_id,
				is_enabled: checked,
				toggled_at: new Date().toISOString(),
			})
		}
	}

	return (
		<CenteredBody bottom>
			<SectionHeader
				title="Opciones de notificaciones"
				subtitle="Aca podrás personalizar las opciones de notificaciones para los pagos que se realizan en la plataforma."
			/>
			<Section
				title={"Notificaciones por pago"}
				footer="Se enviarán notificaciones cuando se realicen pagos en Crypto"
			>
				<Option
					title="Correo electrónico"
					description={isOwner(user) ? user.email : user.owner}
					leading="switch"
					icon={<Mail />}
					switchChecked={isEmailCheckboxSelected}
					onCheckedChange={onCheckboxChange}
				/>
			</Section>
		</CenteredBody>
	)
}

export default NotificationsBody
