import { Price } from "@crypto-payment-gateway/models"
import { AxiosResponse } from "axios"
import logger from "../utils/logger"
import { apiPublic } from "./interceptors"
export async function getPrices(price: Price): Promise<Price[]> {
	const res = await apiPublic.get(
		`/prices/?currency=${price.currency}&amount=${price.amount}`
	)
	logger.info(`PRICES RES===${JSON.stringify(res.data.data)}`)
	return res.data.data.prices as Price[]
}

export async function getPricesWithDifferentPricingProfiles(): Promise<{
	vendedor: string
	comprador: string
	promedio: string
}> {
	const pricing_profiles = ["seller", "average", "buyer"]
	const pricesPromises: Promise<AxiosResponse>[] = []
	pricing_profiles.forEach((elem) => {
		pricesPromises.push(
			apiPublic.get(`/prices/?currency=ARS&amount=1000&pricing_profile=${elem}`)
		)
	})
	const res = await Promise.all(pricesPromises)

	console.log("RES=", res)

	const prices = res.map((elem) => {
		const pricesArray = elem.data.data.prices as any[]
		const usdtPriceIndex = pricesArray.findIndex(
			(value, index) => value.currency === "USDT"
		)
		const usdtPrice = Number(pricesArray[usdtPriceIndex]?.amountReadable)
		return (1000 / usdtPrice).toFixed(2).toString()
	})

	return {
		vendedor: prices[0],
		promedio: prices[1],
		comprador: prices[2],
	}
}
