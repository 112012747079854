import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { Toaster } from "./components/ui/toaster"
import AnalyticsProvider from "./context/AnalyticsContext"
import { AuthContextProvider } from "./context/AuthContext"
import FlagsProvider from "./context/FlagsContext"
import { GlobalStoreProvider } from "./hooks/useGlobalStore"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import reportWebVitals from "./reportWebVitals"
import "./styles/globals.css"
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const queryClient = new QueryClient()
root.render(
	<React.StrictMode>
		<FlagsProvider>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<AuthContextProvider>
						<GlobalStoreProvider>
							<AnalyticsProvider>
								<App />
								<Toaster />
							</AnalyticsProvider>
						</GlobalStoreProvider>
					</AuthContextProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</FlagsProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
