import useBreakpoint from "../../../../hooks/useBreakpoint"
import SideBarMenu from "../../menus/side-bar/SideBarMenu"
import { platformBody } from "../layout-sizes"
import { PlatformBodyProps } from "./platform-types"

const PlatformBody = ({
	children,
	activeTab,
	navbarItems,
}: PlatformBodyProps) => {
	const sidePanelClasses = `grow-0 h-full ${platformBody.sidePanelWidth.class} ${platformBody.padding.class}`
	return (
		<div className={`flex ${platformBody.height.class} justify-between `}>
			<div className={sidePanelClasses + " flex items-center"}>
				<SideBarMenu items={navbarItems} activeTab={activeTab} />
			</div>
			<div
				className={`grow ${platformBody.padding.class}  w-full scroll-smooth overflow-y-scroll scrollbar-hide`}
			>
				{children}
			</div>
			<div className={sidePanelClasses + " flex-none"}>
				<div className="w-full bg-transparent"></div>
			</div>
		</div>
	)
}

export default PlatformBody
