import { Link, Store } from "lucide-react"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import ChargeWizardBody from "../../components/charge/ChargeWizardBody"
import { QRItem } from "../../components/ui/inputs/QRSelector"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { Skeleton } from "../../components/ui/skeleton"
import { useAnalytics } from "../../context/AnalyticsContext"
import { useAuth } from "../../context/AuthContext"
import { createPayment, getPayment } from "../../services/payments"
import {
	createQrPayment,
	deleteQrActivePayment,
	getUserQr,
} from "../../services/qrs"
import {
	Charge,
	ChargeSteps,
	ChargeType,
	PaymentOption,
	WizardChargeData,
} from "./charge-wizard-types"

const chargeTypes: {
	[key in ChargeType]: {
		label: string
		icon: React.ReactNode
	}
} = {
	[ChargeType.QR]: {
		label: "QR en caja",
		icon: <Store />,
	},
	[ChargeType.LINK]: {
		label: "Online",
		icon: <Link />,
	},
}

function getInitialData(
	searchParams: URLSearchParams,
	qrs: QRItem[]
): WizardChargeData {
	const chargeType = searchParams.get("type") as ChargeType | undefined
	const qrId = searchParams.get("qr_id")
	const paymentOptions = searchParams.get("paymentOptions") as
		| PaymentOption
		| undefined
	return {
		step: chargeType ? ChargeSteps.EnterAmount : ChargeSteps.SelectType,
		loading: false,
		data: {
			type: chargeType,
			qr: qrs?.find((qr) => qr.id === qrId),
			price: undefined,
			motive: undefined,
			payment: undefined,
			payment_options: [paymentOptions] as PaymentOption[] | undefined,
		},
	}
}

const CreateChargeBody = () => {
	const [loading, setLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [qrs, setQrs] = useState<QRItem[]>([])
	const [wizardData, setWizardData] = useState<WizardChargeData>(
		getInitialData(searchParams, qrs)
	)
	const { user } = useAuth()
	const { logQrPaymentCancelled } = useAnalytics()

	useEffect(() => {
		const fetchQrs = async () => {
			setLoading(true)
			const resp = await getUserQr(user.id)
			setQrs(resp)
			setWizardData(getInitialData(searchParams, resp))
			setLoading(false)
		}
		fetchQrs()
	}, [user.id, searchParams])

	const handleCancelQRPayment = async () => {
		try {
			if (wizardData.data.type === ChargeType.QR) {
				setWizardData({
					...wizardData,
					loading: true,
					errorMessage: undefined,
				})
				const paymentId = wizardData.data.payment?.id
				const qrId = wizardData.data.qr?.id
				if (!qrId || !paymentId) return
				const qrInfo = await deleteQrActivePayment(qrId, paymentId)
				if (qrInfo && !qrInfo.active_payment) {
					logQrPaymentCancelled({
						user_id: user?.user_id,
						team_id: user?.team_id,
						qr_id: qrId,
						payment_id: paymentId,
						payment_cancelled_at: new Date().toISOString(),
					})
				}
			}
			setSearchParams({})
			setWizardData({
				step: ChargeSteps.SelectType,
				loading: false,
				data: {
					payment: undefined,
					price: undefined,
					qr: undefined,
					type: undefined,
					motive: undefined,
					payment_options: undefined,
				},
			})
		} catch (error: any) {
			setWizardData({
				...wizardData,
				loading: false,
				errorMessage: error.message,
			})
		}
	}

	const handleCreatePayment = async () => {
		setWizardData({
			...wizardData,
			loading: true,
			errorMessage: undefined,
		})
		try {
			var payment
			if (!wizardData.data.price) {
				throw new Error("Price is required")
			}
			if (!wizardData.data.payment_options) {
				console.log(wizardData.data.payment_options, "payment_options")
				throw new Error("Payment options are required")
			}
			if (wizardData.data.type === ChargeType.QR) {
				if (!wizardData.data.qr) throw new Error("QR is required")
				else {
					const res = await createQrPayment(
						wizardData.data.qr.id,
						parseFloat(wizardData.data.price!.amount.toString()),
						wizardData.data.price!.currency,
						user.id,
						wizardData.data.motive ?? "",
						user.team_id,
						wizardData.data.payment_options
					)
					const paymentRes = (await getPayment(
						res.active_payment.id
					)) as unknown
					payment = paymentRes as Charge
				}
			} else {
				payment = await createPayment(
					parseFloat(wizardData.data.price!.amount.toString()),
					wizardData.data.price!.currency,
					user.id,
					wizardData.data.motive ?? "",
					user.team_id,
					wizardData.data.payment_options
				)
			}
			setWizardData({
				...wizardData,
				loading: false,
				data: {
					...wizardData.data,
					payment: payment,
				},
				step: ChargeSteps.ShareCharge,
			})
		} catch (err: any) {
			var errorMsg
			if (
				err.response &&
				err.response.data &&
				err.response.data.message === "QR.already.has.active.payment"
			) {
				//TODO: Ver que hacemos en este caso
				errorMsg = "El QR ya tiene un pago activo"
			}
			//TODO: mejorar mensajes de error
			setWizardData({
				...wizardData,
				loading: false,
				errorMessage: errorMsg ?? err.message,
			})
		}
	}

	if (loading) {
		return (
			<CenteredBody>
				<div className="flex flex-col gap-betweenTexts">
					<Skeleton className="h-6 w-[340px]" />
					<Skeleton className="h-5 w-[560px]" />
				</div>
				<div className="flex flex-row gap-betweenCards">
					<Skeleton className="h-20 w-full" />
					<Skeleton className="h-20 w-full" />
				</div>
			</CenteredBody>
		)
	} else {
		return (
			<ChargeWizardBody
				chargeTypes={
					!qrs || qrs.length <= 0
						? {
								...chargeTypes,
								[ChargeType.QR]: {
									label: "QR en caja",
									disabled: true,
									icon: <Store />,
								},
						  }
						: chargeTypes
				}
				wizardData={wizardData}
				onDataChanged={setWizardData}
				qrs={qrs}
				handleCreatePayment={handleCreatePayment}
				setSearchParams={setSearchParams}
				handleCancelQRPayment={handleCancelQRPayment}
			/>
		)
	}
}

export default CreateChargeBody
