import { useEffect } from "react"
import { useAuth } from "../../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import { useGlobalStore } from "../../../hooks/useGlobalStore"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import KycVerificationAnimation from "../../../components/ui/animations/kyc-verification-animation"
import { Section } from "../../../components/ui/section"
import MetamapButton from "../payment-methods/MetamapButton"
import { OnboardingPersonalWizardData } from "./onboard-personal-wizard-types.ts"

export default function FacialVerification({
	setWizardData,
	wizardData,
}: {
	setWizardData: (data: OnboardingPersonalWizardData) => void
	wizardData: OnboardingPersonalWizardData
}) {
	const { kyc } = useAuth()
	const navigate = useNavigate()
	const { setGlobalStore, globalStore } = useGlobalStore()
	// Use the custom hook


	useEffect(() => {
		if (
			kyc.data?.kyc_status === "metamap" ||
			kyc.data?.kyc_status === "approved"
		) {
			if (globalStore.deepLink) {
				navigate(globalStore.deepLink, { replace: true })
				setGlobalStore((prev) => ({
					...prev,
					deepLink: undefined,
				}))
			} else {
				navigate("/onboarding/payment-methods")
			}
		}
	}, [kyc.data?.kyc_status])

	return (
		<FeatureLayout
			coverImage={<KycVerificationAnimation />}
			sectionHeaderProps={{
				title: "Verificar identidad",
				subtitle: "Solo queda verificar tus datos.",
			}}
		>
			{kyc.data?.metamapButtonSettings && (
				<Section footer="Se te redireccionará para que puedas realizar la verificación">
					<MetamapButton
						clientId={kyc.data.metamapButtonSettings.clientId}
						flowId={kyc.data.metamapButtonSettings.flowId}
						metadata={kyc.data.metamapButtonSettings.metadata}
					/>
				</Section>
			)}
		</FeatureLayout>
	)
}
