import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import ArsCover from "../../settings/payment-methods/PaymentsCover"
import { Trash2 } from "lucide-react"
import PluginsSection from "./PluginSection"
import { SettingsSectionItem } from "../../settings/items"
import { StoreType } from "../../../services/users"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext"
import { Section } from "../../../components/ui/section"
import { Option } from "../../../components/ui/options/option"
import { Button } from "../../../components/ui/button"
import BetaSnack from "../../../components/ui/beta-snack"
import { redirectToEndOfOnboarding } from "../../../lib/utils"

export interface Integration {
	title: string
	icon: React.ReactNode
}

const TransferBody = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const navigate = useNavigate()
	const { stores, user } = useAuth()
	const [isInstalled, setIsInstalled] = useState(false)

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_CVU_APP_ID === undefined) {
				return
			}

			const isTiendanubeStore = stores.some(
				(store: any) =>
					store.store_type === StoreType.TIENDANUBE &&
					store.store_apps.find(
						(app: any) =>
							app.app_id === process.env.REACT_APP_TIENDANUBE_CVU_APP_ID
					)
			)

			if (isTiendanubeStore) {
				setIsInstalled(true)
			}
		}
	}, [stores])

	return (
		<CenteredBody>
			<FeatureLayout
				coverImage={<ArsCover />}
				sectionHeaderProps={{
					title: "Transferencias automáticas",
					subtitle:
						"Aceptar transferencias nunca fue mas fácil. Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y conciliar las transferencias automáticamente.",
				}}
			>
				{isInstalled ? (
					<>
						<Section
							title="Integraciones activas"
							footer="Para agregar nueva integraciones. Prueba desvinculando la activa. "
						>
							<Option
								title="Tiendanube + Talo Pay"
								description="Activa"
								icon={
									<img
										src="/dashboard/plugins/tiendanube.svg"
										className="size-full"
									/>
								}
								leading="div"
								div={<Trash2 className="size-icon"></Trash2>}
								iconInside={false}
								className="bg-background description:text-text-success"
							/>
						</Section>
						<Button
							onClick={() => {
								redirectToEndOfOnboarding(navigate, user)
							}}
						>
							Finalizar
						</Button>
					</>
				) : (
					<PluginsSection subSections={subSections} />
				)}
			</FeatureLayout>
			<BetaSnack />
		</CenteredBody>
	)
}

export default TransferBody
