import { Price, TransferQuote } from "@crypto-payment-gateway/models"
import { Quote } from "../../../pages/checkout/id"
import BodyItem from "../../modals/payment-modal/body/BodyItem"
import { formatAmountAndCurrency } from "../../modals/withdraw-confirmation/WithdrawConfirmationModal"
import Col from "../../ui/layouts/column"

const TransferPaymentCheckout = ({
	quotes,
	pendingHandler,
	price,
	userInfo,
}: {
	quotes: Quote[]
	pendingHandler: () => void
	price: Price
	userInfo: any
}) => {
	const quote = quotes.find(
		(q: Quote) => q.currency === price.currency
	) as TransferQuote
	return (
		<Col>
			<BodyItem
				name="CVU"
				value={(quote!.address as string) ?? ""}
				overflow
				copy
			/>
			<BodyItem name="Alias" value={quote!.alias} copy />
			<BodyItem
				name="Monto total"
				value={formatAmountAndCurrency(quote.amount, quote.currency, false)}
			/>
		</Col>
	)
}

export default TransferPaymentCheckout
