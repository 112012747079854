const Display1 = (props: any) => {
	const { className } = props
	return <p className={`text-d1 font-sans ${className}`}>{props.children}</p>
}
const Display2 = (props: any) => {
	const { className } = props
	return <p className={`text-d2 font-sans ${className}`}>{props.children}</p>
}
const Heading1 = (props: any) => {
	const { className } = props
	return <p className={`text-h1 font-sans ${className}`}>{props.children}</p>
}
const Heading2 = (props: any) => {
	const { className } = props
	return <p className={`text-h2 font-sans ${className}`}>{props.children}</p>
}
const Heading3 = (props: any) => {
	const { className } = props
	return <p className={`text-h3 font-sans ${className}`}>{props.children}</p>
}
const Heading4 = (props: any) => {
	const { className } = props
	return <p className={`text-h4 font-sans ${className}`}>{props.children}</p>
}
const Heading5 = (props: any) => {
	const { className } = props
	return <p className={`text-h5 font-sans ${className}`}>{props.children}</p>
}
const Heading6 = (props: any) => {
	const { className } = props
	return <p className={`text-h6 font-sans ${className}`}>{props.children}</p>
}
const Body1 = (props: any) => {
	const { className } = props
	return <p className={`text-b1 font-sans ${className}`}>{props.children}</p>
}
const Label1 = (props: any) => {
	const { className } = props
	return <p className={`text-l1 font-sans ${className}`}>{props.children}</p>
}
export {
	Display1,
	Display2,
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	Heading6,
	Body1,
	Label1,
}
