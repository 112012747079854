import { useEffect, useState } from "react"
import { cn } from "../../lib/utils"

const Timer = (props: any) => {
	const { initialMinutes, initialSeconds, callback, className } = props
	const [minutes, setMinutes] = useState(initialMinutes)
	const [seconds, setSeconds] = useState(initialSeconds)
	const [displayString, setDisplayString] = useState("")

	useEffect(() => {
		let myInterval = setInterval(() => {
			async function countdown() {
				if (seconds > 0) {
					setSeconds(seconds - 1)
				}
				if (seconds === 0) {
					if (minutes === 0) {
						clearInterval(myInterval)
					} else {
						setMinutes(minutes - 1)
						setSeconds(59)
					}
				}
				displayCountdown()
			}
			//console.log(minutes, seconds)
			if (seconds < 0 || minutes < 0) {
				setDisplayString("Expirado")
			} else {
				countdown()
			}
		}, 1000)
		return () => {
			clearInterval(myInterval)
		}
	})

	useEffect(() => {
		setMinutes(initialMinutes)
		setSeconds(initialSeconds)
	}, [initialMinutes, initialSeconds])

	function displayCountdown() {
		//console.log("DISPLAYING COUNTDOWN", minutes, seconds);
		if (minutes > 60) {
			if (minutes > 60 * 24) {
				// Expiration is in more than 1 day
				setDisplayString(`${Math.floor(minutes / 24 / 60)} d`)
			} else {
				// Expiration is in less than 1 day, display hours
				setDisplayString(`${Math.floor(minutes / 60)} hs`)
			}
		} else {
			// Expiration is in less than 1 hour, display minutes and seconds
			if (minutes === 0 && seconds === 0) {
				if (callback) {
					callback()
				}
				setDisplayString("Expirado")
			} else {
				setDisplayString(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`)
			}
		}
	}
	return (
		<div className={cn("text-text-interactive font-bold", className)}>
			{displayString}
		</div>
	)
}

export default Timer
